const SHARED_INFORMATIONAL_CONSTANTS = {
    'modify-hub': {
        redirect: false,
        getUrl: (reservationId) => `modify/reservation/${reservationId}`
    },
    errorCodes: {
        systemError: 'DCL_SM_40005',
        guestError: 'DCL_SM_40003'
    }
};

export { SHARED_INFORMATIONAL_CONSTANTS };
