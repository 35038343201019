<!--
    IMPORTANT: To make mat-select works with ng-template material items should be present where they are declared,
    not where they are used/rendered, that is why templates are defined inside mat-select element
-->
<div class="dcl-extended-select">
    <mat-select panelClass="dcl-extended-select--mat-mdc-select"
        aria-label="{{ selectArialLabel }}"
        [formControl]="selectCtrl"
        [disableOptionCentering]="true"
        (selectionChange)="onSelectionChange($any($event))">
        <mat-select-trigger>
            <div class="{{ getType(selectCtrl.value) }}"
                *ngIf="isPlaceholderVisible"
                [ngClass]="{ 'empty-option': !selectCtrl.value.value }">
                <ng-container
                    [ngTemplateOutlet]="getTemplateRef(selectCtrl.value, templatesRefTypes.placeholder)"
                    [ngTemplateOutletContext]="{ $implicit: selectCtrl.value }">
                </ng-container>
            </div>
        </mat-select-trigger>

        <ng-container *ngFor="let option of data; index as idx">
            <ng-container
                [ngTemplateOutlet]="getTemplateRef(option)"
                [ngTemplateOutletContext]="{ $implicit: option }">
            </ng-container>
        </ng-container>

        <!-- Item theme Group --------------------------------- -->
        <ng-template #groupTemplate let-option>
            <mat-optgroup
                [label]="option?.label | translate"
                [disabled]="option?.disabled"
                [ngClass]="{
                    'hidden-option': option?.hide || !option?.options?.length
                }">
                <ng-container *ngFor="let item of option.options; index as idx">
                    <ng-container
                        [ngTemplateOutlet]="getTemplateRef(item)"
                        [ngTemplateOutletContext]="{ $implicit: item }">
                    </ng-container>
                </ng-container>
            </mat-optgroup>
        </ng-template>

        <!-- Item theme Avatar -------------------------------- -->
        <ng-template #avatarTemplate let-option>
            <mat-option class="avatar"
                [value]="option"
                [ngClass]="{
                    'default-image': !option.avatar || option.avatar && !option.avatar.url,
                    'hidden-option': option.hide
                }">
                <dcl-avatar-header class="avatar-header-option"
                    [data]="option"
                    [description]="option.description"
                    [textKeyAge]="option.textKeyAge"
                    [textKeyInfant]="option.textKeyInfant"
                    [useInfantCopy]="option.useInfantCopy">
                </dcl-avatar-header>
            </mat-option>
        </ng-template>

        <!-- Item theme Link -------------------------------- -->
        <ng-template #linkTemplate let-option>
            <mat-option class="link"
                [ngClass]="{'with-icon': option?.icon}"
                [value]="option">
                <dcl-link-button class="link-item"
                    [disabled]="option?.disabled"
                    [icon]="option?.icon"
                    [label]="option?.label">
                </dcl-link-button>
            </mat-option>
        </ng-template>

        <!-- Item theme Default ----------------------------- -->
        <ng-template #defaultTemplate let-option>
            <mat-option class="default" [value]="option">
                {{ option?.label | translate }}
            </mat-option>
        </ng-template>

        <!-- Placeholders Items ----------------------------- -->
        <ng-template #avatarPlaceholder let-option>
            <dcl-avatar-header class="avatar-header-placeholder"
                [data]="option"
                [description]="option.description"
                [textKeyAge]="option.textKeyAge"
                [textKeyInfant]="option.textKeyInfant"
                [useInfantCopy]="option.useInfantCopy">
            </dcl-avatar-header>
        </ng-template>

        <ng-template #linkPlaceholder let-option>
            <dcl-link-button class="link-item"
                [ngClass]="{'with-icon': option?.icon}"
                [disabled]="option?.disabled"
                [icon]="option?.icon"
                [label]="option?.label">
            </dcl-link-button>
        </ng-template>

        <ng-template #defaultPlaceholder let-option>
            <div class="default-placeholder">
                {{ option?.label | translate }}
            </div>
        </ng-template>
    </mat-select>
</div>
