<div
    class="quick-quote-container"
    [ngClass]="{'luxlite-theme': useLuxliteTheme}"
    dclClickOutside
    (clickOutside)="closeWrapper($any($event))">
    <dcl-quick-quote-bar
        [disableViewCruisesButton]="disableViewCruisesButton"
        [filterOptions]="filterData"
        [hideViewCruisesButton]="hideViewCruisesButton"
        [isMobile]="isMobile"
        [isOpen]="isOpen"
        (onApplyFilters)="onApplyFilters()"
        (onFilterSelected)="openFilter($event)">
    </dcl-quick-quote-bar>
    <div class="mobile" *ngIf="isModalOpen">
        <dcl-quick-quote-modal
            [isDclAdvancedBooking]="isDclAdvancedBooking"
            [isDclAdvancedBookingPhase2]="isDclAdvancedBookingPhase2"
            [isDclAdvancedBookingPhase3]="isDclAdvancedBookingPhase3"
            [isDclShipAdaPhase2]="isDclShipAdaPhase2"
            [filtersData]="filterData"
            [filterDataSubGroup]="filterDataSubGroup"
            [useLuxliteTheme]="useLuxliteTheme"
            (onApplyFilters)="onApplyFilters($event)"
            (onClearFilters)="onClearFilters()"
            (onCloseModal)="onCloseModal($event)"
            (advanceBookingClickEvent)="advanceBookingBannerClickEvent.emit()"
            [open]="isModalOpen">
        </dcl-quick-quote-modal>
    </div>
    <div class="desktop">
        <dcl-quick-quote-filter-wrapper
            *ngIf="isOpen"
            (onApplyFilters)="onApplyFilters($event)"
            (newItinerariesClickEvent)="onNewItinerariesClickEvent($event)"
            (advanceBookingClickEvent)="advanceBookingBannerClickEvent.emit()"
            [emitNewItinerariesClick]="emitNewItinerariesClick"
            [isDclAdvancedBooking]="isDclAdvancedBooking"
            [isDclAdvancedBookingPhase2]="isDclAdvancedBookingPhase2"
            [isDclAdvancedBookingPhase3]="isDclAdvancedBookingPhase3"
            [isDclShipAdaPhase2]="isDclShipAdaPhase2"
            [filterData]="filterData[filterSelectedIndex]"
            [filterDataSubGroup]="filterDataSubGroup"
            [showClearBtn]="constants.filters['travel-party'].id !== filterData[filterSelectedIndex]?.type">
        </dcl-quick-quote-filter-wrapper>
    </div>
</div>
