<div class="dcl-type-head">
    <div>
        <input
            #portTextField
            class="text-field"
            type="text"
            (keyup)="onSearchType($any($event.target).value)"
            [formControl]="filter"
            [placeholder]="placeholder"
            [pattern]="pattern"
            (focus)="openList()"
            (blur)="closeList()"
            [ngClass]="{
                'open': open && filter.value.length >= minLength,
                'filled': filter.value.length,
                'invalid' : !filter.valid && filter.touched
            }">
        <button (click)="iconOpenList()" class="arrow-down-icon di di-norgie-opened" aria-hidden="true"></button>
        <span *ngIf="movePlaceholder || filter.value.length" class="type-head-placeholder">{{placeholder}}</span>
    </div>
    <div
        class="options-container"
        [ngClass]="{
            'empty-container': items.length < 1 && filter.value.length < minLength
        }"
        [hidden]="!open">
        <ol>
            <li class="type-head-option type-head-alert" *ngIf="items.length < 1 && filter.value.length > minLength">
                {{ 'privateToursForm.errors.typeNoMatch' | translate }} {{ filter.value }}
            </li>
            <li class="type-head-option" (click)="selectItem(item)" *ngFor="let item of items"
                [innerHTML]="item | boldValue: filter.value" [ngClass]="{'selected': filter.value === item}">
            </li>
        </ol>
    </div>
</div>
