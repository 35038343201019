import { CurrencyPipe } from '@angular/common';

import { BUILD_CURRENCY_FORMAT_CONSTANTS as constants} from './build-currency-format.constants';

/**
 * Builds appropriate format for displaying a currency value
 * @param value Value to be formatted
 * @param thousandSeparator Symbol used to separate thousand units
 * @param decimalSeparator Symbol used to separate decimal units
 * @param currencySymbol
 * @param hideDecimals hide/display decimals.
 */
export function buildCurrencyFormat(
    value: number,
    thousandSeparator: string,
    decimalSeparator: string,
    currencySymbol: string,
    hideDecimals = false
): string {
    const currency = new CurrencyPipe(constants.DEFAULT_LOCALE);
    const tempRegExp = new RegExp(constants.TEMP_STRING, 'g');

    return currency
        .transform(
            value,
            currencySymbol,
            'symbol',
            `1.${hideDecimals ? constants.NO_DIGITS : constants.TWO_DIGITS_INFO}`)
        .replace(/,/g, constants.TEMP_STRING)
        .replace('.', decimalSeparator)
        .replace(tempRegExp, thousandSeparator);
}
