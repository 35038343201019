import { GenesysChatConfig } from '../../interfaces';
import { GenesysChatService } from '../genesys-chat/genesys-chat.service';
import { WindowRef } from '../../../window-ref/window-ref.service';

/**
 * Genesys webcaht setup
 * @param genesysChatConfig genesys chat configuration
 * @param windowRef windowRef service
 * @return GenesysChatService
 */
export function GenesysChatFactory(genesysChatConfig: GenesysChatConfig, windowRef: WindowRef): GenesysChatService {
    return new GenesysChatService(genesysChatConfig, windowRef);
}
