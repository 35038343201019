import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';

import { AbsTrimmerPipe } from '../abs-trimmer/abs-trimmer.pipe';
import { REGEX_ABSOLUTE_PATH } from '../abs-trimmer/abs-trimmer.constants';
import { WindowRef } from '../window-ref/window-ref.service';

@Injectable({
    providedIn: 'root'
})
export class NativeBridgeService {
    private nativeBridge;
    private device;
    private conversationId;
    private errorMessageSent;
    private readyMessageSent;
    private simulatesEmbedded;

    constructor(
        private windowRef: WindowRef,
        private cookieService: CookieService,
        private logger: LoggerService,
        private absTrimmer: AbsTrimmerPipe
    ) {
        if (this.windowRef.nativeWindow['disney']) {
            this.nativeBridge = this.windowRef.nativeWindow['disney'].nativeBridgeFactory.createNativeBridge();
            this.device = this.windowRef.nativeWindow['disney'].device;
        }

        this.conversationId = this.cookieService.get('Conversation_UUID');
        this.errorMessageSent = false;
        this.readyMessageSent = false;
        this.simulatesEmbedded = this.cookieService.get('simulatesEmbedded');
    }

    /**
     * @description access the boolean embedded defined in the variable 'device'
     * which allows us to know if the page is embedded.
     * @returns {boolean} true or false if the page is embedded in a webview
     */
    isEmbedded(): boolean {
        return !!this.simulatesEmbedded || (this.device && this.device.embedded);
    }

    /**
     * @description Calls the native bridge to close the app, and add a log for us to
     * debug or test on web.
     */
    sendCloseMessage(): void {
        this.logger.log('Message to close the app was sent.');

        if (this.nativeBridge) {
            this.nativeBridge.sendCloseMessage();
        }
    }

    /**
     * @description Calls the native bridge to notify the native app when web is already loaded
     */
    sendReadyMessage(): void {
        // Flag to validate that sendReadyMessage is called only once
        if (!this.readyMessageSent) {
            this.logger.log('Message to notify when web is already loaded.');
            this.readyMessageSent = true;

            if (this.nativeBridge) {
                this.nativeBridge.sendReadyMessage();
            }
        }
    }

    /**
     * @description calls the native bridge method to send a message to native
     * when there is an error on the web page.
     * @param {string} webFlowId - The id for Web Flow Type as it is defined for native bridge
     * @param {string} errorCode - The error code returned for the service
     * @param {string} errorMessageTitle - The title for the message shown on native
     * @param {string} errorMessage - The error message shown on native
     */
    sendErrorMessage(webFlowId, errorCode, errorMessageTitle, errorMessage): void {
        if (!this.errorMessageSent) {
            const data = {
                conversationId: this.conversationId,
                correlationId: this.generateCorrelationUUID(),
                webFlowId,
                success: null,
                errors: [
                    {
                        errorCode: errorCode.toString(),
                        errorType: 'FATAL_ERROR',
                        messageTitle: errorMessageTitle,
                        message: errorMessage
                    }
                ]
            };

            this.logger.log('Error Message Sent with this data: ', data);
            this.errorMessageSent = true;

            if (this.nativeBridge) {
                this.nativeBridge.sendErrorMessage(data);
            }
        }
    }

    /**
     * @description Redirect to external links using mobile native browsers
     * @param {string} url string that contains the url to be redirected
     * @param {boolean} isDisneyUrl if the url needs to be trimmed in order to point the correct environment
     */
    sendExternalLink(url, isDisneyUrl?): void {
        const urlObj = {
            url
        };

        if (isDisneyUrl) {
            urlObj.url = this.absTrimmer.transform(url);
        }

        this.logger.log('Opening external link: ', urlObj);
        this.sendCustomMessage('DCLAtHomeHybridPlugin.sendExternalLink', urlObj);
    }

    /**
     * @description calls the native bridge method to send a message to native
     * when modification has ended successfully.
     * @param {string} webFlowId - The id for Web Flow Type as it is defined for native bridge
     * @param {string} successMessage - The error message shown on native
     * @param {string} type - The flow type
     */
    sendSuccessMessage(webFlowId, successMessage, type): void {
        const data = {
            conversationId: this.conversationId,
            correlationId: this.generateCorrelationUUID(),
            webFlowId: webFlowId,
            success: {
                message: successMessage,
                type
            },
            errors: null
        };

        this.logger.log('Success Message Sent with this data: ', data);
        this.sendCustomMessage('DCLAtHomeHybridPlugin.sendSuccessMessage', data);
    }

    /**
     * @description if the page is embedded, it checks swid and pep_jwt_token in the other case, it returns true
     * @return {boolean}
     */
    isSessionValid(): boolean {
        let isValid = true;

        if (this.isEmbedded()) {
            if (!this.cookieService.get('SWID') || !this.cookieService.get('pep_jwt_token')) {
                this.logger.log('Missing swid/pep_jwt_token');
                isValid = false;
            }
        }

        return isValid;
    }

    /**
     * @description replace default links behaviour in order to call sendExternalLink function instead of open link
     * @param {string} selector a DOM selector
     */
    setNativeLinks(selector): void {
        let isDisneyUrl = false;
        let currentLink;
        let currentLinkHref;
        const links = Array.from(document.querySelectorAll(`${selector} a[href]`));

        if (links && links.length > 0) {
            links.forEach(link => {
                currentLink = link as HTMLElement;
                currentLinkHref = currentLink.getAttribute('href');
                isDisneyUrl = this.isDisneyUrl(currentLinkHref);
                currentLink['url'] = currentLinkHref;
                currentLink.removeAttribute('href');

                currentLink.onclick = (ev) => {
                    this.sendExternalLink(ev.target['url'], isDisneyUrl);
                };
            });
        }
    }

    /**
     * @description implements sendCustomMessage from native bridge library
     * @param {string} message to send to native app
     * @param {Object} data response to be sent to native app
     */
    sendCustomMessage(message, data): void {
        if (this.nativeBridge) {
            this.nativeBridge.sendCustomMessage(message, data);
        }
    }

    /**
     * @description check if there are changes in dom and sets the native links
     */
    editLinksToNative(selector): void {
        let links;
        const target = document.querySelector(selector);
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                links = target.querySelectorAll(`a[href]`);

                if (links && links.length > 0) {
                    this.setNativeLinks(selector);
                    observer.disconnect();
                }
            });

            observer.disconnect();
        });
        const config = { childList: true };

        observer.observe(target, config);
    }

    /**
     * @description Validates if is a disney url
     * @param {string} url
     * @returns {boolean}
     */
    private isDisneyUrl(url: string): boolean {
        const regex = new RegExp(REGEX_ABSOLUTE_PATH);

        return !regex.test(url);
    }

    /**
     * @description Generates random UUID for the use with x-correlation-id header
     */
    private generateCorrelationUUID() {
        let date = new Date().getTime();
        let uuid;

        uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
            const random = (date + Math.random() * 16) % 16 | 0;
            date = Math.floor(date / 16);

            return (character === 'x' ? random : (random & 0x3 | 0x8)).toString(16);
        });

        return uuid;
    }
}
