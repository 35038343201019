<div class="banner"
    [ngStyle]="{backgroundImage: gradient}"
    [ngClass]="{
        'banner__top-left': position === positions.TOP_LEFT,
        'banner__top-right': position === positions.TOP_RIGHT,
        'banner__bottom-left': position === positions.BOTTOM_LEFT,
        'banner__bottom-right': position === positions.BOTTOM_RIGHT
    }">
    <img
        [src]="imageSrc"
        class="banner__icon"
        [ngClass]="{'banner__icon-hide': showPlaceholder}"
        [alt]="altText"
        (load)="hidePlaceholder()">
    <img [src]="imgPlaceholder" class="banner__icon" *ngIf="showPlaceholder">
    <span class="banner__text" [innerHTML]="bannerText"></span>
</div>
