import { Pipe, PipeTransform } from '@angular/core';
import { PRICE_SUMMARY_CONSTANTS } from '../price-summary.constants';

@Pipe({
    name: 'ageGroupValue'
})
export class AgeGroupValuePipe implements PipeTransform {

    transform(ageGroup: string, isDclInternationalDirect2: boolean) {
        if (!isDclInternationalDirect2) {
            return ageGroup;
        }

        if (ageGroup === PRICE_SUMMARY_CONSTANTS.GUEST.ADULT) {
            return PRICE_SUMMARY_CONSTANTS.ADULT_AGE_GROUP;
        }

        if (ageGroup === PRICE_SUMMARY_CONSTANTS.GUEST.CHILD) {
            return PRICE_SUMMARY_CONSTANTS.CHILD_AGE_GROUP;
        }

        if (ageGroup === PRICE_SUMMARY_CONSTANTS.GUEST.INFANT) {
            return PRICE_SUMMARY_CONSTANTS.INFANT_AGE_GROUP;
        }
    }
}
