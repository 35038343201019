import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ConfigService } from '../../../services/config/config.service';
import { TASessionTimeOutService } from '../../session-timeout/session-timeout.service';
import { TravelAgentService } from '../../travel-agent.service';
import { TRAVEL_AGENT_CONSTANTS } from '../../travel-agent.constants';

@Injectable()
export class TravelAgentV2Interceptor implements HttpInterceptor {

    constructor(private injector: Injector) { }

    /**
     * Defines if the given request is not part of the ones that don't need to refresh
     * the OAuthToken
     * @param req The intercepted HttpRequest
     * @returns boolean result of the configuration constant against the url as criteria
     */
    isNonRefreshableRequet(req: HttpRequest<Object>): boolean {
        return !!req.url.match(TRAVEL_AGENT_CONSTANTS.FILTERED_REQUESTS_TIMEOUT);
    }

    /**
     * Defines if the given request is part of the ones that need to refresh
     * the OAuthToken
     * @param req The intercepted HttpRequest
     * @param configService ConfigService instance for access to the configuration items
     * @returns boolean result of the used configuration setup of the SPA as criteria
     */
    isRefreshableRequest(req: HttpRequest<Object>, configService: ConfigService): boolean {
        return !this.isNonRefreshableRequet(req) &&
            !req.url.match(configService.getValue('filteredRequestTimeout')) &&
            !!req.url.match(configService.getValue('travelAgentFilter'));
    }

    intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
        const travelAgentService = this.injector.get(TravelAgentService);
        const configService = this.injector.get(ConfigService);
        const taSessionTimeOutService = this.injector.get(TASessionTimeOutService);
        let handler$: Observable<HttpEvent<Object>> = next.handle(req);

        if (travelAgentService.isTravelAgentLoggedIn() && this.isRefreshableRequest(req, configService)) {
            if (taSessionTimeOutService.isSessionTTLAlive) {
                if (!taSessionTimeOutService.isOAuthTokenAlive) {
                    handler$ = taSessionTimeOutService.refreshOAuthToken()
                        .pipe(
                            mergeMap(response => {

                                if (response['redirect']) {
                                    taSessionTimeOutService.redirectToLogout();
                                }

                                req = req.clone({
                                    withCredentials: true
                                });

                                return next.handle(req);
                            })
                        );
                }
                taSessionTimeOutService.restartTravelAgentSessionTime();
            } else {
                taSessionTimeOutService.redirectToLogout();
            }
        }

        return handler$;
    }

}

export const TravelAgentV2HttpInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: TravelAgentV2Interceptor,
    multi: true
};
