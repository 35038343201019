import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { AnimationBuilder, AnimationPlayer } from '@angular/animations';

import { CAROUSEL_HERO_IMAGE_CONSTANTS } from './carousel-hero-image.constants';
import { CarouselMedia } from './interfaces/carousel-media.interface';
import { ManageIntervalTime } from '../utils/manage-interval-time/manage-interval-time-utils';
import { progressBarStatus, imagesMotion } from './carousel-hero-image.animation';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-carousel-hero-image',
    templateUrl: './carousel-hero-image.component.html',
    styleUrls: ['./carousel-hero-image.component.scss']
})
export class CarouselHeroImageComponent implements OnChanges, OnInit, OnDestroy {
    @Input() activeTransition = true;
    @Input() controlsBottomSpacing: string;
    @Input() hasPlaceholder: boolean = true;
    @Input() images: CarouselMedia[];
    @Input() imagesMotionDuration: number;
    @Input() progressBarDuration: number;
    @Input() title: string;
    @Input() titleSize: string;
    @Input() titleLineHeight: string;
    @Input() showTitle = false;
    @Input() customImgPlaceholder: string;
    @Output() carouselLoaded = new EventEmitter<null>();
    @ViewChild('carouselItemsWrapper', { static: false } as ViewChildProperties) carouselItemsWrapperRef: ElementRef;
    @ViewChild('carouselTitle', { static: false } as ViewChildProperties) carouselTitleRef: ElementRef;
    @ViewChild('progressBarControl', { static: false } as ViewChildProperties) progressBarControlRef: ElementRef;
    @ViewChildren('carouselImages') carouselImagesRef: QueryList<ElementRef>;

    constants = CAROUSEL_HERO_IMAGE_CONSTANTS;
    imageIndex: number;
    isPause: boolean;
    manageTime: ManageIntervalTime;
    imagesAnimation: AnimationPlayer[];
    progressBarAnimation: AnimationPlayer;
    showControls = false;

    constructor(
        private animationBuilder: AnimationBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        this.manageTime = new ManageIntervalTime(
            () => this.imageRotation(),
            this.constants.animations.progressBarDuration
        );
        this.initDefaultValues();
        this.imageIndex = 0;
        this.manageTime.setTime(this.progressBarDuration);
    }

    ngOnChanges(): void {
        if (this.carouselItemsWrapperRef) {
            this.renderer.setStyle(this.carouselItemsWrapperRef.nativeElement, 'bottom', this.controlsBottomSpacing);
        }

        this.setTitleStyles();

        // pause or resume transition image depend of activeTransition state
        if (this.activeTransition && this.progressBarAnimation) {
            this.resumeTransition();
        } else if (this.progressBarAnimation) {
            this.pauseTransition();
        }
    }

    ngOnDestroy(): void {
        this.manageTime.stop();
    }

    /**
     * Initialize the default Input's values
     */
    initDefaultValues(): void {
        this.controlsBottomSpacing = this.controlsBottomSpacing ?
            this.controlsBottomSpacing :
            this.constants.defaultControlsPosition;
        this.imagesMotionDuration = this.imagesMotionDuration ?
            this.imagesMotionDuration :
            this.constants.animations.imagesMotionDuration;
        this.progressBarDuration = this.progressBarDuration ?
            this.progressBarDuration :
            this.constants.animations.progressBarDuration;
        this.titleSize = this.titleSize ? this.titleSize : this.constants.defaultTitleSize;
        this.titleLineHeight = this.titleLineHeight ? this.titleLineHeight : this.constants.defaultTitleLineHeight;
    }

    /**
     * Sets styles for carousel title
     */
    setTitleStyles(): void {
        if (this.carouselTitleRef && this.showTitle) {
            this.renderer.setStyle(this.carouselTitleRef.nativeElement, 'font-size', this.titleSize);
            this.renderer.setStyle(this.carouselTitleRef.nativeElement, 'line-height', this.titleLineHeight);
        }
    }

    /**
     * Updates imageIndex with the index passed to the function.
     * @param index images array's index that reflects the image that want to be selected
     */
    selectImageByIndex(index: number): void {
        if (this.imageIndex !== index) {
            this.imageIndex = index;
            this.initProgressBarAnimation();
            this.initImagesAnimation();
            this.manageTime.play();
        }
        if (this.isPause) {
            this.resumeTransition();
        }
    }

    /**
     * Updates imageIndex with the index passed and pause transition
     * @param index images array's index that reflects the image that want to be selected
     */
    selectAndPauseImageByIndex(index: number): void {
        this.selectImageByIndex(index);
        this.pauseTransition();
    }

    /**
     * Increase the imageIndex property and update the view scrolling to the corresponding image
     */
    imageRotation(): void {
        if (this.imageIndex < (this.images.length - 1)) {
            this.imageIndex++;
        } else {
            this.imageIndex = 0;
        }

        this.initProgressBarAnimation();
        this.initImagesAnimation();
    }

    /**
     * Initialize the progress bar animation for the current image that is being shown.
     */
    initProgressBarAnimation(): void {
        if (this.progressBarAnimation) {
            this.progressBarAnimation.destroy();
        }

        this.changeDetectorRef.detectChanges();
        if (this.progressBarControlRef) {
            this.progressBarAnimation = this.animationBuilder
                .build(progressBarStatus(this.progressBarDuration))
                .create(this.progressBarControlRef.nativeElement);
            this.progressBarAnimation.play();
        }
    }

    /**
     * Pause the transition image
     */
    pauseTransition(): void {
        if (this.progressBarAnimation) {
            this.isPause = true;
            this.progressBarAnimation.pause();
            this.manageTime.pause();
        }
    }

    /**
     * Resume the transition image
     */
    resumeTransition(): void {
        if (this.progressBarAnimation && this.isPause) {
            this.isPause = false;
            this.progressBarAnimation.play();
            this.manageTime.resume();
        }
    }

    /**
     * Initialize the carousel images animation.
     */
    initImagesAnimation(): void {
        const displacementPercentage = 100;

        this.imagesAnimation = [];

        this.carouselImagesRef.forEach((imageRef: ElementRef, index: number) => {
            this.imagesAnimation.push(this.animationBuilder
                .build(imagesMotion(this.imagesMotionDuration, this.imageIndex * displacementPercentage))
                .create(imageRef.nativeElement)
            );
            this.imagesAnimation[index].play();
        });
    }

    /**
     * Handle on first imag loaded event
     */
    onFirstImageLoaded(): void {
        this.showControls = true;
        this.carouselLoaded.emit();

        setTimeout(() => {
            this.setupControls();
        });
    }

    /**
     * Setup controls logic
     */
    setupControls(): void {
        this.renderer.setStyle(this.carouselItemsWrapperRef.nativeElement, 'bottom', this.controlsBottomSpacing);
        this.setTitleStyles();
        this.initProgressBarAnimation();
        this.initImagesAnimation();
        this.manageTime.play();
    }
}
