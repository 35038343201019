import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DatesFormatIntlModule } from '../dates-format-intl/dates-format-intl.module';
import { ReservationSelectorComponent } from './reservation-selector.component';

@NgModule({
    imports: [
        CommonModule,
        DatesFormatIntlModule,
        TranslateModule
    ],
    providers: [
        TranslateService
    ],
    declarations: [
        ReservationSelectorComponent
    ],
    exports: [
        ReservationSelectorComponent
    ]
})
export class ReservationSelectorModule {}
