import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CASTAWAY_MEMBERSHIP_CONSTANTS } from '../castaway-membership-modal.constants';
import { DCLAnalyticsService } from '../../services/analytics/analytics.service';
import { EBSBrandsToShow } from '../interfaces/ebs-config.interface';
import {
    GuestCastawayInfoRequest,
    GuestCastawayInfoRequestEBS
} from '../../interfaces/guest-castaway-info-request.interface';
import {
    GuestCastawayInfo,
    GuestCastawayInfoEBS,
    GuestCastawayRankingInfo
} from '../../interfaces/guest-castaway-info.interface';
import { ToggleService } from '../../services/toggle/toggle.service';

@Injectable()
export class CastawayMembershipService {
    baseUrl: string;
    constants = CASTAWAY_MEMBERSHIP_CONSTANTS;
    private castawayModelSubject: BehaviorSubject<boolean>;

    constructor(
        private analyticsService: DCLAnalyticsService,
        private httpClient: HttpClient,
        private toggleService: ToggleService
    ) {
        this.castawayModelSubject = new BehaviorSubject<boolean>(false);
    }

    /**
     * Set base url for rest service
     * @param url webapi base url
     */
    setBaseUrl(url: string): void {
        this.baseUrl = url;
    }

    /**
     * Retrieve guest castaway affiliation information
     * @param data guest castaway affiliation data
     * @returns Observable for guest castaway affiliation request
     */
    getGuestAffiliation(data: GuestCastawayInfoRequest): Observable<GuestCastawayInfo> {
        return this.httpClient.get<GuestCastawayInfo>(
            `${this.baseUrl}${this.constants.ENDPOINTS.GUEST_CASTAWAY_INFO}`,
            { params : new HttpParams({fromObject: { ...data }}) }
        );
    }

    /**
     * Retrieve guest castaway affiliation information with early booking
     * @param data guest castaway affiliation data
     * @returns Observable for guest castaway affiliation request
     */
    getGuestAffiliationByType(data: GuestCastawayInfoRequestEBS): Observable<GuestCastawayInfoEBS> {
        return this.httpClient.post<GuestCastawayInfoEBS>(
            `${this.baseUrl}${this.constants.ENDPOINTS.GUEST_CASTAWAY_INFO_EBS}`,
            data
        );
    }

    /**
     * Retrieve guest grants ranking
     * @param ccid guest castaway id
     * @returns Observable for guest grants
     */
    getMemberRanking(ccid: string): Observable<GuestCastawayRankingInfo> {
        const endPointPath = this.constants.ENDPOINTS.GUEST_RANKING_INFO_EBS.replace('{ccid}', ccid);

        return this.httpClient.get<GuestCastawayRankingInfo>(`${this.baseUrl}${endPointPath}`);
    }

    /**
     * Get the toggle value for EBS
     * @returns boolean if toggle is enabled
     */
    isEarlyBooking(): boolean {
        return this.toggleService.getToggle('dclEarlyBookingSegmentation');
    }

    /**
     * Get the toggle value for EBS brands to know which should be visible
     * @returns Object of booleans indicating the brands that should be shown
     */
    getEBSBrandsToShow(): EBSBrandsToShow {
        return {
            castawayClub: true,
            disneyVacationClub: this.toggleService.getToggle('dclEBSDisneyVacationClub'),
            club33: this.toggleService.getToggle('dclEBSClub33'),
            goldenOak: this.toggleService.getToggle('dclEBSGoldenOak')
        };
    }

    /**
     * Send value when analytics model is set
     * @param done Analytics props are already set
     */
    setCastawayModel(done: boolean): void {
        this.castawayModelSubject.next(done);
    }

    /**
     * Gets subject to know if analytics model is set
     * @returns analytics model subject
     */
    getCastawaySubject(): BehaviorSubject<boolean> {
        return this.castawayModelSubject;
    }

    /**
     * Trigger analytics trackLink event
     * @param event type of the event triggered
     */
    trackLink(event: string, value?: string): void {
        const analyticsTrackLink = this.getLinkId(event, value);

        if (event === this.constants.ANALYTICS.LAUNCH) {
            this.getCastawaySubject()
                .subscribe((isModelSet: boolean) => {
                    if (isModelSet) {
                        setTimeout(() => {
                            this.analyticsService.trackLink(analyticsTrackLink);
                        }, this.constants.ANALYTICS.LAUNCH_TRACKLINK_DELAY);
                    }
                });
        } else {
            this.analyticsService.trackLink(analyticsTrackLink);
        }
    }

    /**
     * Return link id for analytics
     * @param sufix analytics event
     * @param value option selected
     * @returns track link id
     */
     private getLinkId(sufix: string, value?: string): string {
        const optionSelected = value && value === this.constants.ANALYTICS.DVC_OPTION
            ? this.constants.CASTAWAY_OPTIONS.ID_TYPES[value]
            : (value ? value.charAt(0).toUpperCase() + value.substring(1) : '');

        return this.constants.ANALYTICS.PREFIX + sufix + optionSelected;
    }
}
