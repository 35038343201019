<button class="cruise-tile" [attr.aria-label]="a11yDescription">
    <div class="cruise-tile__item">
        <div class="cruise-tile__image">
            <dcl-hero-image
                class="dcl-hero-image"
                [customImgPlaceholder]="customImgPlaceholder"
                [media]="media"
                [mediaSize]="constants.TILE_IMAGE_SIZE"
                aria-hidden="true">
            </dcl-hero-image>
        </div>
        <div class="cruise-tile__description-container">
            <span class="cruise-tile__title" aria-hidden="true">{{ title }}</span>
            <p *ngIf="!isHomePage && description" class="cruise-tile__description" aria-hidden="true">{{ description }}</p>
        </div>
    </div>
</button>
