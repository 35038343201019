<!-- Custom Genesys webchat button -->
<div
    class="genesys-webchat-button"
    *ngIf="pluginLoaded"
    [ngClass]="{'webchat-button-visible': showChatButton, 'mobile-button': isMobile}">
    <button class="webchat-button" [ngClass]="{'mobile-button': isMobile}" (click)="openWebchat()">
        <span class="webchat-icon" aria-hidden="true"></span>
        <span class="button-label">{{ webchatButtonLabel }}</span>
    </button>
</div>
