import { animate, style, transition, trigger } from '@angular/animations';

const animateDuration = 100;
const checkedFadeAnimation = trigger('fadeInOut', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate(
            animateDuration,
            style({
                opacity: 1
            })
        )
    ]),
    transition(':leave', [
        animate(
            animateDuration,
            style({
                opacity: 0
            })
        )
    ])
]);

export { checkedFadeAnimation };
