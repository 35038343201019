import { Injectable } from '@angular/core';

import { AppLocaleService } from '../app-locale/app-locale.service';

@Injectable({
    providedIn: 'root'
})
export class ChatAvailabilityService {

    constructor(
        private appLocaleService: AppLocaleService,
    ) { }

    /**
     * @param langRegion (string): Allowed languages and regions configuration string
     * @returns boolean to identify if the chat can be available
     */
    isChatAvailable(langRegion: string): boolean {
        try {
            const langRegionObj = JSON.parse(langRegion);
            const userLang = this.appLocaleService.acceptLanguage().toLocaleLowerCase();
            const [lang, region] = userLang.split('-');

            // Search Lang
            return !!(langRegionObj[lang] && langRegionObj[lang].includes(region));
        } catch {
            return false;
        }
    }
}
