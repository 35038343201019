import { Component, OnInit, Input } from '@angular/core';

import { capitalize } from 'lodash';
import { HeaderReservationDetailInterface } from './interfaces/header-reservation-detail.interface';
import { IntlDateFormatResponse } from '../dates-format-intl/interfaces/intl-date-format-response.interface';
import { IntlDateConfig } from '../dates-format-intl/interfaces/intl-date-config.interface';
import { Guest } from './interfaces/guest.interface';

import { IntlDateConfigService } from '../services/intl-date-config/intl-date-config.service';

@Component({
    selector: 'dcl-header-reservation-detail',
    templateUrl: './header-reservation-detail.component.html',
    styleUrls: ['./header-reservation-detail.component.scss']
})
export class HeaderReservationDetailComponent implements OnInit {
    guestNames: string;
    intlDateConfig: IntlDateConfig;

    constructor(
        private intlDateConfigService: IntlDateConfigService
    ) { }

    @Input() guestList: Guest[] = [];
    @Input() voyage: HeaderReservationDetailInterface;
    @Input() reservationId: Number;
    @Input() isInternationalDirect?: false;
    @Input() internationalization?: IntlDateFormatResponse;

    ngOnInit() {
        this.filterNameOfGuest();
        this.setIntlDateConfig();
    }

    /**
     * Filter the names of the guest
     */
    private filterNameOfGuest(): void {
        this.guestNames = this.guestList.map((guest: Guest) => {
            return capitalize(guest.firstName);
        }).join(', ');
    }

    /**
     * Set the Intl config data used by shared pipe
     */
    private setIntlDateConfig(): void {
        const configuration = {
            sailDateFrom: this.voyage.sailing.sailDateFrom,
            sailDateTo: this.voyage.sailing.sailDateTo,
            template: this.internationalization
        };

        this.intlDateConfig = this.intlDateConfigService.getConfig(configuration);
    }
}
