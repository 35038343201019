<section
    class="reservation-details-header">
    <div class="reservation-details-header__wrapper reservation-details-header__wrapper--main">
        <i
            class="di di-dcl-our-ships"
            aria-hidden="true">
        </i>
        <div>
            <h2 class="reservation-details-header__title">
                {{ voyage.product.name }}
            </h2>
            <div class="reservation-details-header__description">
                {{
                    isInternationalDirect ?
                    (intlDateConfig | DateFormatterIntl) :
                    ([voyage.sailing.sailDateFrom, voyage.sailing.sailDateTo] | datesFormat: '-')
                }}
            </div>
            <div class="reservation-details-header__description">
                <span translate>reservationDetailsHeader.mainDescription</span> {{reservationId}}
            </div>
        </div>
    </div>
    <div class="reservation-details-header__wrapper reservation-details-header__wrapper--secondary">
        <i
            class="di di-room-upgrade"
            aria-hidden="true">
        </i>
        <div>
            <h2 class="reservation-details-header__title">
                {{voyage.stateroomInformation.stateroomName}}
            </h2>
            <div class="reservation-details-header__description">
                <div>
                    <span translate>reservationDetailsHeader.secondaryDescription</span> {{ voyage.stateroomInformation.stateroomId }}
                </div>
                <div>{{ guestNames }}</div>
            </div>
        </div>
    </div>
</section>
