import { KeyboardKeys } from './keyboard-keys';

/**
 * Fix for Edge Issue when the navigation with keyboard applies.
 * Edge doesn't recognize the CODE attribute, so it'll always show UNDEFINED
 *
 * @param event event catched while a key was pressed
 * @returns keyCode as a transformed value to get the event in every web browser
 */
const getKeyboardKey = (event: KeyboardEvent): string => {
    let keyCode: string = event.code;

    if (!event.code) {
        keyCode = event.key === KeyboardKeys.EmptySpace ? KeyboardKeys.Space : event.key;
    }

    return keyCode;
};

/**
 * Determine if a KeyboardEvent match with a specific key
 *
 * @param event the keyboarEvent
 * @param key   the expected key that we want to verify
 * @return true if the event match with the key that we expect
 */
const isKeyboardKey = (event: KeyboardEvent, key: string): boolean => {
    return key === getKeyboardKey(event);
};

export {
    getKeyboardKey,
    isKeyboardKey
};
