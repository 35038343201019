import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatesFormatIntlPipe } from './dates-format-intl.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DatesFormatIntlPipe
    ],
    exports: [
        DatesFormatIntlPipe
    ],
    providers: [
        DatesFormatIntlPipe
    ]
})
export class DatesFormatIntlModule { }
