<div class="date-birth-container"
    #dobContainer>
    <fieldset>
        <legend *ngIf="hasTitle" class="form-legend">
            <h4 class="date-birth-title"
                [ngClass]="{ 'invalid': isInvalid() }"
                translate>
                date-of-birth.title
            </h4>
            <div *ngIf="hasSubtitle" class="copy" translate>date-of-birth.subtitle</div>
        </legend>
        <div class="date-birth-info" [formGroup]="dateOfBirth">
            <div class="input-select month">
                <wdpr-single-select
                    #wdprSelectMonth
                    ngDefaultControl
                    formControlName="month"
                    class="select select-month guestSensitive"
                    [label]="translations['month-label']"
                    [customAriaLabel]="translations['month-aria-label'] | replaceString: { number: guestNumber } | requiredLabel"
                    [options]="months"
                    [invalid]="isInvalid()"
                    (focus)="dobFocusHandler()"
                    (blur)="dobBlurHandler($event)"
                    required-field="true"
                    [customErrorAriaLabel]="getErrors('month', dateOfBirth, errorMessagesKeys) | translate">
                </wdpr-single-select>
            </div>
            <div class="input-select">
                <wdpr-single-select
                    #wdprSelectDay
                    ngDefaultControl
                    formControlName="day"
                    class="select select-day guestSensitive"
                    [label]="translations['day-label']"
                    [customAriaLabel]="translations['day-aria-label'] | replaceString: { number: guestNumber } | requiredLabel"
                    [options]="days"
                    [invalid]="isInvalid()"
                    (focus)="dobFocusHandler()"
                    (blur)="dobBlurHandler($event)"
                    required-field="true"
                    [customErrorAriaLabel]="getErrors('day', dateOfBirth, errorMessagesKeys) | translate">
                </wdpr-single-select>
            </div>
            <div class="input-text-container">
                <wdpr-input
                    ngDefaultControl
                    dclInputNumber
                    [preventPeriod]="true"
                    autocorrect="off"
                    autocomplete="bday-year"
                    text="bday-year"
                    type="number"
                    [maxlength]="4"
                    formControlName="year"
                    class="input-field input-text-year guestSensitive"
                    [label]="translations['year-label']"
                    [customAriaLabel]="translations['year-aria-label'] | replaceString: { number: guestNumber } | requiredLabel"
                    no-validate-on-change="true"
                    no-validate-on-blur="true"
                    [valid]="!isInvalid()"
                    [ngClass]="{ 'invalid touched': isInvalid() }"
                    required="true"
                    (input)="changeDaysData()"
                    (focus)="dobFocusHandler()"
                    (blur)="dobBlurHandler($event)"
                    [customErrorAriaLabel]="getErrors('year', dateOfBirth, errorMessagesKeys) | translate">
                </wdpr-input>
            </div>
        </div>
        <div class="date-birth-errors">
            <dcl-error-message
                *ngIf="isInvalid()"
                [control]="dateOfBirth"
                [errorMessage]="errorMessagesKeys"
                [showSingleError]="true">
            </dcl-error-message>
        </div>
    </fieldset>
</div>
