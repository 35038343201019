<div class="dcl-whats-included-modal">
    <dcl-whats-included-modal-button *ngIf="showButton"></dcl-whats-included-modal-button>
    <dcl-modal
        docker
        block-focus
        [attr.hide-close-icon]="isEmbedded ? '' : null"
        #whatsIncludedModal
        aria-close="close"
        hide-title-body-separator
        (closedOnEscape)="onClose()"
        (closedOnBackgroundClick)="onClose()"
        (closedModalByCloseIcon)="onClose()">
        <div class="dcl-modal-body" slot="body" *ngIf="isOpened" [ngClass]="{'modal-with-baymax': isEmbedded}">
            <span class="sr-only"
                *ngIf="!isEmbedded" 
                tabindex="0"
                role="heading"
                aria-level="1"> 
                {{ header.title }} 
            </span>
            <div *ngIf="isLoading" class="spinner">
                <dcl-loading-spinner></dcl-loading-spinner>
            </div>
            <div *ngIf="isError">
                <div class="whats-include-header-container">
                    <dcl-baymax-header
                        slot="title"
                        *ngIf="isEmbedded"
                        title="whatsIncludedModal.baymaxTitle"
                        [isCloseBtn]="false"
                        [isModal]="true"
                        (iconEvent)="closeModalFromBaymax()">
                    </dcl-baymax-header>
                    <h3 slot="title" *ngIf="!isEmbedded" class="title" translate>whatsIncludedModal.errorState.title</h3>
                </div>
                <div class="error-state-msg" translate>whatsIncludedModal.errorState.errorMsg</div>
            </div>
            <div *ngIf="header && contents.length > 0">
                <dcl-tabbed-content
                    class="dcl-tabbed-content"
                    id="dcl-tabbed-content"
                    #dclTabbedContent
                    [print]="header.enablePrint"
                    [tablink]="header.tabs"
                    [callback-keys]="baseAnalyticsKeys.tabbedContentKeys"
                    (callback-key-triggered-changed)="emitAnalyticsTracking($any($event))">
                    <dcl-baymax-header
                        slot="title"
                        *ngIf="isEmbedded"
                        title="whatsIncludedModal.baymaxTitle"
                        [isCloseBtn]="false"
                        [isModal]="true"
                        (iconEvent)="closeModalFromBaymax()">
                    </dcl-baymax-header>
                    <h1 *ngIf="!isEmbedded" 
                        slot="title" 
                        class="whats-included-main-header">
                        {{ header.title }}
                    </h1>
                    <span slot="subtitle" class="subtitle">
                        {{ header.subtitle }}
                    </span>
                    <div *ngFor="let content of contents"
                        slot="data"
                        class="data-section"
                        id="section_{{ content.id }}">
                        <div class="card-block">
                            <dcl-whats-included-modal-header
                                [source]="content.img.src"
                                [image-alt]="content.img.alt"
                                [title]="content.title"
                                [message]="content.subtitle">
                            </dcl-whats-included-modal-header>
                            <hr class="separator"/>
                            <dcl-comparison-table [content]="content">
                            </dcl-comparison-table>
                        </div>
                    </div>
                </dcl-tabbed-content>
            </div>
        </div>
    </dcl-modal>
</div>
