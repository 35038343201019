import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import * as momentModule from 'moment';

import { AppLocaleService } from '../app-locale/app-locale.service';
import { buildCurrencyFormat } from '../../utils/buildCurrencyFormat/build-currency-format';
import { ConfigService } from '../config/config.service';
import { CurrencyDisclaimer } from '../../interfaces/currency-disclaimer.interface';
import { CurrencyDisclaimerData } from '../../interfaces/currency-disclaimer-data.interface';
import { CurrencyModule } from '../../interfaces/currency-module.interface';
import { DISCLAIMER_CONSTANTS } from './disclaimer.constants';
import { DisclaimerModule } from '../../interfaces/disclaimer-module.interface';
import { LocalStorageCacheHandler } from '../local-storage-cache-handler-service/local-storage.handler';

@Injectable({
    providedIn: 'root'
})
export class DisclaimerService {
    private localStorageHandler = new LocalStorageCacheHandler();
    private moment = momentModule;
    private webApiUrl: string;

    private formattedCurrencyDisclaimer: Subject<CurrencyDisclaimerData | boolean> =
        new Subject<CurrencyDisclaimerData | boolean>();

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private localeService: AppLocaleService
    ) {
        this.webApiUrl = this.configService.getValue(DISCLAIMER_CONSTANTS.webApiUrl);
    }

    /**
     * Fetch currency disclaimer data according to locale from cookie
     */
    getCurrencyDisclaimer(): Observable<CurrencyDisclaimer | HttpErrorResponse> {
        const disclaimerData = this.localStorageHandler.get<CurrencyDisclaimer>(DISCLAIMER_CONSTANTS.currencyIdModule);

        if (disclaimerData && !disclaimerData.expired && this.isSameLocale(disclaimerData.data)) {
            return of(disclaimerData.data);
        }

        const headers = new HttpHeaders().set(
            DISCLAIMER_CONSTANTS.acceptLanguageKey,
            this.localeService.content().toLowerCase().replace('_', '-')
        );

        return this.httpClient.get<CurrencyDisclaimer>(
            `${this.webApiUrl}${DISCLAIMER_CONSTANTS.currencyDisclaimerEndpoint}`,
            { headers }
        ).pipe(
            map(
                (data: CurrencyDisclaimer) => {
                    if (data) {
                        data.locale = this.localeService.content().toLowerCase().replace('_', '-');
                        this.localStorageHandler.set(
                            DISCLAIMER_CONSTANTS.currencyIdModule,
                            data,
                            this.moment().add(DISCLAIMER_CONSTANTS.maxCachedTime, 'hours').toString()
                        );
                    }

                    return data;
                }
            ),
            catchError((error: HttpErrorResponse) => {
                return of(new HttpErrorResponse(error));
            })
        );
    }

    /**
     * Sets currency disclaimer information when local currency is different
     * from the transacting currency
     */
    getFormattedCurrencyDisclaimer(): void {
        this.getCurrencyDisclaimer().subscribe(
            (data: CurrencyDisclaimer) => {
                if (data && data.modules.length >= DISCLAIMER_CONSTANTS.minModuleSize) {
                    const extraModules: Record<string, unknown> = {};
                    let disclaimerData: DisclaimerModule;
                    let currencyData: CurrencyModule;

                    data.modules.forEach((dataModule: DisclaimerModule | CurrencyModule) => {
                        if (dataModule.id === DISCLAIMER_CONSTANTS.disclaimerKey) {
                            disclaimerData = dataModule as DisclaimerModule;
                        } else if (dataModule.id === DISCLAIMER_CONSTANTS.currencyKey) {
                            currencyData = dataModule as CurrencyModule;
                        } else {
                            extraModules[dataModule.id as string] = dataModule.sections;
                        }
                    });

                    const {
                        disclaimerFullDescription,
                        disclaimerShortDescription,
                        readMore,
                        readLess,
                        usd,
                    } = disclaimerData.sections;
                    const {
                        currencyCode,
                        currencySymbol,
                        dollarCentSeparator,
                        thousandSeparator,
                        conversionRate,
                        priceCurrencyCode,
                        currencyLabel
                    } = currencyData.sections;
                    const formattedCurrencyCode = currencyCode
                        .replace(
                            DISCLAIMER_CONSTANTS.exchangeRateKey,
                            buildCurrencyFormat(
                                parseFloat(conversionRate),
                                thousandSeparator,
                                dollarCentSeparator,
                                currencySymbol
                            )
                        );

                    this.formattedCurrencyDisclaimer.next({
                        currencyExchangeExplanation: `${usd} ${formattedCurrencyCode}`,
                        disclaimerFullDescription,
                        disclaimerShortDescription,
                        readMore,
                        readLess,
                        priceCurrencyCode,
                        currencyLabel,
                        conversionRate,
                        currencyDisclaimerFormatPricing: {
                            currencySymbol,
                            dollarCentSeparator,
                            thousandSeparator
                        },
                        widgetSection: extraModules['widget']
                    } as CurrencyDisclaimerData);
                } else {
                    this.formattedCurrencyDisclaimer.next(false);
                }
            },
            (error: HttpErrorResponse) => {
                this.formattedCurrencyDisclaimer.next(false);
            }
        );
    }

    /**
     * get the observable for the formatted currency disclaimer object
     * @returns Observable of currency values
     */
    getCurrencyDisclaimerSubject(): Observable<CurrencyDisclaimerData | boolean> {
        return this.formattedCurrencyDisclaimer;
    }

    /**
     * Validates if currency disclaimer data is equal to locale cookie
     * @param CurrencyDisclaimer
     * @returns boolean
     */
     private isSameLocale(currencyDisclaimer: CurrencyDisclaimer): boolean {
        return currencyDisclaimer.locale === this.localeService.content().toLowerCase().replace('_', '-');
    }
}
