import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ToggleService } from '../toggle/toggle.service';
import { ConfigService } from '../config/config.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    dclSASRefactorToggle = false;

    constructor(
        private router: Router,
        private configService: ConfigService,
        private location: Location,
        private toggleService: ToggleService,
        private cookieService: CookieService
    ) { 
        this.dclSASRefactorToggle = this.toggleService.getToggle('dclSASRefactor');
    }

    /**
     * @description Navigate with angular route or window.location depending on entry data
     * @param flow Object with the information to redirect
     *  flow.url: url to redirect
     *  flow.redirect: if it should navigate out of the current spa
     */
    navigateByUrl(flow, scrollTop: boolean = true): void {
        var cookieName = 'sasRefactor';
        if (scrollTop) {
            window.scrollTo(0, 0);
        }

        if (flow.redirect) {
            if (this.dclSASRefactorToggle && flow.url.match('modify/stateroom')) {
                this.cookieService.set(cookieName, 'true', null, '/');
            } else if (!this.dclSASRefactorToggle &&
                this.cookieService.get(cookieName) &&
                flow.url.match('modify/stateroom')
                ) {
                this.cookieService.delete(cookieName, '/');
            }
            window.location.href = this.formatUrl(flow.url);
        } else {
            this.router.navigateByUrl(flow.url)
                .catch(() => {
                    window.location.href = this.formatUrl(flow.url);
                });
        }
    }

    /**
     * Remove all the query params from url and replace state in history
     */
    cleanQueryParamsFromUrl(): void {
        this.location.replaceState(this.location.path().replace(/\?.+/g, ''));
    }

    /**
     * Format the url before navigate to it
     * @param url String the target url
     */
    private formatUrl(url) {
        let formattedUrl = this.configService.getValue('baseUrl');

        if (!formattedUrl.endsWith('/') && !url.startsWith('/')) {
            formattedUrl += '/';
        }

        return formattedUrl + url;
    }
}
