import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AutofocusModule } from '../autofocus/autofocus.module';
import { AutofocusFirstInvalidModule } from '../autofocus-first-invalid/autofocus-first-invalid.module';
import { CastawayMembershipComponent } from './castaway-membership/castaway-membership.component';
import { CastawayMembershipModalService } from './services/castaway-membership-modal.service';
import { CastawayMembershipService } from './services/castaway-membership.service';
import { DateInputMaskModule } from '../date-input-mask/date-input-mask.module';
import { DclDatesModule } from '../dates/dates.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { LoadingContentModule } from '../loading-content/loading-content.module';
import { TravelAgentModule } from '../travel-agent/travel-agent.module';
import { WarningMessagingModule } from '../warning-messaging/warning-messaging.module';

@NgModule({
    declarations: [
        CastawayMembershipComponent
    ],
    imports: [
        AutofocusModule,
        AutofocusFirstInvalidModule,
        CommonModule,
        DateInputMaskModule,
        DclDatesModule,
        ErrorMessageModule,
        LoadingContentModule,
        ReactiveFormsModule,
        TranslateModule,
        TravelAgentModule,
        WarningMessagingModule
    ],
    providers: [
        CastawayMembershipModalService,
        CastawayMembershipService
    ],
    exports: [
        CastawayMembershipComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CastawayMembershipModule { }
