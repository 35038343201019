import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';

import { get, capitalize } from 'lodash';
import { Subject } from 'rxjs';

import { DCLAnalyticsService } from '../services/analytics/analytics.service';
import { MessageModalData } from './interfaces/message-modal.interface';

/**
 * Generic message modal component
 *
 * It has body container with:
 * - Image
 * - Title
 * - Short description
 * - trackLinks
 * Defined in modalData input.
 *
 * And two buttons:
 * - Action
 * - Cancel
 *
 * Customizable styles:
 * It has multiple customizable styles defined in:
 * src\assets\styles\base\global.scss (default values)
 */
@Component({
    selector: 'dcl-message-modal',
    templateUrl: 'message-modal.component.html',
    styleUrls: ['message-modal.component.scss']
})
export class MessageModalComponent implements OnInit, OnDestroy {
    @ViewChild('messageModal', { static: false }) modal: ElementRef;

    @Input() actionLoading: boolean;

    @Input() disableCloseOnBackground: boolean;
    @Input() hideCloseIcon: boolean;

    @Input() showSeparator: boolean;

    @Input() modalData: MessageModalData;
    @Input() showModal: Subject<boolean> = new Subject<boolean>();

    @Output() action = new EventEmitter<string>();
    @Output() cancel = new EventEmitter<null>();

    private unlistenClosedModalByCloseIcon;
    private unlistenClosedOnEscape;
    private unlistenClosedOnBackgroundClick;

    constructor(
        private analyticsService: DCLAnalyticsService,
        private render: Renderer2
    ) {}

    ngOnInit(): void {
        this.showModal.subscribe(response => {
            if (response) {
                this.openModal();
            } else {
                this.closeModal();
            }
        });

        setTimeout(() => {
            // track close events
            this.unlistenClosedModalByCloseIcon = this.render.listen(
                this.modal.nativeElement,
                'closedModalByCloseIcon',
                () => this.closeModalByAction('icon')
            );
            this.unlistenClosedOnEscape = this.render.listen(
                this.modal.nativeElement,
                'closedOnEscape',
                () => this.closeModalByAction('escape')
            );
            this.unlistenClosedOnBackgroundClick = this.render.listen(
                this.modal.nativeElement,
                'closedOnBackgroundClick',
                () => this.closeModalByAction('background')
            );
        });
    }

    ngOnDestroy() {
        if (this.unlistenClosedModalByCloseIcon || this.unlistenClosedOnEscape || this.unlistenClosedOnBackgroundClick) {
            this.unlistenClosedModalByCloseIcon();
            this.unlistenClosedOnEscape();
            this.unlistenClosedOnBackgroundClick();
        }
    }

    /**
     * Emit action modal
     */
    actionEmit(): void {
        this.trackLink('onAction');

        this.action.emit(this.modalData.actionType);
    }

    /**
     * Emits cancel action and closes the modal
     */
    cancelEmit(): void {
        this.trackLink('onCancel');

        this.cancel.emit();
        this.closeModal();
    }

    openModal(): void {
        this.trackLink('onOpen');

        this.modal.nativeElement.setAttribute('open', 'true');
    }

    closeModal(): void {
        this.trackLink('onClose');

        this.modal.nativeElement.removeAttribute('open');
    }

    /**
     * Handler event by User interaction
     *
     * # Options are:
     * - icon       (when guest click on the modal close button)
     * - escape     (when guest click on escape keyboard)
     * - background (when guest clicked on the background)
     *
     * # Analytics:
     * Note that modalData should contain config based on this actions, those could be:
     * {
     *      trackLinks: {
     *          onCloseByIcon: {linkId: 'MY_LINK_ID_STRING'},
     *          onCloseByEscape: {linkId: 'MY_LINK_ID_STRING'},
     *          onCloseByBackground: {linkId: 'MY_LINK_ID_STRING', modelProps: {abc: 'CUSTOM_MODEL_PROP'}}
     *      }
     * }
     *
     * @param eventStr the custom string based on modal action event
     */
    private closeModalByAction(eventStr: string): void {
        this.trackLink(`onCloseBy${capitalize(eventStr)}`);
    }

    /**
     * Track a link based on an event
     *
     * @param event the action event that should be trigger
     */
    private trackLink(event: string): void {
        if (get(this.modalData, `trackLinks.${event}`)) {
            this.analyticsService.trackLink(
                this.modalData.trackLinks[event].linkId,
                this.modalData.trackLinks[event].modelProps || {}
            );
        }
    }
}
