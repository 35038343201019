import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Subject, Observable, catchError, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { NavigationService } from '../navigation/navigation.service';
import { ReservationFlowService } from '../reservation-flow/reservation-flow.service';
import { ReservationIdService } from '../reservation-id/reservation-id.service';
import { TravelAgentService } from '../../travel-agent/travel-agent.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { ErrorHandlingService } from '../error-handling/error-handling.service';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class ReservationService {
    private releaseReservationUrl;
    private releaseReservationUrlTA;
    private webApiUrl;
    releaseSessionEnded: Subject<boolean>;

    constructor(
        private cookieService: CookieService,
        private reservationFlow: ReservationFlowService,
        private reservationId: ReservationIdService,
        private travelAgentService: TravelAgentService,
        private navigation: NavigationService,
        private authenticationService: AuthenticationService,
        private errorHandlingService: ErrorHandlingService,
        private config: ConfigService,
        private httpClient: HttpClient
    ) {
        this.releaseSessionEnded = new Subject<boolean>();
        this.releaseReservationUrl = this.config.getValue('releaseReservationUrl') || '';
        this.releaseReservationUrlTA = this.config.getValue('releaseReservationUrlTA') || '';
        this.webApiUrl = this.config.getValue('webApiUrl');
    }

    /**
     * Release the session and redirect to a url
     * @param urlToRedirect The url to redirect
     */
    releaseSessionAndRedirect(urlToRedirect?: {}): void {
        const pageToReditect = urlToRedirect || { url: '', redirect: true };
        const flow = this.reservationFlow.getFlow();

        if (flow && flow['releaseSessionNeeded']) {
            const reservationId = this.reservationId.getId();
            const sessionScope = flow['sessionScope'];
            const swid = this.cookieService.get('SWID');

            this.releaseSession(swid, reservationId, sessionScope)
                .subscribe()
                .add(() => {
                    this.navigation.navigateByUrl(pageToReditect);
                    this.releaseSessionEnded.next(null);
                });
        }
    }

    /**
     * Release the session
     * @param swid The user swid
     * @param reservationId The reservation id
     * @param scope The user scope
     * @returns Observable
     */
    releaseSession(swid: string, reservationId: Number, scope: string): Observable<{}> {
        const userIdentifier = this.authenticationService.getUserIdentifier(swid);

        return this.httpClient
            .delete<{}>(this.getReservationUrl(reservationId, userIdentifier, scope))
            .pipe(
                catchError(
                    (httpErrorResponse): Observable<{}> => {
                        this.errorHandlingService.setAnalyticsErrorCodes(httpErrorResponse);

                        return throwError(httpErrorResponse.error);
                    }
                )
            );
    }

    /**
     * Returns the reservation url
     * @param reservationId The reservation id
     * @param userIdentifier the user's swid
     * @param scope the user's scope
     * @returns the release session url
     */
    private getReservationUrl(reservationId, userIdentifier, scope) {
        const isTravelAgentLoggedIn = this.travelAgentService.isTravelAgentLoggedIn();

        return this.webApiUrl + (isTravelAgentLoggedIn ?
            this.releaseReservationUrlTA :
            this.releaseReservationUrl)
            .replace('{reservationId}', reservationId)
            .replace('{userIdentifier}', userIdentifier)
            .replace('{scope}', scope);
    }
}
