import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { throwError, Observable, of } from 'rxjs';
import { LoggerService } from '@wdpr/ra-angular-logger';

import { AT_HOME } from '../at-home/at-home.constants';
import { ConfigService } from '../config/config.service';
import { ErrorHandlingService } from '../../services/error-handling/error-handling.service';
import { HealthMonitor } from '../interfaces/health-monitor';
import { HealthMonitorCapability } from '../interfaces/health-monitor-capability';
import { HEALTH_MONITOR_CONSTANTS } from './health-monitor.constants';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';
import { WindowRef } from '../../window-ref/window-ref.service';

@Injectable({
    providedIn: 'root'
})
export class HealthMonitorService {
    private webApiUrl: string;
    private baseUrl: string;
    private isEmbedded: boolean;

    constructor (
        private configService: ConfigService,
        private cookieService: CookieService,
        private errorHandlingService: ErrorHandlingService,
        private httpClient: HttpClient,
        private logger: LoggerService,
        private nativeBridgeService: NativeBridgeService,
        private windowRef: WindowRef
    ) {
        this.isEmbedded = this.nativeBridgeService.isEmbedded();
        this.webApiUrl = this.configService.getValue('webApiUrl');
        this.baseUrl = this.configService.getValue('baseUrl');
    }

    /**
     * Verify if capabilities are available and redirecto to DMF page if fails
     */
    verify(): void {
        const dfmDeactivate = this.cookieService.get(
            HEALTH_MONITOR_CONSTANTS.COOKIE_DFM_DEACTIVATE
        );

        if (!dfmDeactivate) {
            this.getAvailability([
                HEALTH_MONITOR_CONSTANTS.CAPABILITIES.DCL_CRUISE_BOOKING,
                HEALTH_MONITOR_CONSTANTS.CAPABILITIES.DCL_CRUISE_BOOKING_UNPLANNED
            ]).subscribe();
        }
    }

    /**
     * Return if services are available or not
     * @param {Array} capabilitiesToCheck to check
     * @returns {Observable}
     */
    getAvailability(capabilitiesToCheck?: Array<string>): Observable<boolean> {
        return this.getCapabilities()
            .pipe(
                map((response: HealthMonitor) => {
                    return this.checkCapabilities(response['capabilities'], capabilitiesToCheck);
                }),
                catchError(() => {
                    this.goToDFM(this.getDFMPage());

                    return of(false);
                })
            );
    }

    /**
     * Make the request to the service to get health monitor
     * @returns {Observable}
     */
    getCapabilities(): Observable<HealthMonitor> {
        return this.httpClient.get<HealthMonitor>(`${this.webApiUrl}${HEALTH_MONITOR_CONSTANTS.ENDPOINT}`)
            .pipe(
                catchError((error): Observable<HealthMonitor> => {
                    return throwError(error);
                })
            );
    }

    /**
     * Check if capabilities are down
     * @param {Array} capabilities
     * @param {Array} capabilitiesToCheck
     * @returns {boolean}
     */
    checkCapabilities(capabilities: Array<HealthMonitorCapability>, capabilitiesToCheck?: Array<string>): boolean {
        let capabilitiesAvailable = true;
        let unAvailableCapability: HealthMonitorCapability | boolean | undefined;
        let relevantCapabilities: HealthMonitorCapability[];

        if (capabilities.length) {
            if (capabilitiesToCheck && capabilitiesToCheck.length) {
                relevantCapabilities = capabilities.filter(
                    capability => capabilitiesToCheck && capabilitiesToCheck.includes(capability.name)
                );
                unAvailableCapability = relevantCapabilities.find(
                    capabilityToCheck => !capabilityToCheck['available']
                );
            } else {
                unAvailableCapability = capabilities.some(
                    capabilityToCheck => !capabilityToCheck['available']
                );
            }
        } else {
            unAvailableCapability = true;
        }

        if (unAvailableCapability) {
            capabilitiesAvailable = false;
            this.logger.error(`Capabilities are unavailable`, unAvailableCapability);
            this.goToDFM(this.getDFMPage(unAvailableCapability['name']));
        }

        return capabilitiesAvailable;
    }

    /**
     * Get DFM Page based on capability name
     * @param {string} capabilityName
     * @returns {string}
     */
    getDFMPage(capabilityName?: string): string {
        const dfmPageDwarves = HEALTH_MONITOR_CONSTANTS.DFM_PAGE_DWARVES;
        const dfmPageDory = HEALTH_MONITOR_CONSTANTS.DFM_PAGE_DORY;
        const mapping = {
            'DCL-Cruise-Booking': dfmPageDory,
            'DCL-Cruise-Booking-Unplanned': dfmPageDwarves
        };

        return mapping.hasOwnProperty(capabilityName) ? mapping[capabilityName] : dfmPageDwarves;
    }

    /**
     * Go to DFM Page
     * @param {string} page
     */
    goToDFM(page: string): void {
        if (this.isEmbedded) {
            this.errorHandlingService.handleEmbeddedErrors(AT_HOME.errorStates.dfmError);
        } else {
            this.windowRef.nativeWindow.location.href = this.baseUrl + page;
        }
    }
}
