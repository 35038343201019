<section class="castaway-membership" [ngClass]="{'early-booking': isEarlyBookingSegmentation}">
    <div class="warning-errors" [ngClass]="{ 'errors-top': submited && castawayForm.invalid || serviceError }">
        <dcl-warning-messaging
            message="{{ 'travelAgent.castawayMembershipModal.inputFormError' | translate }}"
            [messageType]="warningMsgType"
            [showMessage]="submited && castawayForm.invalid">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            message="{{ serviceError | translate }}"
            [messageType]="warningMsgType"
            [showMessage]="serviceError && !castawayForm.invalid">
        </dcl-warning-messaging>
    </div>
    <div class="header">
        <button
            class="close-btn"
            (click)="closeDialog()"
            attr.aria-label="{{ 'travelAgent.castawayMembershipModal.closeAriaLabel' | translate }}">
            <span class="di di-close-reversed icon-close-btn" attr.aria-hidden="true"></span>
        </button>
        <h2 class="title" translate>travelAgent.castawayMembershipModal.header</h2>
    </div>
    <p class="description" translate>travelAgent.castawayMembershipModal.subtext</p>
    <form
        [formGroup]="castawayForm"
        #form="ngForm"
        class="castaway-membership-form"
        dclAutofocusFirstInvalid
        (ngSubmit)="submit()">
        <div class="inputs" (keyup.enter)="callSubmit()">
            <div class="instructive-text" aria-hidden="true" translate>
                travelAgent.castawayMembershipModal.instructive-text
            </div>
            <div class="item-input" [ngClass]="{ 'invalid': isInvalid(formControls.FIRST_NAME) }">
                <div class="input-wrap">
                    <wdpr-input
                        #firstFocusableControl
                        class="custom-input guestSensitiveHigh"
                        label="{{ 'travelAgent.castawayMembershipModal.inputFirstName' | translate }}"
                        id="{{ formControls.FIRST_NAME }}"
                        ngDefaultControl
                        autocorrect="off"
                        autocomplete="given-name"
                        type="text"
                        [name]="formControls.FIRST_NAME"
                        [formControlName]="formControls.FIRST_NAME"
                        required="true"
                        [valid]="!isInvalid(formControls.FIRST_NAME)"
                        [ngClass]="{ 'touched invalid': submited && isInvalid(formControls.FIRST_NAME) }"
                        tabindex="0">
                    </wdpr-input>
                </div>
                <dcl-error-message
                    class="errors light-theme"
                    *ngIf="isInvalid(formControls.FIRST_NAME)"
                    [control]="castawayForm.get(formControls.FIRST_NAME)"
                    [errorMessage]="{ required: 'travelAgent.castawayMembershipModal.requiredFirstName' }">
                </dcl-error-message>
            </div>
            <div class="item-input" [ngClass]="{ 'invalid': isInvalid(formControls.LAST_NAME) }">
                <div class="input-wrap">
                    <wdpr-input
                        class="custom-input guestSensitiveHigh"
                        label="{{ 'travelAgent.castawayMembershipModal.inputLastName' | translate }}"
                        id="{{ formControls.LAST_NAME }}"
                        ngDefaultControl
                        autocorrect="off"
                        autocomplete="family-name"
                        type="text"
                        [name]="formControls.LAST_NAME"
                        [formControlName]="formControls.LAST_NAME"
                        required="true"
                        [valid]="!isInvalid(formControls.LAST_NAME)"
                        [ngClass]="{ 'touched invalid': submited && isInvalid(formControls.LAST_NAME) }">
                    </wdpr-input>
                </div>
                <dcl-error-message
                    class="errors light-theme"
                    *ngIf="isInvalid(formControls.LAST_NAME)"
                    [control]="castawayForm.get(formControls.LAST_NAME)"
                    [errorMessage]="{ required: 'travelAgent.castawayMembershipModal.requiredLastName' }">
                </dcl-error-message>
            </div>
            <div class="item-input" [ngClass]="{ 'invalid': isInvalid(formControls.DATE_OF_BIRTH) }">
                <div class="input-wrap">
                    <wdpr-input
                        class="custom-input guestSensitiveHigh"
                        [dclDateInputMask]
                        label="{{ 'travelAgent.castawayMembershipModal.inputBirthdate' | translate }}"
                        placeholder="{{ 'travelAgent.castawayMembershipModal.inputBirthdatePlaceholder' | translate }}"
                        id="{{ formControls.DATE_OF_BIRTH }}"
                        show-label-with-placeholder="true"
                        ngDefaultControl
                        autocorrect="off"
                        autocomplete="bday"
                        type="text"
                        [name]="formControls.DATE_OF_BIRTH"
                        [formControlName]="formControls.DATE_OF_BIRTH"
                        required="true"
                        [valid]="!isInvalid(formControls.DATE_OF_BIRTH)"
                        [ngClass]="{ 'touched invalid': submited && isInvalid(formControls.DATE_OF_BIRTH) }">
                    </wdpr-input>
                </div>
                <dcl-error-message
                    class="errors light-theme"
                    *ngIf="isInvalid(formControls.DATE_OF_BIRTH)"
                    [control]="castawayForm.get(formControls.DATE_OF_BIRTH)"
                    [errorMessage]="{ required: 'travelAgent.castawayMembershipModal.requiredBirthdate',
                        pattern: 'travelAgent.castawayMembershipModal.dateFormatError' }">
                </dcl-error-message>
            </div>
            <hr class="blanck-space" *ngIf="isEarlyBookingSegmentation" aria-hidden="true" tabindex="-1">
            <div class="item-input"
                *ngIf="castawayOptionsList && castawayOptionsList.length"
                [ngClass]="{ 'invalid': isInvalid(formControls.ID_TYPE) }">
                <wdpr-single-select
                    #castawayOptionsSelect
                    ngDefaultControl
                    [formControlName]="formControls.ID_TYPE"
                    id="{{ formControls.ID_TYPE }}"
                    class="select select-castaway guestSensitive"
                    [ngClass]="{'has-value': castawayForm.get(formControls.ID_TYPE).value}"
                    [label]="'travelAgent.castawayMembershipModal.castawayOptionsLabel' | translate"
                    [options]="castawayOptionsList"
                    [invalid]="isInvalid(formControls.ID_TYPE)"
                    required-field="true">
                </wdpr-single-select>
                <dcl-error-message
                    class="errors light-theme"
                    *ngIf="isInvalid(formControls.ID_TYPE)"
                    [control]="castawayForm.get(formControls.ID_TYPE)"
                    [errorMessage]="{ required: 'travelAgent.castawayMembershipModal.requiredCastawayType' }">
                </dcl-error-message>
            </div>
            <div class="item-input"
                *ngIf="castawayNumberLabel && !hideIdNumberInput"
                [ngClass]="{ 'invalid': isInvalid(castawayInputControl) }">
                <div class="input-wrap">
                    <wdpr-input
                        #castawayNumberInput
                        class="custom-input guestSensitiveHigh"
                        label="{{ castawayNumberLabel | translate }}"
                        id="{{ castawayInputControl }}"
                        ngDefaultControl
                        autocorrect="off"
                        autocomplete="on"
                        type="number"
                        [name]="castawayInputControl"
                        [formControlName]="castawayInputControl"
                        required="true"
                        (keypress)="onCastawayIdKeyPress($event)"
                        [valid]="!isInvalid(castawayInputControl)"
                        [ngClass]="{ 'touched invalid': submited && isInvalid(castawayInputControl) }">
                    </wdpr-input>
                </div>
                <dcl-error-message
                    class="errors light-theme"
                    *ngIf="isInvalid(castawayInputControl)"
                    [control]="castawayForm.get(castawayInputControl)"
                    [errorMessage]="{ required: castawayNumberError }">
                </dcl-error-message>
            </div>
        </div>
        <div class="actions">
            <dcl-loading-content
                class="light-label"
                spinnerLabel="travelAgent.castawayMembershipModal.loading"
                spinnerLabelAlign="right"
                [spinnerSize]="'small'"
                [showSpinner]="showSpinner">
                <wdpr-button
                    [disabled]="!castawayForm.valid"
                    class="button-item submit-button"
                    theme="primary"
                    translate="travelAgent.castawayMembershipModal.okButton"
                    (click)="callSubmit()"
                    type="submit">
                </wdpr-button>
            </dcl-loading-content>
            <wdpr-button
                class="cancel-button button-item"
                theme="secondary"
                translate="travelAgent.castawayMembershipModal.cancelButton"
                (click)="closeDialog()">
            </wdpr-button>
        </div>
    </form>
</section>
