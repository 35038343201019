import { Component, EventEmitter, Input, Output } from '@angular/core';

import { WhatsIncludedModalService } from '../../services/whats-included-modal.service';

@Component({
    selector: 'dcl-whats-included-modal-cta',
    templateUrl: './whats-included-modal-cta.component.html',
    styleUrls: ['./whats-included-modal-cta.component.scss']
})
export class WhatsIncludedModalCtaComponent {
    @Input() message: string;
    @Input() buttonText: string;
    @Output() onOpenModal = new EventEmitter();

    constructor(private whatsIncludedModalService: WhatsIncludedModalService) {}

    /**
     * Handle event when button is clicked
     */
    onClick(event: MouseEvent): void {
        this.onOpenModal.emit(event);
    }

    open(): void {
        this.whatsIncludedModalService.openModal();
    }
}
