export const RESERVATION_SUMMARY_CONSTANTS = {
    GTY: 'GTY',
    PARTY_MIX: 'party_mix',
    ERRORS: {
        INVALID_RESERVATION_SUMMARY: 'Invalid reservation summary'
    },
    SCOPES: {
        sailing: 'MOD_SAILING'
    },
    COOKIES: {
        SWID: 'SWID'
    },
    ANALYTICS: {
        BACK_BUTTON_LINK: 'DCL_Modify_ReturnToModify'
    },
    TRANSLATE_KEY_LOCATION: 'reservationSummaryBar.locations.',
    EBS_PROMO_CODE: 'EBS',
    SPECIAL_OFFER_DEFAULT_SUFFIX: ';entityType=marketing-offer;destination=dcl',
    COMMUNICATIVE_RESERVE_TYPE: 'COMMUNICATIVE'
};
