import { Injectable } from '@angular/core';

import { chain } from 'lodash';

import { AvatarMedia } from '../../interfaces/avatar-media.interface';
import { AVATAR_HEADER_CONSTANTS } from '../avatar-header.constants';

@Injectable({
  providedIn: 'root'
})
export class AvatarUtilsService {
    private constants = AVATAR_HEADER_CONSTANTS;

    // holder to know the last avatar used and prevent seeing two equals together
    private lastAvatarImageSelected = '';

    /**
     * Retrieve a random avatar object with a random image
     * @returns AvatarMedia instance
     */
    getRandomAvatar(): AvatarMedia {
        const avatarURL = chain(this.constants.defaultsAvatars)
            .toArray()
            .without(this.lastAvatarImageSelected)
            .shuffle()
            .first()
            .value();

        this.lastAvatarImageSelected = avatarURL;

        return {
            url: avatarURL,
            alt: this.constants.defaultAltText
        } as AvatarMedia;
    }

    /**
     * It gets a default avatar for the application
     * @returns Avatar media instance
     */
     getDefaultAvatar(): AvatarMedia {
        return {
            url: this.constants.signedInAvatar,
            alt: this.constants.defaultAltText
        };
    }
}
