import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FormDataService } from '../form-data/form-data.service';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';
import { GUEST_NAME_CONSTANT } from './guest-name.constants';

@Component({
    selector: '[formGroup] dcl-guest-name',
    templateUrl: './guest-name.component.html',
    styleUrls: ['./guest-name.component.scss']
})
export class GuestNameComponent implements OnInit, AfterViewInit {
    @Input() prefixes: Array<{} | string> = [];
    @Input() suffixes: Array<{} | string> = [];
    @Input() guestData: {};
    @Input() showInfo: boolean;
    @Input() showRequiredLabel?: boolean;
    @Input() firstElementFocus: boolean = false;
    @Input() index: number = 0;
    @Input() isFullMiddleName: boolean;

    @ViewChild('wdprSelect', {static: true} as ViewChildProperties) wdprSelect: ElementRef;
    @ViewChild('wdprSelectSuffix', {static: true} as ViewChildProperties) wdprSelectSuffix: ElementRef;

    guestName: UntypedFormGroup;
    guestNumber: number;
    errorMessagesKeys: {};
    elementRef: ElementRef;
    translations: {} = {};
    guestNameData: {} = {};
    titleSelected: string;
    suffixSelected: string;
    getErrors;
    middleNameLabel: string;
    middleNameMaxLength: number;

    constructor(
        private fb: UntypedFormBuilder,
        private controlContainer: ControlContainer,
        private renderer: Renderer2,
        private translate: TranslateService,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.getErrors = this.formDataService.getErrorMessage;
        this.setData();
        this.setGuestName();
        this.translateValues();
        this.guestNumber = this.index + 1;
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    translateValues(): void {
        this.translate.get('guest-name').subscribe((res: [string]) => {
            this.translations = res;
        });
    }

    getErrorLength(errors: {}): Number {
        return errors ? Object.keys(errors).length : 0;
    }

    setGuestName(): void {
        const middleNamePattern = this.isFullMiddleName ?
            GUEST_NAME_CONSTANT.INPUT_CONFIG.NAME_PATTERN :
            GUEST_NAME_CONSTANT.INPUT_CONFIG.MI_PATTERN;

        const patternName = GUEST_NAME_CONSTANT.INPUT_CONFIG.NAME_PATTERN;
        const titleControl = this.fb.control(this.guestNameData['title'] || '', [Validators.required]);
        const nameControl = this.fb.control(this.guestNameData['firstName'] || '', [Validators.pattern(patternName)]);
        const miControl = this.fb.control(this.guestNameData['middleName'] || '',
            [Validators.pattern(middleNamePattern)]);
        const lastNameControl = this.fb.control(
            this.guestNameData['lastName'] || '',
            [Validators.pattern(patternName)]
        );
        const suffixControl = this.fb.control(this.guestNameData['suffix'] || '');

        this.middleNameMaxLength = this.isFullMiddleName ?
            GUEST_NAME_CONSTANT.INPUT_CONFIG.MAX_LENGTH :
            GUEST_NAME_CONSTANT.INPUT_CONFIG.SHORT_LENGTH;

        this.middleNameLabel = this.isFullMiddleName ?
            GUEST_NAME_CONSTANT.INPUT_CONFIG.LABEL.FULL_MIDDLE_NAME :
            GUEST_NAME_CONSTANT.INPUT_CONFIG.LABEL.MI;

        this.guestName = <UntypedFormGroup>this.controlContainer.control;
        this.guestName.setControl('title', titleControl);
        this.guestName.setControl('firstName', nameControl);
        this.guestName.setControl('middleName', miControl);
        this.guestName.setControl('lastName', lastNameControl);
        this.guestName.setControl('suffix', suffixControl);

        this.errorMessagesKeys = {
            firstName: {
                required: 'guest-name.name-errors.required'
            },
            lastName: {
                required: 'guest-name.last-name-errors.required'
            },
            title: {
                required: 'guest-name.title-errors.required'
            }
        };

        this.renderer.listen(this.wdprSelect.nativeElement, 'item-selected', (evt) => {
            this.titleSelected = evt.detail.key;
            this.guestName.get('title').setValue(this.titleSelected);
        });

        this.renderer.listen(this.wdprSelectSuffix.nativeElement, 'item-selected', (evt) => {
            this.suffixSelected = evt.detail.key;
            this.guestName.get('suffix').setValue(this.suffixSelected);
        });
    }

    /**
     * Verifies if control is invalid
     * @param {AbstactControl} control to validate
     * @returns {boolean}
     */
    isInvalid(control: AbstractControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    /**
     * Verifies if form is invalid
     * @returns {boolean}
     */
    isInvalidForm(): boolean {
        return this.isInvalid(this.guestName.get('title')) ||
            this.isInvalid(this.guestName.get('firstName')) ||
            this.isInvalid(this.guestName.get('lastName')) ||
            this.isInvalid(this.guestName.get('middleName'));
    }

    /**
     * Sets the data in the form if the guest already exist
     */
    setData(): void {
        if (this.showInfo && this.guestData) {
            this.guestNameData = {
                title: this.guestData['title'],
                firstName: this.guestData['firstName'],
                lastName: this.guestData['lastName'],
                middleName: this.guestData['middleName'],
                suffix: this.guestData['suffix']
            };
        }
    }
}
