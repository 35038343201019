import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { CONSTANTS } from './reservation-selector.constants';
import { ReservationSelectorItem } from './reservation-selector.interface';

@Component({
    selector: 'dcl-reservation-selector',
    templateUrl: './reservation-selector.component.html',
    styleUrls: ['./reservation-selector.component.scss'],
})
export class ReservationSelectorComponent {
    @Input() isInternationalDirect: boolean;
    @Input() isInternationalDirectPhase2: boolean;
    @Input() reservationsList: ReservationSelectorItem[] = [];
    @Input() selectedId: string;
    @Output() reservationSelected = new EventEmitter<string>();
    @Output() isReservationSelectorMenuOpen = new EventEmitter<boolean>();

    isOpen: boolean;
    resSelectorConstants = CONSTANTS;

    constructor() {}

    /**
     * Toggles the menu open and closed
     */
    public toggleMenu(): void {
        this.isOpen = !this.isOpen;
        this.isReservationSelectorMenuOpen.emit(this.isOpen);
    }

    /**
     * Emits the selected reservation Id and closes the menu
     * @param reservationId Reservation Id selected
     * @param selectable Boolean indicating if the stateroom is selectable or unavailable
     */
    public selectReservation(reservationId: string, selectable: boolean): void {
        if (!selectable || reservationId === this.selectedId) {
            return;
        }

        this.reservationSelected.emit(reservationId);
        this.isOpen = false;
        this.isReservationSelectorMenuOpen.emit(this.isOpen);
    }

    /**
     * Move to next item in the list, closing the menu if tabbing out of the last one
     * @param isLastItem Boolean indicating if it is the last item in the list
     */
    public nextItem(isLastItem: boolean): void {
        if (!this.isOpen) {
            return;
        }

        if (isLastItem) {
            this.toggleMenu();
        }
    }
}
