import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { DataStorageService } from '../services/data-provider/data-provider.service';
import { ERROR_CONSTANTS as constants } from './error-states.constants';
import { HTTP_STATUS_CODES } from '../services/at-home/at-home.constants';
import { WindowRef } from '../window-ref/window-ref.service';

@Component({
    selector: 'dcl-error-states',
    templateUrl: './error-states.component.html',
    styleUrls: ['./error-states.component.scss'],
})
export class ErrorStatesComponent implements OnInit {
    actionFn: Function;
    actionText: string;
    character: string;
    code: string;
    contextPath: string;
    codeCharacters = constants.CHARACTERS;
    currentPath: string;
    errorContainerClass: string;
    footerImage: string;
    firstDesc: string;
    secondDesc: string;
    title: string;
    image: string;
    imagePath: string;
    redirectionPath: string;

    constructor(
        private dataStorage: DataStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private windowRef: WindowRef
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => this.code = params['code']);
        this.route.data.subscribe(data => this.contextPath = data.contextPath);
        this.setKeys();
        this.currentPath = this.windowRef.nativeWindow.location.pathname;
    }

    /**
     * This function set the keys and characters to be use on the view depending on URL Param.
     */
    setKeys(): void {
        const data = this.dataStorage.getData();
        const {
            action,
            actionFn,
            character,
            firstDesc,
            footerImage,
            image,
            imagePath,
            redirectionPath,
            secondDesc,
            title,
        } = data;

        this.validateCode();
        this.imagePath = imagePath || constants.IMAGE_PATH;
        this.redirectionPath = redirectionPath || this.currentPath;
        this.character = character ? character : this.codeCharacters[this.code].name;
        this.errorContainerClass = `${this.character}-container`;
        this.image = this.imagePath
            .replace('{contextPath}', this.contextPath)
            .replace('{characterImage}', image ? image : this.codeCharacters[this.code].image);
        this.title = title ? title : `errorPage.${this.code}.title`;
        this.firstDesc = firstDesc ? firstDesc : `errorPage.${this.code}.message`;
        this.secondDesc = secondDesc ? secondDesc : `errorPage.${this.code}.longerMessage`;
        this.actionText = this.code === '0' ? `errorPage.${this.code}.cta` : action;
        this.actionFn = actionFn;

        if (footerImage) {
            this.footerImage = this.imagePath
                .replace('{contextPath}', this.contextPath)
                .replace('{characterImage}', footerImage);
        }
    }

    /**
     * If code is not included in allowed codes, then set 500 as default
     */
    private validateCode() {
        if (Object.keys(this.codeCharacters).indexOf(this.code) === -1) {
            this.code = HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR.toString();
        }
    }

    /**
     * Handles click on cta button, with custom function if present,
     * before renavigation.
     */
    public onActionClick() {
        if (this.actionFn) {
            this.actionFn();
        }

        this.router.navigateByUrl(this.redirectionPath);
    }
}
