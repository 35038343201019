const AT_HOME = {
    flows: {
        dining: {
            id: 'dining',
            flowId: 'Itinerary.Dining',
            successType: 'DiningSeating'
        },
        cancel: {
            id: 'cancel',
            flowId: 'Commerce.ModifyCancelation',
            successType: 'ModifyCancelation'
        },
        hub: {
            id: 'hub',
            flowId: 'Commerce.ModifyReservation',
            successType: '',
        },
        room: {
            id: 'room',
            flowId: 'Commerce.ModifyStateroom',
            successType: 'ModifyStateroom',
        },
        party: {
            id: 'party',
            flowId: 'Commerce.TravelParty',
            successType: 'ModifyTravelParty',
        },
        'larger-room': {
            id: 'larger-room',
            flowId: 'Commerce.TravelParty',
            successType: 'ModifyTravelParty',
        },
        sailing: {
            id: 'sailing',
            flowId: 'Commerce.SailDate',
            successType: 'ModifySailDate',
        },
        specialRequests: {
            id: 'specialRequests',
            flowId: 'Itinerary.SpecialRequest',
            successType: 'SpecialRequest'
        },
        specialServices: {
            id: 'specialServices',
            flowId: 'Itinerary.specialServices',
            successType: 'SpecialServices'
        },
        pano: {
            id: 'pano',
            flowId: 'Panaromas.Venue',
        }
    },
    errorStates: {
        unauthorized: 401,
        jwtTokenMissing: 'jwt-token-or-swid-missing',
        contentMissing: 'content-missing',
        dfmError: 'dfm-error',
        sessionExpired: 'session-expired-or-killed',
        sessionConflict: 'session-conflict'
    },
    messages: {
        errorTitle: {
            key: 'at-home.error-title',
            defaultValue: 'Something Went Wrong'
        },
        errorMessage: {
            key: 'at-home.error-message',
            defaultValue: 'System unable to process at this time. Please try again later'
        },
        errorMessageDfm: {
            key: 'at-home.error-message-dfm',
            defaultValue: 'We\'re performing routine maintenance. Please find your way back in a few minutes'
        },
        success: {
            onlyContactInformation: {
                key: 'at-home.success-messages.only-contact-information',
                defaultValue: 'Your contact information has been updated.'
            },
            diningSeatingTime: {
                key: 'atHome.successMessages.diningSeatingTime',
                defaultValue: 'Your stateroom has been updated to include the changes you requested.'
            },
            specialRequests: {
                key: 'atHome.successMessages.specialRequests',
                defaultValue: 'Your stateroom has been updated to include the changes you requested.'
            },
            specialServices: {
                key: 'atHome.successMessages.specialServices',
                defaultValue: 'Your stateroom has been updated to include the changes you requested.'
            }
        }
    },
    initialPages: [
        'modify/reservation'
    ]
};

const HTTP_STATUS_CODES = {
    CONFLICT: 409,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
    SESSION_ERRORS: {
        KILLED: 410,
        CONFLICT: 409
    }
};

const MY_RES_URL = {
    getUrl: (reservationId?) => {
        reservationId = reservationId ? `${reservationId}/` : '';

        return `/my-disney-cruise/my-reservations/${reservationId}`;
    }
};

const SESSION_ERRORS = [
    'DCL_SM_30001',
    'DCL_SM_44401',
    'DCL_SM_40001',
    'DCL_SM_40002',
    'DCL_SM_40003',
    'DCL_SM_40004',
    'DCL_SM_40005',
    'DCL_SM_40006',
    'DCL_SM_19999'
];

export {
    AT_HOME,
    HTTP_STATUS_CODES,
    MY_RES_URL,
    SESSION_ERRORS
};
