import { AfterViewInit, Component, ContentChildren, HostListener, Input, QueryList } from '@angular/core';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { CONSTANTS } from './carousel.constants';
import { CarouselConfig } from './interfaces/config.interface';

@Component({
    selector: 'dcl-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
    @Input() carouselConfig: CarouselConfig;
    @ContentChildren(CarouselItemComponent) items: QueryList<CarouselItemComponent>;

    constants: CarouselConfig;
    percentBase: number;
    widthFactor: number;
    windowsWidth = window.innerWidth;
    widthPxFactor: number;
    deltaX = 0;
    currentIndex = 0;
    carouselActive: boolean;
    carouselType = false;
    leftDirection: boolean;

    ngAfterViewInit(): void {
        this.constants = { ...CONSTANTS.config, ...this.carouselConfig };

        if (this.items.length > CONSTANTS.lonelyElement) {
            setTimeout(() => {
                this.percentBase = this.constants.percentBase;
                this.widthFactor = this.constants.widthFactor;
                if (this.items.length > CONSTANTS.sliderType) {
                    this.carouselType = true;
                    this.currentIndex = 1;
                }
                this.items.toArray()[this.currentIndex].active = true;
                this.items.forEach((item, index) => {
                    item.config.position = index;
                });
                this.setCarousel();
                this.setWidthPxFactor();
            });
        }
    }

    /**
     * Set widthPxFactor value based on window width
     */
    setWidthPxFactor(): void {
        this.widthPxFactor = this.windowsWidth * this.widthFactor / this.percentBase;
    }

    /**
     * Helper method to check windows width
     */
    @HostListener('window:resize')
    onResize() {
        this.windowsWidth = window.innerWidth;
        this.setCarousel();
        this.setWidthPxFactor();
        this.updateConfig(true);
    }

    /**
     * Set Desktop value depending on the device.
     */
    setCarousel(): void {
        this.carouselActive = this.windowsWidth < this.constants.deviceWidth
            && this.items.length > CONSTANTS.lonelyElement;
        this.items.forEach(item => {
            item.carouselActive = this.carouselActive;
        });
    }

    /**
     * Handler for 'panMove' HammerJS event
     * @param {Event} e panMove event
     */
    panMoveAction(e): void {
        if (this.carouselActive) {
            this.deltaX = e.deltaX;
            this.setPanMoveStyles();
        }
    }

    /**
     * Handler for 'panStart' HammerJS event
     */
    panStartAction(): void {
        if (this.carouselActive) {
            this.items.forEach(item => {
                item.clickDisabled = true;
            });
        }
    }

    /**
     * Handler for 'panEnd' HammerJS event
     * @param {Event} e panEnd event
     */
    panEndAction(e): void {
        if (this.carouselActive) {
            this.items.forEach(item => {
                item.clickDisabled = false;
            });
            this.leftDirection = e.deltaX < 0;
            this.setCurrentIndex();
            this.updateConfig();
            this.deltaX = 0;
        }
    }

    /**
     * Method to set current active index based on pan direction
     */
    setCurrentIndex(): void {
        const current = this.currentIndex;

        if (this.carouselType) {
            if (this.leftDirection) {
                this.currentIndex = (current + 1) % this.items.length;
            } else {
                this.currentIndex = current === 0 ? this.items.length - 1 : current - 1;
            }
        } else {
            if (this.leftDirection) {
                this.currentIndex = current < this.items.length - 1 ? current + 1 : this.items.length - 1;
            } else {
                this.currentIndex = current > 0 ? current - 1 : 0;
            }
        }

        this.items.forEach(item => {
            item.active = false;
        });

        if (this.items.toArray()[this.currentIndex]) {
            this.items.toArray()[this.currentIndex].active = true;
        }
    }

    /**
     * Method to update slider config
     * @param {boolean} resize [optional] indicates if a window resize event was fired
     */
    updateConfig(resize?): void {
        this.items.forEach((item, index) => {
            if (!resize && this.carouselType) {
                if (this.leftDirection) {
                    item.config.position = item.config.position === 0 ?
                        this.items.length - 1 :
                        item.config.position - 1;
                } else {
                    item.config.position = (item.config.position + 1) % this.items.length;
                }
            }

            if (this.carouselType) {
                item.config.translateX = this.widthPxFactor * item.config.position - this.widthPxFactor * index;
            } else {
                item.config.translateX = -(this.currentIndex * this.widthPxFactor);
            }
            this.checkStyles(item.config, resize);
        });
    }

    /**
     * Method to check if the indicated item is a border item depending on pan direction
     * @param {object} item item in the config array
     * @return borderFlag
     */
    isBorderItem(item): boolean {
        const position = item.position;

        if (this.carouselType) {
            if (this.leftDirection) {
                return position === this.items.length - 1;
            } else {
                return position === 0;
            }
        }

        return false;
    }

    /**
     * Method to set dynamic styles based on 'panMove' delta
     */
    setPanMoveStyles(): void {
        this.items.forEach(item => {
            const translateFactor = item.config.translateX + this.deltaX;

            item.config.transitionTime = 0;
            this.updateStyles(item.config, translateFactor);
        });
    }

    /**
     * Method to check the current styles for the item
     * @param {object} config    item in the config array
     * @param {boolean} resize indicates if a window resize event was fired
     */
    checkStyles(config, resize?): void {
        if (resize) {
            config.transitionTime = 0;
            this.updateStyles(config);
        } else if (this.isBorderItem(config)) {
            this.setDelayedStyles(config);
        } else {
            config.transitionTime = this.constants.transitionTime;
            this.updateStyles(config);
        }
    }

    /**
     * Method to set styles for border items
     * @param {object} config  item in the config array
     */
    setDelayedStyles(config): void {
        let translateFactor;
        config.transitionTime = 0;

        if (this.leftDirection) {
            translateFactor = config.translateX + this.widthPxFactor + this.deltaX;
        } else {
            translateFactor = config.translateX - this.widthPxFactor - this.deltaX;
        }
        this.updateStyles(config, translateFactor);

        setTimeout(() => {
            config.transitionTime = this.constants.transitionTime - this.constants.borderItemDelay;
            this.updateStyles(config);
        }, this.constants.borderItemDelay);
    }

    /**
     * Helper method to update item styles based on 'translateX' & 'transitionTime' properties
     * @param {object} config       item in the config array
     * @param {number} translateX optional value for transform property
     */
    updateStyles(config, translateX?): void {
        config.styles = {
            transform: `translateX(${translateX || config.translateX}px)`,
            transition: `transform ${config.transitionTime}ms`
        };
    }
}
