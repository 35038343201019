import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';

import { ALERT_MESSAGING_CONSTANTS } from './alert-messaging.constants';

@Component({
    selector: 'dcl-alert-messaging',
    templateUrl: './alert-messaging.component.html',
    styleUrls: ['./alert-messaging.component.scss']
})
export class AlertMessagingComponent implements OnInit {
    @Input() message: string;
    @Input() messageType: string;
    @Input() noAriaLive: boolean;
    @Input() showMessage: boolean;
    @Input() titleMessage: string;

    ariaLive: string = null;
    icon: string;

  constructor(private logger: LoggerService) { }

  ngOnInit() {
      if (!ALERT_MESSAGING_CONSTANTS[this.messageType]) {
          this.messageType = ALERT_MESSAGING_CONSTANTS.defaultType;

          this.logger.warn(`The type sent does not exist, ${ALERT_MESSAGING_CONSTANTS.defaultType} is being used`);
      }

      this.icon = ALERT_MESSAGING_CONSTANTS[this.messageType].icon;
      this.ariaLive = ALERT_MESSAGING_CONSTANTS[this.messageType].ariaLive;

      if (this.noAriaLive) {
          this.ariaLive = null;
      }
  }
}
