import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { TravelAgentService, TASessionTimeOutService } from '@wdpr/dcl-ui-components-library';

import { FILTERED_REQUESTS_TIMEOUT} from '@app/app.constants';
import { HttpClient } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TravelAgentInterceptor implements HttpInterceptor {
    toogleObservable;

    constructor(
        private injector: Injector,
        private httpClient: HttpClient,
        private cookieService: CookieService
    ) {}

    intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
        const travelAgentService = this.injector.get(TravelAgentService);
        const sessionTimeOutService = this.injector.get(TASessionTimeOutService);
        const isTravelAgentLoggedIn = travelAgentService.isTravelAgentLoggedIn();

        if (req.url.endsWith('homepage-banner-toggles.json')) {
            // don't do `withCredentials: true`, it causes CORS errors on cdn.
            return next.handle(req);
        }

        const travelTest = this.cookieService.get('travelTest');

        if ((travelTest || isTravelAgentLoggedIn) &&
            req.url.match('dcl-apps-productavail-vas|resource-bundle') && !req.url.match(FILTERED_REQUESTS_TIMEOUT) ) {

            const httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
            const body = [{'name': 'dclTACommerce'}];
            this.toogleObservable = this.httpClient.post<any>(
                '/gifts-and-amenities/toggles/gna/',
                body,
                httpOptions
            )
                .subscribe(
                    data  => {
                        // const datasString = JSON.stringify(data);
                        console.log('travel datas ', data);
                        if (data.dclTACommerce && data.dclTACommerce === true) {
                            sessionTimeOutService.isSessionTimeOut();
                        }
                    }
                );
        }

        return next.handle(req);
    }
}

export const TravelAgentHttpInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: TravelAgentInterceptor,
    multi: true,
};
