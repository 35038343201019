import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';

import { AnalyticsDataModel } from './../../quick-quote/interfaces/analytics-data-model.interface';
import { NewItinerariesBannerService } from './../../quick-quote/services/new-itineraries.banner.service';
import { QUICK_QUOTE_CONSTANTS } from './../../quick-quote/quick-quote.constants';
import { QuickQuoteService } from './../../quick-quote/services/quick-quote.service';
import { SelectedFilter } from './../../quick-quote/interfaces/selected-filter.interface';
import { TravelPartyService } from './../../travel-party/services/travel-party.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constants = QUICK_QUOTE_CONSTANTS;
    moment = moment;

    constructor(
        private quickQuoteService: QuickQuoteService,
        private travelPartyService: TravelPartyService,
        private newItinerariesBannerService: NewItinerariesBannerService
    ) {}

    /**
     * Creates and track an analytics data model getting all the analytics data
     * from the selected filters and the travel party service when the apply filters
     * function is called
     *
     * @returns analytics model for to be tracked
     */
    applyFiltersAnalyticsTrackLink(): AnalyticsDataModel {
        const applyFiltersValue = this.concatFilterNamesByFilterId();
        const analyticsPartySize = this.travelPartyService.getAnalyticsPartySize();
        const analyticsApplyFiltersModel =
            this.createAnalyticsDataModelApplyFilters(applyFiltersValue, analyticsPartySize);

        return analyticsApplyFiltersModel;
    }

    /**
     * Creates and track an analytics data model getting all the analytics data
     * from the selected filters and the travel party service when the clear all filters
     * function is called
     *
     * @returns analytics model for to be tracked
     */
    clearFiltersAnalyticsTrackLink(): AnalyticsDataModel {
        const analyticsClearFiltersModel: AnalyticsDataModel = {
            trackLinkID:
                `${
                    this.constants.analytics.cruiseFiltersLink
                }${
                    this.constants.analytics.clearAllButtonLink
                }`
        };

        return analyticsClearFiltersModel;
    }

    /**
     * Merge all the filters names inside of string array separate by ';'
     * when isDateFilter parameter is true this array will be formatted like a date
     *
     * @param selectedFilter current filter selected
     * @param isDateFilter flag to know when the filter is a date filter
     * @returns filter names concatenated like a string
     */
    joinSelectedFilterNames(selectedFilter: SelectedFilter[], isDateFilter: boolean = false): string {
        let filtersMapped = [''];

        if (selectedFilter.length > 0) {
            if (isDateFilter) {
                filtersMapped = selectedFilter.map((filter: SelectedFilter) =>
                    this.moment(this.quickQuoteService.getFiltersData()[filter.id].name, 'YYYY-MM')
                        .format('MMMM-YY').toLowerCase());
            } else {
                filtersMapped = selectedFilter.map((filter: SelectedFilter) =>
                    this.quickQuoteService.getFiltersData()[filter.id].name);
            }
        }

        return filtersMapped.join(';');
    }

    /**
     * Loop the selectedFilter from quickQuote service in order to get the names for each filter,
     * to be formatted and concatenated in a string value
     *
     * @return string with te concatenated names
     */
    concatFilterNamesByFilterId(): string {
        const moreFiltersConstants = this.constants.filters['more-filters'];
        let datesFilterNames = '';
        let destinationFilterNames = '';
        let filterNames = [];
        let nightFilterNames = '';
        let portsFilterNames = '';
        let shipFilterNames = '';
        let specialFilterNames = '';

        this.quickQuoteService.selectedFilters
            .forEach((selectedFilter: SelectedFilter[] | SelectedFilter[][], filterIndex: number) => {
                switch (filterIndex) {
                    case this.constants.filters.date.renderOrder:
                        datesFilterNames += this.joinSelectedFilterNames(selectedFilter as SelectedFilter[], true);
                        break;

                    case this.constants.filters.destination.renderOrder:
                        destinationFilterNames += this.joinSelectedFilterNames(selectedFilter as SelectedFilter[]);
                        break;

                    case this.constants.filters.city.renderOrder:
                        portsFilterNames += this.joinSelectedFilterNames(selectedFilter as SelectedFilter[]);
                        break;

                    case moreFiltersConstants.renderOrder:
                        (selectedFilter as SelectedFilter[][])
                            .forEach((moreFilterSelected: SelectedFilter[]) => {
                                if (moreFilterSelected.length > 0) {
                                    switch (moreFilterSelected[0].groupType) {
                                        case moreFiltersConstants.groupKey.theme:
                                            specialFilterNames +=
                                                this.joinSelectedFilterNames(moreFilterSelected as SelectedFilter[]);
                                            break;

                                        case moreFiltersConstants.groupKey.night:
                                            nightFilterNames +=
                                                this.joinSelectedFilterNames(moreFilterSelected as SelectedFilter[]);
                                            break;

                                        case moreFiltersConstants.groupKey.ship:
                                            shipFilterNames +=
                                                this.joinSelectedFilterNames(moreFilterSelected as SelectedFilter[]);
                                            break;

                                    }
                                }
                            });
                        break;
                }
        });

        filterNames = [
            datesFilterNames,
            destinationFilterNames,
            nightFilterNames,
            portsFilterNames,
            shipFilterNames,
            specialFilterNames
        ];

        return this.concatAllFiltersApplied(...filterNames);
    }

    /**
     * Set the analytics base model with the complete data receive in the params
     * for the filters applied and the guest information
     *
     * @param applyFiltersValue complete string with the applied filters
     * @param analyticsPartySize total number of the guest formatted with adults and childs prefix
     * @returns analytics data model with the filter and guest info setted
     */
    createAnalyticsDataModelApplyFilters(applyFiltersValue: string, analyticsPartySize: string): AnalyticsDataModel {
        const analyticsApplyFiltersModel = this.createAnalyticsBaseModelToApplyFilters();
        const advBookingFilterValue = this.newItinerariesBannerService.getAnalyticsAdvBookingFilterValue();

        applyFiltersValue += !applyFiltersValue ? '' : ',';
        applyFiltersValue +=
            `${
                this.constants.analytics['travel-party'].travelPartyPrefix
            }${
                analyticsPartySize
            },${
                this.constants.analytics['travel-party'].stateroomPrefix
            }${
                this.travelPartyService.getStaterooms().length
            }`;

        // by request, this option should be placed at the end
        if (advBookingFilterValue) {
            applyFiltersValue += ',' + advBookingFilterValue;
        }

        analyticsApplyFiltersModel.facetsProgress[0].name = applyFiltersValue;
        analyticsApplyFiltersModel.bookingSearchPartySize = analyticsPartySize;
        analyticsApplyFiltersModel.bookingSearchNumRooms = this.travelPartyService.getStaterooms().length;

        return analyticsApplyFiltersModel;
    }

    /**
     * Creates a string calling the addFilterType function for each filter trype
     * and concatenating all the values retrieve for each call
     *
     * @param filterNamesParams string array with the applied filter for each filter type
     * @returns string value with all the filters applied
     */
    concatAllFiltersApplied(...filterNamesParams: string[]): string {
        const [
            datesFilterNames,
            destinationFilterNames,
            nightFilterNames,
            portsFilterNames,
            shipFilterNames,
            specialFilterNames
        ] = filterNamesParams;
        let applyFilterValue = '';

        applyFilterValue += this.addFilterTypeToStringFilterNames(datesFilterNames,
            this.constants.analytics.date.applyFilterPrefix, applyFilterValue);
        applyFilterValue += this.addFilterTypeToStringFilterNames(destinationFilterNames,
            this.constants.analytics.destination.applyFilterPrefix, applyFilterValue);
        applyFilterValue += this.addFilterTypeToStringFilterNames(nightFilterNames,
            this.constants.analytics['more-filters'].nightFilterPrefix, applyFilterValue);
        applyFilterValue += this.addFilterTypeToStringFilterNames(portsFilterNames,
            this.constants.analytics.city.applyFilterPrefix, applyFilterValue);
        applyFilterValue += this.addFilterTypeToStringFilterNames(shipFilterNames,
            this.constants.analytics['more-filters'].shipFilterPrefix, applyFilterValue);
        applyFilterValue += this.addFilterTypeToStringFilterNames(specialFilterNames,
            this.constants.analytics['more-filters'].specialFilterPrefix, applyFilterValue);

        return applyFilterValue;
    }

    /**
     * Creates a base analytics model for applied filter
     *
     * @returns AnalyticsDataModel object with the trackLinkID
     */
    createAnalyticsBaseModelToApplyFilters(): AnalyticsDataModel {
        const analyticsApplyFiltersModel: AnalyticsDataModel = {
            facetsProgress: [
                {
                    name: ''
                }
            ],
            trackLinkID:
                `${
                    this.constants.analytics.cruiseFiltersLink
                }${
                    this.constants.analytics.applyFiltersButtonLink
                }`
        };

        return analyticsApplyFiltersModel;
    }

    /**
     * Concat the param filter type at the filterNames param
     *
     * @param  filterNames string with the all the filters names applied
     * @param  filterType filter type of  the filterNames string
     * @param  applyFilterValue all the filters has been concatenated and typed
     * @returns filterType value concatenated with filterNames value
     */
    addFilterTypeToStringFilterNames(filterNames: string, filterType: string, applyFilterValue: string): string {
        let filterNamesValue = '';

        if (filterNames) {
            filterNamesValue += applyFilterValue ? ',' : '';
            filterNamesValue += `${filterType}${filterNames}`;
        }

        return filterNamesValue;
    }
}
