import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { TouchEventConfig } from './models/touch-event-config';

@NgModule({
    declarations: [CarouselComponent, CarouselItemComponent],
    exports: [
        CarouselComponent,
        CarouselItemComponent
    ],
    imports: [
        CommonModule
    ], providers: [{
        provide: HAMMER_GESTURE_CONFIG,
        useClass: TouchEventConfig
    }]
})
export class CarouselModule {
}
