import { Component, Input, OnInit, ViewChild, EventEmitter, Output, Optional } from '@angular/core';

import { DclSideModalComponent } from '../side-modal/side-modal.component';
import { ITINERARIES_CONSTANTS } from './itineraries-modal.constants';
import { NativeBridgeService } from '../native-bridge/native-bridge.service';
import { ReservationFlowService } from '../services/reservation-flow/reservation-flow.service';
import { Table } from '../table/interfaces/table.interface';

@Component({
    selector: 'dcl-itineraries-modal',
    templateUrl: './itineraries-modal.component.html',
    styleUrls: ['./itineraries-modal.component.scss'],
})
export class ItinerariesModalComponent implements OnInit {
    @ViewChild('dclSideModal', { static: false }) dclSideModal: DclSideModalComponent;
    @Input() bodyText: string;
    @Input() buttonLabel: string;
    @Input() closeLabel: string;
    @Input() contextPath: string;
    @Input() headerText: string;
    @Input() logoAlt: string;
    @Input() parentSelector: Table;
    @Input() tableData: Table;
    @Input() imageLogo: string;
    @Output() onExploreItinerariesAction = new EventEmitter();
    @Output() onModalAction = new EventEmitter();

    constants = ITINERARIES_CONSTANTS;
    isEmbedded = false;
    parentWithScroll: HTMLElement;
    isOpenModal = false;

    constructor(
        private reservationFlowService: ReservationFlowService,
        @Optional() private nativeBridgeService?: NativeBridgeService
    ) {}

    ngOnInit(): void {
        this.isFromCms();
    }

    /**
     * Detects if the image comes from cms to differentiate when require contextpath the path of the image
     */
    isFromCms(): void {
        if (this.imageLogo && !this.imageLogo.includes('http')) {
            this.imageLogo = this.contextPath + this.imageLogo;
        }
    }

    /**
     * Open side Modal
     */
    openSideModal(): void {
        this.dclSideModal.openModal();
        if (this.nativeBridgeService) {
            this.isEmbedded = this.reservationFlowService.isModFlow() &&
                this.nativeBridgeService.isEmbedded();

            if (this.isEmbedded) {
                this.isOpenModal = true;
                this.parentWithScroll = document.querySelector('dcl-modal[open]').shadowRoot.querySelector('#body');
                this.nativeBridgeService.setNativeLinks('.side-modal-body .native-bridge-links');
            }
        }
    }

    /**
     * Close side Modal
     *
     * @emits onModalAction (close)
     */
    closeSideModal(): void {
        this.dclSideModal.closeModal(true);
        this.onModalAction.emit(this.constants.events.closeModal);
    }

    /**
     * Filter itineraries
     */
    exploreItineraries(): void {
        // we are not using the "closeSideModal" method to prevent emit extra events
        this.dclSideModal.closeModal(true);
        this.onExploreItinerariesAction.emit(this.constants.events.exploreItineraries);

        if (this.isEmbedded) {
            this.isOpenModal = false;
        }
    }

    /**
     * Filter itineraries
     */
    closeModalFromBaymax(): void {
        this.dclSideModal.closeModal(true);
        this.isOpenModal = false;
    }
}
