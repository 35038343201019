import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';

import { noPoBoxValidator } from './custom-validators/no-po-box';

const MAX_LENGTH_CONSTANTS = {
    addressLineOne: 30,
    addressLineThree: 30,
    addressLineTwo: 30,
    city: 32,
    county: 28,
    phone: 10,
    phoneDefault: 20,
    postal: 10,
    territory: 28,
    zip: 5
};

const MIN_LENGTH_CONSTANTS = {
    phone: 10,
    phoneDefault: 1,
    zip: 5
};

export const CONTACT_CONSTANTS = {
    countries: {
        US: {
            iso: 'US',
            name: 'United States',
        },
        CA: {
            iso: 'CA',
            name: 'Canada',
        },
        GB: {
            iso: 'GB',
            name: 'United Kingdom',
        },
        OTHER: {
            iso: 'OTHER'
        }
    },
    validators: {
        country: [
            Validators.required
        ],
        addressLineOne: [
            Validators.required,
            Validators.maxLength(MAX_LENGTH_CONSTANTS.addressLineOne),
            Validators.pattern(/^[0-9a-zA-Z\s\-\.,\(\)\#%&@\'\/]{1,100}$/),
            noPoBoxValidator
        ],
        addressLineTwo: [
            Validators.maxLength(MAX_LENGTH_CONSTANTS.addressLineTwo),
            Validators.pattern(/^[0-9a-zA-Z\s\-\.,\(\)\#%&@\'\/]{1,100}$/)
        ],
        addressLineThree: [
            Validators.maxLength(MAX_LENGTH_CONSTANTS.addressLineThree),
            Validators.pattern(/^[0-9a-zA-Z\s\-\.,\(\)\#%&@\'\/]{1,100}$/)
        ],
        city: [
            Validators.required,
            Validators.maxLength(MAX_LENGTH_CONSTANTS.city),
            Validators.pattern(/^[0-9a-zA-Z\s\-\.,\(\)\#%&@\'\/]{1,32}$/)
        ],
        state: [
            Validators.required
        ],
        county: [
            Validators.maxLength(MAX_LENGTH_CONSTANTS.county),
            Validators.pattern(/^[0-9a-zA-Z\s\-\.,\(\)\#%&@\'\/]{1,28}$/)
        ],
        province: [
            Validators.required
        ],
        territory: [
            Validators.maxLength(MAX_LENGTH_CONSTANTS.territory),
            Validators.pattern(/^[0-9a-zA-Z\s\-\.,\(\)\#%&@\'\/]{1,28}$/)
        ],
        zip: [
            Validators.required,
            Validators.minLength(MIN_LENGTH_CONSTANTS.zip),
            Validators.maxLength(MAX_LENGTH_CONSTANTS.zip),
            Validators.pattern('[0-9]*')
        ],
        postal: {
            GB: [
                Validators.required,
                Validators.maxLength(MAX_LENGTH_CONSTANTS.postal),
                Validators.pattern(new RegExp(['^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ]',
                    '[a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9]',
                    '[a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]',
                    '[0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9]',
                    '[abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$'].join(''), 'i')
                )
            ],
            CA: [
                Validators.required,
                Validators.maxLength(MAX_LENGTH_CONSTANTS.postal),
                Validators
                    .pattern(/^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} {0,1}\d{1}[A-Za-z]{1}\d{1}$/i)
            ],
            default: [
                Validators.required,
                Validators.maxLength(MAX_LENGTH_CONSTANTS.postal),
                Validators.pattern(/^[0-9a-zA-Z\-]{1,10}$/)
            ]
        },
        phone: {
            US: [
                Validators.required,
                Validators.minLength(MIN_LENGTH_CONSTANTS.phone),
                Validators.maxLength(MAX_LENGTH_CONSTANTS.phone),
                Validators.pattern(/^\d{10}$/)
            ],
            CA: [
                Validators.required,
                Validators.minLength(MIN_LENGTH_CONSTANTS.phone),
                Validators.maxLength(MAX_LENGTH_CONSTANTS.phone),
                Validators.pattern(/^\d{10}$/)
            ],
            default: [
                Validators.required,
                Validators.minLength(MIN_LENGTH_CONSTANTS.phoneDefault),
                Validators.maxLength(MAX_LENGTH_CONSTANTS.phoneDefault),
                Validators.pattern(/^\d{1,20}$/)
            ]
        },
        email:  [
            Validators.required,
            Validators.pattern(['^([a-zA-Z0-9\\-_\\+]+)(\\.[a-zA-Z0-9\\-_\\+]+)*',
                '@([a-zA-Z0-9\\-_]+)(\\.[a-zA-Z0-9\\-_]+)*(\\.[a-zA-Z]{2,4})$'].join(''))
        ]
    },
    errorKeys: {
        country: {
            required: 'contact-information.country-errors.empty'
        },
        zip: {
            required: 'contact-information.zip-errors.empty',
            maxlength: 'contact-information.zip-errors.regex',
            minlength: 'contact-information.zip-errors.regex',
            pattern: 'contact-information.zip-errors.regex'
        },
        address: {
            required: 'contact-information.address-line-one-errors.empty',
            maxlength: 'contact-information.address-line-one-errors.regex',
            pattern: 'contact-information.address-line-one-errors.regex',
            noPoBox: 'contact-information.address-line-one-errors.poBox'
        },
        addressLineTwo: {
            maxlength: 'contact-information.address-line-two-errors.regex',
            pattern: 'contact-information.address-line-two-errors.regex',
        },
        city: {
            required: 'contact-information.city-errors.empty',
            pattern: 'contact-information.city-errors.regex'
        },
        state: {
            required: 'contact-information.state-errors.empty'
        },
        province: {
            required: 'contact-information.province-errors.empty'
        },
        territory: {
            required: 'contact-information.territory-errors.empty',
            pattern: 'contact-information.territory-errors.regex'
        },
        postal: {
            required: 'contact-information.postal-errors.empty',
            maxLength: 'contact-information.postal-errors.regex',
            pattern: 'contact-information.postal-errors.regex'
        },
        phone: {
            required: 'contact-information.phone-errors.empty',
            pattern: 'contact-information.phone-errors.regex',
            maxlength: 'contact-information.phone-errors.regex',
            minlength: 'contact-information.phone-errors.regex'
        },
        email: {
            required: 'contact-information.email-errors.empty',
            pattern: 'contact-information.email-errors.regex'
        },
        county: {
            pattern: 'contact-information.county-errors.regex'
        }
    },
    piiClasses: {
        GUEST_SENSITIVE_CLASS: 'guestSensitive'
    },
    maxLength: MAX_LENGTH_CONSTANTS
};
