import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { ErrorHandlingService } from '../../services/error-handling/error-handling.service';
import { HTTP_STATUS_CODES } from '../../services/at-home/at-home.constants';

@Injectable()
export class DclCanActivateNotFoundErrorGuard implements CanActivate {
    constructor(private errorHandlingService: ErrorHandlingService) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.errorHandlingService.redirectToError(HTTP_STATUS_CODES.NOT_FOUND, state.url);

        return true;
    }
}
