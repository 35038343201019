<section class="price-summary">
    <h3 class="title" translate>
        rateDetailsModal.priceDetails
    </h3>
    <div class="price-content">
        <ng-template ngFor let-item [ngForOf]="guests">
            <div class="price-item">
                <div>
                    <span>{{ item.ageGroup | ageGroupValue: isDclInternationalDirect2 | translate | titlecase }} {{ item.count }} {{ showGst ? ('priceSummary.gstInclusive' | translate) : '' }}</span>
                </div>
                <div>
                    <wdpr-price
                        class="price"
                        presentation="inline"
                        hide-currency
                        [value]="item.subtotal"
                        [currency]="item.currency">
                    </wdpr-price>
                    <span *ngIf="isDclInternationalDirect"
                        class="price">
                        {{ item.currency }}
                    </span>
                </div>
            </div>
        </ng-template>
        <div class="price-item">
            <button class="dcl-link taxes-button" (click)="goToTaxes.emit()">
                <span [innerHtml]="'priceSummary.taxModalLink' + (showGst ? 'Gst': '') | translate"></span>
            </button>
            <div>
                <wdpr-price
                    class="price"
                    presentation="inline"
                    hide-currency
                    [value]="price.summary.tax"
                    [currency]="price.summary.currency">
                </wdpr-price>
                <span *ngIf="isDclInternationalDirect"
                    class="price">
                    {{ price.summary.currency }}
                </span>
            </div>
        </div>
    </div>

    <div class="price-item">
        <span translate>{{ totalStateroomPriceText }}</span>
        <div>
            <wdpr-price
                class="total-price"
                presentation="inline"
                hide-currency
                [value]="price.summary.total"
                [currency]="price.summary.currency">
            </wdpr-price>
            <span *ngIf="isDclInternationalDirect"
                class="total-price">
                {{ price.summary.currency }}
            </span>
        </div>
    </div>
    <!--Internationalized price-->
    <div class="price-item" *ngIf="isDclInternationalDirect && price?.summary?.convertedTotal">
        <span class="converted-price">{{ currencyLabel }}</span>
        <div class="converted-total">
            <wdpr-price
                class="total-price converted-price"
                presentation="inline"
                hide-currency
                [value]="price.summary.convertedTotal"
                [currency]="price.summary.convertedCurrency"
                [locale]="locale">
            </wdpr-price>
            <span class="total-price converted-price total-currency">
                {{ price.summary.convertedCurrency }}
            </span>
        </div>
    </div>

    <div class="legend">
        <span
            class="text"
            [innerHtml]="priceDetailsLegend | translate">
        </span>
    </div>
</section>
