import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';

import { EventService } from '../../../services/event-service/event.service';
import { Filter } from '../../interfaces/filter.interface';
import { getKeyboardKey, isKeyboardKey } from '../../../utils/keyboard/keyboard-utils';
import { KeyboardKeys } from '../../../utils/keyboard/keyboard-keys';
import { QUICK_QUOTE_CONSTANTS } from '../../quick-quote.constants';
import { QuickQuoteService } from '../../services/quick-quote.service';
import { TRAVEL_PARTY_CONSTANTS } from '../../../travel-party/travel-party.constants';
import { ViewChildProperties } from '../../../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-quick-quote-bar',
    templateUrl: './quick-quote-bar.component.html',
    styleUrls: ['./quick-quote-bar.component.scss']
})
export class QuickQuoteBarComponent implements OnInit {
    @Input() disableViewCruisesButton: boolean;
    @Input() filterDataSubGroup: Filter;
    @Input() filterOptions: Filter[];
    @Input() hideViewCruisesButton: boolean;
    @Input() isMobile: boolean;
    @Input() isOpen: boolean;
    @Output() onApplyFilters = new EventEmitter();
    @Output() onFilterSelected = new EventEmitter();
    @ViewChild('viewCruisesButton', { static: false } as ViewChildProperties) viewCruisesButton: ElementRef;
    @ViewChildren('tabs') filtersTabs: QueryList<ElementRef>;

    constants = QUICK_QUOTE_CONSTANTS;
    filterSelectedIndex: number;
    hasTPError = false;
    tabindex: number = 0;
    travelPartyConstants = TRAVEL_PARTY_CONSTANTS;

    constructor(
        private eventService: EventService,
        private quickQuoteService: QuickQuoteService
    ) {}

    ngOnInit() {
        this.setTPAriaFlag();

        this.quickQuoteService.getFocusBar().subscribe((elementId: string) => {
            if (!this.quickQuoteService.filterHasErrors(this.filterSelectedIndex)) {
                this.clearFilterSelected();
            }

            if (!elementId) {
                this.viewCruisesButton.nativeElement.focus();
            } else {
                const tabFound = this.filtersTabs.find((tab: ElementRef) => tab.nativeElement.id === elementId);

                if (tabFound) {
                    tabFound.nativeElement.focus();
                }
            }
        });
    }

    /**
     * Validates that none of the filters have invalid errors
     * Sets the filter selected and emits the index when is clicked. When is clicked in mobile
     * view (767px or below) just emits the index without selecting a index.
     * @param index
     */
    selectFilter(index: number): void {
        if (!this.quickQuoteService.filterHasErrors(this.filterSelectedIndex)) {
            if (this.isMobile) {
                delete this.filterSelectedIndex;
            } else {
                this.filterSelectedIndex === index ? delete this.filterSelectedIndex : this.filterSelectedIndex = index;
            }

            this.onFilterSelected.emit(index);
            this.tabindex = this.filterSelectedIndex !== undefined ? -1 : 0;
        }
    }

    /**
     * Actions when apply filters selected
     */
    applyFilters(): void {
        this.quickQuoteService.enableADA2TPErrors();
        const filterHasErrors = this.quickQuoteService.filterHasErrors(this.filterSelectedIndex);

        if (filterHasErrors) {
            this.eventService.sendNextItem({
                name: this.travelPartyConstants.travelPartyError,
                value: filterHasErrors
            });
        } else {
            delete this.filterSelectedIndex;
            this.tabindex = 0;
            this.onApplyFilters.emit();
        }
    }

    /**
     * Handles the key down event for the filters tabs
     * @param event Keyboard event
     * @param index filter index
     */
    onKeyPress(event: KeyboardEvent, index: number): void {
        const pressedKeyOnBrowser: string = getKeyboardKey(event);

        if (this.quickQuoteService.filterHasErrors(this.filterSelectedIndex)) {
            if (event.shiftKey || isKeyboardKey(event, KeyboardKeys.Space)) {
                event.preventDefault();
            }

            return;
        }

        switch (pressedKeyOnBrowser) {
            case KeyboardKeys.Enter:
            case KeyboardKeys.Space:
                event.preventDefault();
                this.selectFilter(index);
                break;

            case KeyboardKeys.ArrowLeft:
                this.focusPrevTab(index);
                break;

            case KeyboardKeys.ArrowRight:
                this.focusNextTab(index);
                break;

            case KeyboardKeys.Escape:
                this.clearFilterSelected();
                break;

            case KeyboardKeys.Tab:
                if (event.shiftKey) {
                    this.clearFilterSelected();
                }

                break;
        }
    }

    /**
     * Clears the filter selected and emit the event to close the Quick Quote Wrapper
     */
    clearFilterSelected(): void {
        if (this.filterSelectedIndex !== undefined) {
            this.tabindex = 0;
            this.onFilterSelected.emit(this.filterSelectedIndex);
            delete this.filterSelectedIndex;
        }
    }

    /**
     * Sends the focus to the next filter tab based on the filter index provided
     * @param index filter index
     */
    focusNextTab(index: number): void {
        this.clearFilterSelected();

        if (index === this.filtersTabs.length - 1) {
            if (this.quickQuoteService.verifyIsMobile()) {
                this.filtersTabs.first.nativeElement.focus();
            } else {
                this.viewCruisesButton.nativeElement.focus();
            }
        } else {
            this.filtersTabs.toArray()[index + 1].nativeElement.focus();
        }
    }

    /**
     * Sends the focus to the previous filter tab based on the filter index provided
     * @param index filter index
     */
    focusPrevTab(index: number): void {
        this.clearFilterSelected();

        if (index === 0) {
            if (this.quickQuoteService.verifyIsMobile()) {
                this.filtersTabs.last.nativeElement.focus();
            } else {
                this.viewCruisesButton.nativeElement.focus();
            }
        } else {
            this.filtersTabs.toArray()[index - 1].nativeElement.focus();
        }
    }

    /**
     * Sets flag which indicates if there are Travel Party errors
     * Used for a11y purposes
     */
    setTPAriaFlag(): void {
        this.eventService.getSubject(this.travelPartyConstants.travelPartyError).subscribe(() => {
            this.hasTPError = this.quickQuoteService.filterHasErrors(this.filterSelectedIndex);
        });
    }
}
