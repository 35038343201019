import { Component, OnInit } from '@angular/core';
import { StoryAbstractComponent } from '../shared/story-abstract.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

declare const window: any;

@Component({
  selector: 'pixie-vpg',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./vpg.component.scss']
})
export class VpgComponent extends StoryAbstractComponent implements OnInit {
  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }
  earlyBookingLevels = [
    'SILVER',
    'GOLD',
    'PLATINUM',
    'TAEarlyBookingBanner'
  ];

  ngOnInit(): void {
    document.addEventListener('navigation', async(event: any) => {
      if (event.detail.event === 'oneId-init' &&
          !!window.PEP?.PROFILE_AUTH
      ) {
        window.PEP.PROFILE_AUTH.getGuest()
          .then(async guestObj => {
            const authToken = guestObj.token.access_token;
            this.checkEarlyBookingAffil(authToken);
          });
      }
    });
  }

  checkEarlyBookingAffil(authToken) {
    if (!authToken) {
      console.log('vpg:token is not available');
    }

    const SWID = this.cookieService.get('SWID');
    if (!SWID) {
      console.log('vpg:swid is not available');
    }

    const guestAffiliationsPath = `/features/gam/${SWID}`;
    fetch(guestAffiliationsPath, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'BEARER ' + authToken
      }
    }).then((resp) => {
      resp.json().then((data) => {
        console.log('vpg:success getting affiliations');
        if (data && data.affiliations && data.affiliations.length) {
          console.log('vpg:affiliations data', data);
          const foundEarlyBookingAffil = data.affiliations.find(affil => (
            affil.category === 'CASTAWAYCLUB' && this.earlyBookingLevels.indexOf(affil.type) > -1)
          );
          if (foundEarlyBookingAffil) {
            console.log('vpg:early booking affiliation found:', foundEarlyBookingAffil.category, foundEarlyBookingAffil.type);
            // hide banner
            this.storyData = {
              sectionHeader: {},
              blockGroups: [],
              dynamicStyles: null
            };
          } else {
              console.log('vpg:no early booking affiliation found');
          }
        } else {
          console.log('vpg:no guest affiliations found');
        }
      }).catch (e => {
        console.log('vpg:parse affiliations error', e);
      });
    }).catch(e => {
      console.log('vpg:fetch affiliations error', e);
    });
  }

  getTemplateBlockGroups(storyData) {
    const {items, itemsWrapped} = this._generateDCLBannerBlockItems(storyData);

    const templateBlockGroups = [{
      classes: 'banner',
      anchorName: 'dcl-looker-standard-banner-block-group-1-anchor',
      blocks: [{
        analyticsTrackSeen: this.createAnalyticsTrackSeen(storyData, 1),
        classes: 'banner story-block-1 story-block-row ',
        items,
        itemsWrapped
      }]
    }];

    return templateBlockGroups;

  }

  /*
   * Construct DCL Banner template block items
   * @param {Object} Story data
   * @returns {Array} Generated items
   */
  _generateDCLBannerBlockItems(storyData) {
    const links = [];
    const bgRegex = /(^| )background( |$)/;

    if (storyData.modules.length > 0 && storyData.modules[0].descriptions) {
      storyData.modules[0].descriptions.forEach((link, index) => {
        links.push({
          'type': 'link',
          'classes': 'banner-link',
          'href': link.webLinks.link1.href,
          'analyticsLinkId': this.getStoryAnalyticslinkId(
            storyData,
            index,
            link.webLinks.link1.analyticsLinkId
          ),
          'items': [
            this.getIconTemplate({
                icon: link.webfont.htmlCharacter,
                title: link.webLinks.link1.title,
                htmlentity: link.webfont.htmlCharacter
              },
              'banner-link-icon'
            ),
            this.getTextTemplate(link.webLinks.link1.title, false, 'banner-link-text')
          ]
        });
      });
    }

    const items = [
      this.getImageTemplate(
        storyData.modules[0].media.dclBannerBackgroundDesktop,
        'banner-desktop-bg background hidden-xs hidden-sm visible-md visible-lg',
        false
      ),
      this.getImageTemplate(
        storyData.modules[0].media.dclBannerBackgroundMobile,
        'banner-mobile-bg background visible-xs visible-sm hidden-md hidden-lg',
        false
      ),
      this.getImageTemplate(storyData.modules[0].media.shipImage, 'ship-image'),
      this.getTextTemplate(storyData.modules[0].sections.title, false, 'banner-title'),
      this.getTextTemplate(
        storyData.modules[0].sections.subtitle,
        false,
        'banner-subtitle hidden-xs hidden-sm visible-md visible-lg'
      ),
      this.getTextTemplate(
        storyData.modules[0].sections.mobileSubtitle,
        false,
        'banner-subtitle visible-xs visible-sm hidden-md hidden-lg'
      ),
      this.getWrapperTemplate(links, 'banner-links-wrapper')
    ];

    const itemsWrapped = {
      background: [
        items[0],
        items[1]
      ],
      foreground: [
        items[2],
        items[3],
        items[4],
        items[5],
        items[6]
      ]
    };

    return {items, itemsWrapped};
  }

}
