import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { HealthMonitorService } from './health-monitor.service';

/**
 * This is a workarround and can be deleted once v11 has been reachead
 *
 * @see https://github.com/stevermeister/ngx-cookie-service/issues/100#issuecomment-679053266
 */
@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        CookieService,
        HealthMonitorService
    ]
})
export class HealthMonitorModule { }
