import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';

import { AnalyticsDataModel } from './interfaces/analytics-data-model.interface';
import { AnalyticsService } from './services/analytics.service';
import { ContextualFilterService } from '../contextual-filter/services/contextual-filter.service';
import { EventService } from '../services/event-service/event.service';
import { EventType } from '../services/interfaces/event-type';
import { Filter } from './interfaces/filter.interface';
import { GrayoutService } from './services/grayout.service';
import { NewItinerariesV2BannerService } from './services/new-itineraries-v2.banner.service';
import { NewItinerariesV3BannerService } from './services/new-itineraries-v3.banner.service';
import { OptionsGroup } from '../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from './quick-quote.constants';
import { QuickQuoteService } from './services/quick-quote.service';
import { ReservationIdService } from '../services/reservation-id/reservation-id.service';
import { ReservationFlowService } from '../services/reservation-flow/reservation-flow.service';
import { ReservationSummary } from '../reservation-summary-bar/reservation-summary.interface';
import { TravelPartyService } from '../travel-party/services/travel-party.service';

@Component({
    selector: 'dcl-quick-quote',
    templateUrl: './quick-quote.component.html',
    styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent implements OnInit, OnChanges {
    @Input() advancedBookingBannerFilters: string[];
    @Input() contextPath: string;
    @Input() data: {};
    @Input() disableViewCruisesButton: boolean;
    @Input() emitNewItinerariesClick: boolean;
    @Input() hideViewCruisesButton: boolean;
    @Input() isDclAdvancedBookingPhase2: boolean;
    @Input() isDclAdvancedBookingPhase3: boolean;
    @Input() isDclShipAdaPhase2: boolean;
    @Input() isDclInternationalDirectPhase2: boolean;
    @Input() productsAvailability: boolean;
    @Input() isDclAdvancedBooking: boolean;
    @Input() reservationSummary: ReservationSummary;
    @Input() updateURL: boolean;
    @Input() useLuxliteTheme: boolean;
    @Output() analyticsTrackingEvent: EventEmitter<AnalyticsDataModel> = new EventEmitter();
    @Output() analyticsUpdateModelEvent: EventEmitter<AnalyticsDataModel> = new EventEmitter();
    @Output() filterOptionGroupEvent: EventEmitter<OptionsGroup> = new EventEmitter();
    @Output() newItinerariesClickEvent: EventEmitter<AnalyticsDataModel> = new EventEmitter();
    @Output() onOpenChange: EventEmitter<boolean> = new EventEmitter();
    @Output() advanceBookingBannerClickEvent = new EventEmitter();

    constants = QUICK_QUOTE_CONSTANTS;
    filterData: Filter[];
    filterDataSubGroup: Filter;
    filterSelectedIndex: number;
    isMobile: boolean;
    isModalOpen: boolean;
    isOpen: boolean;
    moment = moment;
    reservationId: Number;
    screenWidth: number;
    isModFlow: boolean;

    constructor(
        private analyticsService: AnalyticsService,
        private contextualFilterService: ContextualFilterService,
        private eventService: EventService,
        private grayoutService: GrayoutService,
        private newItinerariesV2BannerService: NewItinerariesV2BannerService,
        private newItinerariesV3BannerService: NewItinerariesV3BannerService,
        private quickQuoteService: QuickQuoteService,
        private reservationFlowService: ReservationFlowService,
        private reservationIdService: ReservationIdService,
        private translateService: TranslateService,
        private travelPartyService: TravelPartyService
    ) { }

    ngOnInit(): void {
        this.isModFlow = this.reservationFlowService.isModFlow();
        this.reservationId = this.reservationIdService.getId();
        this.quickQuoteService.setHomepageToggleValueDclInternationalDirectPhase2(this.isDclInternationalDirectPhase2);
        this.quickQuoteService.setIsDclAdvancedBooking(this.isDclAdvancedBooking);
        this.isMobile = this.quickQuoteService.verifyIsMobile();
        this.isModalOpen = false;
        this.setIsOpen(false);
        this.filterData = this.quickQuoteService.setModel(this.data);
        this.quickQuoteService.getPreSelectedFilters(this.updateURL, this.reservationSummary);
        this.quickQuoteService.setContextPath(this.contextPath);
        this.emitAnalyticsDataModel();
        this.contextualFilterService.subscribeFilterOptionGroup();

        this.quickQuoteService
            .getModelChangedObservable()
            .subscribe((): void => {
                this.filterData = this.quickQuoteService.getModel();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.data && !changes.data.firstChange) {
            this.filterData = this.quickQuoteService.setModel(changes.data.currentValue);

            if (this.isModFlow) {
                this.quickQuoteService.getPreSelectedFilters(this.updateURL, this.reservationSummary);
            }

            this.contextualFilterService.subscribeFilterOptionGroup();
            this.initNewSailingBanner();
        }

        if (changes && changes.advancedBookingBannerFilters && !changes.advancedBookingBannerFilters.firstChange) {
            this.initNewSailingBanner();
        }
    }

    /**
     * Emits the filter options group received in the params if it is defined
     *
     * @param filterType filter type to be emitted
     */
    emitFilterOptions(filterType: string): void {
        // TODO: ask the team if I can delete this unused method and the emitter
        const filterOptionGroup = this.quickQuoteService.getOptionGroupFiltersByType(filterType);

        if (filterOptionGroup) {
            this.filterOptionGroupEvent.emit(filterOptionGroup);
        }
    }

    /**
     * emit the analytics data model from event service using the analytics data model name to get it
     */
    emitAnalyticsDataModel(): void {
        let analyticsApplyFiltersModel;

        if (this.isModFlow) {
            analyticsApplyFiltersModel = this.analyticsService.applyFiltersAnalyticsTrackLink();
            this.analyticsUpdateModelEvent.emit(analyticsApplyFiltersModel);
        }

        this.eventService.getSubject(this.constants.analytics.analyticsSubjectEvent)
            .subscribe((data: EventType) => {
                this.analyticsTrackingEvent.emit(data.value as AnalyticsDataModel);
            });
    }

    /**
     * Catches the window:resize event to verify if its at mobile breakpoint or not
     */
    @HostListener('window:resize', [])
    onResize(): void {
        this.isMobile = this.quickQuoteService.verifyIsMobile();
    }

    /**
     * Assign the index of the filter that has been selected and identify if the filter wrapper
     * is been opened/closed
     * @param filterIndex
     */
    openFilter(filterIndex: number): void {
        if (this.isMobile) {
            this.isModalOpen = true;
            delete this.filterSelectedIndex;
        } else {
            this.quickQuoteService.mergeIntoSelectedFilters();
            this.grayoutService.checkGrayout();

            if (this.filterSelectedIndex === filterIndex) {
                this.analyticsTriggerEvent();
                delete this.filterSelectedIndex;
            } else {
                this.filterSelectedIndex = filterIndex;
                this.analyticsTriggerEvent();
            }
        }

        this.setFilterDataSubGroup();
        this.handleToggle(!(this.filterSelectedIndex > -1));
    }

    /**
     * throws the analytics data model with the tracklinkID when any filter in the QQBar is used
     */
    analyticsTriggerEvent(): void {
        const filterType = this.filterData[this.filterSelectedIndex].type;
        const analyticsModel: AnalyticsDataModel = {
            facetsUtilized: undefined,
            trackLinkID:
                `${
                    this.constants.analytics.cruiseFiltersLink
                }${
                    this.constants.analytics[filterType].filterButtonLink
                }`
        };

        this.analyticsTrackingEvent.emit(analyticsModel);
    }

    /**
     * Close the modal depending on the param received
     * @param isClosed
     */
    onCloseModal(isClosed: boolean): void {
        this.isModalOpen = !isClosed;
        const heroImage = document.querySelector('dcl-hero-image img');

        if (heroImage) {
            setTimeout(() => heroImage.scrollIntoView());
        }
    }

    /**
     * Handle the state of the component
     * @param prevState the prev state
     */
    handleToggle(prevState: boolean): void {
        this.setIsOpen(!prevState);

        if (!this.isOpen) {
            delete this.filterSelectedIndex;
        }
    }

    /**
     * @TOOD unify with mobile modal state property, for now "isOpen" is valid only for desktop
     * Determine the open/close state of this component
     * @param isOpen
     */
    setIsOpen(isOpen: boolean): void {
        this.isOpen = isOpen;
        this.onOpenChange.emit(isOpen);
    }

    /**
     * Functions that is triggered when click event is being sent after click on QuickQuoteBar
     * If the travel party has errors, the data won't persist and filter won't be applied
     * view cruises button
     * @param skipTrackLink Flag to skip the analytics track link call
     */
    onApplyFilters(skipTrackLink?: boolean): void {
        const travelPartyHasNoErrors = this.travelPartyService.updateTravelPartyStorage();

        if (travelPartyHasNoErrors) {
            delete this.filterSelectedIndex;
            this.grayoutService.checkGrayout();
            this.quickQuoteService.mergeIntoSelectedFilters();
            this.quickQuoteService.updateCookieValueFromSelectedFilters();

            if (this.updateURL) {
                this.quickQuoteService.updateURLFromCookieValue();
            }

            this.quickQuoteService.applyFilters();

            if (!skipTrackLink) {
                this.applyFiltersAnalyticsTrackLink();
            }

            this.handleToggle(true);
        }
    }

    /**
     * Functions that is triggered when are filters applied
     * to clear all the filter in the quick quote
     */
    onClearFilters(): void {
        const analyticsApplyFiltersModel = this.analyticsService.clearFiltersAnalyticsTrackLink();

        this.analyticsTrackingEvent.emit(analyticsApplyFiltersModel);
    }

    /*
     * Handle to close the wrapper component
     * @param event the filterType opened
     */
    closeWrapper(event: string): void {
        if (this.isOpen && this.travelPartyService.getStateroomsWithErrors().length === 0) {
            this.sendFocusToBar(event);
            this.handleToggle(true);
        }
    }

    /**
     * Sends the focus to the Quick Quote bar based on the filter type provided
     * @param filterType filter type
     */
    sendFocusToBar(filterType: string): void {
        this.quickQuoteService.focusToBar(filterType, -1);
    }

    /**
     * Creates and track an analytics data model getting all the analytics data
     * from the selected filters and the travel party service when the apply filters
     * function is called
     */
    applyFiltersAnalyticsTrackLink(): void {
        const analyticsApplyFiltersModel = this.analyticsService.applyFiltersAnalyticsTrackLink();

        this.analyticsTrackingEvent.emit(analyticsApplyFiltersModel);
    }

    /**
     * Set the content for the property "filterDataSubGroup" based on rules
     * @TODO this can be deleted when QQ support subgroups
     */
    setFilterDataSubGroup(): void {
        const filterSelected = this.filterData[this.filterSelectedIndex];

        if (filterSelected &&
            this.constants.filters['destination'].id === filterSelected.type) {
            this.filterDataSubGroup = this.filterData.find(
                (item: Filter) => item && item.type === this.constants.filters['privateIsland'].id
            );
        } else {
            this.filterDataSubGroup = undefined;
        }
    }

    /**
     * Emit the event when new itineraries banner is clicked
     * This method is called only when emitNewItinerariesClick is true
     * @param analiticsData
     */
    onNewItinerariesClickEvent(analiticsData: AnalyticsDataModel): void {
        if (!this.isMobile) {
            this.quickQuoteService.focusToBar(this.constants.filters['more-filters'].id, -1);
        }

        this.handleToggle(true);
        this.newItinerariesClickEvent.emit(analiticsData);
    }

    /**
     * Initialize the New Sailing banner filter V2,V3
     */
    private initNewSailingBanner(): void {
        if (this.isDclAdvancedBookingPhase3) {
            this.translateService.get(this.constants.translationKeys.advancedBookingBanner.filters)
                .subscribe((translation: string) => {
                    this.newItinerariesV3BannerService.setFilterBannerType(translation);
                });
        } else if (this.isDclAdvancedBookingPhase2) {
            this.newItinerariesV2BannerService.init(this.filterData, this.advancedBookingBannerFilters);
        }
    }
}
