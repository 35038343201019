import { Injectable } from '@angular/core';

@Injectable()
export class DclDatesService {
    constructor() {}

    /**
     * Transforms input into ISO calendar format
     * @param inputDate Date in MM/DD/YYYY format
     * @return Date in ISO calendar string (YYYY-MM-DD)
     */
    toISOCalendarString(inputDate: string): string {
        const dateParts = inputDate.split('/');
        const yearIndex = 2;
        const monthIndex = 0;
        const dayIndex = 1;

        return `${dateParts[yearIndex]}-${dateParts[monthIndex]}-${dateParts[dayIndex]}`;
    }
}
