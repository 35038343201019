import { Pipe, PipeTransform } from '@angular/core';

import { TYPE_AHEAD_CONSTANTS } from './type-ahead.constants';
@Pipe({
    name: 'boldValue'
})

export class BoldValuePipe implements PipeTransform {

    transform(item, search) {
        const regex = new RegExp(this.escapeRegExp(search), 'gi');

        return item.replace(regex, (match) => '<b>' + match + '</b>');
    }

    escapeRegExp(item) {
        return item.replace(TYPE_AHEAD_CONSTANTS.regex, '\\$&');
    }
}
