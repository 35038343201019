
<div class="errors"
    [attr.aria-live]="ariaHidden ? null : 'assertive'"
    [attr.aria-hidden]="ariaHidden">
    <div class="error"
        *ngFor="let errorType of errorListTypes">
        <i class="error-icon di di-alert" aria-hidden="true">
            <span class="sr-only">{{ 'error-messages.error-icon-label' | translate }}</span>
        </i>
        <span class="error-text" translate>{{ getErrorMessage(errorType) }}</span>
    </div>
</div>
