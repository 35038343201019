import { throwError, forkJoin, Observable, empty } from 'rxjs';
import { at, get as getValue } from 'lodash';
import { catchError, concatMap, finalize, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import * as momentModule from 'moment';

import { Address } from '../interfaces/address.interface';
import { AddressType } from './enums/address-type.enum';
import { AgencyAuthToken } from './interfaces/agency-auth-token.interface';
import { AgencyLogin } from './interfaces/agency-login.interface';
import { AgencyLogout } from './interfaces/agency-logout.interface';
import { AgencyLookup } from './interfaces/agency-lookup.interface';
import { AgencyPassport } from './interfaces/agency-passport.interface';
import { AgencyPassportRequest } from './interfaces/agency-passport-request.interface';
import { GuestCastawayInfo } from '../interfaces/guest-castaway-info.interface';
import { ItemStorage } from '../services/interfaces/item-storage';
import { LocalStorageService } from '../services/local-storage-service/local-storage.service';
import { ToggleService } from '../services/toggle/toggle.service';
import { TRAVEL_AGENT_CONSTANTS } from './travel-agent.constants';
import { TravelAgentInfoBuilder } from './factories/travel-agent-info.factory';
import { TravelAgentRestService } from './rest/travel-agent-rest.service';
import { WindowRef } from '../window-ref/window-ref.service';

@Injectable()
export class TravelAgentService {
    // TODO: flowEnabled property should be removed when travel agent toggle removed (enabled as default)
    private flowEnabled: boolean = false;
    private constants = TRAVEL_AGENT_CONSTANTS;
    private moment = momentModule;
    private oneHourExpirationDate: Date;
    private oneDayExpirationDate: Date;

    constructor(
        private cookieService: CookieService,
        private localStorageService: LocalStorageService,
        private travelAgentRestService: TravelAgentRestService,
        private toggleService: ToggleService,
        private windowRef: WindowRef
    ) { }

    /**
     * Set if travel agent flow is enabled based on toggle value
     * @param toggleValue travel agent feature toggle value
     */
    setFlowEnabled(toggleValue: boolean): void {
        this.flowEnabled = toggleValue;
    }

    /**
     * Set expiration times variables for session related and TA related cookies
     * @param expiresIn auth token expiration time
     */
    setCookiesExpirationTimes(expiresIn: string): void {
        this.oneHourExpirationDate = this.moment().add(
            parseInt(expiresIn, 10) || this.constants.SESSION_HOUR_SEC,
            'seconds'
        ).toDate();
        this.oneDayExpirationDate = this.moment().add(
            this.constants.DEFAULT_DAY_EXPIRATION_MIN,
            'minutes'
        ).toDate();
    }

    /**
     * Set webapi base url for rest service
     * @param baseUrl
     */
    setRestBaseUrl(baseUrl: string): void {
        this.travelAgentRestService.setBaseUrl(baseUrl);
    }

    /**
     * Validate if a Travel Agent user is logged in
     * @returns Validation result
     */
    isTravelAgentLoggedIn(): boolean {
        return this.flowEnabled && !!this.cookieService.get(this.constants.COOKIES.TA_INFO);
    }

    /**
     * Returns agency id for a logged in Travel Agent user
     * @returns Agency id value
     */
    getTravelAgencyId(): string {
        return this.cookieService.get(this.constants.COOKIES.AGENCY_ID);
    }

    /**
     * Returns auth token for a logged in Travel Agent user, to be used in Authorization header
     * @returns Value for Authorization header
     */
    getTravelAgentAuthToken(): string {
        return this.travelAgentRestService.getAuthHeader();
    }

    /**
     * Returns AgencyUUID based on agencyId and Conversation_UUID cookies
     * @returns AgencyUUID value for the session
     */
    getAgencyUUID(): string {
        const agencyId = this.getTravelAgencyId();

        return agencyId && `${this.constants.AGENCY_ID_PREFIX}${agencyId}`;
    }

    /**
     * Removes reservation from local storage
     * @param confirmationNumber confirmation number from reservation
     */
    removeStorageReservation(confirmationNumber: string) {
        if (this.localStorageService.getItemStorage(this.constants.STORAGE.RECENT_RESERVATIONS, true) &&
            confirmationNumber) {
            const recentReservations = this.localStorageService
                .getItemStorage(this.constants.STORAGE.RECENT_RESERVATIONS, true) as ItemStorage[];
            const validResevations = recentReservations.filter((reservation: ItemStorage) => {
                return reservation.confirmationNumber !== confirmationNumber;
            });

            this.localStorageService.setItemStorage(this.constants.STORAGE.RECENT_RESERVATIONS, validResevations);
        }
    }

    /**
     * Removes a Travel Agent related cookie with
     * regular domain
     * starting with dot domain
     * base level domain. Example: go.com, disney.go.com
     * @param cookieName
     */
    removeCookie(cookieName: string): void {
        const { path, domain, secondLevelDomain, thirdLevelDomain } = this.constants.COOKIES.COOKIE_OPTIONS;

        this.cookieService.delete(cookieName, path);
        this.cookieService.delete(cookieName, path, domain);
        this.cookieService.delete(cookieName, path, secondLevelDomain);
        this.cookieService.delete(cookieName, path, thirdLevelDomain);
    }

    /**
     * Stores auth token based cookies
     * @param data auth token response
     * @param { string } data.access_token auth token (B2B)
     * @param { string } data.expires_in auth token expiration time
     */
    storeAuthToken({ access_token, expires_in }: AgencyAuthToken): void {
        const cookieConstants = this.constants.COOKIES;
        const storageConstants = this.constants.STORAGE;
        const { path, domain } = cookieConstants.COOKIE_OPTIONS;
        const expires = this.moment().add(this.constants.SESSION_MIN, 'minutes').toDate();
        const ttlString = String(expires.getTime());
        const currentTime = String(new Date().valueOf());

        this.setCookiesExpirationTimes(expires_in);

        this.removeCookie(cookieConstants.SWID);
        this.removeCookie(cookieConstants.TOKEN);
        this.removeCookie(cookieConstants.TOKEN_EXPIRATION);
        this.removeCookie(cookieConstants.TOKEN_START_TIME);
        this.removeCookie(cookieConstants.TOKEN_REFRESH);
        this.removeCookie(cookieConstants.TOKEN_REFRESH_PP);
        this.removeCookie(cookieConstants.GUEST_INFO);
        this.removeCookie(cookieConstants.JWT_TOKEN);
        this.removeCookie(cookieConstants.REMEMBER_ME);
        this.removeCookie(cookieConstants.SESSION_TIMEOUT);
        this.removeCookie(cookieConstants.PHPSESSID);

        localStorage.removeItem(storageConstants.ADRUM_CLIENTINFO);

        this.cookieService.set(cookieConstants.TOKEN, access_token, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.TA_AUTH, access_token, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.SESSION_TTL, ttlString, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.TOKEN_EXPIRATION, expires_in, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.TOKEN_START_TIME, currentTime, this.oneHourExpirationDate, path, domain);
    }

    /**
     * Stores agency info based cookies
     * @param data Agency info response
     * @param fromDTA flag to indicate whether or not is from DTA
     * @param isInternationalDirect flag to know when internationalization is active
     */
    storeAgencyInfo(data: AgencyLookup, fromDTA?: boolean, isIntl?: boolean, isIntlPhase2?: boolean): void {
        let taInfoJSON: string;
        const taInfoBuilder = new TravelAgentInfoBuilder();
        const infoPaths: string[] = [
            'agencies.agency.agencyHeader',
            'agencies.agency.agencyAddresses.address',
            'agencies.agency.phones.phone[0]'
        ];
        const [headerInfo, addressesInfo, phoneInfo] = at(data, infoPaths);
        const cookieConstants = this.constants.COOKIES;
        const localeConfig = cookieConstants.LOCALE_COOKIE;
        const internationalConfig = cookieConstants.INTERNATIONAL_COOKIE;
        const { path, domain } = cookieConstants.COOKIE_OPTIONS;
        const agencyId = headerInfo.agencyID;
        const guestSwid = `${this.cookieService.get(cookieConstants.CONVERSATION_UUID)}_${headerInfo.agencyID}`;
        const sessionGuid = data.msgHeader.sessionGuid;
        const showConvertionCountriesPhase1 = this.constants.SHOW_CONVERTION_COUNTRIES_PHASE1;
        const showConvertionCountriesPhase2 = this.constants.SHOW_CONVERTION_COUNTRIES_PHASE2;
        const showConvertionCountries = isIntlPhase2 ?
            showConvertionCountriesPhase1.concat(showConvertionCountriesPhase2) :
            showConvertionCountriesPhase1;
        const primaryAddress: Address =
            addressesInfo.find((address: Address) => address.addressType === AddressType.Primary);
        const emailAddress: Address =
            addressesInfo.find((address: Address) => address.addressType === AddressType.Email);
        const addressInfo: Address = primaryAddress || emailAddress;

        let defaultCurrency = localeConfig.defaultCurrency;
        let currencyConfig = localeConfig.currency[defaultCurrency];

        if (isIntl || isIntlPhase2) {
            const isUSDAgency = headerInfo.defaultCurrency === defaultCurrency;
            const isFromConvertionCountries = showConvertionCountries.includes(primaryAddress.country);
            const doesContryConfigExist = primaryAddress.country
                && internationalConfig.currency[primaryAddress.country];

            currencyConfig = isFromConvertionCountries &&
                isUSDAgency &&
                doesContryConfigExist ?
                internationalConfig.currency[primaryAddress.country] :
                localeConfig.currency[headerInfo.defaultCurrency];
            defaultCurrency = currencyConfig.currencyCode;
        }

        const localeCookie = {
            contentLocale: currencyConfig.contentLocale,
            preferredRegion: currencyConfig.preferredRegion,
            localeCurrency: defaultCurrency,
            precedence: localeConfig.precedence,
            version: localeConfig.version
        };
        const localeCookieString = JSON.stringify(localeCookie);
        const localeCookieExpiration = new Date();
        localeCookieExpiration.setDate(localeCookieExpiration.getDate() + localeConfig.expireDays);

        taInfoJSON = taInfoBuilder.setAddressLine1(addressInfo.address)
            .setAddressLine2('')
            .setAgencyName(headerInfo.name)
            .setAgentId(data.agentId)
            .setCity(addressInfo.city)
            .setCountry(addressInfo.country)
            .setDefaultCurrency(headerInfo.defaultCurrency)
            .setEmail(data.agentEmail)
            .setFirstName(data.agentFirstName)
            .setId(agencyId)
            .setLastName(data.agentLastName)
            .setPartnerId(headerInfo.clia || headerInfo.arc || headerInfo.iata)
            .setPartnerIdType(this.constants.AFFILIATION_LABEL)
            .setPhone(phoneInfo.phoneNumber)
            .setReturnUrl(fromDTA ? this.getDTAReferrer() : '')
            .setSate(addressInfo.state)
            .setZipCode(addressInfo.zip)
            .buildAsJSON();

        if (agencyId !== this.cookieService.get(cookieConstants.AGENCY_ID)) {
            this.removeCookie(cookieConstants.CART_ID_MAPPING);
            localStorage.removeItem(this.constants.STORAGE.ACTIVE_SESSION);
            localStorage.removeItem(this.constants.STORAGE.RECENT_RESERVATIONS);
            sessionStorage.removeItem(this.constants.STORAGE.GUEST_CASTAWAY_INFO);
        }

        this.cookieService.set(cookieConstants.TA_SWID, guestSwid, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.AGENCY_ID, agencyId, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.TICKET_DATA, taInfoJSON, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(cookieConstants.SESSION_GUID, sessionGuid, this.oneHourExpirationDate, path, domain);
        this.cookieService.set(localeConfig.cookieName, localeCookieString, localeCookieExpiration, path);
        this.cookieService.set(localeConfig.cookieNameAka, localeCookieString, localeCookieExpiration, path);
    }

    /**
     * Stores SESSION_GUID cookie
     * @param data Agency info response
     */
    storeSessionGuid(data: AgencyLookup): void {
        const cookieConstants = this.constants.COOKIES;
        const { path, domain } = cookieConstants.COOKIE_OPTIONS;
        const sessionGuid = data && data.msgHeader && data.msgHeader.sessionGuid;

        this.cookieService.delete(cookieConstants.SESSION_GUID, path, domain);
        this.cookieService.set(cookieConstants.SESSION_GUID, sessionGuid, this.oneHourExpirationDate, path, domain);
    }

    /**
     * Stores agency passport cookie
     * @param data passport token response
     * @param { string } data.token passport token
     */
    storeAgencyPassportToken({ token }: AgencyPassport): void {
        const { path, domain } = this.constants.COOKIES.COOKIE_OPTIONS;

        this.cookieService.set(this.constants.COOKIES.TA_INFO, token, this.oneDayExpirationDate, path, domain);
    }

    /**
     * Retrieve passport token request payload based on agency information
     * @param agencyInfo agency info response
     * @returns agency passport token request data
     */
    getPassportTokenRequestData(agencyInfo: AgencyLookup): AgencyPassportRequest {
        const agencyHeader = getValue(agencyInfo, 'agencies.agency.agencyHeader');
        const phoneNumber = getValue(agencyInfo, 'agencies.agency.phones.phone[0].phoneNumber');
        const affiliationId = agencyHeader && (agencyHeader.clia || agencyHeader.arc || agencyHeader.iata);

        return <AgencyPassportRequest>{
            phoneNumber,
            affiliationId,
            reservationId: this.constants.DEFAULT_RESERVATION_ID
        };
    }

    /**
     * Clear all travel agent data
     */
    clearTravelAgentData(): void {
        const cookieConstants = this.constants.COOKIES;
        const { path, domain } = cookieConstants.COOKIE_OPTIONS;

        this.cookieService.delete(cookieConstants.TOKEN, path, domain);
        this.cookieService.delete(cookieConstants.TOKEN_EXPIRATION, path, domain);
        this.cookieService.delete(cookieConstants.TOKEN_START_TIME, path, domain);
        this.cookieService.delete(cookieConstants.SESSION_TTL, path, domain);
        this.cookieService.delete(cookieConstants.TA_AUTH, path, domain);
        this.cookieService.delete(cookieConstants.AGENCY_ID, path, domain);
        this.cookieService.delete(cookieConstants.SESSION_GUID, path, domain);
        this.cookieService.delete(cookieConstants.TICKET_DATA, path, domain);
        this.cookieService.delete(cookieConstants.TA_SWID, path, domain);
        this.cookieService.delete(cookieConstants.TA_INFO, path, domain);
        this.cookieService.delete(cookieConstants.LOCALE_COOKIE.cookieName, path);
        this.cookieService.delete(cookieConstants.LOCALE_COOKIE.cookieNameAka, path);
        localStorage.removeItem(this.constants.STORAGE.RECENT_RESERVATIONS);
        localStorage.removeItem(this.constants.STORAGE.ACTIVE_SESSION);
        sessionStorage.removeItem(this.constants.STORAGE.GUEST_CASTAWAY_INFO);
    }

    /**
     * Clear DTA referrer from storage
     */
    removeDTAReferrer(): void {
        localStorage.removeItem(this.constants.STORAGE.DTA_REFERRER);
    }

    /**
     * Return DTA referrer from storage
     * @returns DTA referrer
     */
    getDTAReferrer(): string {
        return localStorage.getItem(this.constants.STORAGE.DTA_REFERRER);
    }

    /**
     * Store DTA referrer in local storage
     */
    storeDTAReferrer(): void {
        if (!this.getDTAReferrer()) {
            localStorage.setItem(this.constants.STORAGE.DTA_REFERRER, document.referrer);
        }
    }

    /**
     * Method to redirect to DTA site
     */
    redirectToDTAReferrer(): void {
        const referrer = this.getDTAReferrer();

        this.removeDTAReferrer();
        this.windowRef.nativeWindow.location.href = referrer;
    }

    /**
     * Login based on agency data
     * @param data agency login data
     * @param data.phoneNumber agency phone number
     * @param data.affiliationId agency affiliation id
     * @param data.affiliationId agency affiliation id
     * @param isIntl flag to know when internationalization is active
     * @param isIntlPhase2 flag to know when internationalization is active
     * @returns Observable for agency login process
     */
    agencyLogin({ phoneNumber, affiliationId }: AgencyLogin,
        isIntl: boolean = false,
        isIntlPhase2: boolean = false
): Observable<null> {
        return this.travelAgentRestService.getAuthToken()
            .pipe(
                tap((resp: AgencyAuthToken) => this.storeAuthToken(resp)),
                concatMap(() =>
                    forkJoin([
                        this.travelAgentRestService.agencyLookup({ phoneNumber, affiliationId })
                            .pipe(
                                tap((resp: AgencyLookup) => this.storeAgencyInfo(resp, false, isIntl, isIntlPhase2))
                            ),
                        this.travelAgentRestService.getPassportToken(<AgencyPassportRequest>{
                            phoneNumber,
                            affiliationId,
                            reservationId: this.constants.DEFAULT_RESERVATION_ID
                        })
                            .pipe(tap((resp: AgencyPassport) => this.storeAgencyPassportToken(resp)))
                    ])
                ),
                catchError(error => {
                    this.clearTravelAgentData();

                    return throwError(error);
                }),
                concatMap(resp => empty())
            );
    }

    /**
     * Login based on DTA token
     * @param tokens DTA tokens
     * @returns Observable for agency login process
     */
    dtaLogin({ tokens }: AgencyLogin): Observable<null> {
        this.storeDTAReferrer();

        return this.travelAgentRestService.getAuthToken()
            .pipe(
                tap((resp: AgencyAuthToken) => this.storeAuthToken(resp)),
                concatMap(() => this.travelAgentRestService.agencyLookup({ tokens })),
                tap((resp: AgencyLookup) => this.storeAgencyInfo(resp, true)),
                concatMap((resp: AgencyLookup) => {
                    return this.travelAgentRestService.getPassportToken(this.getPassportTokenRequestData(resp));
                }),
                tap((resp: AgencyPassport) => this.storeAgencyPassportToken(resp)),
                catchError(error => {
                    this.clearTravelAgentData();

                    return throwError(error);
                }),
                concatMap(resp => empty())
            );
    }

    /**
     * Delete sessions and agency data
     * @returns Observable for agency login process
     */
    logout(): Observable<never> {
        let sessions: Array<string>;
        let observable: Observable<never>;

        try {
            sessions = JSON.parse(localStorage.getItem(this.constants.STORAGE.ACTIVE_SESSION));
        } catch (error) {
            sessions = null;
        }

        observable = sessions ? this.travelAgentRestService.agencyLogout(<AgencyLogout>{ sessions }) : empty();

        return observable.pipe(finalize(() => this.clearTravelAgentData()));
    }

    /**
     * Stores guest castaway info in session storage
     * @param data castaway info
     */
    storeGuestCastawayData(data: GuestCastawayInfo): void {
        try {
            sessionStorage.setItem(
                this.constants.STORAGE.GUEST_CASTAWAY_INFO,
                encodeURIComponent(JSON.stringify(data))
            );
        } catch (error) { }
    }

    /**
     * Retrieve stored guest castaway data
     * @return guest castaway data from session storage
     */
    getGuestCastawayData(): GuestCastawayInfo {
        let guestData: GuestCastawayInfo;

        try {
            guestData = JSON.parse(
                decodeURIComponent(sessionStorage.getItem(this.constants.STORAGE.GUEST_CASTAWAY_INFO))
            );

            if (guestData) {
                this.setGuestClubLevel(guestData);
            }
        } catch (error) { }

        return guestData;
    }

    /**
     * Sets the guest club level string
     * @param guestData
     */
    setGuestClubLevel(guestData: GuestCastawayInfo) {
        const eligibleGrant = guestData.eligibleGrant && guestData.eligibleGrant.toUpperCase();

        guestData.eligibleGrantKey = eligibleGrant && this.constants.LEVEL_KEYS[eligibleGrant];

        if (!this.toggleService.getToggle('dclEarlyBookingSegmentation')) {
            if (guestData.eligibleGrant.startsWith('PLAT_')) {
                guestData.eligibleGrantKey = this.constants.LEVEL_KEYS.PLATINUM;
            }
        }
    }

    /**
     * Deletes stored guest castaway data
     */
    deleteGuestCastawayData(): void {
        sessionStorage.removeItem(this.constants.STORAGE.GUEST_CASTAWAY_INFO);
    }

    /**
     * Validate if the eligible grant should show an id number
     * @param memberData guest membership data
     * @returns if id number should be hidden
     */
    hideIdNumber(memberData: GuestCastawayInfo): boolean {
        const eligibleGrant = memberData && memberData.eligibleGrant &&
            memberData.eligibleGrant.toUpperCase();

        return eligibleGrant && this.constants.BRANDS_WITHOUT_ID_NUMBER.includes(eligibleGrant);
    }
}
