import { Injectable } from '@angular/core';
import { LocaleService } from '../../locale/locale.service';

import { INTL_DATE_CONFIG_CONSTANTS } from './intl-date-config.constants';
import { IntlDateConfig } from '../interfaces/intl-date-config';

@Injectable({
    providedIn: 'root'
})
export class IntlDateConfigService {

    constructor(
        private localeService: LocaleService
    ) { }

    /**
     * Get the Intl config data used by shared pipe
     */
    getConfig(datesConfig: IntlDateConfig) {
        const config = {
            dates: [datesConfig.sailDateFrom, datesConfig.sailDateTo] as [string, string],
            locale: this.localeService.getLocale(),
            showDates: true,
            template: datesConfig.template || INTL_DATE_CONFIG_CONSTANTS.dateTemplate
        };

        return config;
    }
}
