export class ManageIntervalTime {
    private interval;
    private initTime: number;
    private timeLeft = 0;
    private totalTime: number;

    constructor(private func: () => void, private time: number) {
        this.totalTime = time;
    }

    /**
     * Play the time for a interval time
     */
    play(): void {
        this.totalTime = this.time;

        if (this.interval) {
            clearInterval(this.interval);
        }

        this.initTime = Date.now();
        this.interval = setInterval(() => {
            this.initTime = Date.now();
            this.func();
        }, this.time);
    }

    /**
     * Pause the interval time and save the moment when is pause to resume it again
     */
    pause(): void {
        this.stop();
        this.timeLeft = this.totalTime - (Date.now() - this.initTime);
    }

    /**
     * Resume the time where the interval was paused
     */
    resume(): void {
        if (this.timeLeft <= 0) {
            this.play();
        } else {
            this.initTime = Date.now();
            this.totalTime = this.timeLeft;
            this.interval = setTimeout(() => {
                this.func();
                this.play();
            }, this.timeLeft);
        }
    }

    /**
     * Stop the interval time
     */
    stop(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }

        this.timeLeft = 0;
    }

    /**
     * Update the time wait for the interval time
     * @param time New time for the interval
     */
    setTime(time: number): void {
        this.time = time;
    }
}
