import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { GeoLocationInfo, GeolocationService } from '@wdpr/ra-angular-geolocation';

import { CartService } from '../../../cart/cart.service';
import { CustomForm, CustomFormInput, GenesysAdditionalUserData, GenesysUserGecodeData } from '../../interfaces';
import { GenesysChatConstants as constants } from '../../genesys-chat.constants';
import { ReservationIdService } from '../../../services/reservation-id/reservation-id.service';
import { ReservationType  } from '../../reservation-type.enum';

@Injectable({
    providedIn: 'root'
})
export class GenesysUserDataService {
    private additionalData: GenesysAdditionalUserData;
    private userGeocode: GenesysUserGecodeData = {};

    constructor(
        private cartService: CartService,
        private cookieService: CookieService,
        private geolocationService: GeolocationService,
        private loggerService: LoggerService,
        private reservationIdService: ReservationIdService
    ) {}

    /**
     * Sets the current additional data with the params
     * @param additionalUserData params to be added or modified in the additional data
     */
    public set additionalUserData(additionalUserData: GenesysAdditionalUserData) {
        this.additionalData = additionalUserData;
    }

    /**
     * Gets the additional data object
     * @returns `GenesysAdditionalUserData` object with the user info
     */
    public get additionalUserData(): GenesysAdditionalUserData {
        return this.additionalData;
    }

    /**
     * Returns webchat form with client information prefilled
     * @return entry form prefill data
     */
    getCustomForm(): CustomForm {
        const form = { ...constants.CUSTOM_FORM };
        let userData: Object;

        try {
            userData = JSON.parse(this.cookieService.get(constants.GUEST_INFO_COOKIE));
        } catch (error) {
            this.loggerService.warn(constants.NO_GUEST_WARN);
        }

        form.inputs = constants.CUSTOM_FORM_INPUTS.map((input: CustomFormInput) => {
            const mappedValue = userData && userData[constants.USER_INFO_MAP.get(input.name)];

            return {
                ...input,
                value: mappedValue || input.value,
            };
        });

        return form;
    }

    /**
     * Gets the default user data checking if this one is a travel agent or guest user
     * @returns defaul user data
     */
    getDefaultUserData(): GenesysAdditionalUserData {
        const isTravelAgentLogged = !!this.cookieService.get(constants.TA_INFO_COOKIE);
        const defaultUserData = isTravelAgentLogged ?
            this.getTravelAgentDefaultData() :
            this.getGuestDefaultData();

        this.additionalData = { ...this.additionalData, ...defaultUserData, ...this.userGeocode };

        return this.additionalData;
    }

    /**
     * Gets User information to send with webchat form
     */
    private getGuestDefaultData(): GenesysAdditionalUserData {
        const cartId = this.cartService.getCartId();
        const reservationId = this.reservationIdService.getId();
        let additionalDefaultData: GenesysAdditionalUserData;

        if (cartId) {
            additionalDefaultData = {
                resType: ReservationType.ReservationNew,
            };
        }

        if (reservationId) {
            additionalDefaultData = {
                resNumber: `${reservationId}`,
                resType: ReservationType.ReservationExisting,
            };
        }

        return additionalDefaultData;
    }

    /**
     * Gets the default data for the travel angent user
     */
    private getTravelAgentDefaultData(): GenesysAdditionalUserData {
        // the user is activated as a guest by default in the genesys configuration,
        // now if the user is a travel agent we need to define the properties
        // CallerType and spCustomerSegmentTA in order to let genesys know that config will be change.
        const travelAgencyId = this.cookieService.get(constants.AGENCY_ID_COOKIE);

        return {
            callerType: constants.TRAVEL_AGENT_VALUES.callerTypeTA,
            spCustomerSegment: constants.TRAVEL_AGENT_VALUES.spCustomerSegmentTA,
            travelAgentId: travelAgencyId,
            taIDType: constants.TRAVEL_AGENT_VALUES.taIDType
        };
    }

    /**
     * Gets user geocode information
     */
    public getUserGeocode(): void {
        this.geolocationService.getGeoCode({ forceRefresh: false }).subscribe((data: GeoLocationInfo) => {
            if (data) {
                this.userGeocode.city = data.metro;
                this.userGeocode.country = data.country;
                this.userGeocode.ipAddress = data.ip;
            }
        });
    }
}
