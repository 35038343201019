import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import { BaseModalV2 } from './interfaces/base-modal-v2.interface';
import {
    BreakpointChangeDetectionService
} from '../../breakpoint-change-detection/services/breakpoint-change-detection.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

/**
 * Base modal V2 component
 *
 * It has body container with:
 * - Image
 * - Title
 * - Short description
 * Defined in baseModal input.
 *
 * A header for mobile version
 *
 * And buttons section
 *
 * Customizable styles:
 * It has multiple customizable styles defined in:
 * src\assets\styles\theme\components\base-modal-v2.scss (default values)
 *
 * Modal config should be set as input in baseModal:
 * - baseModal.openModal: true or false, show or hide the modal.
 * - close actions (from background or X icon) emit another action (Output) with
 * 'close' string as param.
 * - buttons actions emit action Output with the action defined in button.action
 * (baseModal.buttons[0].action)
 *
 * # Custom content can be used as "content projection"
 *
 * ## Example of use:
 * ----------------------------------------------
 *    const data: BaseModalV2 = {
 *         body: {},
 *         disableCloseOnBackground: false,
 *         hideCloseIcon: true,
 *         hideMobileArrowCloseIcon: true,
 *         buttonClass: 'my-scoped-custom-modal',
 *         openModal: boolean,
 *         useCustomBody: true // you need to specify this as TRUE to use "custom content projection"
 *     }
 *
 *     <dcl-base-modal-v2 [baseModal]="data">
 *         <my-custom-content class="customBody"></my-custom-content>
 *      </dcl-base-modal-v2>
 *
 * ## consideration in case that is used:
 * ----------------------------------------------
 * - the styles for your custom content should be placed in the global file and be scoped by component, use
 *   the "buttonClass" to scope your styles
 * - if you have hidden content and the translations don't work use the manual "detectChanges" once view resolved, ie:
 *   onSomeChildComponentChange(newSelectedValue) {
 *      // your changes go here, then trigger detectChanges ---
 *      this.changeDetector.detectChanges();
 *   }
 */
@Component({
    selector: 'dcl-base-modal-v2',
    templateUrl: 'base-modal-v2.component.html',
    styleUrls: ['base-modal-v2.component.scss']
})
export class DclBaseModalV2Component implements OnInit {
    @ViewChild('baseModalV2', { static: false }) modal: ElementRef;
    // determine a config options for a JSON image(animated or not) to be displayed
    // @see https://github.com/ngx-lottie/ngx-lottie#usage
    // prop to be filled: baseModal.body.animationOptions

    @Input() baseModal: BaseModalV2;
    // determine the timeout in miliseconds that the component should respond until know if isMobile (300 by default)
    @Input() timeoutIsMobile = 300;
    @Output() action = new EventEmitter<string>();

    animationItem: AnimationItem;
    buttonTypes = {
        link: 'link'
    };
    isMobile: boolean;

    private timeoutListener;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private screenBreakpoint: BreakpointChangeDetectionService
    ) { }

    ngOnInit(): void {
        this.verifyIsMobile();
    }

    /**
     * Emit close modal action
     */
    closeModal(): void {
        this.action.emit('close');
    }

    /**
     * Emit modal action
     * Virtual pointer (JAWS) can access to disabled wdpr-button component, for this case it is added
     * a validation to exit the function.
     * @todo Remove virtual poiter validation once wdpr-button component is updated to the latest version
     * (Polymer 3 or Stencil)
     * @param actionFunction
     */
    actionEmit(event: PointerEvent, action: string): void {
        const butonElement = event.target as HTMLButtonElement;

        if (butonElement.disabled) {
            return;
        }

        this.action.emit(action);
    }

    /**
     * Dispatched after the lottie successfully creates animation
     * @param animationItem
     */
    animationCreated(animationItem: AnimationItem): void {
      this.animationItem = animationItem;
    }

    /**
     * Play the image manually
     */
    play(): void {
        this.animationItem.play();
    }

    /**
     * Stop the image manually
     */
    stop(): void {
        this.animationItem.stop();
    }

    /**
     * Update the current animation options
     * @param options
     */
    updateAnimation(options: AnimationOptions): void {
        this.baseModal.body.animationOptions = {
            ...options
        };

        this.changeDetector.detectChanges();
    }

    /**
     * Verify if the screen width is mobile or desktop depending on given breakpoint
     * @returns true if is mobile or false otherwise
     */
    @HostListener('window:resize')
    verifyIsMobile(): void {
        clearTimeout(this.timeoutListener);

        this.timeoutListener = setTimeout(() => {
            this.isMobile = this.screenBreakpoint.isMobile();
        }, this.timeoutIsMobile);
    }
}
