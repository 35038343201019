import { Injectable } from '@angular/core';

import { DataStorage } from '../../interfaces/data-storage';

@Injectable({
    providedIn: 'root'
})
export class DataStorageService {
    private data: DataStorage;

    /**
     * @description this function retrieves data assigned before by 'setData' function
     * @param persist flag that defines if the data will persist after getting it
     * @returns the data
     */
    getData(persist?: boolean): DataStorage {
        const dataClone = Object.assign({}, this.data);

        if (persist) {
            return this.data;
        }
        delete this.data;

        return dataClone;
    }

    /**
     * @description this function set new data, overwriting the data object
     * @returns  the new data object
     */
    setData(data: DataStorage): void {
        this.data = data;
    }
}
