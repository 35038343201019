<ng-container *ngFor="let group of optionsGroups; first as firstGroup; last as lastGroup; trackBy: trackByGroupId">
    <div class="group-options-container"
        *ngIf="!group.hiddenGroup">
        <h2 translate class="group-label">{{ group.labelKey }}</h2>
        <div class="group-options-item-wrapper">
            <ul class="group-options-items" [attr.aria-label]="group.labelKey | translate">
                <li class="option-item"
                    *ngFor="let option of group.options, let i = index; first as firstOption; last as lastOption; trackBy: trackByOptionId">
                    <dcl-option-item
                        id="checkbox-item-{{i}}"
                        [optionGroupLabel]="!firstGroup && firstOption ? group.labelKey : ''"
                        [isLastGroup]="lastGroup"
                        [isLastOption]="lastOption"
                        [option]="option"
                        (onItemChanged)="onItemChanged($event, group.type)"
                        (lastItemTabbed)="lastItemTabbed.emit($event)">
                    </dcl-option-item>
                </li>
            </ul>
        </div>
    </div>
</ng-container>
