<button class="dcl-link link-action"
    [ngClass]="{ 'with-icon': icon , 'print': icon && icon.indexOf('print') !== -1 }"
    [disabled]="disable"
    (click)="onClick()">
    <i class="di"
        aria-hidden="true"
        *ngIf="icon"
        [ngClass]="icon">
    </i>
    <span class="link-action__text">{{ label | translate }}</span>
</button>
<wdpr-button
    class="button-action"
    theme="secondary"
    [disabled]="disable"
    (click)="onClick()">
    <i class="di link-icon"
        aria-hidden="true"
        *ngIf="icon"
        [ngClass]="icon">
    </i>
    <span class="button-action__text">{{ label | translate }}</span>
</wdpr-button>
