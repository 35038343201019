export const INTL_DATE_CONFIG_CONSTANTS = {
    dateTemplate: {
        'modules': [
            {
                'id': 'dateFormat',
                'sections': {
                    'customDisplayDiffMonth': '{startMonth} {startDate} {separator} {endMonth} {endDate}, {year}',
                    'customDisplayDiffYear': '{startMonth} {startDate}, {startYear} {separator} {endMonth} {endDate}, {endYear}',
                    'customDisplaySameMonth': '{month} {startDate} - {endDate}, {year}',
                    'endDateDiffMonth': 'MMMM D, YYYY',
                    'endDateSameMonth': 'D, YYYY',
                    'formatDateDiffYear': 'MMMM D, YYYY',
                    'separator': '-',
                    'startDateDiffMonth': 'MMMM D',
                    'startDateSameMonth': 'MMMM D'
                },
                'modules': [
                    {
                        'id': 'months',
                        'sections': {
                            'months1': 'January',
                            'months2': 'February',
                            'months3': 'March',
                            'months4': 'April',
                            'months5': 'May',
                            'months6': 'June',
                            'months7': 'July',
                            'months8': 'August',
                            'months9': 'September',
                            'months10': 'October',
                            'months11': 'November',
                            'months12': 'December'
                        }
                    },
                    {
                        'id': 'monthsAbrv',
                        'sections': {
                            'months1': 'Jan',
                            'months2': 'Feb',
                            'months3': 'Mar',
                            'months4': 'Apr',
                            'months5': 'May',
                            'months6': 'Jun',
                            'months7': 'Jul',
                            'months8': 'Aug',
                            'months9': 'Sep',
                            'months10': 'Oct',
                            'months11': 'Nov',
                            'months12': 'Dec'
                        }
                    },
                    {
                        'id': 'daysOfWeek',
                        'sections': {
                            'daysOfWeek1': 'Monday',
                            'daysOfWeek2': 'Tuesday',
                            'daysOfWeek3': 'Wednesday',
                            'daysOfWeek4': 'Thursday',
                            'daysOfWeek5': 'Friday',
                            'daysOfWeek6': 'Saturday',
                            'daysOfWeek7': 'Sunday'
                        }
                    }
                ]
            }
        ]
    }
};
