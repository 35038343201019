import { Component, Input } from '@angular/core';

import { CRUISE_TILE_CONSTANTS } from './cruise-tile.constants';
import { MediaProduct } from '../../../interfaces/media-product.interface';

@Component({
    selector: 'dcl-cruise-tile',
    templateUrl: './cruise-tile.component.html',
    styleUrls: ['./cruise-tile.component.scss']
})
export class CruiseTileComponent {
    @Input() a11yDescription: string;
    @Input() altText: string;
    @Input() customImgPlaceholder: string;
    @Input() description: string;
    @Input() isHomePage: boolean;
    @Input() media: MediaProduct;
    @Input() title: string;

    constants = CRUISE_TILE_CONSTANTS;
}
