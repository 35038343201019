<section
    class="dcl-contact-info"
    [ngClass]="{'required-bottom-space': bottomSpace && showRequiredLabel && !showForm, 'bottom-space' : bottomSpace && showForm}">
    <fieldset>
        <legend class="form-legend">
            <div class="title-container" [ngClass]="{'show-required': showRequiredLabel, 'is-primary': isPrimaryGuest}">
                <h4 class="title" translate>contact-information.title</h4>
                <span translate
                    class="action-message"
                    *ngIf="showRequiredLabel">
                    contact-information.guest-required
                </span>
            </div>
        </legend>
        <div [formGroup]="contactForm">
            <div [ngClass]="{'is-primary': !isPrimaryGuest}">
                <ng-template [ngIf]="!isPrimaryGuest">
                    <wdpr-checkbox
                        ngDefaultControl
                        class="checkbox-primary-guest"
                        formControlName="isSameAsPrimaryGuest"
                        [customAriaLabel]="translationsKeys['aria-label']?.primary | replaceString: { number: guestNumber }"
                        [checked]="!showForm"
                        #sameAsPrimaryCheckbox>
                        {{ translationsKeys['primary'] }}
                    </wdpr-checkbox>
                    <span *ngIf="showRequiredLabel" class="action-message mobile" translate>
                        contact-information.guest-required
                    </span>
                </ng-template>
            </div>
            <ng-template [ngIf]="showForm">
                <div class="form">
                    <div class="item select">
                        <wdpr-single-select
                            #country
                            ngDefaultControl
                            formControlName="country"
                            class="select select-country guestSensitive"
                            [label]="translationsKeys['country']"
                            [customAriaLabel]="translationsKeys['aria-label']?.country | replaceString: { number: guestNumber } | requiredLabel"
                            [options]="countries"
                            [invalid]="contactForm.get('country').hasError('required') && contactForm.get('country').touched"
                            required-field="true"
                            [customErrorAriaLabel]="getErrors('country', contactForm.get('country'), constants.errorKeys) | translate">
                        </wdpr-single-select>
                        <dcl-error-message
                            *ngIf="contactForm.get('country').hasError('required') && contactForm.get('country').touched"
                            class="errors"
                            [control]="contactForm.get('country')"
                            [errorMessage]="constants.errorKeys.country">
                        </dcl-error-message>
                    </div>
                    <div class="item">
                        <wdpr-input
                            class="input-field guestSensitive"
                            autocorrect="off"
                            autocomplete="street-address"
                            type="text"
                            name="address"
                            [label]="translationsKeys['address']"
                            [customAriaLabel]="translationsKeys['aria-label']?.address | replaceString: { number: guestNumber } | requiredLabel"
                            ngDefaultControl
                            formControlName="address"
                            no-validate-on-change="true"
                            no-validate-on-blur="true"
                            required="true"
                            [maxlength]="constants.maxLength.addressLineOne"
                            [valid]="!isInputInvalid('address')"
                            [ngClass]="{'invalid touched': isInputInvalid('address')}"
                            [customErrorAriaLabel]="getErrors('address', contactForm.get('address'), constants.errorKeys) | translate">
                        </wdpr-input>
                        <dcl-error-message
                            *ngIf="isInputInvalid('address')"
                            class="errors"
                            [control]="contactForm.get('address')"
                            [errorMessage]="constants.errorKeys.address">
                        </dcl-error-message>
                    </div>
                    <div class="item">
                        <wdpr-input
                            class="input-field guestSensitive"
                            autocorrect="off"
                            autocomplete="address-line1"
                            type="text"
                            name="address-2"
                            [label]="translationsKeys['address-two']"
                            [customAriaLabel]="(translationsKeys['aria-label'] && translationsKeys['aria-label']['address-two']) | replaceString: { number: guestNumber }"
                            ngDefaultControl
                            formControlName="addressTwo"
                            no-validate-on-change="true"
                            no-validate-on-blur="true"
                            [maxlength]="constants.maxLength.addressLineTwo"
                            [valid]="!isInputInvalid('addressTwo')"
                            [ngClass]="{'invalid touched': isInputInvalid('addressTwo')}"
                            [customErrorAriaLabel]="getErrors('addressLineTwo', contactForm.get('addressTwo'), constants.errorKeys) | translate">
                        </wdpr-input>
                        <dcl-error-message
                            *ngIf="isInputInvalid('addressTwo')"
                            class="errors"
                            [control]="contactForm.get('addressTwo')"
                            [errorMessage]="constants.errorKeys.addressLineTwo">
                        </dcl-error-message>
                    </div>
                    <ng-template [ngIf]="selectedCountry !== constants.countries['US'].iso">
                        <div class="item">
                            <wdpr-input
                                class="input-field guestSensitive"
                                autocorrect="off"
                                autocomplete="address-line2"
                                type="text"
                                name="address-3"
                                [label]="translationsKeys['address-three']"
                                [customAriaLabel]="(translationsKeys['aria-label'] && translationsKeys['aria-label']['address-three']) | replaceString: { number: guestNumber }"
                                ngDefaultControl
                                formControlName="addressThree"
                                no-validate-on-change="true"
                                no-validate-on-blur="true"
                                [maxlength]="constants.maxLength.addressLineTwo"
                                [valid]="!isInputInvalid('addressThree')"
                                [ngClass]="{'invalid touched': isInputInvalid('addressThree')}"
                                [customErrorAriaLabel]="getErrors('addressLineTwo', contactForm.get('addressThree'), constants.errorKeys) | translate">
                            </wdpr-input>
                            <dcl-error-message
                                *ngIf="isInputInvalid('addressThree')"
                                class="errors"
                                [control]="contactForm.get('addressThree')"
                                [errorMessage]="constants.errorKeys.addressLineTwo">
                            </dcl-error-message>
                        </div>
                    </ng-template>
                    <div class="item">
                        <wdpr-input
                            class="input-field guestSensitive"
                            autocorrect="off"
                            autocomplete="address-level2"
                            type="text"
                            name="city"
                            [label]="translationsKeys['city']"
                            [customAriaLabel]="translationsKeys['aria-label']?.city | replaceString: { number: guestNumber } | requiredLabel"
                            ngDefaultControl
                            formControlName="city"
                            no-validate-on-change="true"
                            no-validate-on-blur="true"
                            required="true"
                            [maxlength]="constants.maxLength.city"
                            [valid]="!isInputInvalid('city')"
                            [ngClass]="{'invalid touched': isInputInvalid('city')}"
                            [customErrorAriaLabel]="getErrors('city', contactForm.get('city'), constants.errorKeys) | translate">
                        </wdpr-input>
                        <dcl-error-message
                            *ngIf="isInputInvalid('city')"
                            class="errors"
                            [control]="contactForm.get('city')"
                            [errorMessage]="constants.errorKeys.city">
                        </dcl-error-message>
                    </div>
                    <ng-template [ngIf]="selectedCountry === constants.countries['US'].iso">
                        <div class="inline-fields with-select"
                            [ngClass]="{'invalid': (contactForm.get('state').hasError('required') && contactForm.get('state').touched) || isInputInvalid('zip')}">
                            <div class="item select us-select">
                                <wdpr-single-select
                                    #state
                                    class="select guestSensitive"
                                    [label]="translationsKeys['state']"
                                    [customAriaLabel]="translationsKeys['aria-label']?.state | replaceString: { number: guestNumber } | requiredLabel"
                                    ngDefaultControl
                                    formControlName="state"
                                    [options]="provincesObject[selectedCountry]"
                                    [invalid]="contactForm.get('state').hasError('required') && contactForm.get('state').touched"
                                    required-field="true"
                                    [customErrorAriaLabel]="getErrors('state', contactForm.get('state'), constants.errorKeys) | translate">
                                </wdpr-single-select>
                                <dcl-error-message
                                    *ngIf="contactForm.get('state').hasError('required') && contactForm.get('state').touched"
                                    class="errors"
                                    [control]="contactForm.get('state')"
                                    [errorMessage]="constants.errorKeys.state">
                                </dcl-error-message>
                            </div>
                            <div class="item zip-code">
                                <wdpr-input
                                    class="input-field guestSensitive"
                                    autocorrect="off"
                                    autocomplete="postal-code"
                                    type="tel"
                                    name="zip"
                                    pattern="[0-9]*"
                                    [label]="translationsKeys['zip']"
                                    [customAriaLabel]="translationsKeys['aria-label']?.zip | replaceString: { number: guestNumber } | requiredLabel"
                                    ngDefaultControl
                                    formControlName="zip"
                                    no-validate-on-change="true"
                                    no-validate-on-blur="true"
                                    required="true"
                                    [maxlength]="constants.maxLength.zip"
                                    [valid]="!isInputInvalid('zip')"
                                    [ngClass]="{'invalid touched': isInputInvalid('zip')}"
                                    [customErrorAriaLabel]="getErrors('zip', contactForm.get('zip'), constants.errorKeys) | translate">
                                </wdpr-input>
                                <dcl-error-message
                                    *ngIf="isInputInvalid('zip')"
                                    class="errors"
                                    [control]="contactForm.get('zip')"
                                    [errorMessage]="constants.errorKeys.zip"
                                    [showSingleError]="true">
                                </dcl-error-message>
                            </div>
                        </div>
                    </ng-template>
                    <div class="inline-fields with-select"
                        [ngClass]="{'invalid': (contactForm.get('province').hasError('required') && contactForm.get('province').touched) || isInputInvalid('postal')}">
                        <ng-template
                            [ngIf]="(selectedCountry !== constants.countries['US'].iso && selectedCountry !== constants.countries['GB'].iso)">
                            <ng-container
                                *ngIf="provincesObject[selectedCountry]; else territory">
                                <div class="item select">
                                    <wdpr-single-select
                                        #province
                                        class="select guestSensitive"
                                        [label]="selectedCountry === constants.countries['CA'].iso ? translationsKeys['province'] : translationsKeys['territory']"
                                        [customAriaLabel]="(selectedCountry === constants.countries['CA'].iso ?
                                            translationsKeys['aria-label']?.province : translationsKeys['aria-label']?.territory) | replaceString: { number: guestNumber }"
                                        ngDefaultControl
                                        formControlName="province"
                                        [options]="provincesObject[selectedCountry]"
                                        [invalid]="contactForm.get('province').hasError('required') && contactForm.get('province').touched"
                                        [customErrorAriaLabel]="getErrors('province', contactForm.get('province'), constants.errorKeys) | translate">
                                    </wdpr-single-select>
                                    <dcl-error-message
                                        *ngIf="contactForm.get('province').hasError('required') && contactForm.get('province').touched"
                                        class="errors"
                                        [control]="contactForm.get('province')"
                                        [errorMessage]="constants.errorKeys.province">
                                    </dcl-error-message>
                                </div>
                            </ng-container>
                            <ng-template #territory>
                                <div class="item">
                                    <wdpr-input
                                        class="input-field guestSensitive"
                                        autocorrect="off"
                                        autocomplete="address-level1"
                                        type="text"
                                        name="territory"
                                        [label]="translationsKeys['territory']"
                                        [customAriaLabel]="translationsKeys['aria-label']?.territory | replaceString: { number: guestNumber }"
                                        ngDefaultControl
                                        formControlName="territory"
                                        no-validate-on-change="true"
                                        no-validate-on-blur="true"
                                        [maxlength]="constants.maxLength.territory"
                                        [valid]="!isInputInvalid('territory')"
                                        [ngClass]="{'invalid touched': isInputInvalid('territory')}"
                                        [customErrorAriaLabel]="getErrors('territory', contactForm.get('territory'), constants.errorKeys) | translate">
                                    </wdpr-input>
                                    <dcl-error-message
                                        *ngIf="isInputInvalid('territory')"
                                        class="errors"
                                        [control]="contactForm.get('territory')"
                                        [errorMessage]="constants.errorKeys.territory">
                                    </dcl-error-message>
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template
                            [ngIf]="selectedCountry === constants.countries['GB'].iso">
                            <div class="item">
                                <wdpr-input
                                    class="input-field guestSensitive"
                                    autocorrect="off"
                                    autocomplete="address-level1"
                                    type="text"
                                    name="county"
                                    [label]="translationsKeys['county']"
                                    [customAriaLabel]="translationsKeys['aria-label']?.county | replaceString: { number: guestNumber }"
                                    ngDefaultControl
                                    formControlName="county"
                                    no-validate-on-change="true"
                                    no-validate-on-blur="true"
                                    [maxlength]="constants.maxLength.county"
                                    [valid]="!isInputInvalid('county')"
                                    [ngClass]="{'invalid touched': isInputInvalid('county')}"
                                    [customErrorAriaLabel]="getErrors('county', contactForm.get('county'), constants.errorKeys) | translate">
                                </wdpr-input>
                                <dcl-error-message
                                    *ngIf="isInputInvalid('county')"
                                    class="errors"
                                    [control]="contactForm.get('county')"
                                    [errorMessage]="constants.errorKeys.county">
                                </dcl-error-message>
                            </div>
                        </ng-template>
                        <ng-template
                            [ngIf]="selectedCountry !== constants.countries['US'].iso">
                            <div class="item postal-code">
                                <wdpr-input
                                    class="input-field guestSensitive"
                                    autocorrect="off"
                                    autocomplete="postal-code"
                                    type="text"
                                    name="postal"
                                    [label]="translationsKeys['postal']"
                                    [customAriaLabel]="translationsKeys['aria-label']?.postal | replaceString: { number: guestNumber }  | requiredLabel"
                                    ngDefaultControl
                                    formControlName="postal"
                                    no-validate-on-change="true"
                                    no-validate-on-blur="true"
                                    required="true"
                                    [maxlength]="constants.maxLength.postal"
                                    [valid]="!isInputInvalid('postal')"
                                    [ngClass]="{'invalid touched': isInputInvalid('postal')}"
                                    [customErrorAriaLabel]="getErrors('postal', contactForm.get('postal'), constants.errorKeys) | translate">
                                </wdpr-input>
                                <dcl-error-message
                                    *ngIf="isInputInvalid('postal')"
                                    class="errors"
                                    [control]="contactForm.get('postal')"
                                    [errorMessage]="constants.errorKeys.postal">
                                </dcl-error-message>
                            </div>
                        </ng-template>
                    </div>
                    <div class="item">
                        <wdpr-input
                            class="input-field guestSensitive"
                            autocorrect="off"
                            autocomplete="tel"
                            type="tel"
                            name="phone"
                            [label]="translationsKeys['phone']"
                            [customAriaLabel]="translationsKeys['aria-label']?.phone | replaceString: { number: guestNumber } | requiredLabel"
                            ngDefaultControl
                            formControlName="phone"
                            no-validate-on-change="true"
                            no-validate-on-blur="true"
                            required="true"
                            [maxlength]="[constants.countries['US'].iso, constants.countries['CA'].iso].indexOf(selectedCountry) === -1 ?
                                constants.maxLength.phoneDefault : constants.maxLength.phone"
                            [valid]="!isInputInvalid('phone')"
                            [ngClass]="{'invalid touched': isInputInvalid('phone')}"
                            [customErrorAriaLabel]="getErrors('phone', contactForm.get('phone'), constants.errorKeys) | translate">
                        </wdpr-input>
                        <dcl-error-message
                            *ngIf="isInputInvalid('phone')"
                            class="errors"
                            [control]="contactForm.get('phone')"
                            [errorMessage]="constants.errorKeys.phone"
                            [showSingleError]="true">
                        </dcl-error-message>
                    </div>
                    <ng-template [ngIf]="isPrimaryGuest">
                        <div class="item">
                            <wdpr-input
                                class="input-field guestSensitive"
                                autocapitalize="off"
                                autocorrect="off"
                                autocomplete="email"
                                type="email"
                                name="email"
                                [label]="translationsKeys['email']"
                                [customAriaLabel]="translationsKeys['aria-label']?.email | replaceString: { number: guestNumber } | requiredLabel"
                                ngDefaultControl
                                formControlName="email"
                                no-validate-on-change="true"
                                no-validate-on-blur="true"
                                required="true"
                                [valid]="!isInputInvalid('email')"
                                [ngClass]="{'invalid touched': isInputInvalid('email')}"
                                [customErrorAriaLabel]="getErrors('email', contactForm.get('email'), constants.errorKeys) | translate">
                            </wdpr-input>
                            <dcl-error-message
                                *ngIf="isInputInvalid('email')"
                                class="errors"
                                [control]="contactForm.get('email')"
                                [errorMessage]="constants.errorKeys.email">
                            </dcl-error-message>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </fieldset>
</section>
