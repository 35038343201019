import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';

import { ConfigService } from '../../services/config/config.service';
import { StateroomAttributes } from '../interfaces/stateroom-attributes';

@Injectable({
    providedIn: 'root'
})
export class StateroomsService {
    private webApiUrl;

    constructor(
        private config: ConfigService,
        private httpClient: HttpClient
    ) {
        this.webApiUrl = this.config.getValue('webApiUrl');
     }

    /**
     * Retrieves Stateroom Attributes from Cruise Sales Service
     * @param stateroomId
     * @param shipCode
     * @param sailStartDate
     * @returns Observable of Stateroom Attributes
     */
    getStateroomAttributes(
        stateroomId: string,
        shipCode: string,
        sailStartDate: string
    ): Observable<StateroomAttributes | {}> {
        const queryParams = `?shipCode=${shipCode}&sailStartDate=${sailStartDate}`;

        return this.httpClient
            .get<StateroomAttributes>(`${this.webApiUrl}staterooms/${stateroomId}/attributes${queryParams}`)
            .pipe(catchError(this.handleError));
    }

    /**
     * Set webApiUrl from SPA
     * @param webApiUrl
     */
    setSvcUrl(webApiUrl: String): void {
        this.webApiUrl = webApiUrl;
    }

    /**
     * Returns error when HTTP Call fails
     * @param error
     */
    private handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
