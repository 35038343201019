const imagePickerStatesKey = 'imagePicker.state' ;
const imagePickerRole = 'checkbox' ;

const IMAGE_PICKER_IMAGE_SIZE = {
    DESKTOP: {
        WIDTH: '400',
        HEIGHT: '160'
    },
    TABLET: {
        WIDTH: '400',
        HEIGHT: '160'
    },
    MOBILE: {
        WIDTH: '400',
        HEIGHT: '160'
    }
};

export { imagePickerStatesKey, imagePickerRole, IMAGE_PICKER_IMAGE_SIZE };
