<button
    class="whats-included-modal-button"
    attr.aria-label="{{ 'whatsIncludedModal.aria.openWhatsIncludedModal' | translate }}"
    (click)="onClick()">
    <span class="di di-dining-plan-accepted di-icon"></span>
    <div class="whats-included-modal-button__content">
        <span class="whats-included-modal-button__content-text" translate>whatsIncludedModal.label</span>
    </div>
    <span class="di di-next di-icon-right"></span>
</button>
