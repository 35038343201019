import { Directive, Input, HostListener } from '@angular/core';

import { InputNumberConstants } from './input-number.constants';
import { WindowRef } from '../window-ref/window-ref.service';

@Directive({
    selector: '[dclInputNumber]'
})
export class InputNumberDirective {
    @Input() maxlength: number;
    @Input() preventPeriod: boolean = false;
    keycodes = InputNumberConstants.getKeyCodes();
    eraseCodes = [
        this.keycodes.KEYCODE_DELETE,
        this.keycodes.KEYCODE_LEFT,
        this.keycodes.KEYCODE_RIGHT,
        this.keycodes.KEYCODE_BACKSPACE,
        this.keycodes.KEYCODE_TAB
    ];
    textHighlighted: string | boolean = '';
    constructor(private windowRef: WindowRef) { }

    /**
     * Restrict the user to only press numeric keys on the input if used on ng-change
     * @param {object} event
     * @return {Boolean}
     */
    @HostListener('keypress', ['$event'])
    onKeyPress(event) {
        const eKeyCode = event.which || event.charCode || event.keyCode;
        const valueLength = event.target.value.length;
        this.textHighlighted = this.windowRef.nativeWindow.getSelection().toString() ||
            (event.currentTarget.selectionEnd > event.currentTarget.selectionStart);

        if (!this.preventPeriod) {
            this.eraseCodes.push(this.keycodes.KEYCODE_PERIOD);
        }

        return this.validationOnKeyPress(eKeyCode, valueLength, event);
    }

    validationOnKeyPress (eKeyCode, valueLength, event): boolean {
        const maxlength = Number(this.maxlength);

        if (((eKeyCode < this.keycodes.KEYCODE_ZERO || eKeyCode > this.keycodes.KEYCODE_NINE) &&
            this.eraseCodes.indexOf(eKeyCode) === -1) || (maxlength && valueLength >= maxlength) &&
            !this.textHighlighted
        ) {
            event.preventDefault();
            event.stopPropagation();

            return false;
        }

        return true;
    }
}
