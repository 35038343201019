<div
    class="carousel-box"
    [ngClass]="{'active':active, 'carousel-box--last':lastItem, 'equal-height': equalHeight, 'has-unique-item': hasUniqueItem }"
    [ngStyle]="{
        'transform': carouselActive ? config?.styles?.transform : '',
        'transition': carouselActive ? config?.styles?.transition : ''
    }">
    <div
        class="carousel-box-wrapper"
        [ngClass]="{'click-disabled': clickDisabled}">
        <ng-content></ng-content>
    </div>
</div>
