import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { HERO_IMAGE_CONSTANTS } from './hero-image.constants';

import { HeroMedia } from './interfaces/hero-media.interfaces';
import { HeroSize } from './interfaces/hero-size.interfaces';
import { HeroSrc } from './interfaces/hero-src.interfaces';
import { MediaProduct } from '../interfaces/media-product.interface';

@Component({
    selector: 'dcl-hero-image',
    templateUrl: 'hero-image.component.html',
    styleUrls: ['hero-image.component.scss']
})
export class HeroImageComponent implements OnChanges, OnInit {
    /**
     * Entry data to defined images, it is composed by:
     *  mediaProductDesktopHero
     *  mediaProductMobilehero
     */
    @Input() media: MediaProduct;
    @Input() customMediaDesktop: string;
    @Input() customMediaMobile: string;
    @Input() customAltText: string;

    /**
     * Custom image size override
     */
    @Input() mediaSize: HeroSize;

    /**
     * Custom default image
     */
    @Input() customImgPlaceholder: string = HERO_IMAGE_CONSTANTS.DEFAULT_PRODUCT_IMAGE;
    @Input() customAltPlaceholder: string = HERO_IMAGE_CONSTANTS.DEFAULT_PRODUCT_TITLE;
    @Input() hasPlaceholder: boolean = true;

    /**
     * Event to emit when image load finished
     */
    @Output() imageLoaded = new EventEmitter<null>();

    /**
     * SourceSet images to support multiple sizes
     */
    heroImageSrc: any;

    /**
     * Default image to be used when media is not defined
     * and browser does not support multiple sizes
     */
    defaultImageSrc = '';

    /**
     * Default image to be used when media is not defined
     */
    defaultImageSrcSet = '';

    /**
     * Determines if media contains data
     */
    hasMedia: boolean;

    altImage: string;

    showPlaceholderImage = true;
    hideHeroImage = false;

    ngOnInit() {
        this.loadMedia();
    }

    ngOnChanges() {
        this.loadMedia();
    }

    /**
     * show image when it is loaded and hidden the placeholder image
     */
    finishLoad(): void {
        this.showPlaceholderImage = false;
        this.imageLoaded.emit();
    }

    /**
     * Hide image if it can´t be loaded to prevent that alt text on Iphone 5 is displayed when no needed
     */
    hideImage(): void {
        this.hideHeroImage = true;
    }

    /**
     * Inits the media elements.
     */
    loadMedia(): void {
        const { imageSrcSet, imageSizes }: HeroMedia = this.getHeroMedia();

        this.hasMedia = this.media ? Object.keys(this.media).length !== 0 : false;
        this.setSrcImages(imageSrcSet, imageSizes);
    }

    /**
     * @description Gets hero media info for mobile, tablet and desktop
     * @returns Hero media info (imageSrcSet, imageSizes)
     */
    getHeroMedia(): HeroMedia {
        const imageSrcSet: HeroSrc = {};
        const mediaDesktop = this.media && (this.media['mediaProductDesktopHero'] || this.media);
        const mediaMobile = this.media && (this.media['mediaProductMobilehero'] || this.media);
        let imageSizes: HeroSize;

        if (mediaDesktop && mediaMobile) {
            imageSrcSet.mediaDesktop = imageSrcSet.mediaTablet = mediaDesktop.transcodeTemplate;
            imageSrcSet.mediaMobile = mediaMobile.transcodeTemplate;
            imageSizes = HERO_IMAGE_CONSTANTS.IMAGE_MEDIA_SIZES;
            this.altImage = mediaDesktop.alt;
        } else if (this.customMediaDesktop && this.customMediaMobile) {
            imageSrcSet.mediaDesktop = this.customMediaDesktop;
            imageSrcSet.mediaTablet = imageSrcSet.mediaMobile = this.customMediaMobile;
            imageSizes = HERO_IMAGE_CONSTANTS.IMAGE_MEDIA_HERO_SIZES;
            this.altImage = this.customAltText;
        }

        imageSizes = this.getHeroSize(imageSizes);

        return { imageSrcSet, imageSizes };
    }

    /**
     * @description Gets hero media size for mobile, tablet and desktop
     * @returns Hero media Size imageSizes
     */
    getHeroSize(imageSizes: HeroSize): HeroSize {
        if (this.mediaSize) {
            imageSizes = this.mediaSize;
        }

        return imageSizes;
    }

    /**
     * @description Create a srcSet to support several images size and devices
     * Desktop, Tablet and Mobile
     * @param imageSrcSet desktop, tablet, mobile image transcodeTemplate
     * @param imageSizes media size constants
     */
    setSrcImages(imageSrcSet?: HeroSrc, imageSizes?: HeroSize): void {
        const { mediaDesktop, mediaMobile, mediaTablet } = imageSrcSet;
        let mobileUrl: string;
        let tabletUrl: string;
        let desktopUrl: string;

        if (this.customMediaDesktop || this.customMediaMobile || this.hasMedia) {
            mobileUrl = mediaMobile
                .replace('{width}', imageSizes.MOBILE.WIDTH)
                .replace('{height}', imageSizes.MOBILE.HEIGHT);
            tabletUrl = mediaTablet
                .replace('{width}', imageSizes.TABLET.WIDTH)
                .replace('{height}', imageSizes.TABLET.HEIGHT);
            desktopUrl = mediaDesktop
                .replace('{width}', imageSizes.DESKTOP.WIDTH)
                .replace('{height}', imageSizes.DESKTOP.HEIGHT);

            this.heroImageSrc = [
                {
                    srcSet: desktopUrl,
                    mediaQuery: HERO_IMAGE_CONSTANTS.IMAGE_MEDIA_QUERY.MEDIA_QUERY_DESKTOP
                },
                {
                    srcSet: tabletUrl,
                    mediaQuery: HERO_IMAGE_CONSTANTS.IMAGE_MEDIA_QUERY.MEDIA_QUERY_TABLET
                },
                {
                    srcSet: mobileUrl,
                    mediaQuery: HERO_IMAGE_CONSTANTS.IMAGE_MEDIA_QUERY.MEDIA_QUERY_MOBILE
                }
            ];

            this.defaultImageSrc = desktopUrl;
            this.defaultImageSrcSet = desktopUrl;
        }
    }
}
