<div class="reservation-summary-bar-content">
    <h1 class="sr-only" translate>reservationSummaryBar.title</h1>
    <h2 class="reservation-summary-bar-highlight">
        {{ "reservationSummaryBar.reservationTitle" | translate: { reservationId: reservationSummary.reservationId } }}
    </h2>
    <span *ngIf="showSailingInformation" class="reservation-summary-bar-label">
        {{ reservationSummary.productName }} | {{ reservationSummary.sailDateFrom | sailingDate:reservationSummary.sailDateTo }}
    </span>
    <!-- Regular -->
    <span
        *ngIf="!isGTY"
        class="reservation-summary-bar-label reservation-summary-bar-stateroom">
        {{ (isCommunicative ? 'reservationSummaryBar.stateroomWithReserveType' : 'reservationSummaryBar.stateroom') | translate: {
                description: reservationSummary.description,
                category: reservationSummary.category,
                location: locationKey | translate,
                deck: reservationSummary.deck,
                stateroomId: reservationSummary.stateroomId
            }
        }}
    </span>
    <!-- Guaranteed -->
    <span
        *ngIf="isGTY"
        class="reservation-summary-bar-label reservation-summary-bar-stateroom"
        [innerHtml]="(isCommunicative ? 'reservationSummaryBar.gtyStateroomWithReserveType' : 'reservationSummaryBar.gtyStateroom') | translate: {
            description: reservationSummary.description,
            category: reservationSummary.category
        }">
    </span>
    <div class="reservation-summary-bar-highlight">
        <span *ngIf="showSailingInformation" translate>
            reservationSummaryBar.sailingPrice
        </span>
        <span *ngIf="!showSailingInformation" translate>
            reservationSummaryBar.stateroomPrice
        </span>
        <wdpr-price
            presentation="inline"
            hide-currency
            [value]="reservationSummary.total"
            [currency]="reservationSummary.currency">
        </wdpr-price>
    </div>
    <button (click)="goBack()" class="btn-link" translate>
        reservationSummaryBar.returnLink
    </button>
</div>
