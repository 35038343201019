import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { AT_HOME, HTTP_STATUS_CODES } from '../at-home/at-home.constants';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfigService } from '../config/config.service';
import { ErrorHandlingService } from '../error-handling/error-handling.service';
import { GuestCastawayInfo } from '../../interfaces/guest-castaway-info.interface';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';
import { RESERVATION_SUMMARY_CONSTANTS } from '../../reservation-summary-bar/reservation-summary-bar.constants';
import { ReservationSummary } from '../../reservation-summary-bar/reservation-summary.interface';
import { TravelAgentService } from '../../travel-agent/travel-agent.service';

@Injectable({
    providedIn: 'root'
})
export class ReservationSummaryService {
    private reservationSummaryUrl;
    private reservationSummaryUrlTA;
    private webApiUrl: string;

    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private config: ConfigService,
        private errorHandlingService: ErrorHandlingService,
        private nativeBridgeService: NativeBridgeService,
        private travelAgentService: TravelAgentService
    ) {
        this.reservationSummaryUrl = this.config.getValue('reservationSummaryUrl') || '';
        this.reservationSummaryUrlTA = this.config.getValue('reservationSummaryUrlTA') || '';
    }

    /**
     * Gets the Reservation Summary from the Product Availability Service
     * @param alwaysCreateNew
     * @param reservationId
     * @param scope
     * @param swid
     * @returns Observable of ReservationSummary
     */
    getReservationSummary(
        alwaysCreateNew: boolean,
        reservationId: Number,
        scope: string,
        swid: string
    ): Observable<ReservationSummary> {
        if (!alwaysCreateNew) {
            alwaysCreateNew = false;
        }

        const isTravelAgentLoggedIn = this.travelAgentService.isTravelAgentLoggedIn();
        const endpoint = (isTravelAgentLoggedIn ?
            this.reservationSummaryUrlTA :
            this.reservationSummaryUrl)
            .replace('{reservationId}', reservationId.toString())
            .replace('{swid}', this.authenticationService.getUserIdentifier(swid))
            .replace('{scope}', scope)
            .replace('{alwaysCreateNew}', alwaysCreateNew.toString());

        return this.httpClient.get<ReservationSummary>(`${this.webApiUrl}${endpoint}`)
            .pipe(
                map(data => {
                    if (this.reservationSummaryValid(data)) {
                        return this.buildReservationSummary(data);
                    } else {
                        let errorStatus: string | number;

                        if (this.nativeBridgeService.isEmbedded()) {
                            errorStatus = AT_HOME.errorStates.contentMissing;
                            this.errorHandlingService.handleEmbeddedErrors(AT_HOME.errorStates.contentMissing);
                        } else {
                            errorStatus = HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR;
                        }

                        throw {
                            status: errorStatus
                        };
                    }
                }),
                catchError((error) => {
                    if (error['status'] !== AT_HOME.errorStates.contentMissing) {
                        return throwError(this.errorHandlingService.sessionErrorHandler(error, {
                            reservationId: reservationId.toString(),
                            conflictRedirect: true
                        }));
                    }

                    return throwError(error);
                })
            );
    }

    /**
     * Set webApiUrl from SPA
     * @param webApiUrl
     */
    setSvcUrl(webApiUrl: string): void {
        this.webApiUrl = webApiUrl;
    }

    /**
     * Get offer id from reservation summary data
     * @param promoCode from Reservation data
     * @returns offer id
     */
     getOfferIdFromSummary(promoCode: string): string {
        const offer = promoCode || RESERVATION_SUMMARY_CONSTANTS.EBS_PROMO_CODE;

        return `${offer}${RESERVATION_SUMMARY_CONSTANTS.SPECIAL_OFFER_DEFAULT_SUFFIX}`;
    }

    /**
     * Validate offer id from reservation summary data
     * @param promoCode from Reservation data
     * @returns if there is an offer
     */
    hasOffer(promoCode: string): boolean {
        return promoCode && promoCode !== RESERVATION_SUMMARY_CONSTANTS.EBS_PROMO_CODE;
    }

    /**
     * Validates if the offers are the same and different from EBS
     * @param resOffer special offer comming in reservation
     * @param sessionOffer special offer comming in session
     * @returns if the two offers are equal
     */
    private resOfferEqualToSession(resOffer: string, sessionOffer: string): boolean {
        return this.hasOffer(resOffer) && this.hasOffer(sessionOffer) && resOffer === sessionOffer;
    }

    /**
     * Build reservation summary
     * @param data
     * @returns {ReservationSummary}
     */
    private buildReservationSummary(data): ReservationSummary {
        return {
            reserveType: data.reservationSession.reservation.reserveType,
            reservationId: data.reservationSession.reservation.reservationId,
            description: data.voyage.stateroomInformation.stateroomName,
            category: data.reservationSession.reservation.stateroom.stateroomCategory,
            location: data.voyage.stateroomInformation.stateroomLocation,
            deck: data.voyage.stateroomInformation.stateroomDeck,
            stateroomId: data.reservationSession.reservation.stateroom.stateroomId,
            productName: data.voyage.product.name,
            sailDateFrom: data.voyage.sailing.sailDateFrom,
            sailDateTo: data.voyage.sailing.sailDateTo,
            total: data.reservationSession.reservation.grandTotal.subtotal,
            currency: data.reservationSession.reservation.grandTotal.currency,
            shipCode: data.reservationSession.reservation.shipCode,
            partyMix: data.reservationSession.session.partyMix,
            isNewSession: data.reservationSession.session.isNewSession,
            sailYearMonth: data.voyage.sailing.sailYearMonth,
            destinationId: data.voyage.destination.id,
            portFromId: data.voyage.portFrom.id,
            reservationPromoCode: this.getOfferIdFromSummary(data.reservationSession.reservation.promoCode),
            sessionPromoCode: this.getOfferIdFromSummary(data.reservationSession.session.promoCode),
            voyageId: data.reservationSession.reservation.voyageId,
            primaryGuest21Birthdate: data.reservationSession.reservation.primaryGuest21Birthdate,
            hasOfferInReservation: this.hasOffer(data.reservationSession.reservation.promoCode),
            hasOfferInSession: this.resOfferEqualToSession(
                data.reservationSession.reservation.promoCode,
                data.reservationSession.session.promoCode
            ),
            guestCastawayInfo: this.mapCastawayInfo(data.reservationSession.reservation.guests),
            isPrimaryGuestCastawayEligible: data.reservationSession.reservation.isPrimaryGuestCastawayEligible,
            withinEarlyBookingWindow: data.reservationSession.reservation.withinEarlyBookingWindow,
            earlyBookingAvailableFor: data.reservationSession.reservation.earlyBookingAvailableFor,
            newLocale: data.reservationSession.reservation.newLocale,
            newCurrency: data.reservationSession.reservation.newCurrency
        };
    }

    /**
     * Checks that the Reservation Summary Data is valid
     * @param data
     * @returns {boolean}
     */
    private reservationSummaryValid(data): boolean {
        return !!(data &&
            this.reservationSessionValid(data) &&
            this.reservationVoyageValid(data) &&
            this.reservationPartyMixValid(data)
        );
    }

    /**
     * Checks that the Reservation Session Data is valid
     * @param data
     * @returns {boolean}
     */
    private reservationSessionValid(data): boolean {
        return !!(data.reservationSession &&
            data.reservationSession.reservation);
    }

    /**
     * Checks that the Reservation Voyage Data is valid
     * @param data
     * @returns {boolean}
     */
    private reservationVoyageValid(data): boolean {
        return !!(data.voyage &&
            data.voyage.product &&
            data.voyage.product.name &&
            data.voyage.sailing &&
            data.voyage.sailing.sailDateFrom &&
            data.voyage.sailing.sailDateTo);
    }

    /**
     * Checks that the Reservation Party Mix Data is valid
     * @param data
     * @returns {boolean}
     */
    private reservationPartyMixValid(data): boolean {
        return !!(data.reservationSession &&
            data.reservationSession.session &&
            data.reservationSession.session.partyMix);
    }

    /**
     * Build castaway object with primary guest information
     * @param data
     * @returns {GuestCastawayInfo}
     */
    private mapCastawayInfo(data): GuestCastawayInfo {
        let guestInfo: GuestCastawayInfo;

        if (data && data.length) {
            guestInfo = {
                castawayLevel: data[0].castawayClubLevel,
                castawayId: data[0].pastPassengerID && Number(data[0].pastPassengerID),
                isCastawayClubMember: data[0].isCastawayClubMember,
                firstName: data[0].firstName,
                lastName: data[0].lastName,
                title: data[0].title,
                eligibleGrant: data[0].eligibleGrant
            };
        }

        return guestInfo;
    }
}
