import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ContextualFilterComponent } from './contextual-filter.component';
import { ContextualFilterService } from './services/contextual-filter.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        ContextualFilterComponent
    ],
    providers: [
        ContextualFilterService
    ],
    exports: [
        ContextualFilterComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ContextualFilterModule { }
