import { Injectable } from '@angular/core';

import { BREAKPOINT_CONSTANTS } from '../../window-ref/window-ref.constants';
import { WindowRef } from '../../window-ref/window-ref.service';

/**
 * You could use it in your feature like this
 *
 *   private timeoutListener = 500;
 *
 *   constructor(
 *       private breakpointChangeDetectionSvc: BreakpointChangeDetectionService
 *   ) { }
 *
 *   @HostListener('window:resize')
 *   private verifyIsMobile(): void {
 *      clearTimeout(this.timeoutListener);
 *
 *      this.timeoutListener = setTimeout(() => {
 *          this.isMobile = this.breakpointChangeDetectionSvc.isMobile();
 *      }, this.timeoutResponsive);
 *   }
 */
@Injectable({
    providedIn: 'root'
})
export class BreakpointChangeDetectionService {

    constructor(
        private windowRef: WindowRef
    ) {}

    /**
     * Verify if the screen size width is mobile
     *
     * @returns true if is mobile otherwise false
     */
    isMobile(): boolean {
        return this.windowRef.nativeWindow.innerWidth <= BREAKPOINT_CONSTANTS.medium.breakpoint;
    }

    /**
     * Verify if the screen size width is tablet
     *
     * @returns true if is tablet otherwise false
     */
    isTablet(): boolean {
        return this.windowRef.nativeWindow.innerWidth > BREAKPOINT_CONSTANTS.medium.breakpoint &&
            this.windowRef.nativeWindow.innerWidth <= BREAKPOINT_CONSTANTS.large.breakpoint;
    }

    /**
     * Verify if the screen size width is desktop
     *
     * @returns true if is desktop otherwise false
     */
    isDesktop(): boolean {
        return this.windowRef.nativeWindow.innerWidth > BREAKPOINT_CONSTANTS.large.breakpoint;
    }
}
