<dcl-base-modal-v2
    [baseModal]="baseModal">
    <div
        #dropdownDocument
        class="customBody mdd"
        [ngClass]="baseModal.modalClass">
        <ul class="list-dropdown-container" role="listbox">
            <ng-container *ngFor="let option of extendedSelectData; index as idx">
                <li
                    [ngClass]="{
                        'dropdown-selected': extendedSelectSelected.id === option.id,
                        'hidden-option': option.hide || (!option.value && option.type !== extendedSelectTypes.group)
                    }"
                    class="{{'list-dropdown__item ' + option.type}}"
                    tabindex="-1"
                    (keydown.enter)="onSelectionChange($any($event), option)"
                    role="option"
                    attr.aria-selected="{{ extendedSelectSelected.id === option.id ? 'true' : 'false' }}"
                    attr.aria-label="{{ option.label | translate }}">
                    <ng-container
                        [ngTemplateOutlet]="getTemplateRef(option)"
                        [ngTemplateOutletContext]="{ $implicit: option, selected: extendedSelectSelected }">
                    </ng-container>
                </li>
            </ng-container>
        </ul>

        <!-- Item theme Group --------------------------------- -->
        <ng-template #groupTemplate let-option let-selected>
            <div
                class="label-placeholder"
                *ngIf="option.label">
                {{ option?.label | translate }}
            </div>
            <ul class="list-dropdown-container" role="listbox">
                <li
                    *ngFor="let option of option.options; index as idx"
                    [ngClass]="{
                        'dropdown-selected': extendedSelectSelected.id === option.id,
                        'hidden-option': option.hide || (!option.value && option.type !== extendedSelectTypes.group)
                    }"
                    class="{{'list-dropdown__item ' + option.type}}"
                    tabindex="-1"
                    (keydown.enter)="onKeyDownEnterKey($any($event), option)"
                    role="option"
                    attr.aria-selected="{{ extendedSelectSelected.id === option.id ? 'true' : 'false' }}"
                    attr.aria-label="{{ (option?.age === 0) ? (option?.textKeyInfant | translate) :
                        (option?.age >= 18 ?
                        (option?.textKeyAge | translate).replace('{age}', eighteenAgePlus) :
                        (option?.textKeyAge | translate).replace('{age}', option?.age)">
                    <ng-container
                        [ngTemplateOutlet]="getTemplateRef(option)"
                        [ngTemplateOutletContext]="{ $implicit: option, selected: selected }">
                    </ng-container>
                </li>
            </ul>
        </ng-template>

        <!-- Item theme Avatar -------------------------------- -->
        <ng-template #avatarTemplate let-option let-selected>
            <dcl-avatar-header
                class="item-avatar-header"
                [data]="option"
                [description]="option.description"
                [textKeyAge]="option.textKeyAge"
                [textKeyInfant]="option.textKeyInfant"
                [useInfantCopy]="option.useInfantCopy"
                (click)="onSelectionChange($any($event), option)">
            </dcl-avatar-header>
        </ng-template>

        <!-- Item theme Link -------------------------------- -->
        <ng-template #linkTemplate let-option let-selected>
            <div
                class="link-content"
                (click)="onSelectionChange($any($event), option)">
                <dcl-link-button class="item-link"
                    [disabled]="option?.disabled"
                    [icon]="option?.icon"
                    [label]="option?.label">
                </dcl-link-button>
            </div>
        </ng-template>

        <!-- Item theme Default ----------------------------- -->
        <ng-template #defaultTemplate let-option let-selected>
            <div
                class="item-default"
                (click)="onSelectionChange($any($event), option)">
                {{ option?.label | translate }}
            </div>
        </ng-template>
    </div>
</dcl-base-modal-v2>
