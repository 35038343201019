import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgencyAuthToken } from '../interfaces/agency-auth-token.interface';
import { AgencyPassport } from '../interfaces/agency-passport.interface';
import { AgencyPassportRequest } from '../interfaces/agency-passport-request.interface';
import { AgencyLogin } from '../interfaces/agency-login.interface';
import { AgencyLogout } from '../interfaces/agency-logout.interface';
import { AgencyLookup } from '../interfaces/agency-lookup.interface';
import { TRAVEL_AGENT_CONSTANTS } from '../travel-agent.constants';

@Injectable()
export class TravelAgentRestService {
    private constants = TRAVEL_AGENT_CONSTANTS;
    private baseUrl: string;

    constructor(private httpClient: HttpClient, private cookieService: CookieService) {}

    /**
     * Set base URL from the Spa that uses this service
     * It is mandatory to configure this url in the appModule
     * @param url base url to be set
     */
    setBaseUrl(url: string): void {
        this.baseUrl = url;
    }

    /**
     * Retrieve auth http header from cookies
     * @returns value for Authorization header
     */
    getAuthHeader(): string {
        const token = this.cookieService.get(this.constants.COOKIES.TOKEN);

        return token ? `${this.constants.TOKEN_PREFIX} ${token}` : '';
    }

    /**
     * Retrieve agency auth token (B2B Token)
     * @returns Observable for auth token http request
     */
    getAuthToken(): Observable<AgencyAuthToken> {
        return this.httpClient.get<AgencyAuthToken>(
            `${this.baseUrl}${this.constants.ENDPOINTS.AGENCY_TOKEN}`
        );
    }

    /**
     * Retrieve agency information
     * @param data
     * @returns Observable for agency lookup http request
     */
    agencyLookup(data: AgencyLogin): Observable<AgencyLookup> {
        return this.httpClient.post<AgencyLookup>(
            `${this.baseUrl}${this.constants.ENDPOINTS.AGENCY_LOOKUP}`,
            data
        );
    }

    /**
     * Retrieve agency passport token
     * @param data
     * @returns Observable for agency passport token http request
     */
    getPassportToken(data: AgencyPassportRequest): Observable<AgencyPassport> {
        return this.httpClient.post<AgencyPassport>(
            `${this.baseUrl}${this.constants.ENDPOINTS.AGENCY_PASSPORT}`,
            data
        );
    }

    /**
     * Deletes active agency sessions
     * @param data
     * @returns Observable for agency logout http request
     */
    agencyLogout(data: AgencyLogout): Observable<never> {
        return this.httpClient.post<never>(
            `${this.baseUrl}${this.constants.ENDPOINTS.AGENCY_LOGOUT}`,
            data
        );
    }
}
