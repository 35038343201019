import { Component, Input, OnInit } from '@angular/core';
import { unescape } from 'lodash';

import { BANNER_PLACEHOLDER, DEGRADE_CONFIG_DIRECTION } from './special-banner.constants';
import { BannerPosition } from './enums/banner-position.enum';
import { QuickQuoteService } from '../quick-quote/services/quick-quote.service';

@Component({
    selector: 'dcl-special-banner',
    templateUrl: './special-banner.component.html',
    styleUrls: ['./special-banner.component.scss']
})
export class SpecialBannerComponent implements OnInit {
    @Input() altText = '';
    @Input() colorDegradeEnd: string;
    @Input() colorDegradeInit: string;
    @Input() gradientEnd: string;
    @Input() gradientInit: string;
    @Input() imageSrc: string;
    @Input() position: BannerPosition = BannerPosition.TOP_LEFT;
    @Input() text: string;

    readonly positions = BannerPosition;

    bannerText: string;
    imgPlaceholder: string;
    showPlaceholder = true;

    constructor(private quickQuoteService: QuickQuoteService) {
        this.imgPlaceholder = `${this.quickQuoteService.getContextPath()}${BANNER_PLACEHOLDER}`;
    }

    ngOnInit(): void {
        this.setText();
    }

    /**
     * Set the banner text based on the text provided to the component
     * unescaping HTML entities to their corresponding characters
     */
    setText(): void {
        this.bannerText = unescape(this.text);
    }

    /**
     * Hide the placeholder when the image was loaded
     */
    hidePlaceholder(): void {
        this.showPlaceholder = false;
    }

    /**
     * returns a gradient string depending of the position input
     */
    get gradient() {
        switch (this.position) {
            case BannerPosition.BOTTOM_RIGHT:
            case BannerPosition.TOP_RIGHT:
                return this.createGradient(
                    DEGRADE_CONFIG_DIRECTION,
                    this.gradientInit,
                    this.gradientEnd,
                    this.colorDegradeEnd,
                    this.colorDegradeInit
                );
            default:
                return this.createGradient(
                    DEGRADE_CONFIG_DIRECTION,
                    this.gradientInit,
                    this.gradientEnd,
                    this.colorDegradeInit,
                    this.colorDegradeEnd
                );
        }
    }

    /**
     * Creates a gradient string by using config parameters
     * @params direction gradient direction
     * @params initPercentage percentage where de gradient initiates
     * @params endPercentage percentage where de gradient ends
     * @params initColor first color of gradient
     * @params endColor second color of gradient
     *
     * @returns string with the gradient value built
     */
    private createGradient(
        direction: string,
        initPercentage: string,
        endPercentage: string,
        initColor: string,
        endColor: string
    ) {
        return `linear-gradient(${direction}, ${initColor} ${initPercentage}, ${endColor} ${endPercentage})`;
    }
}
