<section
    *ngIf="bannerData"
    class="base-banner"
    [ngClass]="theme">
    <div
        class="base-banner-container"
        [ngClass]="{'noise': bannerData.showBackgroundNoise}"
        [style]="backgroundStyle">
        <div
            *ngIf="bannerData.bannerIcon && !imageError"
            class="base-banner-container--image-wrapper">
            <img
                class="banner--image"
                aria-hidden="true"
                [src]="bannerData.bannerIcon"
                (error)="handleImageError()">
        </div>
        <div
            *ngIf="bannerData.title || bannerData.description"
            class="base-banner-container--content">
            <h2
                *ngIf="bannerData.title"
                class="banner--title"
                [innerHTML]="bannerData.title">
            </h2>
            <p
                *ngIf="bannerData.description"
                class="banner--description"
                [innerHTML]="bannerData.description">
            </p>
        </div>
        <div
            *ngIf="bannerData.bannerCTA.href"
            class="base-banner-container--cta-wrapper"
            [ngClass]="{'decoration-image': bannerData.decorationImage}"
            [style]="ctaDecorationImageStyle">
            <button
                class="banner--cta"
                role="link"
                (click)="onClickCta()">
                {{ bannerData.bannerCTA.title }}
            </button>
        </div>
        <button
            *ngIf="bannerData.showCloseButton"
            class="base-banner-container--close-btn"
            [attr.aria-label]="constants.ariaLabelCloseButton"
            (click)="closeBanner()">
            <span class="di di-close-reversed" aria-hidden="true"></span>
        </button>
    </div>
</section>
