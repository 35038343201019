// taken from https://hyperion.disney.com/breakpoints-grids/
export const BREAKPOINT_CONSTANTS = {
    small: {
        breakpoint: 567
    },
    medium: {
        breakpoint: 767
    },
    large: {
        breakpoint: 1023
    }
};
