import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { difference } from 'lodash';

import { ActionTile } from './interfaces/action-tile.interface';
import { BREAKPOINT_CONSTANTS } from '../breakpoint-change-detection/constants/breakpoint.constants';
import { BreakpointItem } from '../breakpoint-change-detection/interfaces/breakpoint-item.interface';
import { CRUISE_TILES_CONSTANTS } from './featured-cruise-tiles.constants';
import { CruiseTile, CruiseTileTranslateOptions } from './interfaces/cruise-tile.interface';
import { CruiseTileAction } from './interfaces/cruise-tile-action.interface';
import { FeaturedCruisesHidden } from './interfaces/featured-cruises-hidden.interface';
import { FeaturedCruiseService } from './services/featured-cruise.service';
import { OptionItem } from '../options-groups/interfaces/option-item.interface';
import { TileData } from './interfaces/tile-data.interface';
import { WindowRef } from '../window-ref/window-ref.service';

@Component({
    selector: 'dcl-featured-cruise-tiles',
    templateUrl: './featured-cruise-tiles.component.html',
    styleUrls: ['./featured-cruise-tiles.component.scss']
})
export class FeaturedCruiseTilesComponent implements OnInit {
    @Input() cruiseName: string;
    @Input() cruiseTiles: CruiseTile;
    @Input() customImgPlaceholder: string;
    @Input() isHomePage = false;
    @Input() title: string;
    @Output() onFeaturedCruiseSelected = new EventEmitter<TileData>();
    @Output() onExploreAllCruisesSelected = new EventEmitter<void>();
    /**
     * Useful event to capture any problems with visibility of the section and tiles,
     * which can be used to log and alert why they are not shown
     */
    @Output() onHidden = new EventEmitter<FeaturedCruisesHidden>();

    breakpoints = BREAKPOINT_CONSTANTS;
    constants = CRUISE_TILES_CONSTANTS;
    cruiseListingUrl = CRUISE_TILES_CONSTANTS.cruiseLink;
    isMobile: boolean;

    constructor(
        private featuredCruiseService: FeaturedCruiseService,
        private windowRef: WindowRef
        ) {}

    public ngOnInit(): void {
        this.applyVisibilityRules();
    }

    /**
     * Actions executed when tile selected
     * @param actionTile - Tile selected by user
     */
    onTileSelected(actionTile: ActionTile): void {
        const tileData = {
            filterData: this.parseFilters(actionTile.actions),
            actions: actionTile.actions
        };

        this.onFeaturedCruiseSelected.emit(tileData);
    }

    /**
     * Parses action values to be used in the QQ filter selection flow
     * @param actionTile - Tile selected by user
     * @returns Option item array for each action
     */
    parseFilters(actions: CruiseTileAction[]): OptionItem[] {
        return actions.map((action: CruiseTileAction) =>
            ({
                disabled: true,
                id: action.id,
                label: action.name,
                selected: false,
                urlFriendlyId: action.urlFriendlyId
            })
        );
    }

    /**
     * Triggered by dclBreakpointChange() event
     * @param breakpoint - New value according to window size breakpoint
     */
    onBreakPointChange(breakpoint: BreakpointItem): void {
        this.isMobile = breakpoint.matches;
    }

    /**
     * Unify information for translate options pipe
     * @param actionTile - single title from cruiseTiles.tiles
     * @param index - position tile from ngFor directive
     * @returns Object with dynamic properties for translate options pipe
     */
    parseTranslateOptions(actionTile: ActionTile, index: number): CruiseTileTranslateOptions {
        return {
            copy: `${actionTile.text} - ${actionTile.description}`,
            index: (index + 1),
            total: this.cruiseTiles.tiles.length
        };
    }

    /**
     * Clear filters when selecting FC button from homepage
     */
    clearFiltersOnRedirect(): void {
        this.featuredCruiseService.clearFilters();
        this.onExploreAllCruisesSelected.emit();
        this.windowRef.nativeWindow.location.href = this.cruiseListingUrl;
    }

    /**
     * The visibility of the component and its tiles are based on:
     *  - (1) when there are no product tiles to feature, then hide this section
     *  - (2) when you can't retrieve all the content to display the product tile properly,
     *    then hide the tile (For homepage, the price is not need it)
     *  - (3) when you can't retrieve the tag line (header - title) properly, then hide this section
     *
     * Note that the visibility of the section is determine by the tiles so if there is any
     * tile available to display it will be hidden using ngIf
     */
    private applyVisibilityRules(): void {
        let filteredTiles: ActionTile[] = [];

        // first verify rule #3
        if (this.cruiseTiles.title) {
            // then verify rules #1 & #2
            filteredTiles = this.cruiseTiles.tiles
                .filter((tile: ActionTile) => {
                    return tile.text && tile.tileImage && (this.isHomePage || tile.description);
                });
        }

        if (this.cruiseTiles.tiles.length !== filteredTiles.length || !this.cruiseTiles.tiles.length) {
            this.emitOnHidden(difference(this.cruiseTiles.tiles, filteredTiles));
        }

        this.cruiseTiles.tiles = filteredTiles;
    }

    /**
     * Emit event when there are issues related to visibility,
     * this could be capture by the SPA to, for example, log info
     *
     * @param cruiseTiles
     * @emits onHidden<FeaturedCruisesHidden> with the issues information
     */
    private emitOnHidden(cruiseTiles: CruiseTile[]): void {
        const dataHidden = {
            isSectionHidden: this.cruiseTiles.tiles.length === 0 ||
                cruiseTiles.length === this.cruiseTiles.tiles.length,
            hasTilesHidden: cruiseTiles.length > 0,
            tilesHidden: cruiseTiles
        } as FeaturedCruisesHidden;

        this.onHidden.emit(dataHidden);
    }
}
