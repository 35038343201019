<picture
    class="picture"
    [ngClass]="{ 'picture__show': !showPlaceholderImage }">
    <source
        *ngFor="let source of heroImageSrc"
        [srcset]="source.srcSet"
        [media]="source.mediaQuery">

    <img
        *ngIf="!hideHeroImage"
        class="hero-image"
        (load)="finishLoad()"
        (error)="hideImage()"
        [srcset]="defaultImageSrcSet"
        [src]="defaultImageSrc"
        alt="{{ altImage | translate }}"/>
</picture>
<img
    *ngIf="hasPlaceholder && showPlaceholderImage"
    class="custom-img-placeholder"
    [src]="customImgPlaceholder"
    [alt]="customAltPlaceholder"/>
