import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * Format saling dates depending on entry data in the next way:
 * different year: Dec 27, 2018 - Jan 3, 2019
 * different month: Nov 27 - Dec 3, 2019
 * different day: Nov 20 - 27, 2019
 * Only receive this format '2019-01-31' for dateFrom and dateTo
 */
@Pipe({
    name: 'sailingDate'
})
export class SailingDatePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe
    ) { }

    /**
     * @description format sailing dates according entry data
     * @param dateFrom Initial date
     * @param dateTo Final date
     */
    transform(dateFrom, dateTo): string {
        let from;
        let to;
        let year;

        if (!dateFrom || !dateTo) {
            return '';
        }

        /**
         * Should transform date from 'YYYY-MM-DD' into 'YYYY/MM/DD' in order to avoid
         * timezone that javascript applies in dashes format
         */
        dateFrom =  new Date(dateFrom.replace(/-/g, '\/'));
        dateTo = new Date(dateTo.replace(/-/g, '\/'));

        if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
            from = this.datePipe.transform(dateFrom, 'MMM d, yyyy');
            to = this.datePipe.transform(dateTo, 'MMM d, yyyy');
        } else if (dateFrom.getMonth() !== dateTo.getMonth()) {
            from = this.datePipe.transform(dateFrom, 'MMM d');
            to = this.datePipe.transform(dateTo, 'MMM d');
            year = this.datePipe.transform(dateTo, 'yyyy');
        } else {
            from = this.datePipe.transform(dateFrom, 'MMM d');
            to = this.datePipe.transform(dateTo, 'd');
            year = this.datePipe.transform(dateTo, 'yyyy');
        }

        return this.concatDates(from, to, year);
    }

    /**
     * @description Concat date according entry data
     * @param {string} from initial date
     * @param {string} to final date
     * @param {string} year [optional] when year is not included in 'from' and 'to' dates
     * @return format date
     */
    private concatDates(from: string, to: string, year?: string): string {
        year = year ? (', ' + year) : '';

        return from + ' - ' + to + year;
    }
}
