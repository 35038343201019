import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.dlr.module';
import { environment } from './environments/environment';

import { defineCustomElementWdprLodgingQuickquote } from '@wdpr/lodging-quickquote/dist/components';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

defineCustomElementWdprLodgingQuickquote();
