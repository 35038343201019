import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CASTAWAY_MEMBERSHIP_CONSTANTS } from '../castaway-membership-modal.constants';
import { CastawayMembershipComponent } from '../castaway-membership/castaway-membership.component';
import { GuestCastawayInfo } from '../../interfaces/guest-castaway-info.interface';
import { ModalConfig } from '../../interfaces/modal-config.interface';

@Injectable()
export class CastawayMembershipModalService {
    constructor(private dialog: MatDialog, private translate: TranslateService) { }

    /**
     * Opens castaway membership modal
     * @returns Replay subject for castaway membership modal
     */
    open(): ReplaySubject<GuestCastawayInfo> {
        const modalResponse: ReplaySubject<GuestCastawayInfo> = new ReplaySubject<GuestCastawayInfo>();
        this.showModal(modalResponse);

        return modalResponse;
    }

    /**
     * Shows castaway membership modal
     * @param modalResponse to manage successfull and not successuful castaway membership response
     */
    private showModal(modalResponse: ReplaySubject<GuestCastawayInfo>): void {
        const data = this.getCastawayMembershipModalData();
        let modal;
        let wrapper;

        modal = this.dialog.open(data['component'], {
            panelClass: data['config'].panel,
            backdropClass: data['config'].backdrop,
            autoFocus: false,
            ariaLabel: this.translate.instant('travelAgent.castawayMembershipModal.header')
        });

        modal.afterOpened().subscribe(() => {
            wrapper = document.querySelector('.cdk-overlay-container');

            if (wrapper && data['data'].animateClass) {
                wrapper.classList.add(data['data'].animateClass);
            }
        });

        modal.afterClosed().subscribe((result: GuestCastawayInfo) => {
            if (result) {
                modalResponse.next(result);
            } else {
                modalResponse.error({
                    error: CASTAWAY_MEMBERSHIP_CONSTANTS.ERRORS.NO_DATA
                });
            }
        });
    }

    /**
     * Returns configuration object for castaway membership modal
     * @returns modal config
     */
    private getCastawayMembershipModalData(): ModalConfig {
        return {
            component: CastawayMembershipComponent,
            config: {
                panel: ['base-dialog'],
                backdrop: 'dcl-dialog-backdrop',
            },
            data: {
                animateClass: 'no-animate'
            }
        };
    }
}
