import { WhatsIncludedDataColumn } from './interfaces/whats-included-data-column.interface';

const DEFAULTS: Record<string, Partial<WhatsIncludedDataColumn['webfont']>> = {
    'columnIncluded': {
        htmlCharacter: '&#57921;'
    },
    'columnExtras': {
        htmlCharacter: '&#57867;'
    }
};

export const WHATS_INCLUDED_MODAL_CONSTANTS = {
    tabsKeys: {
        DCL_WHATSINCLUDED_ENTERTAINMENT: 'DCL_WhatsIncluded_Entertainment',
        DCL_WHATSINCLUDED_DINING: 'DCL_WhatsIncluded_Dining',
        DCL_WHATSINCLUDED_ACCOMMODATION: 'DCL_WhatsIncluded_Accommodation',
        DCL_WHATSINCLUDED_YOUTHCLUBS: 'DCL_WhatsIncluded_YouthClubs',
        DCL_WHATSINCLUDED_ADULTEXCLUSIVE: 'DCL_WhatsIncluded_AdultExclusive',
        DCL_WHATSINCLUDED_OURPRIVATEISLAND: 'DCL_WhatsIncluded_OurPrivateIsland'
    },
    DCL_WHATSINCLUDED_MODAL: 'DCL_WhatsIncludedModal',
    DCL_WHATSINCLUDED_EXIT: 'DCL_WhatsIncluded_Exit',
    DCL_WHATSINCLUDED_PRINT: 'DCL_WhatsIncluded_Print',
    INCLUDED_COLUMN_ID: 'columnIncluded',
    COLUMNS_MODULE_ID: 'columns',
    NAVIGATION_MODULE_ID: 'navigation',
    EXTRAS_COLUMN_ID: 'columnExtras',
    INCLUDED_PROPERTY_NAME: 'included',
    EXTRAS_PROPERTY_NAME: 'extras',
    DEFAULTS,
    fallbackHtmlCharacter: DEFAULTS.columnIncluded.htmlCharacter,
    headers: {
        ACCEPT_LANGUAGE: 'Accept-Language'
    },
    FLAG_TYPES: {
        included: {
            columnId: 'columnIncluded',
            propertyName: 'included',
            iconDefaultAriaLabel: 'Check Mark'
        },
        extras: {
            columnId: 'columnExtras',
            propertyName: 'extras',
            iconDefaultAriaLabel: 'Dollar Sign'
        }
    }
} as const;
