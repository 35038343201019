<dcl-modal
    *ngIf="baseModal"
    #baseModalV2
    aria-close
    block-focus
    class="base-modal"
    [ngClass]="baseModal.modalClass"
    [attr.open]="baseModal.openModal || null"
    [disableCloseOnBackground]="baseModal.disableCloseOnBackground || null"
    [disableTabIndexOnSlotButtons]="true"
    [fullscreen]="isMobile"
    [hideButtonsSeparator]="isMobile"
    [hideCloseIcon]="isMobile || baseModal.hideCloseIcon || null"
    [hideTitleBodySeparator]="!isMobile"
    [noPadding]="true"
    (closedModalByCloseIcon)="closeModal()"
    (closedOnBackgroundClick)="closeModal()"
    (closedOnEscape)="closeModal()">
    <div
        *ngIf="isMobile"
        slot="title"
        class="base-modal__header">
        <button
            *ngIf="!baseModal.hideMobileArrowCloseIcon"
            class="base-modal__arrow-close-button"
            (click)="closeModal()">
            <i class="di di-previous" aria-hidden="true"></i>
        </button>
        <h2
            *ngIf="baseModal.header?.title"
            class="base-modal__header-title">
            {{baseModal.header.title | translate}}
        </h2>
        <ng-container *ngIf="baseModal.header?.logo">
            <dcl-header-logo class="header-container"></dcl-header-logo>
        </ng-container>
    </div>

    <div
        slot="body"
        class="base-modal__body">
        <div
            *ngIf="!baseModal.useCustomBody"
            class="base-modal__body-image-container">
            <dcl-loading-spinner
                *ngIf="baseModal.showLoadingSpinner"
                class="base-modal__loading-spinner"
                [size]="'small'"
                [showSpinner]="baseModal.loading"
                [showBackdrop]="baseModal.loading">
            </dcl-loading-spinner>
            <div
                role="alert"
                aria-atomic="true">
                <div
                    *ngIf="baseModal.body.image || baseModal.body.animationOptions"
                    class="base-modal__image-container">
                    <img
                        *ngIf="baseModal.body.image"
                        class="base-modal__image"
                        src="{{baseModal.body?.image | translate}}"
                        [attr.alt]="baseModal.body?.altImage | translate"/>
                    <ng-lottie
                        *ngIf="baseModal.body.animationOptions"
                        [options]="baseModal.body.animationOptions"
                        [styles]="baseModal.body.animationStyles"
                        (animationCreated)="animationCreated($event)">
                    </ng-lottie>
                </div>
                <h1
                    *ngIf="baseModal.body.title"
                    class="base-modal__title">
                    {{baseModal.body.title | translate}}
                </h1>
                <div
                    *ngIf="baseModal.body?.description"
                    class="base-modal__description"
                    [innerHTML]="baseModal.body.description | translate: baseModal.body.descriptionParams">
                </div>
                <div
                    *ngIf="baseModal.body.buttons && baseModal.body.buttons.length"
                    class="base-modal__body-buttons">
                    <wdpr-button
                        *ngFor="let button of baseModal.body.buttons"
                        class="base-modal__body-button {{button.buttonClass}}"
                        [ngClass]="{'btn-link': button.type === buttonTypes.link}"
                        [theme]="button.theme"
                        [disabled]="baseModal.loading || button.disabled"
                        (click)="actionEmit($any($event), button.action)">
                        {{ button.text | translate }}
                    </wdpr-button>
                </div>
            </div>
            <hr
                *ngIf="!isMobile && baseModal.buttons && baseModal.buttons.length"
                class="base-modal__buttons-body-separator"/>
        </div>
        <ng-content class="base-modal__body"
            select=".customBody"
            *ngIf="baseModal.useCustomBody">
        </ng-content>
    </div>

    <div
        *ngIf="baseModal.buttons && baseModal.buttons.length"
        slot="buttons"
        class="base-modal__buttons">
        <wdpr-button
            *ngFor="let button of baseModal.buttons"
            class="base-modal__button {{button.buttonClass}}"
            [ngClass]="{'btn-link': button.type === buttonTypes.link}"
            [theme]="button.theme"
            [disabled]="baseModal.loading || button.disabled"
            (click)="actionEmit($any($event), button.action)">
            <span>{{ button.text | translate }}</span>
        </wdpr-button>
    </div>
</dcl-modal>
