import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';

import { AnalyticsDataModel } from '../quick-quote/interfaces/analytics-data-model.interface';
import { AnalyticsStateroom } from './interfaces/stateroom-analytics';
import { ReservationFlowService } from '../services/reservation-flow/reservation-flow.service';
import { Stateroom } from './interfaces/stateroom.interface';
import { StateroomComponent } from './components/stateroom/stateroom.component';
import { StateroomLimit } from './interfaces/stateroom-limit.interface';
import { TRAVEL_PARTY_CONSTANTS } from './travel-party.constants';
import { TravelPartyService } from './services/travel-party.service';

@Component({
    selector: 'dcl-travel-party',
    templateUrl: './travel-party.component.html',
    styleUrls: ['./travel-party.component.scss']
})
export class TravelPartyComponent implements OnInit, AfterViewInit {
    @Input() showTravelPartyBox: boolean;
    @Input() isDclShipAdaPhase2: boolean;
    @Output() onTravelPartyCountChange: EventEmitter<number> = new EventEmitter();
    @Output() analyticsTrackingEvent: EventEmitter<AnalyticsDataModel> = new EventEmitter();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();
    @Output() firstItemTabbed = new EventEmitter<KeyboardEvent>();
    @Output() analyticsStateroomEvent = new EventEmitter<AnalyticsStateroom>();
    @ViewChildren('staterooms') stateroomsComponents: QueryList<StateroomComponent>;

    constants = TRAVEL_PARTY_CONSTANTS;
    isFirstLoad = true;
    isModFlow = false;
    maxStaterooms: number;
    staterooms: Stateroom[];
    stateroomLimit: StateroomLimit;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private reservationFlowService: ReservationFlowService,
        private travelPartyService: TravelPartyService
    ) {}

    ngOnInit() {
        this.travelPartyService.setIsDclShipAdaPhase2Value(this.isDclShipAdaPhase2);
        this.isModFlow = this.reservationFlowService.isModFlow();
        this.staterooms = this.travelPartyService.getStaterooms();
        this.maxStaterooms = this.travelPartyService.getMaxStaterooms();
        this.getStateroomLimit();
    }

    /**
     * Adds a single stateroom to the stateroom item list
     * @param ev the MouseEvent
     */
    addNewStateroom(ev: MouseEvent): void {
        this.travelPartyService.addNewStateroom();
        this.onTravelPartyCountChange.emit(this.travelPartyService.getTravelPartyCount());
        this.getStateroomLimit();

        if (!this.isFirstLoad) {
            const analyticsStateroomData = {
                stateroomTypeAction: TRAVEL_PARTY_CONSTANTS.analytics.actionTypes.addStateroom,
                numberRoomsBooked: this.staterooms.length
            };

            this.analyticsStateroomEvent.emit(analyticsStateroomData);
        }

        this.changeDetector.detectChanges();
        this.stateroomsComponents.last.focusCheckbox();
        ev.stopPropagation();
    }

    /**
     * Updates a single stateroom according to the one that has been edited
     * @param stateroom stateroom with the new changes
     * @param stateroomIndex index of stateroom that will be updated
     */
    updateStateroom(stateroom: Stateroom, stateroomIndex: number): void {
        const analyticsStateroomData = {
            bookingSearchPartySize: this.travelPartyService.getAnalyticsPartySize(),
            isAddAdult: stateroom.isAdult,
            isAddGuest: stateroom.isAddGuest,
            stateroomTypeAction: stateroom.stateroomAction
        };

        if (!this.isFirstLoad) {
            this.analyticsStateroomEvent.emit(analyticsStateroomData);
        }

        this.travelPartyService.updateStateroom(stateroom, stateroomIndex);
        this.onTravelPartyCountChange.emit(this.travelPartyService.getTravelPartyCount());
    }

    /**
     * Removes a single stateroom given by its index in the stateroom list
     * @param index is the index of the stateroom to remove
     * @param stateroom data from the staterooms
     */
    removeStateroom(index: number, stateroom: Stateroom): void {
        this.travelPartyService.removeStateroom(index);
        this.onTravelPartyCountChange.emit(this.travelPartyService.getTravelPartyCount());
        this.getStateroomLimit();

        if (!this.isFirstLoad) {
            const analyticsStateroomData = {
                stateroomTypeAction: stateroom.stateroomAction,
                numberRoomsBooked: this.staterooms.length
            };

            this.analyticsStateroomEvent.emit(analyticsStateroomData);
        }
    }

    /**
     * Checks if there's more than 1 stateroom to show/hide remove link button on Stateroom Component
     */
    checkForMoreStaterooms() {
        return this.staterooms.length > 1;
    }

    /**
     * Emits firstItemTabbed event if was called by the first stateroom and there is no errors
     * @param event Keyboard event
     * @param isFirstStateroom Flag to know if is the first stateroom
     */
    onFirstItemTabbed(event: KeyboardEvent, isFirstStateroom: boolean): void {
        if (isFirstStateroom && !this.travelPartyService.getStateroomsWithErrors().length) {
            this.firstItemTabbed.emit(event);
        }
    }

    /**
     * Emits lastItemTabbed event based on some conditions
     * Emits if was not called by a stateroom
     * Emits if was called by the last stateroom and the staterooms length is the max count
     * @param event Keyboard event
     * @param isFromStateroom optional - Flag to know if was called by a stateroom
     * @param isLastStateroom optional - Flag to know if is the last stateroom
     */
    onLastItemTabbed(event: KeyboardEvent, isFromStateroom?: boolean, isLastStateroom?: boolean): void {
        if (!isFromStateroom ||
            (isLastStateroom && this.staterooms.length === this.maxStaterooms)
        ) {
            this.lastItemTabbed.emit(event);
        }
    }

    ngAfterViewInit(): void {
        this.isFirstLoad = false;
    }

    /**
     * Get stateroomLimit validated to know if the AddMoreStaterooms' button should be disabled
     */
    private getStateroomLimit(): void {
        this.stateroomLimit = this.travelPartyService.getStateroomLimitValidated();
    }
}
