import { Component, Input, OnInit } from '@angular/core';
import { VisibleMqService } from '@app/services/visible-mq/visible-mq.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pixie-story-block-item',
  templateUrl: './story-block-item.component.html',
  styleUrls: ['./story-block-item.component.scss'],
})
export class StoryBlockItemComponent implements OnInit {
  @Input() analyticsTrackingEvent = (linkId, {}) => {};
  @Input() item: any = {
    type: '',
  };
  renderImage = false;
  anchorAriaLabel = '';

  constructor(private mqService: VisibleMqService) {}

  ngOnInit() {
    if (this.item.itemsWrapped?.foreground[0] && this.item.itemsWrapped?.foreground[0].classes.indexOf('title') > -1) {
      this.anchorAriaLabel = this.removeHtmlTags(this.item.itemsWrapped?.foreground[0].text || this.item.itemsWrapped?.foreground[0].items[0].text);
    }
    if (this.item.type === 'image') {
      if (this.item.classes.match(/(^|\s)(visible|hidden)-\S*/g)) {
        this.mqService.currentMediaChanged$
          .pipe(debounceTime(50))
          .subscribe((currMedia) => this.mqHandler(currMedia.className));
        this.mqHandler(this.mqService.currentMedia.className);
      } else {
        this.renderImage = true;
      }
    }
  }

  removeHtmlTags(inputString) {
    // Use a regular expression to remove HTML tags
    let result = inputString.replace(/<[^>]*>/g, '');
    result = result.replace(/&nbsp;/g, ' ');

    return result;
  }

  analyticsTrackingClick = () => {
    if (this.analyticsTrackingEvent) {
      this.analyticsTrackingEvent(this.item.analyticsLinkId, {});
    }
  }

  mqHandler(mqClassName): void {
    this.renderImage = this.item.classes.indexOf(mqClassName) > -1;
  }
}
