import { Component, Input, OnInit } from '@angular/core';

import { AvatarHeader } from './interfaces/avatar-header.interface';
import { AvatarMedia } from '../interfaces/avatar-media.interface';
import { AVATAR_HEADER_CONSTANTS } from './avatar-header.constants';
import { TranslateService } from '@ngx-translate/core';
import { AvatarUtilsService } from './services/avatar-utils.service';
import { capitalizeEachString } from '../utils/capitalizeEachString/capitalize-each-string';

/**
 * Avatar Header component
 *
 * ## how to use?
 *
 * -- Minimun translations keys/values taht could be required
 *   "avatarHeaderInfant": "Infant",
 *   "avatarHeaderAge": "Age {age}",
 *
 * <dcl-avatar-header
 *       [useInfantCopy]="(optional, TRUE by default) to display the "Infant" text instead of "Age 0" text"
 *       [description]="(optional, FALSE by default) a custom translated text do display instead of "Age x" text"
 *       [textKeyAge]="(optional, "avatarHeaderAge" by default) a custom translate key text do display the age of
 *                      the guest. Example in your i18n SPA file: "myFeature.avatarHeaderAge": "Age {age}"
 *       [textKeyInfant]="(optional, "avatarHeaderInfant" by default) a custom translate key text do display the age of
 *                      an infant guest. Example in your i18n SPA file: "myFeature.avatarHeaderInfant": "Infant"
 *       [data]="an AvatarHeader interface object">
 *   </dcl-avatar-header>
 *
 */
@Component({
    selector: 'dcl-avatar-header',
    templateUrl: './avatar-header.component.html',
    styleUrls: ['./avatar-header.component.scss']
})
export class AvatarHeaderComponent implements OnInit {
    // to display the label this component use the Guest interface
    @Input() data: AvatarHeader;

    // by default this component will try to display the "Age" line (ie: Age 18+),
    // this param could define a custom description already translated to replace default
    // you need to provide the copies of these keys "avatar-header.age" and "avatar-header.age"
    @Input() description?: string;

    // Determine the translation key for "age" text(ie: Age {age}), if not provided the key "avatarHeaderAge" is used
    @Input() textKeyAge?: string;

    // Determine the translation key for "infant" text, if not provided the key "avatarHeaderInfant" is used
    @Input() textKeyInfant?: string;

    // Determine if the "Infant" copy text should be used while resolving the age of this Guest
    // By PRODUCT decision usually the the copy to display is "Infant"('avatar-header.infant), but
    // you must validate it in your project (TRUE by default)
    @Input() useInfantCopy?: boolean = true;

    altDefaultAvatar: string;
    defaultAvatar: AvatarMedia;

    // holder that represent the initals of firstName and lastName
    initials?: string;

    // holder that represent what we display using the Guest information interface
    label: string;

    urlDefaultAvatar: string;

    constructor(
        private translateSvc: TranslateService,
        private avatarUtilsSvc: AvatarUtilsService
    ) { }

    ngOnInit() {
        this.setLabel();

        // by default this component will try to create the "Age x" information, but we could have
        // custom description text or not displayed if empty string is provided
        if (!this.description && this.description !== '') {
            this.setAgeDescription();
        }

        if (this.data.useRandomAvatar && !this.data.useDefaultAvatar) {
            this.setRandomAvatar();
        }

        if (this.data.useInitials) {
            this.setInitials();
        }
        // Use a default application avatar if the flag comes on
        if (this.data.useDefaultAvatar) {
            this.setDefaultAvatar();
        }
    }

    /**
     * Set the label that will be displayed based on Guest interface
     *
     * [TIP]
     * If your service response return the fullname already resolved you just need to use the "title" prop to display it
     *   - ie of Service Response:
     *      {
     *          fullname: "Mr. Biellej Qwerty",
     *          age: 53
     *      }
     *   - updated to AvatarHeader interface:
     *    {
     *          title: "Mr. Biellej Qwerty",
     *          age: 53
     *    }
     *
     */
    private setLabel(): void {
        const dataLabel = [
            this.data.title,
            this.data.firstName,
            this.data.middleName,
            this.data.lastName,
            this.data.suffix
        ];

        this.label = this.capitalizeDataLabel(dataLabel).filter(Boolean).join(' ');
    }

    /**
     * Receives an array of strings and returns an array of string capitalized
     */
    private capitalizeDataLabel(dataArray: string[]): string[] {
        return dataArray.map((data) => this.data.useCapitalizeWords ? capitalizeEachString(data) : data);
    }

    /**
     * Determine the description to display based on a Guest "age"
     */
    private setAgeDescription(): void {
        const translationsKeys = [
            (this.textKeyInfant || 'avatarHeaderInfant'),
            (this.textKeyAge || 'avatarHeaderAge')
        ];
        const copyKey = this.useInfantCopy && this.data.age === 0 ? translationsKeys[0] : translationsKeys[1];

        // Guests over 18 will have their age shown as "18+"
        // while guests under 18 will have their actual age
        // or "Infant" if age 0
        const displayAge = this.data.age >= AVATAR_HEADER_CONSTANTS.adultAge
            ? AVATAR_HEADER_CONSTANTS.eighteenAgePlus
            : this.data.age;

        this.translateSvc.get(translationsKeys, {age: displayAge})
            .subscribe(translations => {
                this.description = translations[copyKey] || '';
            });
    }

    /**
     * Set the default application avatar
     */
    private setDefaultAvatar() {
        if (!this.data.avatar) {
            this.data.avatar = {
                url: ''
            };
        }

        this.data.avatar = this.avatarUtilsSvc.getDefaultAvatar();
    }

    /**
     * Set a random avatar media item for this Guest
     */
    private setRandomAvatar(): void {
        if (!this.data.avatar) {
            this.data.avatar = {
                url: ''
            };
        }

        this.data.avatar = this.avatarUtilsSvc.getRandomAvatar();

        // once we finished to define the random avatar we turn-off this functionality
        this.data.useRandomAvatar = false;
    }

    /**
     * Generates the initials information
     */
    private setInitials(): void {
        this.initials = `${this.data.firstName.charAt(0)}${this.data.lastName.charAt(0)}`;
    }
}
