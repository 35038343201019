export class InputNumberConstants {
    static getKeyCodes() {
        return {
            'KEYCODE_LEFT': 37,
            'KEYCODE_RIGHT': 39,
            'KEYCODE_TAB': 9,
            'KEYCODE_BACKSPACE': 8,
            'KEYCODE_DELETE': 127,
            'KEYCODE_ZERO': 48,
            'KEYCODE_NINE': 57,
            'KEYCODE_PERIOD': 46
        };
    }
}
