const WARNING_MESSAGING_CONSTANTS = {
    WARNING_MSG_ARIA_LIVE: {
        ERROR: 'assertive',
        WARNING: 'polite',
        INFO: 'off'
    },
    ARIA_ROLES: {
        ALERT: 'alert'
    }
};

export { WARNING_MESSAGING_CONSTANTS };
