import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[dclAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
    @Input() isPolymerInput: boolean;

    constructor(private el: ElementRef) { }

    ngAfterViewInit() {
        if (this.isPolymerInput) {
            const input = this.el.nativeElement.shadowRoot.querySelectorAll('input');
            input[0].focus();
        } else {
            this.el.nativeElement.focus();
        }
    }
}
