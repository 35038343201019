import { Injectable } from '@angular/core';

import { DclBaseModalComponent } from '../../base-modal/modal/base-modal.component';
import { ModalService } from '../../base-modal/services/modal.service';
import { SHARED_INFORMATIONAL_CONSTANTS } from './shared-informational-modal.constants';

@Injectable({
    providedIn: 'root'
})
export class SharedInformationalModalService {

    constructor(
        private modalService: ModalService,
    ) { }

    handleLockedSession(error, action: Function): void {
        const button = [{
            theme: 'primary',
            text: 'shared-informational-modal.okay',
            executeAfterClose: true,
            action
        }];
        const component = DclBaseModalComponent;
        let modalBody: string = 'shared-informational-modal.guest-error';

        error = error || {};

        if (error.body &&
            error.body.errorCode === SHARED_INFORMATIONAL_CONSTANTS.errorCodes.systemError) {
            modalBody = 'shared-informational-modal.system-error';
        }

        this.modalService.openModal({
            component,
            config: {
                panel: ['base-dialog', 'medium'],
                backDrop: 'dcl-dialog-backdrop',
                disableClose: true
            },
            data: {
                title: 'shared-informational-modal.title',
                body: modalBody,
                isTitleLogo: false,
                isClose: false,
                buttons: button,
                animateClass: 'no-animate',
                scrollTop: true
            }
        });
    }
}
