import { NgModule } from '@angular/core';

import { AutofocusFirstInvalidDirective } from './autofocus-first-invalid.directive';

@NgModule({
    declarations: [
        AutofocusFirstInvalidDirective
    ],
    exports: [
        AutofocusFirstInvalidDirective
    ]
})
export class AutofocusFirstInvalidModule { }
