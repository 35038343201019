import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class ErrorLogInterceptor implements HttpInterceptor {
    errorsArray = [];

    constructor() {}

    intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {

        if (req.url.endsWith('wdpr-web-dcl-homepage/error')) {
            try {
                const bodyJSON = JSON.parse (req.body[0][1]);
                const messageJSON = bodyJSON.message;
                if (!this.errorsArray.includes(messageJSON)) {
                    this.errorsArray.push(messageJSON);
                } else {
                    return;
                }
            } catch (e) {
                console.log(e);
            }
        }

        return next.handle(req);
    }
}

export const ErrorLogHttpInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorLogInterceptor,
    multi: true,
};
