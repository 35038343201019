const ALERT_MESSAGING_CONSTANTS = {
    info: {
        icon: 'di-app-card-info',
        ariaLive: 'polite'
    },
    warning: {
        icon: 'di-alert',
        ariaLive: 'polite'
    },
    error: {
        icon: '',
        ariaLive: 'assertive'
    },
    magicDisclaimer: {
        icon: 'di-personal-magic',
        ariaLive: 'polite'
    },
    defaultType: 'info'
};

export { ALERT_MESSAGING_CONSTANTS };
