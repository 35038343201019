export const CRUISE_TILE_CONSTANTS = {
    TILE_IMAGE_SIZE: {
        DESKTOP: {
            WIDTH: '350',
            HEIGHT: '200'
        },
        TABLET: {
            WIDTH: '350',
            HEIGHT: '200'
        },
        MOBILE: {
            WIDTH: '350',
            HEIGHT: '200'
        }
    }
};
