<dcl-modal
    #messageModal
    block-focus
    hide-title-body-separator
    disable-tab-index-on-slot-buttons
    aria-close
    [noPadding]="true"
    [attr.disable-close-on-background]="disableCloseOnBackground || null"
    [attr.hide-close-icon]="hideCloseIcon || null"
    [attr.show-separator]="showSeparator || null"
    class="message-modal {{modalData?.modalClass}}">
    <div
        slot="body"
        class="message-modal__body">
        <div class="message-modal__image-container">
            <img
                *ngIf="modalData.image"
                src="{{modalData.image | translate}}"
                class="message-modal__image {{modalData?.imageClass}}"
                aria-hidden="true"/>
        </div>
        <h1
            *ngIf="modalData.title"
            class="message-modal__title">
            {{modalData.title  | translate}}
        </h1>
        <div
            *ngIf="modalData.description"
            class="message-modal__description"
            [innerHTML]="modalData.description | translate">
    </div>
    </div>
    <div
        slot="buttons"
        class="message-modal__buttons">
        <hr
            class="message-modal__separator"
            aria-hidden="true"/>
        <wdpr-button
            *ngIf="modalData.cancel"
            class="message-modal__button"
            theme="secondary"
            [disabled]="actionLoading"
            (click)="cancelEmit()">
            {{ modalData.cancel | translate }}
        </wdpr-button>
        <wdpr-button
            *ngIf="modalData.action"
            class="message-modal__button"
            theme="primary"
            [disabled]="actionLoading"
            (click)="actionEmit()">
            {{ modalData.action | translate }}
        </wdpr-button>
    </div>
</dcl-modal>
