import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BaymaxHeaderModule } from '../baymax-header/baymax-header.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import {
    WhatsIncludedModalButtonComponent
} from './components/whats-included-modal-button/whats-included-modal-button.component';
import {
    WhatsIncludedModalComponent
} from './components/whats-included-modal/whats-included-modal.component';
import {
    WhatsIncludedModalCtaComponent
} from './components/whats-included-modal-cta/whats-included-modal-cta.component';
import {
    WhatsIncludedModalHeaderComponent
} from './components/whats-included-modal-header/whats-included-modal-header';
import { WhatsIncludedModalService } from './services/whats-included-modal.service';

@NgModule({
    imports: [
        BaymaxHeaderModule,
        CommonModule,
        LoadingSpinnerModule,
        TranslateModule
    ],
    declarations: [
        WhatsIncludedModalButtonComponent,
        WhatsIncludedModalComponent,
        WhatsIncludedModalCtaComponent,
        WhatsIncludedModalHeaderComponent
    ],
    providers: [
        WhatsIncludedModalService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        WhatsIncludedModalButtonComponent,
        WhatsIncludedModalComponent,
        WhatsIncludedModalCtaComponent
    ]
})
export class WhatsIncludedModalModule {}
