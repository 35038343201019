<div class="grid-picker-container">
    <ng-container *ngFor="let gridData of gridPickerData; index as index; last as last">
        <dcl-grid
            [ariaLabelKey]="ariaLabelKey"
            [gridConfig]="gridData"
            (onItemChanged)="onItemChanged($event)"
            (lastItemTabbed)="lastItemTabbed.emit($event)"
            [isLastGrid]="last">
        </dcl-grid>
    </ng-container>
</div>
<div *ngIf="earlyBookingData" class="early-book-info">
    <span [innerHTML]=" 'gridPicker.earlyBookingInfo' | translate "></span>
</div>
