export const ITINERARIES_CONSTANTS = {
    events: {
        closeModal: {
            name: 'CLOSE_MODAL',
            value: true
        },
        exploreItineraries: {
            name: 'EXPLORE_ITINERARIES',
            value: true
        }
    }
};
