import {
    AfterViewInit,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

import { BreakpointItem } from './interfaces/breakpoint-item.interface';
import { MediaQueryItem } from './interfaces/mediaquery-item.interface';

@Directive({
    selector: '[dclBreakPointDetection]',
})
export class BreakPointChangeDetectionDirective implements AfterViewInit, OnDestroy {
    @Input() dclBreakpoints: BreakpointItem[] = [];
    @Output() dclBreakpointChange = new EventEmitter<BreakpointItem>();

    mediaQueryList: MediaQueryItem[] = [];

    ngAfterViewInit(): void {
        this.dclBreakpoints.forEach((breakpointItem: BreakpointItem) => {
            if (!breakpointItem.breakpoint) {
                return;
            }

            const mediaQuery = window.matchMedia(breakpointItem.breakpoint);
            const callback = this.generateMediaQueryCallback(breakpointItem);
            const { matches } = mediaQuery;

            this.mediaQueryList.push({
                label: breakpointItem.label,
                mediaQuery,
                callback,
            });

            callback({ matches });

            if (typeof mediaQuery.addEventListener === 'function') {
                return mediaQuery.addEventListener('change', callback);
            }

            // Prior to Safari, MediaQueryList is based on EventTarget,
            // so you must use addListener() and removeListener() to observe media query lists.
            if (typeof mediaQuery.addListener === 'function') {
                return mediaQuery.addListener(callback);
            }
        });
    }

    ngOnDestroy(): void {
        this.mediaQueryList.forEach((mediaQueryItem: MediaQueryItem) => {
            const { mediaQuery } = mediaQueryItem;

            if (typeof mediaQuery.removeEventListener === 'function') {
                return mediaQuery.removeEventListener(
                    'change',
                    mediaQueryItem.callback
                );
            }

            // Prior to Safari 14, MediaQueryList is based on EventTarget,
            // so you must use addListener() and removeListener() to observe media query lists.
            if (typeof mediaQuery.removeListener === 'function') {
                return mediaQuery.removeListener(mediaQueryItem.callback);
            }
        });
    }

    /**
     * Generates callbacks that emit changes
     * for each media query registered
     *
     * @param {BreakpointItem} breakpoint
     * @memberof ScreenSizeChangeDetectionDirective
     */
    private generateMediaQueryCallback(breakpoint: BreakpointItem): ({ matches }: { matches: boolean }) => void {
        return ({ matches }: { matches: boolean }) => {
            breakpoint.matches = matches;
            this.dclBreakpointChange.emit(breakpoint);
        };
    }
}
