import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { values } from 'lodash';

import { AppLocaleService } from '../services/app-locale/app-locale.service';
import { Price } from './interfaces/price';
import { PRICE_SUMMARY_CONSTANTS } from './price-summary.constants';
import { SubPrice } from './interfaces/sub-price';
import { TaxesFeesExpensesService } from '../services/taxes-fees-expenses/taxes-fees-expenses.service';

@Component({
    selector: 'dcl-price-summary',
    templateUrl: './price-summary.component.html',
    styleUrls: ['./price-summary.component.scss']
})
export class PriceSummaryComponent implements OnInit {
    constructor(
        private localeService: AppLocaleService,
        private taxesFeesExpensesService: TaxesFeesExpensesService
    ) {}

    @Input() currencyLabel: string;
    @Input() isDclInternationalDirect: boolean;
    @Input() isDclInternationalDirect2: boolean;
    @Input() price: Price;
    @Input() hideTotalPriceText: boolean;
    @Output() goToTaxes = new EventEmitter();

    guests: SubPrice[];
    locale: string;
    priceDetailsLegend: string;
    totalStateroomPriceText: string;
    showGst = false;

    ngOnInit() {
        if (this.isDclInternationalDirect) {
            this.locale = this.localeService.content();

            // determine the GST status
            // note that the initialization setting the sailingId happens in the SPA controller
            this.showGst = this.taxesFeesExpensesService.shouldShowGST();
        }

        this.generateGuests();
        this.setTotalPriceText();
    }

    /**
     * Generate the guest list in order to show the price by guest
     */
    generateGuests(): void {
        const count = { adult: 1, children: 1 };

        if (this.price && this.price.breakdownByGuest) {
            if (!Array.isArray(this.price.breakdownByGuest)) {
                this.guests = values(this.price.breakdownByGuest);
            } else {
                this.guests = this.price.breakdownByGuest;
            }

            this.guests.forEach(item => {
                if (item.ageGroup === PRICE_SUMMARY_CONSTANTS.GUEST.ADULT) {
                    item.count = count.adult;
                    count.adult++;
                } else {
                    item.count = count.children;
                    count.children++;
                }
            });
        }
    }

    /**
     * Sets total price text based on hideTotalPriceText flag value
     */
    private setTotalPriceText(): void {
        this.totalStateroomPriceText = PRICE_SUMMARY_CONSTANTS.TOTAL_PRICE_TEXT;
        this.priceDetailsLegend = PRICE_SUMMARY_CONSTANTS.TOTAL_PRICE_LEGEND;

        if (this.isDclInternationalDirect && (this.hideTotalPriceText || this.showGst)) {
            this.totalStateroomPriceText = PRICE_SUMMARY_CONSTANTS.PRICE_TEXT;
            this.priceDetailsLegend = PRICE_SUMMARY_CONSTANTS.PRICE_LEGEND;
        }
    }
}
