/* tslint:disable:no-magic-numbers */
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { ErrorsToAvoid } from '../interfaces/errors-to-avoid';
import {
    AT_HOME,
    HTTP_STATUS_CODES,
    MY_RES_URL,
    SESSION_ERRORS
} from '../at-home/at-home.constants';
import { LoginModalService } from '../../login-modal/services/login-modal.service';
import { ConfigService } from '../config/config.service';
import { ErrorHandlingEmbeddedService } from '../error-handling-embedded/error-handling-embedded.service';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';
import { NavigationService } from '../navigation/navigation.service';
import { SharedInformationalModalService } from '../shared-informational-modal/shared-informational-modal.service';
import { SHARED_INFORMATIONAL_CONSTANTS } from '../shared-informational-modal/shared-informational-modal.constants';

import { DCLAnalyticsService } from '../analytics/analytics.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingService {
    private isEmbedded: boolean;
    private defaultError;

    constructor(
        private analyticsService: DCLAnalyticsService,
        private errorHandlingEmbeddedService: ErrorHandlingEmbeddedService,
        private location: Location,
        private loginModalService: LoginModalService,
        private nativeBridgeService: NativeBridgeService,
        private navigationService: NavigationService,
        private router: Router,
        private config: ConfigService,
        private sharedInformationalModalService: SharedInformationalModalService,
        private navigation: NavigationService,
    ) {
        this.isEmbedded = this.nativeBridgeService.isEmbedded();
        this.defaultError =  {
            status: 500
        };
    }

    /**
     * common error handler for layout pages
     * @param {Object} error error object of HttpErrorResponse
     * @param {boolean} checkLogin
     * @param {Object} ErrorsToAvoid
     * @returns {Observable}
     */
    errorHandler(error: HttpErrorResponse = this.defaultError, checkLogin?: boolean, errorsToAvoid?: ErrorsToAvoid) {
        const errorCode = errorsToAvoid ? this.manageErrors(error, errorsToAvoid) : '';
        const code = Object.keys(HTTP_STATUS_CODES).map(key => HTTP_STATUS_CODES[key]).indexOf(error.status) !== -1 ?
            error.status :
            HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR;

        if (errorCode) {
            return of({...error, errorAvoided: true});
        } else {
            if (this.isEmbedded) {
                this.setAnalyticsErrorCodes(error);
                this.handleEmbeddedErrors(error.status);
            } else {
                if (code === HTTP_STATUS_CODES.UNAUTHORIZED && checkLogin) {
                    return of({...error, redirectToLoginPage: true});
                } else {
                    this.setAnalyticsErrorCodes(error);
                    this.redirectToError(code);
                }
            }

            return of(error);
        }
    }

    /**
     * Handler for session error
     * @param {Object} error error object of httpErrorResponse
     * @param {Object} data
     */
    sessionErrorHandler(error: HttpErrorResponse = this.defaultError , data: {
        conflictRedirect?: boolean,
        reservationId?: string,
        checkLogin?: boolean,
        errorsToAvoid?: ErrorsToAvoid,
        conflictModalAction?: Function
    }) {
        const sessionError = this.getSessionError(error.error);

        if (sessionError) {
            if (error.status === HTTP_STATUS_CODES.SESSION_ERRORS.KILLED) {
                this.setAnalyticsErrorCodes(error);
                if (this.isEmbedded) {
                    this.handleEmbeddedErrors(AT_HOME.errorStates.sessionExpired);
                } else {
                    this.navigationService.navigateByUrl(
                        {
                            url: MY_RES_URL.getUrl(data && data.reservationId),
                            redirect: true
                        }
                    );
                }
            } else if (error.status === HTTP_STATUS_CODES.SESSION_ERRORS.CONFLICT) {
                this.setAnalyticsErrorCodes(error);
                this.conflictErrorHandler(error, data);
            } else {
                return this.errorHandler(error, data.checkLogin, data.errorsToAvoid);
            }
        } else {
            return this.errorHandler(error, data.checkLogin, data.errorsToAvoid);
        }
    }

    /**
     * call handleEmbeddedErrors from service to handle native errors
     * @param statusCode
     * @param currentFlow
     */
    handleEmbeddedErrors(status: string | number, currentFlow?: string): void {
        const statusCode = this.getStatusCode(status);
        this.errorHandlingEmbeddedService.handleEmbeddedErrors(statusCode, currentFlow);
    }

    /**
     * Set analytics error codes
     * @param {HttpErrorResponse} httpErrorResponse
     */
    setAnalyticsErrorCodes(httpErrorResponse: HttpErrorResponse): void {
        const errorCodes = (httpErrorResponse.status || httpErrorResponse) + this.getErrorCodes(httpErrorResponse);
        this.updateAnalyticsModel(errorCodes);
    }

    /**
     * Get error codes from error object
     * @param {HttpErrorResponse} httpErrorResponse
     * @returns {String} errorCodes
     */
    getErrorCodes(httpErrorResponse: HttpErrorResponse): string {
        return httpErrorResponse.error && httpErrorResponse.error.errorCode ?
            `,${httpErrorResponse.error.errorCode}` :
            '';
    }

    /**
     * Manage errors
     * @param {HttpErrorResponse} err
     * @param {Object} errorsToAvoid
     * @returns {String} errorCode
     */
    manageErrors(err: HttpErrorResponse, errorsToAvoid: ErrorsToAvoid = {} ): string {
        const { status, error } = err;
        let skip = false;

        if (error && error.errorCode) {
            Object.keys(errorsToAvoid).forEach((statusKey: string) => {
                if (status === +statusKey) {
                    skip = errorsToAvoid[statusKey].some((code: string) => code === error.errorCode);
                }
            });
        }

        return skip ? error.errorCode : '';
    }

    /**
     * Navigate to a given error code
     * @param code error code to go
     * @param url Url to replace state after navigation is completed
     */
    redirectToError(code: number, url?: string): Promise<void> {
        const errorUrlTemplate = this.config.getValue('errorUrl') || '';
        const errorUrl = errorUrlTemplate.replace('${code}', code.toString());

        return this.router.navigate([ errorUrl ], {
            skipLocationChange: true,
            queryParams: { statusCode: code.toString() }
        }).then(() => {
            if (url) {
                this.location.replaceState(url);
            }
        });
    }

    private conflictErrorHandler(error: HttpErrorResponse, data?: {
        conflictRedirect?: boolean,
        reservationId?: string,
        conflictModalAction?: Function
    }): void {
        if (data.conflictRedirect) {
            if (this.isEmbedded) {
                this.handleEmbeddedErrors(AT_HOME.errorStates.sessionConflict);
            } else {
                this.redirectToError(error.status);
            }
        } else {
            this.sharedInformationalModalService.handleLockedSession({
                    body: error.error
                },
                () => {
                    if (data.conflictModalAction) {
                        data.conflictModalAction();
                    } else {
                        this.redirectToModify(Number(data.reservationId));
                    }
                });
        }
    }

    private redirectToModify(resId: Number): void {
        const flow = SHARED_INFORMATIONAL_CONSTANTS['modify-hub'];

        flow['url'] = flow.getUrl(resId);
        this.navigation.navigateByUrl(flow);
    }

    /**
     * Set the error code to the analytics model
     */
    private updateAnalyticsModel(errorCodes: string) {
        this.analyticsService.updateAnalyticsModel({
            errorCodes: errorCodes
        });
    }

    /**
     * Show Login Modal when session has expired
     */
    showLoginModal() {
        return this.loginModalService.open()
            .pipe(
                catchError((): Observable<{}> => {
                    this.navigationService.navigateByUrl({
                        url: '/', redirect: true
                    });

                    return throwError({ hasRedirected: true });
                })
            );
    }

    /**
     * Checks if the error comming from service is a session error to return it.
     * @param {object} error
     * @returns {object} session error if exists
     */
    private getSessionError(error) {
        let sessionError;
        const sessionManagerErrors = SESSION_ERRORS;

        if (error && error.errorCode && sessionManagerErrors.indexOf(error.errorCode) > -1) {
            sessionError = error;
        }

        return sessionError;
    }

    private getStatusCode(statusCode) {
        const { errorStates: errorStatesObject } = AT_HOME;
        const { INTERNAL_SERVER_ERROR } = HTTP_STATUS_CODES;
        const errorStates = [403, 404, 409, 410, 500, 503];

        Object.keys(errorStatesObject).forEach(key => errorStates.push(errorStatesObject[key]));

        return errorStates.indexOf(statusCode) > -1 ? statusCode : INTERNAL_SERVER_ERROR;
    }
}
