import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgModule } from '@angular/core';

import { AppLocaleService } from './app-locale.service';
import { ToggleService } from '../toggle/toggle.service';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        CookieService,
        AppLocaleService,
        ToggleService
    ]
})
export class AppLocaleModule {}
