import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, ControlContainer, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { CONTACT_CONSTANTS } from './contact-info.constants';
import { FormDataService } from '../form-data/form-data.service';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';

@Component({
    selector: '[formGroup] dcl-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit, AfterViewInit {
    @Input() isPrimaryGuest: boolean;
    @Input() sameAsPrimaryChecked: boolean;
    @Input() data: {};
    @Input() guestData: {};
    @Input() index: number = 0;
    @Input() isEdit: boolean;
    @Input() showRequiredLabel: boolean;
    @Input() bottomSpace?: boolean = false;

    @ViewChild('country', {static: false} as ViewChildProperties) countryEl: ElementRef;
    @ViewChild('province', {static: false} as ViewChildProperties) provinceEl: ElementRef;
    @ViewChild('state', {static: false} as ViewChildProperties) stateEl: ElementRef;
    @ViewChild('sameAsPrimaryCheckbox', {static: false} as ViewChildProperties) sameAsPrimaryCheckbox: ElementRef;
    // Empty arrays are the lists needed for the dropDowns, those are filled on a function bellow called generateLists()
    constants = CONTACT_CONSTANTS;
    contactForm: UntypedFormGroup;
    countries: {}[] = [];
    guestNumber: number;
    provincesObject: {} = {};
    selectedCountry: string;
    showForm: boolean = true;
    translationsKeys: {} = {};
    contactInfoData: {} = {
        phone: '',
        email: '',
        address: '',
        addressTwo: '',
        addressThree: '',
        city: '',
        country: '',
        postal: '',
        state: '',
        territory: '',
        province: '',
        county: '',
        zip: ''
    };
    isSameAsPrimaryGuestGroup: UntypedFormGroup;
    getErrors;

    constructor(
        private controlContainer: ControlContainer,
        private fb: UntypedFormBuilder,
        private renderer: Renderer2,
        private translate: TranslateService,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.getErrors = this.formDataService.getErrorMessage;
        this.setData();
        this.getTranslations();
        // This add the formGroup reference to this component.
        this.contactForm = <UntypedFormGroup>this.controlContainer.control;
        this.showFormValidation();
        this.contactForm.addControl('isSameAsPrimaryGuest', this.fb.control(this.sameAsPrimaryChecked));
        this.guestNumber = this.index + 1;
    }

    ngAfterViewInit() {
        if (this.sameAsPrimaryCheckbox) {
            this.renderer.listen(this.sameAsPrimaryCheckbox.nativeElement, 'checked-changed', (event) => {
                this.isPrimarySelection(event.detail.value);
            });
        }

        this.cd.detectChanges();
    }

    activateListeners(): void {
        // Just like the form doesn't exist yet we need to add a
        // little delay so the listener will be added when the element exists.
        setTimeout(() => {
            this.renderer
                .listen(this.countryEl.nativeElement, 'item-selected', (event) => this.countryChange(event));
        });
    }

    countryChange(event?) {
        let countryValidators;
        const value = event ? event.detail.key : this.contactInfoData['country'];
        this.contactForm.controls['country'].setValue(value);
        this.selectedCountry = value;

        switch (this.selectedCountry) {
            case this.constants['countries'].US.iso:
                countryValidators = this.getCountryValidators('phone', 'state', 'zip');
                this.generateRenderListener('state', 'stateEl');
                this.removeUnusedData('province', 'county', 'territory', 'postal');
                break;
            case this.constants['countries'].CA.iso:
                countryValidators = this.getCountryValidators('addressThree', 'phone', 'province', 'postal');
                this.generateRenderListener('province', 'provinceEl');
                this.removeUnusedData('state', 'county', 'territory', 'zip');
                break;
            case this.constants['countries'].GB.iso:
                countryValidators = this.getCountryValidators('addressThree', 'county', 'phone', 'postal');
                this.removeUnusedData('state', 'province', 'territory', 'zip');
                break;
            default:
                const territoryValidator = !this.provincesObject[this.selectedCountry] ? 'territory' : null;
                countryValidators = this.getCountryValidators('addressThree', 'phone', 'postal', territoryValidator);
                if (!territoryValidator) {
                    this.generateRenderListener('province', 'provinceEl');
                }
                this.removeUnusedData(
                    'state', territoryValidator === 'territory' ? 'province' : 'territory', 'county', 'zip'
                );
                break;
        }

        if (event) {
            this.cleanFixedFields();
        }

        this.setFieldsValidators(countryValidators.validatorsToRemove, countryValidators.validatorsToAdd, event);
    }

    /**
     * Remove value from some controls when they have been touched,
     * this is called when country changed
     */
    cleanFixedFields(): void {
        const controlsToClean = [
            'address',
            'addressTwo',
            'addressThree',
            'city'
        ];

        for (const key of controlsToClean) {
            const control = this.contactForm.controls[key];
            if (control.touched || control.value) {
                control.setValue(null);
                control.updateValueAndValidity();
            }
        }
    }

    /**
     * Create a list of validators to add and remove
     * @param args list of arguments to add separated by comma
     */
    getCountryValidators(...args: string[]): {} {
        const validatorsToAdd = [];
        const postalValidator = this.constants.validators.postal[this.selectedCountry] ||
            this.constants.validators.postal['default'];
        const phoneValidator = this.constants.validators.phone[this.selectedCountry] ||
            this.constants.validators.phone['default'];
        const countryValidators = {
            addressThree: {
                name: 'addressThree',
                validators: this.constants.validators.addressLineThree
            },
            county: {
                name: 'county',
                validators: this.constants.validators.county
            },
            phone: {
                name: 'phone',
                validators: phoneValidator
            },
            postal: {
                name: 'postal',
                validators: postalValidator
            },
            province: {
                name: 'province',
                validators: this.constants.validators.province
            },
            state: {
                name: 'state',
                validators: this.constants.validators.state
            },
            territory: {
                name: 'territory',
                validators: this.constants.validators.territory
            },
            zip: {
                name: 'zip',
                validators: this.constants.validators.zip
            }
        };

        for (const argument of args) {
            if (countryValidators.hasOwnProperty(argument)) {
                validatorsToAdd.push(countryValidators[argument]);
            }
        }

        return {
            validatorsToAdd: validatorsToAdd,
            validatorsToRemove: Object.keys(countryValidators)
        };
    }

    generateLists(): void {
        for (const country of this.data['countries']) {
            this.countries.push({
                value: country.name,
                key: country.isoCountryCode2,
                tagClasses: [ this.constants.piiClasses.GUEST_SENSITIVE_CLASS ]
            });
        }

        for (const province of this.data['states']) {
            const iso = province.country.isoCountryCode2;

            if (!(this.provincesObject.hasOwnProperty(iso))) {
                this.provincesObject[iso] = [];
            }
            this.provincesObject[iso].push({
                value: province.name,
                key: province.stateOrProvince,
                tagClasses: [ this.constants.piiClasses.GUEST_SENSITIVE_CLASS ]
            });
        }
    }

    generateForm() {
        const fieldsValidators = this.constants.validators;
        this.contactForm.addControl('country', this.fb.control('', fieldsValidators.country));
        this.contactForm.addControl('address', this.fb.control('', fieldsValidators.addressLineOne));
        this.contactForm.addControl('addressTwo', this.fb.control('', fieldsValidators.addressLineTwo));
        this.contactForm.addControl('addressThree', this.fb.control('', fieldsValidators.addressLineThree));
        this.contactForm.addControl('city', this.fb.control('', fieldsValidators.city));
        this.contactForm.addControl('state', this.fb.control(''));
        this.contactForm.addControl('province', this.fb.control(''));
        this.contactForm.addControl('county', this.fb.control('', fieldsValidators.county));
        this.contactForm.addControl('territory', this.fb.control('', fieldsValidators.territory));
        this.contactForm.addControl('zip', this.fb.control(''));
        this.contactForm.addControl('postal', this.fb.control(''));
        this.contactForm.addControl('phone', this.fb.control('', fieldsValidators.phone.default));

        if (this.isPrimaryGuest) {
            this.contactForm.addControl('email', this.fb.control('', fieldsValidators.email));
        }

        if (this.isEdit && this.guestData) {
            Object.keys(this.contactInfoData).forEach(key => {
                if (this.contactForm.get(key)) {
                    this.contactForm.get(key).setValue(this.contactInfoData[key] || '');
                }
            });
        }
        /*
            Delay to update value changes when there is an existing guest.
            Because the value change generates an angular error, when the component loads
            https://blog.angular-university.io/angular-debugging
        */
        setTimeout(() => {
            this.countryChange();
        });
    }

    generateRenderListener(control: string, el: string): void {
        setTimeout(() => {
            const element = this[el];
            if (element) {
                this.renderer.listen(element.nativeElement, 'item-selected', (event) => {
                    this.contactForm.controls[control].setValue(event.detail.key);
                });
            }
        });
    }

    getTranslations(): void {
        this.translate.get('contact-information').subscribe((res) => {
            this.translationsKeys = res;
        });
    }

    isPrimarySelection(value): void {
        if (value) {
            this.showForm = false;
            this.resetForm(this.contactForm);
        } else {
            this.showForm = true;
            this.activateListeners();
            this.generateLists();
            this.generateForm();
        }

        this.contactForm.controls['isSameAsPrimaryGuest'].setValue(value);
    }

    setFieldsValidators(controlsToRemove: string[], controlToAdd?: {}[], event?): void {
        for (const control of controlsToRemove) {
            this.contactForm.controls[control].clearValidators();
            this.contactForm.controls[control].setErrors(null);
            if (event) {
                this.contactForm.controls[control].setValue(null);
            }
        }

        if (controlToAdd) {
            for (const control of controlToAdd) {
                const currentControl = this.contactForm.controls[control['name']];

                currentControl.setValidators(control['validators']);
                currentControl.setValue(!event ? this.contactInfoData[control['name']] : null);

                if (currentControl.value) {
                    currentControl.markAsTouched();
                } else {
                    currentControl.markAsUntouched();
                }

                currentControl.updateValueAndValidity();
            }
        }
    }

    showFormValidation(): void {
        if (this.sameAsPrimaryChecked) {
            this.showForm = false;
        } else {
            this.activateListeners();
            this.generateLists();
            this.generateForm();
        }
    }

    /**
     * Sets the data in the form if the guest already exist
     */
    setData(): void {
        if (this.isEdit && this.guestData) {
            this.contactInfoData['phone'] = this.guestData['phone'] && this.guestData['phone']['phoneNumber'];
            this.contactInfoData['email'] = this.guestData['email'];

            if (this.guestData['address']) {
                const stateFullName = this.getStateFullName(
                    this.guestData['address']['stateOrProvince'],
                    this.guestData['address']['countryCode'],
                    this.data
                );
                this.contactInfoData['address'] = this.guestData['address']['addressLine1'];
                this.contactInfoData['addressTwo'] = this.guestData['address']['addressLine2'];
                this.contactInfoData['addressThree'] = this.guestData['address']['addressLine3'];
                this.contactInfoData['city'] = this.guestData['address']['city'];
                this.contactInfoData['country'] = this.getCountryKey(
                    this.guestData['address']['countryCode'], this.data);
                this.contactInfoData['postal'] = this.guestData['address']['postalCode'];
                this.contactInfoData['state'] = this.guestData['address']['stateOrProvince'];
                this.contactInfoData['territory'] = stateFullName;
                this.contactInfoData['province'] = this.guestData['address']['stateOrProvince'];
                this.contactInfoData['county'] = stateFullName;
                this.contactInfoData['zip'] = this.guestData['address']['postalCode'];
            }
        }
    }

    /**
     * Returns the state/province full name after we provide the stateCode
     * @param {string} stateCode
     * @param {string} countryCode
     * @param {Object} countriesInformation
     * @returns {string}
     */
    getStateFullName(stateCode: string, countryCode: string, countriesInformation: {}): string {
        let stateName;

        for (const state of countriesInformation['states']) {
            if ((state.country.isoCountryCode2 === countryCode || state.country.isoCountryCode3 === countryCode) &&
                state.stateOrProvince === stateCode) {
                stateName = state.name;
                break;
            }
        }

        return stateName;
    }

    /**
     * Gets key for country value
     * @param {string} countryCode
     * @param {Object} countriesInformation
     * @returns {string}
     */
    getCountryKey(countryCode: string, countriesInformation: {}): string {
        const countryObj = countryCode && countriesInformation['countries'].find(country =>
            (country.isoCountryCode2 === countryCode || country.isoCountryCode3 === countryCode));

        return countryObj && countryObj.isoCountryCode2;
    }

    /**
     * Resets the form
     * @param {FormGroup} form
     */
    resetForm(form: UntypedFormGroup): void {
        form.reset();
        Object.keys(form.controls).forEach(key => {
            form.get(key).setErrors(null);
        });
    }

    /**
     * Removes data from contact object
     * @param args list of arguments to add separated by comma
     */
    removeUnusedData(...args: string[]): void {
        for (const argument of args) {
            if (this.contactInfoData.hasOwnProperty(argument)) {
                delete this.contactInfoData[argument];
                this.contactForm.controls[argument].setValue(null);
            }
        }
    }

    /**
     * Check when an input is valid/invalid
     * @param {string} fieldName field to be validated
     * @returns {boolean} field state (valid/invalid)
     */
    isInputInvalid(fieldName: string): boolean {
        return this.contactForm.get(fieldName).invalid && this.contactForm.get(fieldName).touched;
    }
}
