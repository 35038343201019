import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatesFormatPipe } from './dates-format.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DatesFormatPipe
    ],
    exports: [
        DatesFormatPipe
    ]
})
export class DatesFormatModule {}
