import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { CACHED_PATH } from './header-syndicated.constants';
import { FeatureState } from '../interfaces/feature-state.model';
import getParams from '../utils/getParams';
import { SkipNavigation } from '../interfaces/skip-navigation.model';
import { SyndicatedOptions } from '../interfaces/syndicated-options.model';
import { HEADER_SYNDICATED_ANIMATIONS } from './header-syndicated.animations';

@Component({
    selector: 'dcl-header-syndicated',
    templateUrl: './header-syndicated.component.html',
    styleUrls: ['./header-syndicated.component.scss'],
    animations: [...HEADER_SYNDICATED_ANIMATIONS]
})
export class HeaderSyndicatedComponent implements OnInit {
    @Input() url: string;
    @Input() version: number;
    @Input() locale: string;
    @Input() language: string;
    @Input() siteId: string;
    @Input() showLanguageSelector: boolean;
    @Input() environment: string;
    @Input() brandHeaderDefaultOptions;
    @Input() useCache = false;
    @Input() featureState: FeatureState;
    @Input() skipNavigation: SkipNavigation;
    @Output() onHeaderStateDone = new EventEmitter<boolean>();
    @Output() onExistsGlobalMessages = new EventEmitter<boolean>();

    syndicatedHeaderUrl: string;
    defaultOptions: SyndicatedOptions;
    stateName: string;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        this.defaultOptions = {
            ...this.brandHeaderDefaultOptions[this.siteId],
            languageSelector: this.showLanguageSelector ? this.featureState.on : this.featureState.off
        };
        this.defaultOptions.version = this.version || this.defaultOptions.version;
        this.defaultOptions.locale = this.locale || this.defaultOptions.locale;
        this.defaultOptions.language = this.language || this.defaultOptions.language;
        this.defaultOptions.siteOverride = this.siteId;
        this.syndicatedHeaderUrl =
            `${this.url}${this.useCache ? CACHED_PATH : '/'}${getParams(this.defaultOptions)}`;
        this.stateName = 'loaded';
        this.addHrefToSkipNavigationLink();
    }

    /**
     * @description We want to make sure that the animation is complete and the style has been set by the
     * animation module.
     * Once that is done we can manually remove the style attr.
     * @param event Native event.
     */
    headerStateDone(event: AnimationEvent) {
        setTimeout(() => {
            event['element'].removeAttribute('style');
            this.onHeaderStateDone.emit(true);
        });
    }

    /**
     * @description check if exist and Add url path on skip navigation button to prevent
     * the page redirect to #pageContentStart
     */
    addHrefToSkipNavigationLink(): void {
        const target = document.querySelector(this.skipNavigation.headerSelector);
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                const link = document.querySelector(this.skipNavigation.selector);
                const globalMessages = document.querySelectorAll('.global-messaging-old');

                // Validates if there's global messages and sets the layoutConfig variable in true
                if (globalMessages.length) {
                    this.onExistsGlobalMessages.emit(true);
                }

                if (link) {
                    link.setAttribute(
                        'href',
                        this.router.url.replace(this.skipNavigation.href, '') + this.skipNavigation.href
                    );
                }

                if (link && globalMessages.length) {
                    observer.disconnect();
                }
            });
        });
        const config = { childList: true, subtree: true };

        observer.observe(target, config);
    }
}
