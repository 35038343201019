import { Component, HostListener, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';

import { DeviceDataService } from '@wdpr/ra-angular-device-detection';

import { GenesysChatConstants as constants } from '../../genesys-chat.constants';
import { GenesysChatService, GenesysUserDataService } from '../../services/';
import { GenesysDataEvent } from '../../interfaces';

@Component({
    selector: 'dcl-genesys-web-chat',
    templateUrl: './genesys-web-chat.component.html',
    styleUrls: ['./genesys-web-chat.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GenesysWebChatComponent implements OnInit {
    @Input() buttonLabel: string;

    isMobile: boolean;
    pluginLoaded: boolean;
    showChatButton: boolean;
    webchatButtonLabel: string;

    constructor(
        private deviceDataService: DeviceDataService,
        private genesysChatService: GenesysChatService,
        private genesysUserDataService: GenesysUserDataService,
        private zone: NgZone
    ) {
        this.deviceDataService.getCookie(this.deviceDataService.getConfig());
    }

    ngOnInit(): void {
        this.isMobile = this.deviceDataService.isMobile();
        this.genesysChatService.loadWebchatPlugin().then(
            () => {
                this.webchatButtonLabel = this.buttonLabel || constants.CHAT_LABEL;
                this.pluginLoaded = true;
                this.showChatButton = true;
                this.subscribeToEvents();
            },
            (error: Event | string) => {
                console.warn('error loading genesys webchat plugin', error);
            }
        );

        // Gets guest geolocation info to be sent to Genesys
        // along with additional guest data when chatting start
        this.genesysUserDataService.getUserGeocode();
    }

    /**
     * Method to handle webchat button click event
     */
    openWebchat(): void {
        this.genesysChatService.open({
            formJSON: this.genesysUserDataService.getCustomForm(),
            userData: this.genesysUserDataService.getDefaultUserData()
        }).then(() => {
            this.showChatButton = false;
        });
    }

    /**
     * Event listener for `GenesysDataEvent`.
     * @param event The `GenesysDataEvent`
     */
     @HostListener(constants.GENESYS_DATA_EVENT, ['$event'])
     public onGenesysData(event: GenesysDataEvent): void {
         this.genesysUserDataService.additionalUserData = {
             ...this.genesysUserDataService.additionalUserData,
             ...event && event.detail,
         };
     }

    /**
     * Subscribe to webchat plugin events
     */
    private subscribeToEvents(): void {
        this.genesysChatService.subscribe('closed', () => this.zone.run(() => this.showChatButton = true));
        this.genesysChatService.subscribe('opened', () => this.zone.run(() => this.showChatButton = false));
    }
}
