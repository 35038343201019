<section class="dcl-login">
    <!-- Errors section -->
    <div class="warning-errors">
        <dcl-warning-messaging
            message="{{ errorMessage | translate}}"
            [messageType]="'error'"
            [showMessage]="isUserPasswordError">
        </dcl-warning-messaging>

        <dcl-warning-messaging
            message="{{'login.errors.header' | translate}}"
            [messageType]="'error'"
            [showMessage]="isFormErrors && (loginForm.get('email').invalid || loginForm.get('password').invalid) && !isUserPasswordError">
        </dcl-warning-messaging>
    </div>
    <!-- Title section -->
    <div class="header" tabindex="0">
        <h1 class="title" translate>login.title</h1>
        <div class="account-logo"></div>
    </div>

    <form [formGroup]="loginForm" #form="ngForm" (ngSubmit)="submit(loginForm)">
        <!-- Inputs section -->
        <div class="form" (keyup.enter)="callSubmit()">
            <div class="item">
                <!-- dclAutofocus is an autofocus directive needed among with isPolymerInput param passed.  -->
                <wdpr-input
                    [dclAutofocus]
                    [isPolymerInput]="true"
                    label="{{ 'login.email' | translate }}"
                    ngDefaultControl
                    autocorrect="off"
                    autocomplete="on"
                    type="email"
                    name="email"
                    formControlName="email"
                    no-validate-on-change="true"
                    no-validate-on-blur="true"
                    required="true"
                    [valid]="!isInvalid('email')"
                    [ngClass]="{'invalid touched': isInvalid('email')}">
                </wdpr-input>
                <dcl-error-message
                    class="errors light-theme"
                    *ngIf="isInvalid('email')"
                    [control]="loginForm.get('email')"
                    [errorMessage]="{required: 'login.errors.email'}">
                </dcl-error-message>
            </div>

            <div class="item">
                <wdpr-input
                    label="{{ 'login.password' | translate }}"
                    ngDefaultControl
                    autocorrect="off"
                    autocomplete="current-password"
                    type="password"
                    name="password"
                    formControlName="password"
                    no-validate-on-change="true"
                    no-validate-on-blur="true"
                    required="true"
                    [valid]="!isInvalid('password')"
                    [ngClass]="{'invalid touched': isInvalid('password')}">
                </wdpr-input>
                <dcl-error-message
                    class="errors light-theme"
                    [control]="loginForm.get('password')"
                    *ngIf="isInvalid('password')"
                    [errorMessage]="{required: 'login.errors.password'}">
                </dcl-error-message>
            </div>

            <button translate class="forgot-pass btn-link" (click)="navigate('/forgotpassword')">
                login.forgot
            </button>
        </div>

        <!-- Buttons section -->
        <div class="actions">
            <div class="pass-actions">
                <wdpr-checkbox
                    class="hyperion"
                    [checked]="isRememberMe"
                    #rememberMeCheckbox>
                    {{ 'login.remember' | translate }}
                </wdpr-checkbox>
                <button translate class="forgot-pass-mobile btn-link" (click)="navigate('/forgotpassword')">
                    login.forgot
                </button>
            </div>

            <div class="buttons">
                <dcl-loading-content
                    class="light-label"
                    spinnerLabel="login.loading"
                    spinnerLabelAlign="right"
                    [spinnerSize]="'small'"
                    [showSpinner]="showSpinner">
                    <wdpr-button
                        class="button-item"
                        theme="primary"
                        translate="login.sign"
                        (click)="callSubmit()"
                        type="submit">
                    </wdpr-button>
                </dcl-loading-content>
                <wdpr-button
                    class="cancel-button button-item"
                    theme="secondary"
                    translate="login.cancel"
                    (click)="cancel()">
                </wdpr-button>
            </div>
        </div>
    </form>

    <!-- Footer Section  -->
    <hr class="separator">
    <div>
        <h1 class="footer-title" translate>login.have-account</h1>
        <button class="create-account" (click)="navigate('/registration')">
            <span class="di di-add-button"></span>
            <span class="footer-text" translate>login.create-account</span>
        </button>
    </div>
</section>
