<div
    class="popover"
    [ngClass]="{ 'isOpen': showPopover, 'leftOutside': leftOutside, 'rightOutside': rightOutside }">

    <ng-container [ngSwitch]="trigger">
        <div
            *ngSwitchCase="'hover'"
            class="popover-trigger zoom"
            [ngClass]="direction"
            (keydown.enter)="toggleVisibility()"
            (keydown.escape)="onClose()"
            (keydown.tab)="onClose()"
            (keydown.shift.tab)="onClose()">
            <ng-container *ngTemplateOutlet="transclude"></ng-container>
        </div>
        <div
            *ngSwitchDefault
            class="popover-trigger zoom"
            [ngClass]="direction"
            tabindex="0"
            (keydown.enter)="toggleVisibility()"
            (keydown.escape)="onClose()"
            (keydown.tab)="onClose()"
            (keydown.shift.tab)="onClose()"
            (click)="toggleVisibility()">
            <ng-container *ngTemplateOutlet="transclude"></ng-container>
        </div>
    </ng-container>

    <div
        #popoverElement
        *ngIf="showPopover"
        [ngClass]="direction"
        class="popover-container zoom">
        <div class="popover-body">
            <h6 aria-hidden="true" class="title" *ngIf="title">{{ title }}</h6>
            <p class="text">{{ text }}</p>
        </div>
        <button class="dcl-link close-button" *ngIf="isCloseButton" (click)="onIconClick()">
            <i class="close-icon di di-close-reversed">
                <span class="sr-only">{{ 'close-button.close' | translate }}</span>
            </i>
        </button>
    </div>
</div>

<ng-template #transclude>
    <ng-content></ng-content>
</ng-template>
