export const ERROR_CONSTANTS = {
  CHARACTERS: {
    0: {name: 'anchor', image: 'shipanchor.png'},
    401: {name: 'donald', image: 'donald.jpg'},
    403: {name: 'ralph', image: 'ralph.jpg'},
    404: {name: 'stitch', image: 'stitch.jpg'},
    409: {name: 'fungus', image: 'fungus.png'},
    500: {name: 'dwarfs', image: 'dwarfs.png'}
  },
  IMAGE_PATH: `url({contextPath}/assets/images/error/{characterImage})`
};
