<div class="alert-message-container" *ngIf="showMessage" [ngClass]="messageType">
    <div class="alert-message-icon-container">
        <i class="di alert-message-icon" [ngClass]="icon" aria-hidden="true"></i>
    </div>
    <div class="alert-message-data" [attr.aria-live]="ariaLive" aria-atomic="false">
        <span *ngIf="titleMessage" class="alert-message-title">
            {{ titleMessage | translate }}
        </span>
        <p class="alert-message-body" [innerHtml]="message | translate"></p>
    </div>
</div>
