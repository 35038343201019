<div>
    <ul class="attribute-list" *ngIf="!hideAttributes">
        <li class="attribute" *ngFor="let attribute of attributes">
            <img class="attribute-img"
                [src]="attribute['attributeIcon']"
                [alt]="attribute['attributeIconAlt']"/>
            <span class="attribute-text">
                {{ attribute['attributeName'] }}
            </span>
        </li>
    </ul>
    <p *ngIf="hideAttributes">{{ description }}</p>
</div>
