import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DclLoginComponent } from './../login/login.component';

@Injectable()
export class LoginModalService {
    constructor(
        private dialog: MatDialog,
        private translate: TranslateService
    ) { }

    /**
     * Opens login modal
     * @returns {ReplaySubject}
     */
    open(): ReplaySubject<{}> {
        const modalResponse: ReplaySubject<{}> = new ReplaySubject<{}>();
        // Shows login Modal
        this.showModal(modalResponse);

        return modalResponse;
    }

    /**
     * Shows login modal
     * @param {ReplaySubject} modalResponse to manage successfull and not successuful login response
     */
    private showModal(modalResponse: ReplaySubject<{}>): void {
        const data = this.getLoginModalData();
        let modal;
        let wrapper;

        modal = this.dialog.open(data['component'], {
            panelClass: data['config'].panel,
            backdropClass: data['config'].backdrop,
            disableClose: !!data['config'].disableClose,
            autoFocus: false,
            ariaLabel: this.translate.instant('login.title')
        });

        modal.afterOpen().subscribe(() => {
            wrapper = document.querySelector('.cdk-overlay-container');
            if (wrapper && data['data'].animateClass) {
                wrapper.classList.add(data['data'].animateClass);
            }
        });

        modal.afterClosed().subscribe((isLoggedInModal) => {
            if (isLoggedInModal) {
                modalResponse.next({
                    isLoggedIn: isLoggedInModal
                });
            } else {
                modalResponse.error({
                    error: 'not logged in'
                });
            }
        });
    }

    /**
     * Returns configuration object for login modal
     * @returns {Object}
     */
    private getLoginModalData(): {} {
        return {
            component: DclLoginComponent,
            config: {
                panel: 'base-dialog',
                backdrop: 'dcl-dialog-backdrop',
                disableClose: true
            },
            data: {
                animateClass: 'no-animate'
            }
        };
    }
}
