<section
    class="advance-booking-banner"
    (click)="onClickAllBanner($event)">
    <div
        [ngClass]="isLargeBanner ? 'halfcircle--large' : 'halfcircle--small'"
        [ngStyle]="backgroundStyle.halfCircle">
    </div>
    <div
        class="banner"
        [ngStyle]="backgroundStyle.banner"
        [style.cursor]="cursorDefault ? 'default': 'pointer'"
        [ngClass]="isLargeBanner ? 'banner--large' : 'banner--small'">
        <div *ngIf="!isLargeBanner" class="logo--mobile">
            <div class="logo_img" [ngStyle]="backgroundStyle.logo"></div>
        </div>
        <div class="logo-text">
            <div class="logo">
                <div class="logo_img" [ngStyle]="backgroundStyle.logo"></div>
            </div>
            <div class="text-banner">
                <h2 class="text-banner__title">{{ bannerTitle }}</h2>
                <span *ngIf="isLargeBanner" class="text-banner__subtitle">
                    {{ bannerSubTitle }}
                </span>
                <ng-container *ngIf="!isLargeBanner; then bannerLinkMobileRef"></ng-container>
            </div>
        </div>
        <!-- Banner link -->
        <ng-container *ngIf="!isLargeBanner || !openEBModal; then bannerLinkRef else bannerLinkLargeRef"></ng-container>
    </div>
</section>

<ng-template #bannerLinkLargeRef>
    <div
        class="banner-button-wrapper"
        [style]="starStyle"
        [ngClass]="{'banner-button-wrapper--hide': hideStars}">
        <button
            [id]="buttonId"
            class="banner-button"
            [attr.aria-label]="a11yDescription | translate: { description: actionText }">
            <span aria-hidden="true">{{ actionText }}</span>
        </button>
    </div>
</ng-template>
<ng-template #bannerLinkRef>
    <button class="banner-link" role="link" (keydown.tab)="onTabPress($any($event))">
        <span class="banner-link__text">{{ actionText }}</span>
        <span class="di di-next di-icon-right" aria-hidden="true"></span>
    </button>
</ng-template>
<ng-template #bannerLinkMobileRef>
    <button class="banner-link banner-link--mobile" role="link" (keydown.tab)="onTabPress($any($event))">
        <span class="banner-link__text">{{ actionText }}</span>
        <span class="di di-next di-icon-right" aria-hidden="true"></span>
    </button>
</ng-template>
