/*eslint max-lines: ["error", 2000]*/
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import * as _ from 'lodash';
import * as momentImported from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsDataModel } from '../interfaces/analytics-data-model.interface';
import {
    clearAllUtil,
    clearByTypeUtil,
    clearSelectedFiltersByTypeUtil
} from '../utils/clear-filters.utils';
import { CruiseTileAction } from '../../featured-cruise-tiles/interfaces/cruise-tile-action.interface';
import { EventService } from '../../services/event-service/event.service';
import { Filter } from '../interfaces/filter.interface';
import FILTERS_UTILS from '../utils/filters.utils';
import { filterMappers } from '../mappers/mappers';
import { getSpecialtyName } from '../mappers/more-filters.mapper';
import { GridConfig } from '../../grid-picker/interfaces/grid-config.interface';
import { GridContent } from '../../grid-picker/interfaces/grid-content.interface';
import { ImagePicker } from '../../images-picker/image-picker.interface';
import { ItemFilterChange } from '../interfaces/item-filter-changed.interface';
import { LocalStorageService } from '../../services/local-storage-service/local-storage.service';
import { OptionItem } from '../../options-groups/interfaces/option-item.interface';
import { OptionsGroup } from '../../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote.constants';
import { RawFilter } from '../interfaces/raw-filter.interface';
import { ReservationIdService } from '../../services/reservation-id/reservation-id.service';
import { ReservationSummary } from '../../reservation-summary-bar/reservation-summary.interface';
import { SelectedFilter } from '../interfaces/selected-filter.interface';
import { SelectedFiltersModel } from '../interfaces/selected-filters-model.interface';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { TravelPartyService } from '../../travel-party/services/travel-party.service';
import { WindowRef } from '../../window-ref/window-ref.service';
import { ToggleService } from '../../services/toggle/toggle.service';

@Injectable({
    providedIn: 'root'
})
export class QuickQuoteService {
    clearAllFiltersUtil = clearAllUtil;
    constants = QUICK_QUOTE_CONSTANTS;
    contextPath: string;
    contextualFilterGroup: OptionsGroup;
    dclInternationalDirectPhase2: boolean;
    filtersData: {};
    filtersUtils = FILTERS_UTILS;
    focusBar: Subject<string> = new Subject<string>();
    modelChanged$: Subject<undefined> = new Subject<undefined>();
    isProductAvailable: boolean;
    model: Filter[];
    onApplyFilters: Subject<string[]> = new Subject<string[]>();
    removeFiltersByType = clearByTypeUtil;
    removeSelectedFiltersByType = clearSelectedFiltersByTypeUtil;
    selectedFilters: SelectedFiltersModel;
    selectedDoneFilters: SelectedFiltersModel;
    selectedFiltersList: string[] = [];
    specialFilter: RawFilter;
    temporalContextualFilterGroup: OptionsGroup;
    tempSelectedFilters: SelectedFiltersModel;
    tpFilterKeys = this.constants.travelPartyFilterKeys;
    urlDelimiters = this.constants.urlDelimiters;
    urlFilters: Subject<string[]> = new Subject<string[]>();
    dclAdvancedBooking: boolean = false;
    homepageToggleValue: boolean = false;

    private disableFilterDictionary = {};

    constructor (
        private cookieService: CookieService,
        private eventService: EventService,
        private localStorageService: LocalStorageService,
        private location: Location,
        private reservationIdService: ReservationIdService,
        private sessionStorageService: SessionStorageService,
        private translate: TranslateService,
        private travelPartyService: TravelPartyService,
        private toggleService: ToggleService,
        private windowRef: WindowRef
    ) {}

    /**
     * Create an subject from analytics data model
     * @param analyticsData analytics model
     */
    trackAnalyticsEvent(analyticsData: AnalyticsDataModel): void {
        this.eventService.sendNextItem({
            name: this.constants.analytics.analyticsSubjectEvent,
            value: analyticsData
        });
    }

    /**
     * Returns the filter model
     */
    getModel() {
        return this.model;
    }

    /**
     * Sets the model parsing the data provided by the data param
     * @param data Raw filters data
     * @return The filter data with the model format
     */
    setModel(data: {}): Filter[] {
        this.model = this.parseData(data);
        this.filtersData = data;
        this.selectedFilters = this.initSelectedFilters(this.selectedFilters);
        this.selectedDoneFilters = this.initSelectedFilters(this.selectedDoneFilters);
        this.tempSelectedFilters = this.initSelectedFilters(this.tempSelectedFilters);

        return this.model;
    }

    /**
     * Returns the filters raw data
     */
    getFiltersData() {
        return this.filtersData;
    }

    /**
     * Returns the filter selected
     */
    getFiltersSelected(): SelectedFiltersModel {
        return this.selectedFilters;
    }

    /**
     * Returns the temp selected filters
     */
    getTempFiltersSelected(): SelectedFiltersModel {
        return this.tempSelectedFilters;
    }

    /**
     * returns the contextPath saved
     */
    getContextPath(): string {
        return this.contextPath;
    }

    /**
     * Returns the special filter
     * @returns special filter
     */
    getSpecialFilter(): RawFilter {
        return this.specialFilter;
    }

    /**
     * Retrieves the current locale from the cookie service
     * If the locale is not set in the cookie, a default locale is returned
     */
    get locale(): string {
        try {
            const locale = JSON.parse(this.cookieService.get(this.constants.cookie.localeCookie));
                
            return locale.preferredRegion || this.constants.cookie.defaultLocale;
        } catch (error) {

            return this.constants.cookie.defaultLocale;
        }
    }
    
    /**
     * Gets the current state of the DCL International Direct Phase 2 toggle.
     *
     * @returns {boolean} The state of the toggle, either `true` if enabled or `false` if disabled.
     */
    get dclInternationalDirectPhase2Toggle(): boolean {
        const toggleValue = this.toggleService.getToggle(this.constants.toggles.dclInternationalDirectPhase2);

        return toggleValue || this.homepageToggleValue;
    }

    /**
     * sets the context got from the SPA
     *
     * @param contextPath
     */
    setContextPath(contextPath: string): void {
        this.contextPath = contextPath;
    }

    /**
     * Verify if the screen width is mobile or desktop depending on given breakpoint
     * @returns true if is mobile or false otherwise
     */
    verifyIsMobile(): boolean {
        return this.windowRef.nativeWindow.innerWidth <= this.constants.viewBreakpoints.mobile;
    }

    /**
     * Parse the data to the model format
     * This function iterates on the data object to convert the object to a filters array.
     * First, it asks if a filter item was already created with the type of the item,
     * if the filter item was not created, creates a new filter item, and calls the mapper associate with this type,
     * finally adds the new filter item to the final array result.
     * If the filter item was already created by a previous item, the data of the item is just add to the data property
     * of the filter item after map its value.
     *
     * Note that to add filter items to the final array we use a renderOrder property of each filter,
     * it is because to render the filters in the proper way, we need that filters ordered in a specific way
     * @param data Raw filters data
     */
    parseData(data: {}): Filter[] {
        const filtersResult: Filter[] = [];

        _.forIn(data, (filterData: RawFilter) => {
            // Save special filter coming from special offer or port of call
            if (this.constants.specialFilters.includes(filterData.type)) {
                this.specialFilter = filterData;

                return;
            }

            // Avoid unnecessary processing for hidden filters
            if (filterData.hiddenFilter) {
                return;
            }

            const filterType = this.constants.filters[filterData.type]
                ? filterData.type
                : this.constants.filters['more-filters'].id;

            const filterParsed = filtersResult.find(filter => filter && filter.type === filterType);

            // Define isDisabled flag value with dictionary
            if (this.disableFilterDictionary[filterType]) {
                filterParsed['isDisabled'] = this.disableFilterDictionary[filterType];
            }

            if (filterParsed) {
                this.addItemToFilter(filterType, filterData, filterParsed);
            } else {
                const translationkeys = this.getTranslationKeys(filterType);
                const newfilterParsed = {
                    type: filterType,
                    headingKey: translationkeys.heading,
                    nameKey: translationkeys.name,
                    value: translationkeys.defaultValue,
                    modalValue: translationkeys.defaultValue,
                    isSubgroup: this.constants.filters[filterType].isSubgroup,
                    data: []
                };

                this.addItemToFilter(filterType, filterData, newfilterParsed);
                filtersResult[this.constants.filters[filterType].renderOrder] = newfilterParsed;
            }
        });

        filtersResult[this.constants.filters['travel-party'].renderOrder] = {
            ...this.getTravelPartyFilter(),
            isDisabled: this.disableFilterDictionary['travel-party']
        };

        return filtersResult;
    }

    /**
     * Calls the mapper associate with the filterType param and applies it to the filterData param,
     * finally adds it into the data property of the filterObject param
     * @param filterType Filter type
     * @param filterData Raw filter data
     * @param filterObject Filter item
     */
    addItemToFilter(filterType: string, filterData: RawFilter, filterObject: Filter): void {
        const mapper = filterMappers[filterType];

        if (mapper) {
            if (filterData.isEarlyBooking && !filterObject.isEarlyBooking) {
                filterObject.isEarlyBooking = true;
            }

            if (filterType === this.constants.filters['more-filters'].id) {
                filterData.name = this.translate.instant(getSpecialtyName(filterData));
            }

            if (this.dclInternationalDirectPhase2Toggle && filterType === 'date') {
                mapper(filterData, filterObject.data, this.locale);
            } else {
                mapper(filterData, filterObject.data);
            }

        } else {
            filterObject.data.push(filterData);
        }
    }

    /**
     * Returns the filter item for the Travel Party filter
     */
    getTravelPartyFilter(): Filter {
        // The value for the Travel Party should be calculated, so this method should be updated after
        // Travel Party component be created
        const guestCount: number = this.travelPartyService.getTravelPartyCount();

        return {
            type: this.constants.filters['travel-party'].id,
            headingKey: this.constants.filters['travel-party'].headingKey,
            nameKey: this.constants.filters['travel-party'].nameKey,
            value: this.getTranslatedTravelPartySubHeading(guestCount),
            modalValue: this.getTranslatedTravelPartySubHeading(guestCount),
            data: []
        };
    }

    /**
     * Get the corresponding translation for travel party depending on the travel party count
     * @param travelPartyCount
     * @returns the corresponding translation
     */
    getTranslatedTravelPartySubHeading(travelPartyCount: number): string {
        let travelPartyCountLabel: string;
        let defaultValue = this.tpFilterKeys.defaultValuePlural;

        if (travelPartyCount === 1) {
            defaultValue = this.tpFilterKeys.defaultValueSingular;
        }

        travelPartyCountLabel = this.translate.instant(
            this.constants.filters['travel-party'][defaultValue],
            { count: travelPartyCount }
        );

        return travelPartyCountLabel;
    }

    /**
     * Returns the translations keys associate with the filterType param
     * @param filterType Filter type
     */
    getTranslationKeys(filterType: string): { heading: string; name: string; defaultValue: string } {
        const filterKeysData = this.constants.filters[filterType];

        return {
            heading: filterKeysData.headingKey,
            name: filterKeysData.nameKey,
            defaultValue: filterKeysData.defaultValue
        };
    }

    /**
     * Enables ADA2 stateroom error validation in the Travel Party Service
     */
    enableADA2TPErrors() {
        this.travelPartyService.enableADA2TPErrors();
    }

    /**
     * Validates that the filter selected has errors by calling its corresponding service
     * @param index Filter index
     */
    filterHasErrors(index: number): boolean {
        let hasErrors = false;

        if (index) {
            const filterType = this.model[index].type;

            switch (filterType) {
                case 'travel-party':
                    hasErrors = this.travelPartyService.getStateroomsWithErrors().length > 0;
            }
        }

        return hasErrors;
    }

    /**
     * Initialize the selected filters object to save the ids that are been selected in the same order
     * that the model has the filters type.
     * For 'more filters' type, wil generate an array for each group type (theme, ship, night)
     * @param filterArray
     *
     * @returns An array that reflects the model structure
     */
    initSelectedFilters(filterArray) {
        const moreFilter = this.constants.filters['more-filters'].id;
        const privateIsland = this.constants.filters['privateIsland'].id;
        const hasNotPrivateIslandKey = !this.model.some(item => item.type === privateIsland);

        filterArray = [];

        this.model.forEach((filter: Filter, filterIndex: number) => {
            filterArray.push([]);

            if (filter.type === moreFilter) {
                filter.data.forEach(() => filterArray[filterIndex].push([]));
            }
        });

        // privateIsland can be disabled from Service side
        // @TODO remove once privateIsland are enabled by default
        if (hasNotPrivateIslandKey) {
            filterArray.push([]);
        }

        return filterArray;
    }

    /**
     * Get selected filters from URL or cookie to select them in the QuickQuoteBar
     * @param updateURL flag to update the url
     * @param reservationSummary the reservation summary
     * @param useResFilters flag to read filters from res summary
     * @returns Filters selected as a string array
     */
    getPreSelectedFilters(
        updateURL?: boolean,
        reservationSummary?: ReservationSummary,
        useResFilters?: boolean
    ): string[] {
        let preSelectedFilters = this.getFiltersFromBrowser(updateURL);

        // if modify flow
        if (reservationSummary) {
            const storageKey = this.getStorageKey(this.reservationIdService.getId());

            this.selectedFiltersList = [];
            // if the key is empty is expected that returns true to not try to get the default filters from session
            if (
                useResFilters &&
                !reservationSummary.hasOfferInReservation &&
                !preSelectedFilters &&
                (this.sessionStorageService.getItem(storageKey) === null)
            ) {
                preSelectedFilters = this.captureFiltersFromSession(reservationSummary);
            }
        }

        preSelectedFilters = preSelectedFilters && preSelectedFilters.replace('/', '');

        if (preSelectedFilters) {
            if (this.model) {
                const validFilters = this.getValidFilters(preSelectedFilters);

                preSelectedFilters = validFilters.join();

                this.selectUrlFriendlyFilters(preSelectedFilters, updateURL);
                this.sendSelectedFilters(validFilters);
            }

            if (!_.get(this.selectedFiltersList, 'length')) {
                this.selectedFiltersList = this.getFilterOptions(preSelectedFilters);
            }
        }

        return this.selectedFiltersList;
    }

    /**
     * Get valid filters by comparing the preselected filters with the filter service response
     * @params preselectedFilters. Filters selected got from browser
     * @returns valid filters array
     */
    getValidFilters(preselectedFilters: string): string[] {
        const portsDelimiter = this.constants.urlDelimiters.ports;

        return preselectedFilters.split(',').filter((filterValue: string) =>
            filterValue.startsWith(portsDelimiter) || _.find(this.filtersData, (filterItem: RawFilter) =>
                filterItem.urlFriendlyId === filterValue
            )
        );
    }

    /**
     * Get location path
     * @params delimiter threshold value to fragment the url
     * @params withHash [withHash=false] says if the path obtained should include the hash
     * @returns location path needed
     */
    getLocationPath(delimiter: string, withHash: boolean = false): string {
        let locationPath = '';

        if (this.location.path(withHash).includes(delimiter)) {
            locationPath = this.location.path(withHash).split(delimiter)[1];
        }

        return locationPath;
    }

    /**
     * Get filter values from either address bar or cookies
     * @param updateURL flag to update URL
     * @returns selected filters as string value
     */
    getFiltersFromBrowser(updateURL: boolean): string {
        const reservationId = this.reservationIdService.getId();
        const hashFilters = this.getLocationPath(this.urlDelimiters.hash, true);
        const itemStorage = this.localStorageService.getItemStorage(
            this.constants.localStorage.specialFilters,
            true
        );
        let filterAddressBar = '';
        let filterCookieValue = '';
        let specialFilter = this.getLocationPath(this.urlDelimiters.filteredPort);
        let specialFilterStorage = '';

        specialFilterStorage = itemStorage && itemStorage[this.constants.specialFiltersTypes.portOfCall];
        specialFilterStorage = this.getSpecialFilterValue(
            updateURL,
            ...[
                this.getLocalFilters(reservationId),
                hashFilters,
                this.location.path(),
                specialFilterStorage
            ]
        );
        specialFilter = specialFilter || specialFilterStorage;
        filterCookieValue = this.getLocalFilters(reservationId);
        filterAddressBar = this.getAddressBarFilter(...[
            filterCookieValue,
            hashFilters,
            specialFilter
        ]);

        return filterAddressBar || filterCookieValue;
    }

    /**
     * Get address bar filter value
     * @param addressBarFilters Array of params used for the validation
     * @returns address bar filter value
     */
    getAddressBarFilter(...addressBarFilters: string[]): string {
        const [
            filterCookieValue,
            hashFilters,
            specialFilter
        ] = addressBarFilters;
        let filterAddressBar = '';

        if (specialFilter) {
            const commonFilters = hashFilters || filterCookieValue;

            filterAddressBar = (commonFilters && `${specialFilter},${commonFilters}`) || specialFilter;
        } else if (hashFilters) {
            filterAddressBar = hashFilters;
        }

        return filterAddressBar;
    }

    /**
     * Get special filters value
     * @param updateURL Flag to update URL
     * @param filterParams Array of params used for the validation
     * @returns special filter value
     */
    getSpecialFilterValue(updateURL: boolean, ...filterParams: string[]): string {
        const reservationId = this.reservationIdService.getId();
        const [
            filterCookieValue,
            hashFilters,
            pathWithoutHash,
            specialFilterStorage
        ] = filterParams;
        const filteredPortDelimiter = `?${this.constants.urlDelimiters.filteredPort}`;
        let specialFilterValue = specialFilterStorage || '';

        // Only updates the url if no hash, there are special filters in localStorage, but they are not in the url
        // This case is when the user navigates from another page
        if (!hashFilters && specialFilterStorage && !pathWithoutHash.includes(filteredPortDelimiter)) {
            if (updateURL) {
                this.location.go(this.location.path() + `${filteredPortDelimiter}${specialFilterStorage}`);
            }

        // Avoid requesting for products with wrong special filters
        // Case when user navigates with new filters selected from another page
        } else if (hashFilters && hashFilters !== filterCookieValue && specialFilterStorage) {
            specialFilterValue = '';
            localStorage.removeItem(this.constants.localStorage.specialFilters);
            this.saveFiltersLocally(reservationId, hashFilters);
        }

        return specialFilterValue;
    }

    /**
     * Functions that iterates over the model to find match the filters that are been pre selected and select them
     * and add it to selectedFilters array
     * @param filters
     */
    updateSelectedFiltersFromPreSelectedFilters(filters: string): void {
        const preSelectedFilters = filters.split(',');

        this.model.forEach((filter: Filter, filterIndex) => {
            switch (filter.type) {
                case this.constants.filters['date'].id:
                    filter.data.forEach((gridConfig: GridConfig) => {
                        gridConfig.contentData.forEach((gridContent: GridContent) => {
                            if (preSelectedFilters.includes(gridContent.urlFriendlyId)) {
                                const itemFilterSelected: SelectedFilter = {
                                    id: gridContent.id,
                                    urlFriendlyId: gridContent.urlFriendlyId,
                                    type: filter.type
                                };

                                gridContent.isSelected = true;
                                this.filtersUtils.addByFilterName(
                                    itemFilterSelected,
                                    this.selectedFilters[filterIndex] as SelectedFilter[],
                                    this.filtersData
                                );
                            }
                        });
                    });

                    break;
                case this.constants.filters['privateIsland'].id:
                case this.constants.filters['destination'].id:
                case this.constants.filters['city'].id:
                    filter.data.forEach((imagePicker: ImagePicker) => {
                        if (preSelectedFilters.includes(imagePicker.urlFriendlyId)) {
                            const itemFilterSelected: SelectedFilter = {
                                id: imagePicker.id,
                                urlFriendlyId: imagePicker.urlFriendlyId,
                                type: filter.type
                            };

                            imagePicker.selected = true;
                            this.filtersUtils.addByFilterName(
                                itemFilterSelected,
                                this.selectedFilters[filterIndex] as SelectedFilter[],
                                this.filtersData
                            );
                        }
                    });

                    break;
                case this.constants.filters['more-filters'].id:
                    filter.data.forEach((optionsGroup: OptionsGroup, optionsGroupIndex) => {
                        optionsGroup.options.forEach((optionItem: OptionItem) => {
                            if (preSelectedFilters.includes(optionItem.urlFriendlyId)) {
                                const itemFilterSelected: SelectedFilter = {
                                    id: optionItem.id,
                                    urlFriendlyId: optionItem.urlFriendlyId,
                                    type: filter.type,
                                    groupType: optionsGroup.type
                                };

                                optionItem.selected = true;

                                if (optionsGroup.type === this.constants.filters['more-filters'].groupKey.night) {
                                    this.filtersUtils.addByFilterNights(
                                        itemFilterSelected,
                                        this.selectedFilters[filterIndex][optionsGroupIndex] as SelectedFilter[]
                                    );
                                } else {
                                    this.filtersUtils.addByFilterName(
                                        itemFilterSelected,
                                        this.selectedFilters[filterIndex][optionsGroupIndex] as SelectedFilter[],
                                        this.filtersData
                                    );
                                }
                            }
                        });
                    });

                    break;
            }
        });
    }

    /**
     * Manage the onChangedFilterItem event.
     * @param itemFilterChanged
     */
    changedFilterItem(itemFilterChanged: ItemFilterChange): void {
        const filterIndex = this.model.findIndex((filterData) => {
            return filterData.type === itemFilterChanged.filterType;
        });

        if (itemFilterChanged.isSelected) {
            this.addItemToTempSelectedFilters(itemFilterChanged, filterIndex);
        } else {
            this.removeItemFromTempSelectedFilters(itemFilterChanged, filterIndex);
        }

        this.updateFilterLabelValuesFromSelectedFilters(this.tempSelectedFilters);
    }

    /**
     * Update the sub heading value based on the travelParty count
     * @param travelpartyCount
     */
    changedTravelPartyCount(travelpartyCount: number): void {
        const travelPartyIndex = this.model.findIndex((filter) => {
            return filter.type === this.constants.filters['travel-party'].id;
        });

        this.model[travelPartyIndex].value = this.getTranslatedTravelPartySubHeading(travelpartyCount);
        this.model[travelPartyIndex].modalValue = this.model[travelPartyIndex].value;
    }

    /**
     * Add the item to the selectedFilters object depending on the filter type
     * @param itemFilterChanged
     * @param filterIndex
     */
    addItemToTempSelectedFilters(itemFilterChanged: ItemFilterChange, filterIndex: number): void {
        if (itemFilterChanged.filterType === this.constants.filters['more-filters'].id) {
            const groupTypeIndex = this.model[filterIndex].data.findIndex((optionsGroup: OptionsGroup) => {
                return optionsGroup.type === itemFilterChanged.groupType;
            });
            const itemSelected: SelectedFilter = {
                groupType: itemFilterChanged.groupType,
                id: itemFilterChanged.id,
                type: itemFilterChanged.filterType,
                urlFriendlyId: itemFilterChanged.urlFriendlyId
            };

            // TODO: GroupKey could be moved to an object
            if (itemFilterChanged.groupType === this.constants.filters['more-filters'].groupKey.night) {
                this.filtersUtils.addByFilterNights(
                    itemSelected,
                    this.tempSelectedFilters[filterIndex][groupTypeIndex] as SelectedFilter[]
                );
            } else {
                this.filtersUtils.addByFilterName(
                    itemSelected,
                    this.tempSelectedFilters[filterIndex][groupTypeIndex] as SelectedFilter[],
                    this.filtersData
                );
            }

        } else if (itemFilterChanged.filterType !== this.constants.filters['travel-party'].id) {
            const itemSelected: SelectedFilter = {
                id: itemFilterChanged.id,
                type: itemFilterChanged.filterType,
                urlFriendlyId: itemFilterChanged.urlFriendlyId
            };

            this.filtersUtils.addByFilterName(
                itemSelected,
                this.tempSelectedFilters[filterIndex] as SelectedFilter[],
                this.filtersData
            );
        }
    }

    /**
     * Remove the item from selectedFilters
     * @param itemFilterChanged
     * @param filterIndex
     */
    removeItemFromTempSelectedFilters(itemFilterChanged: ItemFilterChange, filterIndex: number): void {
        if (itemFilterChanged.filterType === this.constants.filters['more-filters'].id) {
            const groupTypeIndex = this.model[filterIndex].data.findIndex((optionsGroup: OptionsGroup) => {
                return optionsGroup.type === itemFilterChanged.groupType;
            });

            const selectedFilters = this.tempSelectedFilters[filterIndex][groupTypeIndex] as SelectedFilter[];

            const index = selectedFilters.findIndex((selectedFilter) => {
                return selectedFilter.id === itemFilterChanged.id;
            });

            if (index >= 0) {
                selectedFilters.splice(index, 1);
            }
        } else {
            const index = (this.tempSelectedFilters[filterIndex] as SelectedFilter[]).findIndex((selectedFilter) => {
                return selectedFilter.id === itemFilterChanged.id;
            });

            this.tempSelectedFilters[filterIndex].splice(index, 1);
        }
    }

    /**
     * Updates the URL based on the cookie value
     */
    updateURLFromCookieValue(): void {
        const reservationId = this.reservationIdService.getId();
        const cookieValue = this.getLocalFilters(reservationId);

        if (cookieValue) {
            this.location.go(this.location.path() + this.urlDelimiters.hash + cookieValue);
        } else {
            this.location.go(this.location.path());
        }
    }

    /**
     * Send applied filters
     * @param selectedFilters Selected filters as array
     */
    sendSelectedFilters(selectedFilters: string[]): void {
        this.urlFilters.next(selectedFilters);
    }

    /**
     * Get filters displayed in the address bar as a string array
     * @returns filters applied
     */
    getSelectedFilters(): Observable<string[]> {
        return this.urlFilters;
    }

    /**
     * Retrives the list of properties of the selected filters list based on SelectedFiltersModel
     *
     * @param prop the property of the SelectedFilter
     * @returns array string list (ie: ["2020-10;filterType=date"])
     */
    getSelectedFiltersBy(prop: string): string[] {
        return _.flatten(this.selectedFilters)
            .filter(item => Object.keys(item).length)
            .map(item => item[prop]);
    }

    /**
     * Functions that helps to add string into an accumulator. Separator is used to separates each
     * string added
     * @param value
     * @param accumulator
     * @param separator Optional. Default ','
     */
    addToStringAccumulator(value, accumulator: string, separator = ','): string {
        if (accumulator && value) {
            accumulator += separator + value;
        } else if (value) {
            accumulator = value;
        }

        return accumulator;
    }

    /**
     * Updates the filter label value for each filter from selection object
     * @param selectedFilters selectedFilters/tempSelectedFilter
     */
    updateFilterLabelValuesFromSelectedFilters(selectedFilters): void {
        const filterDate = this.constants.filters['date'].id;
        const filterMoreFilter = this.constants.filters['more-filters'].id;
        const filterTravelParty = this.constants.filters['travel-party'].id;
        const filterMatrix = [
            this.constants.filters['destination'].id,
            this.constants.filters['privateIsland'].id,
            this.constants.filters['city'].id
        ];

        this.model.map((filterData: Filter, filterDataindex: number) => {
            let filterValueLabel: string = '';
            let filterCount: number = selectedFilters[filterDataindex].length;

            if (filterData.type === filterDate) {
                selectedFilters[filterDataindex].forEach((selectedFilter) => {
                    filterValueLabel = this.updateDateFilterValue(selectedFilter.id, filterValueLabel);
                });
            }

            if (filterMatrix.includes(filterData.type)) {
                selectedFilters[filterDataindex].forEach((selectedFilter) => {
                    filterValueLabel = this.addToStringAccumulator(
                        this.filtersData[selectedFilter.id].name,
                        filterValueLabel,
                        ', '
                    );
                });

                filterValueLabel = this.fixLabelForPrivateIsland(filterData, selectedFilters, filterValueLabel);
                filterCount = this.fixCountForPrivateIslandAndDestination(filterData, selectedFilters, filterCount);
                filterData = this.fixDataForPrivateIsland(filterData);
            }

            if (filterData.type === filterMoreFilter) {
                selectedFilters[filterDataindex].forEach((moreFilterGroup) => {
                    moreFilterGroup.forEach((filter) => {
                        filterValueLabel = this.addToStringAccumulator(
                            this.filtersData[filter.id].name,
                            filterValueLabel,
                            ', '
                        );
                    });
                });
            }

            if (filterData.type !== filterTravelParty) {
                if (filterValueLabel) {
                    this.setFilterDataValues(filterData, filterValueLabel, filterCount);
                } else {
                    const translationkeys = this.getTranslationKeys(filterData.type);

                    if (translationkeys) {
                        filterData.value = translationkeys.defaultValue;
                        filterData.modalValue = translationkeys.defaultValue;
                    }
                }
            }
        });
    }

    /**
     * Select urlFriendlyId filters
     * @param filters comma separated filters
     * @param updateURL flag to update the url
     */
    selectUrlFriendlyFilters(filters: string, updateURL: boolean): void {
        this.updateSelectedFiltersFromPreSelectedFilters(filters);
        this.updateFilterLabelValuesFromSelectedFilters(this.selectedFilters);
        this.mergeDoneIntoSelectedFilters();
        this.mergeIntoTempSelectedFilters();
        this.updateCookieValueFromSelectedFilters();

        if (updateURL) {
            this.updateURLFromCookieValue();
        }
    }

    /**
     * Return the Destination data object when match PrivateIsland
     * @TODO this should be removed whe QQ support subgroups
     * @param filterData
     * @returns {Filter} destination group
     */
    private fixDataForPrivateIsland(filterData: Filter): Filter {
        if (filterData.type === this.constants.filters['privateIsland'].id) {
            filterData = this.model.find(
                (item: Filter) => item.type === this.constants.filters['destination'].id
            );
        }

        return filterData;
    }

    /**
     * Merge labels names of filters selected for Destination & Private Island
     * @param filterData
     * @param selectedFilters
     * @param filterValueLabel
     * @returns {string} all valid labels strings separated by coma
     */
    private fixLabelForPrivateIsland(
        filterData: Filter,
        selectedFilters: SelectedFilter,
        filterValueLabel: string
        ): string {
        if (filterData.type === this.constants.filters['privateIsland'].id) {
            selectedFilters[this.constants.filters['destination'].renderOrder]
                .forEach((selectedFilter: SelectedFilter) => {
                    filterValueLabel = this.addToStringAccumulator(
                        this.filtersData[selectedFilter.id].name,
                        filterValueLabel,
                        ', '
                    );
                });
        } else if (filterData.type === this.constants.filters['destination'].id) {
            selectedFilters[this.constants.filters['privateIsland'].renderOrder]
                .forEach((selectedFilter: SelectedFilter) => {
                    filterValueLabel = this.addToStringAccumulator(
                        this.filtersData[selectedFilter.id].name,
                        filterValueLabel,
                        ', '
                    );
                });
        }

        return filterValueLabel;
    }

    /**
     * Merge total result of filters selected for Destination & Private Island
     * @param filterData
     * @param selectedFilters
     * @param filterCount
     * @returns {int} number of total filters
     */
    private fixCountForPrivateIslandAndDestination(
        filterData: Filter,
        selectedFilters: SelectedFilter,
        filterCount: number
    ): number {
        if (filterData.type === this.constants.filters['privateIsland'].id) {
            filterCount += selectedFilters[this.constants.filters['destination'].renderOrder].length;
        } else if (filterData.type === this.constants.filters['destination'].id) {
            filterCount += selectedFilters[this.constants.filters['privateIsland'].renderOrder].length;
        }

        return filterCount;
    }

    /**
     * Assigns the corresponding value to the filter data for desktop and mobile based on the amount
     * of filters selected
     * @param filterData
     * @param filterValueLabel
     * @param filterCount
     */
    setFilterDataValues(filterData: Filter, filterValueLabel: string, filterCount: number): void {
        if (filterCount === 1) {
            filterData.value = filterValueLabel;
        } else if (filterCount > 1 && filterData.type !== this.constants.filters['more-filters'].id) {
            filterData.value = this.translate.instant(
                this.constants.filters[filterData.type].countValue,
                { count: filterCount}
            );
        }

        filterData.modalValue = filterValueLabel;
    }
    /**
     * Function that format the filter date id to it respective filter value date form
     * example: June 2020
     * @param filterId date filter id
     * @param filterValueLabel Initial accumulator string
     * @returns {string} date filter value
     */
    updateDateFilterValue(filterId: string, filterValueLabel: string): string {
        const moment = momentImported;
        const dateString = filterId.split(';')[0];
        const momentDate = moment(dateString, 'YYYY-MM');
        const dateValue = momentDate.format('MMMM YYYY');

        return this.addToStringAccumulator(dateValue, filterValueLabel, ', ');
    }

    /**
     * Updates cookie value based on the selected filters
     */
    updateCookieValueFromSelectedFilters(): void {
        const reservationId = this.reservationIdService.getId();
        let cookieValue = '';

        this.model.forEach((filterData, filterIndex) => {
            let urlFilterValue = '';

            if (filterData.type === this.constants.filters['more-filters'].id) {
                (this.selectedFilters[filterIndex] as SelectedFilter[][])
                    .forEach((selectedFilter: SelectedFilter[]) => {
                        selectedFilter.forEach((groupType: SelectedFilter) => {
                            urlFilterValue = this.addToStringAccumulator(groupType.urlFriendlyId, urlFilterValue);
                        });
                    });

            } else if (filterData.type !== this.constants.filters['travel-party'].id) {
                (this.selectedFilters[filterIndex] as SelectedFilter[])
                    .forEach((selectedFilter: SelectedFilter) => {
                        urlFilterValue = this.addToStringAccumulator(selectedFilter.urlFriendlyId, urlFilterValue);
                    });
            }

            cookieValue = this.addToStringAccumulator(urlFilterValue, cookieValue);
        });

        if (cookieValue) {
            this.saveFiltersLocally(reservationId, cookieValue);
        } else {
            this.removeFiltersLocally(true);
        }
    }

    /**
     * Removes the filters from the session storage
     */
    removeFiltersLocally(clearFilters = false) {
        const reservationId = this.reservationIdService.getId();
        const storageKey = this.getStorageKey(reservationId);

        if (reservationId) {
            if (clearFilters) {
                // This key is set to empty to know if the user cleared the filters
                this.sessionStorageService.setItem(storageKey, '');
            } else {
                this.sessionStorageService.removeItem(storageKey);
            }
        } else {
            this.cookieService.delete(storageKey, '/');
        }
    }

    /**
     * Emit an event with the filters that have been selected
     */
    applyFilters(): void {
        const filters = this.getSelectedFiltersToApply();
        let filtersValues: string[];

        this.mergeIntoDoneFilters();
        filtersValues = this.getFiltersValues(filters);
        this.onApplyFilters.next(filtersValues);
    }

    /**
     * Retrieves an array of filters values based on array of filters ids
     * @param filtersId filters ids array
     * @returns Filters values array
     */
    getFiltersValues(filtersId: string[]): string[] {
        return filtersId.map((filterId: string) => this.filtersData[filterId].filterValue);
    }

    /**
     * Get filter options as a string array
     * @param selectedFilters Selected filters as a string value
     * @returns Filter options
     */
    getFilterOptions(selectedFilters: string): string[] {
        let filterOptions = [];

        if (selectedFilters) {
            filterOptions = selectedFilters.split(',').map(
                (selectedFilter: string) => selectedFilter + this.constants.filters.preSelectedFiltersSuffix
            );
        }

        return filterOptions;
    }

    /**
     * Clears all filters for the filter type provided
     * @param filterType filter type
     */
    clearFiltersByType(filterType: string): void {
        this.removeFiltersByType(filterType, this.model);
        this.removeSelectedFiltersByType(filterType, this.tempSelectedFilters, this.model);
    }

    /**
     * this function calls the clear all filter function from util
     * sending the model already create and the filters already selected,
     * clears the temporary selected object and
     * also clears the cookieValues and the url values already saved
     * @param updateURL flag to update the url
     */
    clearAllFilters(updateURL?: boolean): void {
        if (this.model && this.selectedFilters) {
            this.clearAllFiltersUtil(this.model, this.selectedDoneFilters);
            this.clearAllFiltersUtil(this.model, this.selectedFilters);
            this.clearAllFiltersUtil(this.model, this.tempSelectedFilters);
            this.updateFilterLabelValuesFromSelectedFilters(this.selectedFilters);
            this.updateCookieValueFromSelectedFilters();

            if (updateURL) {
                this.location.go(this.location.path().split('?')[0]);
            }

            this.deleteSpecialFilter();
        }
    }

    /**
     * Restores the temporary selected filters with the actual selected filters.
     * Updates selections states in the model.
     *
     * @param filterIndex
     * @param closeModal
     */
    cancelFilterSelections(filterIndex: number, closeModal: boolean): void {
        const filter = this.model[filterIndex];
        let filtersActionType;

        if (closeModal) {
            filtersActionType = this.selectedFilters;
            this.mergeDoneIntoSelectedFilters();
        } else {
            filtersActionType = this.selectedDoneFilters;
        }

        this.updateFilterLabelValuesFromSelectedFilters(filtersActionType);
        this.mergeIntoTempSelectedFilters();

        switch (filter.type) {
            case this.constants.filters['date'].id:
                filter.data.forEach((gridConfig: GridConfig) => {
                    gridConfig.contentData.forEach((gridContent: GridContent) => {
                        const selectedFilters = filtersActionType[filterIndex] as SelectedFilter[];
                        const isFilterSelected = selectedFilters.find((selectedFilter) => {
                            return selectedFilter.id === gridContent.id;
                        });

                        gridContent.isSelected = !!isFilterSelected;
                    });
                });

                break;
            case this.constants.filters['privateIsland'].id:
            case this.constants.filters['destination'].id:
            case this.constants.filters['city'].id:
                filter.data.forEach((imagePicker: ImagePicker) => {
                    const selectedFilters = filtersActionType[filterIndex] as SelectedFilter[];
                    const isFilterSelected = selectedFilters.find((selectedFilter) => {
                        return selectedFilter.id === imagePicker.id;
                    });

                    imagePicker.selected = !!isFilterSelected;
                });

                break;
            case this.constants.filters['more-filters'].id:
                filter.data.forEach((optionsGroup: OptionsGroup, optionGroupIndex: number) => {
                    optionsGroup.options.forEach((optionItem: OptionItem) => {
                        const selectedFilters = filtersActionType[filterIndex][optionGroupIndex] as SelectedFilter[];
                        const isFilterSelected = selectedFilters.find(
                            (selectedFilter) => {
                                return selectedFilter.id === optionItem.id;
                            });

                        optionItem.selected = !!isFilterSelected;
                    });
                });
                break;
            case this.constants.filters['travel-party'].id:
                this.travelPartyService.resetStaterooms();
                this.changedTravelPartyCount(this.travelPartyService.getTravelPartyCount());
                break;
        }
    }

    /**
     * Copy the values from tempSelectedFilters to selectedFilters
     */
    mergeIntoSelectedFilters(): void {
        this.selectedFilters = _.cloneDeep(this.tempSelectedFilters);
    }

    /**
     * Copy the values from selectedFilters to tempSelectedFilters
     */
    mergeIntoTempSelectedFilters(): void {
        this.tempSelectedFilters = _.cloneDeep(this.selectedDoneFilters);
    }

    /**
     * Copy the values from tempSelectedFilters to selectedDoneFilters
     */
    mergeIntoDoneFilters(): void {
        this.selectedDoneFilters = _.cloneDeep(this.tempSelectedFilters);
    }

    /**
     * Copy the values from selectedFilters to selectedDoneFilters
     */
    mergeDoneIntoSelectedFilters(): void {
        this.selectedDoneFilters = _.cloneDeep(this.selectedFilters);
    }

    /**
     * Returns an observable to know when focus should go to the Quick Quote Bar
     * @returns Observable to watch when the Quick Quote Bar should be focused
     */
    getFocusBar(): Observable<string> {
        return this.focusBar;
    }

    /**
     * Emits a new item for the focusBar Observable to send the focus to the Quick Quote Bar
     * based on the filter type and direction provided
     * @param filterType Filter type
     * @param direction Direction to focus, 1 to right and -1 to left
     */
    focusToBar(filterType: string, direction: number): void {
        const index = this.model.findIndex((filter: Filter) => filter.type === filterType);
        // indicates what is the last filter displayed in the qq bar
        const lastFilterBarIndex = this.model.findIndex((filter: Filter) => filter.type === 'more-filters');

        if (direction === 1) {
            if (index === lastFilterBarIndex) {
                this.focusBar.next(null);
            } else if (this.filterHasErrors(index)) {
                this.focusBar.next(`tab-${index}`);
            } else {
                this.focusBar.next(`tab-${index + 1}`);
            }
        } else {
            this.focusBar.next(`tab-${index}`);
        }
    }

    /**
     * Gets the options group filters from quick quote service
     *
     * @param filterType to be returned
     * @returns filter OptionGroup value
     */
    getOptionGroupFiltersByType(filterType: string): OptionsGroup {
        const moreFiltersConstants = this.constants.filters['more-filters'];

        if (this.model && this.model[moreFiltersConstants.renderOrder].data) {
            this.contextualFilterGroup =
                this.model[moreFiltersConstants.renderOrder].data.filter((filters: OptionsGroup) => {
                    return filters.type === filterType;
                })[0] as OptionsGroup;

            return this.contextualFilterGroup;
        }
    }

    /**
     * Get the filter id selected from filters that has been selected previously
     *
     * @param itemFilterChange selected filter
     * @param appliedFilters current applied filters
     * @returns filter id
     */
    getSelectedFilterByID(itemFilterChange: ItemFilterChange, appliedFilters: string[]): string {
        let selectedFilter = '';

        appliedFilters.forEach((filter: string) => {
            const filterId = filter.split(';')[0];
            const currentFilterId = itemFilterChange.id.split(';')[0];
            const currentFilterFriendlyId = itemFilterChange.urlFriendlyId;

            if (filterId === currentFilterId || filterId === currentFilterFriendlyId) {
                selectedFilter = filter;
            }
        });

        return selectedFilter;
    }

    /**
     * Disable all the contextual filters
     */
    disableContextualFilters(): void {
        if (this.contextualFilterGroup) {
            this.temporalContextualFilterGroup = _.cloneDeep(this.contextualFilterGroup);
            this.contextualFilterGroup.options.forEach((optionItem: OptionItem) => optionItem.disabled = true);
        }
    }

    /**
     * Enable the contextual filters that were previous enabled
     */
    enablePreviousSelectedFilters(): void {
        if (this.contextualFilterGroup && this.temporalContextualFilterGroup) {
            Object.assign(this.contextualFilterGroup, this.temporalContextualFilterGroup);
        }
    }

    /**
     * Set the DclAdvancedBooking toggle status in this service
     * @param dclAdvancedBooking
     */
    setIsDclAdvancedBooking(dclAdvancedBooking: boolean): void {
        this.dclAdvancedBooking = dclAdvancedBooking;
    }

    /**
     * Determine if DclAdvancedBooking toggle status is ON
     * @returns toggle status value
     */
    isDclAdvancedBooking(): boolean {
        return this.dclAdvancedBooking;
    }

    /**
     * Get the current selected filter ids as string array
     * @returns array with selected ids
     */
    getSelectedFiltersToApply(): string[] {
        const filters = [];

        this.model.forEach((filterData, filterIndex) => {
            if (filterData.type === this.constants.filters['more-filters'].id) {
                (this.selectedFilters[filterIndex] as SelectedFilter[][])
                    .forEach((groupType: SelectedFilter[]) => {
                        groupType.forEach((selectedFilter: SelectedFilter) => {
                            filters.push(selectedFilter.id);
                        });
                    });
            } else {
                (this.selectedFilters[filterIndex] as SelectedFilter[])
                    .forEach((selectedFilter: SelectedFilter) => {
                        filters.push(selectedFilter.id);
                    });
            }
        });

        return filters;
    }

    /**
     * Enables selected status for filters based on actions received as param
     * @param action - array of filter actions that have to be selected
     */
    selectFilters(action: CruiseTileAction[]): void {
        this.getModel().forEach((filter: Filter) => {
            const typeDate = filter.type === this.constants.filters['date'].id;
            const typeMoreFilters = filter.type === this.constants.filters['more-filters'].id;

            if (typeDate || typeMoreFilters) {
                filter.data.forEach((filterData: OptionsGroup | GridConfig) => {
                    if ('options' in filterData) {
                        this.setIsSelected(filterData.options, action);
                    } else {
                        this.setIsSelected(filterData.contentData as GridContent[], action);
                    }
                });
            } else {
                this.setIsSelected(filter.data as ImagePicker[], action);
            }
        });
    }

    /**
     * Delete the special Filter selected
     */
    deleteSpecialFilter(): void {
        localStorage.removeItem(this.constants.localStorage.specialFilters);
        delete this.specialFilter;
    }

    /**
     * Sets selected status
     * @param filterData - data model for a particular filter group
     * @param action - array of actions
     */
    private setIsSelected(filterData: OptionItem[] | ImagePicker[] | GridContent[], action: CruiseTileAction[]): void {
        filterData.forEach((filterItem: OptionItem | ImagePicker | GridContent) => {
            const hasSomeUrlFriendlyId = _.some(action, ['urlFriendlyId', filterItem.urlFriendlyId]);
            const currentSelectedStatus = _.get(filterItem, 'selected') || _.get(filterItem, 'isSelected');

            if ('selected' in filterItem) {
                filterItem.selected = hasSomeUrlFriendlyId || currentSelectedStatus;
            } else {
                filterItem.isSelected = hasSomeUrlFriendlyId || currentSelectedStatus;
            }
        });
    }

    /**
     * Capture filters from the reservation summary session
     * @param reservationSummary the reservation summary session
     * @returns Captured filters as a string
     */
     private captureFiltersFromSession(reservationSummary: ReservationSummary): string {
        const filters = [];

        const sailYearMonthFilter = reservationSummary.sailYearMonth ?
            this.parseUrlFriendlyId([reservationSummary.sailYearMonth,
                QUICK_QUOTE_CONSTANTS.filterTypes.date].join(';')) :
            '';

        if (sailYearMonthFilter) {
            filters.push(sailYearMonthFilter);
        }

        const destinationFilter = reservationSummary.destinationId ?
            this.parseUrlFriendlyId([reservationSummary.destinationId,
                QUICK_QUOTE_CONSTANTS.filterTypes.destination].join(';')) :
            '';

        if (destinationFilter) {
            filters.push(destinationFilter);
        }

        const portFromFilter = reservationSummary.portFromId ?
            this.parseUrlFriendlyId([reservationSummary.portFromId,
                QUICK_QUOTE_CONSTANTS.filterTypes.city].join(';')) :
            '';

        if (portFromFilter) {
            filters.push(portFromFilter);
        }

        return filters.join(',');
    }

    /**
     * Parses the urlFriendlyId from the filter data
     * @param key the filter key
     * @returns the urlFriendlyId
     */
    private parseUrlFriendlyId(key: string): string {
        return this.filtersData[key] && this.filtersData[key].urlFriendlyId;
    }

    /**
     * Returns an observable to know when focus should go to the Quick Quote Bar
     * @returns Observable to subscribe for changes when the Quick Quote Bar should be focused
     */
     getModelChangedObservable(): Observable<undefined> {
        return this.modelChanged$;
    }

    /**
     * Saves the filter in the session storage if the reservation id was provided,
     * otherwise, saves the filter in the cookie service
     * @param reservationId The reservation Id
     * @param value The stringify filters
     */
    private saveFiltersLocally(reservationId: Number, value: string) {
        const storageKey = this.getStorageKey(reservationId);

        if (reservationId) {
            this.sessionStorageService.setItem(storageKey, value);
        } else {
            this.cookieService.set(storageKey, value, null, '/');
        }
    }

    /**
     * Returns the stringify filters from the session storage if the reservation id was provided
     * otherwise, return the stringify filters from the cookie
     * @param reservationId The reservation Id
     */
    private getLocalFilters(reservationId: Number): string {
        const storageKey = this.getStorageKey(reservationId);

        if (reservationId) {
            return this.sessionStorageService.getItem(storageKey);
        }

        return this.cookieService.get(storageKey);
    }

    /**
     * Returns the storage key according to the reservation id
     * @param reservationId The reservation Id
     */
    private getStorageKey(reservationId: Number) {
        if (reservationId) {
            return `${this.constants.cookie.name}-${reservationId}`;
        }

        return this.constants.cookie.name;
    }

    /**
     * Set the value coming from the hompage component
     * @param {boolean} value - The value to be set
     */
    setHomepageToggleValueDclInternationalDirectPhase2(value: boolean): void {
        this.homepageToggleValue = value;
    }
}
