<dcl-side-modal
    #dclSideModal
    class="dcl-side-modal"
    [closeLabel]="closeLabel"
    [disableCloseOnBackground]="isEmbedded"
    [parentSelector]="$any(parentSelector)"
    [hideCloseButton]="true"
    (onCloseModal)="closeSideModal()">
    <div class="side-modal-body-wrapper" dcl-site-modal-body>
        <div class="side-modal-header-wrapper" *ngIf="!isEmbedded">
            <button
                class="close-icon-button"
                [attr.aria-label]="closeLabel"
                (click)="closeSideModal()">
                <i class="close-icon di di-close-reversed di20" aria-hidden="true"></i>
            </button>
            <img
                *ngIf="imageLogo"
                [src]="imageLogo"
                class="logo"
                aria-hidden="true"
                [alt]="logoAlt">
            <div class="header-text">
                {{ headerText }}
            </div>
        </div>
        <dcl-baymax-header
            *ngIf="isEmbedded && isOpenModal"
            title="itinerariesModal.baymaxTitle"
            [isCloseBtn]="false"
            [isModal]="true"
            [parentWithScroll]="parentWithScroll"
            (iconEvent)="closeModalFromBaymax()">
        </dcl-baymax-header>
        <div class="membership-description native-bridge-links"
            [ngClass]="{'modal-with-baymax': isEmbedded}"
            [innerHtml]="bodyText">
        </div>
        <div class="membership-level-table native-bridge-links">
            <dcl-table [data]="tableData" class="advance-booking-table"></dcl-table>
        </div>
        <div class="side-modal-buttons-wrapper">
            <button
                class="explore-itineraries-button"
                (click)="exploreItineraries()">
                <span>{{ buttonLabel }}</span>
            </button>
        </div>
    </div>
</dcl-side-modal>
