import { Component, Input, OnInit } from '@angular/core';

import { StateroomsService } from './services/staterooms.service';

@Component({
    selector: 'dcl-stateroom-attributes',
    templateUrl: './stateroom-attributes.component.html',
    styleUrls: ['./stateroom-attributes.component.scss']
})
export class StateroomAttributesComponent implements OnInit {
    @Input() sailStartDate: string;
    @Input() shipCode: string;
    @Input() stateroomId: string;
    @Input() description: string;
    attributes: Array<object> = [];
    hideAttributes: boolean;

    constructor(
        private stateroomService: StateroomsService
    ) { }

    ngOnInit() {
        this.getStateroomAttributes();
    }

    /**
     * Get Stateroom Attributes from Service
     */
    getStateroomAttributes(): void {
        this.stateroomService.getStateroomAttributes(this.stateroomId, this.shipCode, this.sailStartDate)
            .subscribe(response => {
                this.attributes = this.groupAttributesByType(response['stateroomAttributes']);
                this.hideAttributes = !this.attributes.length;
            }, () => {
                // Error state
                this.hideAttributes = true;
            });
    }

    /**
     * Function to group attributs per attribute type
     * @param attributes
     * @returns Stateroom Attributes grouped
     */
    private groupAttributesByType(attributes = []): Array<object> {
        const temporalAttribute = {};
        const attributesByType = [];

        attributes.forEach((value) => {
            const attributeType = temporalAttribute[value.attributeType];
            const attributeMedia = value.attributeMedia;

            if (attributeType) {
                attributesByType[attributeType.position].attributeName += ', ' + value.attributeName;
            } else {
                temporalAttribute[value.attributeType] = {
                    ...value,
                    position: attributesByType.length
                };

                attributesByType.push({
                    attributeName: value.attributeName,
                    attributeType: value.attributeType,
                    attributeIcon: attributeMedia && attributeMedia.icon && attributeMedia.icon.url,
                    attributeIconAlt: attributeMedia && attributeMedia.icon && attributeMedia.icon.alt
                });
            }
        });

        return attributesByType;
    }
}
