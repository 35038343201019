<div class="contextual-filter" *ngIf="filterOptionGroup && filterOptionGroup.options">
    <span class="contextual-filter__title">{{ filterTitle | translate }}</span>
    <div class="contextual-filter__button-container">
        <wdpr-button
            *ngFor="let filterOptionItem of filterOptionGroup.options; let index = index"
            #contextualFilterButtons
            class="contextual-filter__button"
            theme="secondary"
            [ngClass]="{
                'active': isFilterActive(filterOptionItem),
                'contextual-filter__button--disabled': filterOptionItem.disabled
            }"
            [attr.aria-label]="getAriaLabel(filterOptionItem)"
            (click)="!filterOptionItem.disabled ? onChangeItemState(filterOptionItem) : null"
            (keydown.arrowLeft)="focusPrevButton(index)"
            (keydown.arrowRight)="focusNextButton(index)">
            {{ filterOptionItem.label | translate }}
        </wdpr-button>
    </div>
</div>
