import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ReservationData } from './reservation-data.interface';
import { ReservationFlowService } from '../reservation-flow/reservation-flow.service';
import { ReservationIdService } from '../reservation-id/reservation-id.service';

@Injectable()
export class ReservationDataResolver implements Resolve<ReservationData> {
    constructor(
        private reservationFlowService: ReservationFlowService,
        private reservationIdService: ReservationIdService) {}

    resolve(route: ActivatedRouteSnapshot): ReservationData {
        const reservationId = route.params && route.params['reservation-id'];
        const flow = route.data && route.data['flowValue']
            ? route.data['flowValue']
            : route.params && route.params['flow'];

        const reservationData = {
            reservationId: reservationId,
            flow: flow
        };

        if (reservationData.reservationId) {
            this.reservationIdService.setId(reservationData.reservationId);
        }

        if (reservationData.flow) {
            this.reservationFlowService.setFlow(reservationData.flow);
        }

        return reservationData;
    }
}
