export const CONSTANTS = {
    change: 'reservationSelector.change',
    changeA11y: 'reservationSelector.changeA11y',
    closeMenuA11y: 'reservationSelector.closeMenuA11y',
    reservation: 'reservationSelector.reservation',
    select: 'reservationSelector.select',
    selectA11y: 'reservationSelector.selectA11y',
    selected: 'reservationSelector.selected',
    selectedA11y: 'reservationSelector.selectedA11y',
    stateroom: 'reservationSelector.stateroom',
    title: 'reservationSelector.title',
    unavailable: 'reservationSelector.unavailable',
    unavailableA11y: 'reservationSelector.unavailableA11y'
};
