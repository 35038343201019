import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { LoginService } from '../services/login.service';
import { ViewChildProperties } from '../../interfaces/view-child-properties.interface';
import { WindowRef } from '../../window-ref/window-ref.service';

@Component({
    selector: 'dcl-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class DclLoginComponent implements OnInit, AfterViewInit {
    @ViewChild('form', { static: false } as ViewChildProperties ) form: FormGroupDirective;
    @ViewChild('rememberMeCheckbox', { static: false } as ViewChildProperties ) rememberMeCheckbox: ElementRef;

    isFormErrors: boolean = false;
    isRememberMe: boolean = false;
    isUserPasswordError: boolean = false;
    loginForm: UntypedFormGroup;
    returnUrl: string;
    showSpinner: boolean = false;
    errorMessage: string = 'login.errors.incorrect';

    constructor(
        private dialogRef: MatDialogRef<DclLoginComponent>,
        private fb: UntypedFormBuilder,
        private router: Router,
        private renderer: Renderer2,
        private loginService: LoginService,
        private windowRef: WindowRef
    ) {
        this.returnUrl = this.router.url || '';
    }

    ngOnInit() {
        this.initForm();
    }

    ngAfterViewInit() {
        if (this.rememberMeCheckbox) {
            this.renderer.listen(this.rememberMeCheckbox.nativeElement, 'checked-changed', (event) => {
                this.isRememberMe = event.detail.value;
            });
        }
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    /**
     * We implemented this functionality to call the submit because using polymer components the submit doesn't work
     * and in order to do autocompletion on the inputs there must be submittion events.
     */
    callSubmit(): void {
        this.form.ngSubmit.emit();
    }

    initForm(): void {
        const user = this.loginService.getUserLocalStorage();
        this.isRememberMe = !!user;
        this.setForm(user);
    }

    navigate(url: string): void {
        this.windowRef.nativeWindow.location.href = `${url}/?returnUrl=${this.returnUrl}/&cancelUrl=${this.returnUrl}/`;
    }

    setForm(user: string): void {
        this.loginForm = this.fb.group({
            email: [
                { value: user || '', disabled: false }, [Validators.required]
            ],
            password: ['', [Validators.required]]
        });
    }

    /**
     * Submit login modal data
     * @param {Object} value
     */
    submit({ value }): void {
        this.showSpinner = true;
        this.isUserPasswordError = false;

        if (this.loginForm.invalid) {
            this.showSpinner = false;
            this.isFormErrors = true;

            return;
        }

        if (this.isRememberMe) {
            this.loginService.setUserLocalStorage(value.email);
        }

        this.loginService.login(value.email, value.password, this.isRememberMe)
            .pipe(
                finalize(() => this.showSpinner = false)
            )
            .subscribe((resp) => {
                this.handleLoginResponse(resp);
            }, (err) => {
                this.setErrorMessage(err);
            });
    }

    /**
     * Close the modal if the guest is signed in and close modal
     * @param resp login response
     */
    handleLoginResponse(resp): void {
        const forwardTnC = resp.headers.get('ForwardTnC');
        const forward = resp.headers.get('Forward');
        let redirectTo;
        let forwardHeaders;

        if (forwardTnC !== null && forwardTnC !== '/') {
            // Login ok and a guest account is complete,
            // but guest needs to accept T&C.
            // Use ForwardTnC response header to redirect
            // to Lamp stack Accept Terms and Conditions page.
            redirectTo = forwardTnC;
        } else if (forward !== null && forward !== '/') {
            // Login ok, but a guest account is incomplete.
            // Use Forward response header to redirect
            // to Lamp stack Incremental Reg page.
            forwardHeaders = forward.split(',');
            // When incremental reg url is set, the value will be
            // "/registration/index/swid/{swid}/, /".
            // We are only interested in the first value.
            redirectTo = forwardHeaders[0];
        }

        if (redirectTo) {
            this.navigate(`/registration/'${redirectTo}`);
        } else {
            // Only close the modal when a guest is signed in,
            // guest account is complete, and guest has accepted the legal terms.
            this.dialogRef.close(true);
        }
    }

    setErrorMessage(err): void {
        const code = 401;

        this.errorMessage = 'login.errors.generic-error';

        if (err.status === code) {
            if (err.error &&
                err.error.reason &&
                err.error.reason === 'accountLockedOut') {
                this.errorMessage = 'login.errors.too-many-attempts-error';
            } else {
                this.errorMessage = 'login.errors.authentication-error';
            }
        }

        this.isUserPasswordError = true;
    }

    /**
     * Check when an input is valid/invalid
     * @param {string} fieldName field to be validated
     * @returns {boolean} field state (valid/invalid)
     */
    isInvalid(fieldName: string): boolean {
        return this.isFormErrors && this.loginForm.get(fieldName).invalid;
    }
}
