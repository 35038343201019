import { Component, Input, OnInit } from '@angular/core';

import { CruisePricing } from '../interfaces/cruise-pricing.interface';
import { CRUISE_PRICING_CONSTANTS as CONSTANTS } from './cruise-pricing.constants';

@Component({
    selector: 'dcl-cruise-pricing',
    templateUrl: './cruise-pricing.component.html'
})

/**
 * Prepares the Cruise Pricing information inside a "wrapper" to use it on a DCL Component.
 * The information used needs to be separated because they came from different sources.
 * For now there is no error handling, because it's requested in the ticket DCLCOMCLRE-24
 *
 * IMPORTANT NOTE: This new feature is implemented in this way
 * because in the future, the project will use 'internationalization'
 * COMPS LINK: http://ultron.wdproux.wdig.com/dcl/internationalization/#artboard39
 */
export class CruisePricingComponent implements OnInit {
    @Input() pricingData?: CruisePricing = {};
    @Input() localeFromGeolocation?: string;
    @Input() formatWithDecimal?: boolean;

    hideDecimals: boolean = true;

    ngOnInit() {
        this.initializePricingData();
    }

    /**
     * Checks the incoming information to intitialize it; if it's NULL, UNDEFINED or EMPTY, it will set default values.
     * Otherwise, it will set the incoming value.
     */
    initializePricingData(): void {
        this.hideDecimals = !this.formatWithDecimal;
        this.pricingData.currency = this.pricingData.currency || CONSTANTS.DEFAULT_CURRENCY;
        this.pricingData.total = this.pricingData.total || CONSTANTS.DEFAULT_TOTAL;
        this.localeFromGeolocation = this.localeFromGeolocation || CONSTANTS.DEFAULT_LOCALE;
    }
}
