import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigService } from '../../services/config/config.service';
import { TravelAgentService } from '../travel-agent.service';
import { TASessionTimeOutService } from '../session-timeout/session-timeout.service';
import { TRAVEL_AGENT_CONSTANTS } from '../travel-agent.constants';

@Injectable()
export class TravelAgentInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) { }

    intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
        const travelAgentService = this.injector.get(TravelAgentService);
        const configService = this.injector.get(ConfigService);
        const sessionTimeOutService = this.injector.get(TASessionTimeOutService);
        const isTravelAgentLoggedIn = travelAgentService.isTravelAgentLoggedIn();

        req = req.clone({
            withCredentials: true
        });

        if (isTravelAgentLoggedIn &&
            !req.url.match(TRAVEL_AGENT_CONSTANTS.FILTERED_REQUESTS_TIMEOUT) &&
            !req.url.match(configService.getValue('filteredRequestTimeout')) &&
            req.url.match(configService.getValue('travelAgentFilter'))
        ) {
            sessionTimeOutService.isSessionTimeOut();
        }

        return next.handle(req);
    }
}

export const TravelAgentHttpInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: TravelAgentInterceptor,
    multi: true,
};
