import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectionModule } from '@wdpr/ra-angular-device-detection';
import { GeolocationModule } from '@wdpr/ra-angular-geolocation';

import { CartRestService } from '../cart/rest/cart-rest.service';
import { CartService } from '../cart/cart.service';
import { GENESYS_CHAT_CONFIG, GenesysChatModuleConfig } from './interfaces';
import { GenesysChatFactory, GenesysChatService, GenesysUserDataService } from './services';
import { GenesysWebChatComponent } from './components';
import { WindowRef } from '../window-ref/window-ref.service';

@NgModule({
    imports: [
        CommonModule,
        DeviceDetectionModule,
        GeolocationModule.configure()
    ],
    declarations: [
        GenesysWebChatComponent
    ],
    exports: [
        GenesysWebChatComponent
    ]
})
export class GenesysWebChatModule {
    // For Angular 9 migration https://angular.io/guide/migration-module-with-providers
    static forRoot(config: GenesysChatModuleConfig): ModuleWithProviders<GenesysWebChatModule> {
        return {
            ngModule: GenesysWebChatModule,
            providers: [
                CartService,
                CartRestService,
                CookieService,
                GenesysUserDataService,
                WindowRef,
                {
                    provide: GenesysChatService,
                    useFactory: GenesysChatFactory,
                    deps: [
                        GENESYS_CHAT_CONFIG, WindowRef,
                    ]
                },
                config.configProvider
            ]
        };
    }
}
