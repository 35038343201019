import { Component, Input } from '@angular/core';

import { WhatsIncludedAnalyticsKeys } from '../../interfaces/whats-included-analytics-keys.interface';
import { WhatsIncludedModalService } from '../../services/whats-included-modal.service';

@Component({
    selector: 'dcl-whats-included-modal-button',
    templateUrl: './whats-included-modal-button.component.html',
    styleUrls: ['./whats-included-modal-button.component.scss']
})
export class WhatsIncludedModalButtonComponent {
    @Input() analyticsCustomKeys: WhatsIncludedAnalyticsKeys;

    constructor(private whatsIncludedModalService: WhatsIncludedModalService) {}

    /**
     * Handle event when button is clicked
     */
    onClick(): void {
        this.whatsIncludedModalService.setAnalyticsCustomKeys(this.analyticsCustomKeys);
        this.whatsIncludedModalService.openModal();
    }
}
