<div class="dcl-site-modal-wrapper">
    <dcl-modal
        #sideModal
        docker
        block-focus
        hide-title-body-separator
        hide-buttons-separator
        disable-tab-index-on-slot-buttons
        [attr.aria-close]="closeLabel"
        [attr.disable-close-on-background]="disableCloseOnBackground || null"
        [attr.hide-close-icon]="hideCloseButton ? '' : null"
        [attr.id]="disableAnimation ? '' : 'docker-modal'"
        [ngClass]="modalCssClass"
        (closedModalByCloseIcon)="closeModal(false, true)"
        (closedOnBackgroundClick)="checkScroll()"
        (closedOnEscape)="closedModalOnEscape()">
        <div slot="title">
            <ng-content select="[dcl-site-modal-header]"></ng-content>
        </div>
        <div class="side-modal-body" slot="body">
            <ng-content select="[dcl-site-modal-body]"></ng-content>
        </div>
        <div slot="buttons">
            <ng-content select="[dcl-site-modal-buttons]"></ng-content>
        </div>
    </dcl-modal>
</div>
