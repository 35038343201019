import { Pipe, PipeTransform } from '@angular/core';

import * as momentModule from 'moment';

import {
    CustomDateFormat,
    DaysOfWeek,
    IntlDateConfig,
    Modules,
    Months,
    MonthsAbbreviated
} from './interfaces/intl-date-format.index';
import { DATE_FORMAT_INTL_CONSTANTS } from './date-format-intl.constants';

@Pipe({
    name: 'DateFormatterIntl'
})
export class DatesFormatIntlPipe implements PipeTransform {
    constants = DATE_FORMAT_INTL_CONSTANTS;
    customDisplayDiffMonth: string;
    customDisplayDiffYear: string;
    customDisplaySameMonth: string;
    customDisplaySingleDateFormat: string;
    customEndDateDiffMonth: string;
    customEndDateSameMonth: string;
    customFullDate: string;
    customMonths: string[];
    customMonthsAbbreviated: string[];
    customStartDateDiffMonth: string;
    customStartDateSameMonth: string;
    customWeeks: string[];
    endDate: momentModule.Moment;
    locale: string;
    moment = momentModule;
    separator: string;
    startDate: momentModule.Moment;

    public transform(dateFormatData: IntlDateConfig): string {
        // for single date the pipe needs to receveive the data in format ['2022-06-08', '']
        return this.setUpIntlDateFormat(dateFormatData);
    }

    /**
     * Sets the template for international dates
     * @param dateFormatData date format configuration
     * @returns formatted date
     */
    private setUpIntlDateFormat(dateFormatData: IntlDateConfig): string {
        try {
            const modules = dateFormatData.template.modules[0] as Modules;

            this.locale = dateFormatData.locale;
            this.setLocalConfig(modules, modules.sections as CustomDateFormat);
            this.setUpLocale(dateFormatData.dates);

            if (dateFormatData.dates[0] && dateFormatData.dates[1]) {
                return this.fill2DatesTemplate(dateFormatData.showDates, dateFormatData.showDays);
            }

            return this.fillSingleDateTemplate();
        } catch (error) {
            return this.setUpDefaultDateFormat(dateFormatData);
        }
    }

    /**
     * Sets the local values for date format configuration
     * @param customModules D-Scribe object
     * @param customDateFormat custom properties for date format
     */
    private setLocalConfig(customModules: Modules, customDateFormat: CustomDateFormat): void {
        this.separator = customDateFormat.separator;
        this.customDisplayDiffMonth = customDateFormat.customDisplayDiffMonth;
        this.customDisplayDiffYear = customDateFormat.customDisplayDiffYear;
        this.customDisplaySameMonth = customDateFormat.customDisplaySameMonth;
        this.customDisplaySingleDateFormat = customDateFormat.customDisplaySingleDateFormat;
        this.customEndDateDiffMonth = customDateFormat.endDateDiffMonth;
        this.customEndDateSameMonth = customDateFormat.endDateSameMonth;
        this.customFullDate = customDateFormat.formatDateDiffYear;
        this.customStartDateDiffMonth = customDateFormat.startDateDiffMonth;
        this.customStartDateSameMonth = customDateFormat.startDateSameMonth;
        // D-Scribe response always has the same format, so this positions will be the same no matter what language
        this.customMonths = Object.values(customModules.modules[0].sections as Months);
        this.customMonthsAbbreviated = Object.values(
            customModules.modules[1].sections as MonthsAbbreviated
        );
        this.customWeeks = Object.values(customModules.modules[2].sections as DaysOfWeek);
    }

    /**
     * Sets the date format to be default language 'en'
     * @param dateFormatData date format configuration
     * @returns formatted date
     */
    private setUpDefaultDateFormat(dateFormatData: IntlDateConfig): string {
        this.locale = this.constants.defaultLocale;

        if (dateFormatData && dateFormatData.dates && dateFormatData.showDates && dateFormatData.showDays) {
            this.setUpLocale(dateFormatData.dates);

            return this.fillDateDefaultTemplate(dateFormatData.showDates, dateFormatData.showDays);
        }
    }

    /**
     * Sets the locale value that will be used in the formatter
     * @param dates array with startDate and endDate values
     */
     setUpLocale(dates: [string, string]): void {
        const [startDate, endDate] = dates;

        if (this.customMonths && this.customMonthsAbbreviated && this.customWeeks) {
            this.moment.locale(this.locale, {
                months : this.customMonths,
                weekdays : this.customWeeks,
                monthsShort: this.customMonthsAbbreviated
            });
        } else {
            this.moment.locale(this.locale);
        }

        this.startDate = this.moment(startDate, this.constants.defaultFormat);
        this.endDate = this.moment(endDate, this.constants.defaultFormat);
    }

    /**
     * Creates a string value that contains the concatenated dates
     * @param showDates flag to concatenate dates
     * @param showDays flag to concatenate days
     * @returns concatenated string with selected format
     */
    private fill2DatesTemplate(showDates: boolean, showDays: boolean): string {
        const concatenatedDate = `${this.startDate.format('dddd')} ${this.separator} ${this.endDate.format('dddd')}`;
        let formattedDate = '';

        if (showDates) {
           if (this.startDate.year() === this.endDate.year()) {
                formattedDate += this.getDateFormatSameYear();
           } else {
                formattedDate += this.getDateForDiffYear();
           }
        }

        if (showDays) {
            formattedDate += formattedDate ?
                `, ${ concatenatedDate }` :
                concatenatedDate;
        }

        return formattedDate;
    }

    /**
     * Concatenates the formatted date as string
     * @returns concatenated dates
     */
    fillSingleDateTemplate(): string {
        return this.customDisplaySingleDateFormat
            .replace('{dayOfWeek}', this.startDate.format('dddd'))
            .replace('{day}', this.startDate.format('D'))
            .replace('{month}', this.startDate.format('MMMM'))
            .replace('{year}', this.startDate.format('YYYY'));
    }

    /**
     * Creates a string value with the default format for the provided dates
     * @param showDates flag to concatenate dates
     * @param showDays flag to concatenate days
     * @returns string with concatenated default dates
     */
    private fillDateDefaultTemplate(showDates: boolean, showDays: boolean): string {
        const yearsMatch = this.moment(this.startDate).format('YYYY') === this.moment(this.endDate).format('YYYY');
        const monthsMatch = this.moment(this.startDate).format('M') === this.moment(this.endDate).format('M');
        let formattedDate = '';

        if (showDates) {
            if (yearsMatch) {
                formattedDate = `${this.startDate.format('MMMM D')} - `;
                formattedDate += monthsMatch ?
                    this.endDate.format('D, YYYY') :
                    this.endDate.format('MMMM D, YYYY');
            } else {
                formattedDate = `${this.startDate.format('MMMM D, YYYY')} - ${this.endDate.format('MMMM D, YYYY')}`;
            }
        }

        if (showDays) {
            const separator = ', ';
            const formattedDay = `${this.startDate.format('dddd')} - ${this.endDate.format('dddd')}`;
            formattedDate += showDates ?
                `${separator}${formattedDay}` :
                formattedDay;
        }

        return formattedDate;
    }

    /**
     * Selects which of the formats will be used checking if the date belong to same month
     * @returns string value that contains concatenated dates
     */
    private getDateFormatSameYear(): string {
        const isSameMonth = this.startDate.month() === this.endDate.month();

        return isSameMonth ? this.getDateForSameMonth() : this.getDateForDiffMonth();
    }

    /**
     * Creates a string value filling all the fields in the template when the dates belongs to same month
     * @returns string value that contains concatenated dates
     */
    private getDateForSameMonth(): string {
        let formattedDate: string;

        if (this.customDisplaySameMonth) {
            formattedDate = this.customDisplaySameMonth
                .replace('{startDate}', this.startDate.format('D'))
                .replace('{separator}', this.separator)
                .replace('{endDate}', this.endDate.format('D'))
                .replace('{month}', this.endDate.format('MMMM'))
                .replace('{year}', this.endDate.format('YYYY'));
        } else {
            const formattedStartDate = this.startDate.format(this.customStartDateSameMonth);
            const formattedEndDate = this.endDate.format(this.customEndDateSameMonth);

            formattedDate = `${ formattedStartDate } ${this.separator} ${ formattedEndDate }`;
        }

        return formattedDate;
    }

    /**
     * Creates a string value filling all the fields in the template when the dates belongs to different months
     * @returns string value that contains concatenated dates
     */
    private getDateForDiffMonth(): string {
        let formattedDate: string;

        if (this.customDisplayDiffMonth) {
            formattedDate = this.customDisplayDiffMonth
                .replace('{startDate}', this.startDate.format('D'))
                .replace('{startMonth}', this.startDate.format('MMMM'))
                .replace('{separator}', this.separator)
                .replace('{endDate}', this.endDate.format('D'))
                .replace('{endMonth}', this.endDate.format('MMMM'))
                .replace('{year}', this.endDate.format('YYYY'));
        } else {
            const formattedStartDate = this.startDate.format(this.customStartDateDiffMonth);
            const formattedEndDate = this.endDate.format(this.customEndDateDiffMonth);

            formattedDate = `${ formattedStartDate } ${this.separator} ${ formattedEndDate }`;
        }

        return formattedDate;
    }

    /**
     * Creates a string value filling all the fields in the template when the dates belongs to different year
     * @returns string value that contains concatenated dates
     */
    private getDateForDiffYear(): string {
        let formattedDate: string;

        if (this.customDisplayDiffYear) {
            formattedDate = this.customDisplayDiffYear
                .replace('{startDate}', this.startDate.format('D'))
                .replace('{startMonth}', this.startDate.format('MMMM'))
                .replace('{startYear}', this.startDate.format('YYYY'))
                .replace('{separator}', this.separator)
                .replace('{endDate}', this.endDate.format('D'))
                .replace('{endMonth}', this.endDate.format('MMMM'))
                .replace('{endYear}', this.endDate.format('YYYY'));
        } else {
            const formattedStartDate = this.startDate.format(this.customFullDate);
            const formattedEndDate = this.endDate.format(this.customFullDate);

            formattedDate = `${ formattedStartDate } ${this.separator} ${ formattedEndDate }`;
        }

        return formattedDate;
    }
}
