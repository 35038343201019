import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'dcl-header-logo',
    templateUrl: './header-logo.component.html',
    styleUrls: ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnInit {
    @Input() baseUrl: string;
    @Output() onRedirect = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    redirectPage(): void {
        this.onRedirect.emit();

        if (this.baseUrl) {
            window.location.href = this.baseUrl;
        }
    }
}
