import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GeolocationService } from '../geolocation/geolocation.service';
// DO NOT REMOVE [AnalyticsService] - It is necessary for the framework to load
import { AnalyticsService, ConfigService as AnalyticsConfig } from '@wdpr/angular-analytics';
// DO NOT REMOVE [AnalyticsService] - It is necessary for the framework to load

import { ConfigService } from '@app/core/config.service';
import { TrackLink } from '@interfaces/track-link';
import { getLangFromOrigin } from '@app/shared/utils/utils.string';
import { Languages, Brands  } from '@app/shared/utils/utils.enum';

@Injectable()
export class HomepageAnalyticsService {
    pageKey = false;
    scheduledAnalyticsTrackLinks: TrackLink[] = [];
    private siteId = 'dcl';
    private environment = 'prod';
    private affiliationsJar = 'AFFILIATIONS_jar';

    constructor(
        private analyticsConfig: AnalyticsConfig,
        private analyticsService: AnalyticsService,
        private configService: ConfigService,
        private httpClient: HttpClient,
        private cookieService: CookieService,
        private geolocationService: GeolocationService
    ) {
        this.siteId = this.configService.getValue('siteId') || 'dcl';
        this.environment = this.configService.getValue('environment') || 'prod';
        this.customLoader();
        this.configureLangLocale();
    }

    initAnalytics() {
        this.analyticsService.getModel().then((model: any) => {
            this.analyticsService.trackPage(model);
        });
    }

    p13nLoadGuestData() {
        var promise = new Promise((resolve, reject) => {
            this.geolocationService.getGeoCode().subscribe((geo) => {
                let affiliations = window.sessionStorage[this.affiliationsJar];
                let affiliationsParsed = {};
                if (affiliations) {
                    affiliationsParsed = JSON.parse(affiliations);
                }
                resolve({
                    configuration: {
                        contentLocale: 'us',
                        contentLanguage: 'en'
                    },
                    customerId: this.getSwid(),
                    geo: geo,
                    guestAffiliations: (affiliationsParsed['wdw'] || {}).storedAffiliations || [],
                    site: 'wdw',
                    pageId: 'home',
                    siteSection: 'content'
                });
            });
        });

        return promise;
    }

    private customLoader() {
        // make changes to analytics configuration here
        let lang = '';
        const langFromOrigin = getLangFromOrigin();
        if (langFromOrigin === Languages.EU || langFromOrigin === Languages.UK) {
            lang = langFromOrigin + '.';
        }
        const baseUrl = `wdpr-web-homepage/analytics/${this.siteId}.site.`;
        const analyticsUrl = (this.siteId === Brands.HKDL || this.siteId === Brands.DCL) ?
            `${baseUrl}homepage.${this.environment}.json` :
            `${baseUrl}${lang}homepage.json`;

        this.analyticsConfig.loader = () => this.httpClient.get(analyticsUrl).toPromise();
    }

    private configureLangLocale() {
        this.analyticsConfig.addGlobalProcessors({
          contentLocale: () => this.getLocale(),
          contentLanguage: () => this.getLanguage()
        });
    }

    private getLocaleCookie() {
        try {
            return JSON.parse(this.cookieService.get('localeCookie_jar_aka'));
        } catch (ignore) { }

        return {};
    }

    private getSwid() {
        var swid = null;
        try {
            var cookieValue = this.cookieService.get('isLoggedIn');
            if (cookieValue) {
                const status = JSON.parse(this.cookieService.get('isLoggedIn')),
                    isLoggedIn = status.isLoggedIn || false,
                    isSecure = status.isSecure || false;
                swid = (isLoggedIn && isSecure && this.cookieService.get('SWID')) ?
                    this.cookieService.get('SWID') :
                    null;
            }
        } catch (e) {
            console.log('Analytics SWID error: ', e);
        }

        return swid;
    }

    private isLoggedInSecure() {
        var swid = this.getSwid();

        return swid !== null ? true : false;
    }

    private getLangLocale() {
        const cookieValue = this.getLocaleCookie();
        const value = cookieValue && cookieValue.contentLocale;

        if (value && typeof value === 'string') {
            if (value.length === 5 && value[2] === '_') {
                return value;
            } else if (value.length === 2) {
                return value + '_XX';
            }
        }
    }

    private getLocale() {
        const langLocale = this.getLangLocale();
        if (langLocale) {
            return langLocale.substr(3);
        }
    }

    private getLanguage() {
        const langLocale = this.getLangLocale();
        if (langLocale) {
            return langLocale.substr(0, 2);
        }
    }

    storyAnalytics(storyBlock) {
        var before: any = window.localStorage.getItem('analyticslist') ? window.localStorage.getItem('analyticslist') + ',' + storyBlock : window.localStorage.setItem('analyticslist', storyBlock);
        window.localStorage.setItem('analyticslist', before);
        var list;
        if (before) {
            list = before.split(',');
            if (list[0] === 'undefined') {
                list.shift();
            }
        }

        onbeforeunload = (event) => {
            window.localStorage.setItem('analyticslist', '');
            this.analyticsService.trackElement({
                linkId: 'SeenModulesList',
                contentList: list
            });
        };
    }

    /**
     * Get the model, set the link ID, and send the model back for tracking
     * @param {string} linkId linkId to track
     * @param {object} modelProps Additional props to send in the trackLink call
     */
    trackLink(linkId: string, modelProps?: object): void {
        this.analyticsService.trackLink(Object.assign({}, modelProps, { linkId }));
        this.storyAnalytics(linkId);
    }
}
