import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ErrorMessageComponent } from './error-message.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        ErrorMessageComponent
    ],
    exports: [
        ErrorMessageComponent
    ]
})
export class ErrorMessageModule { }
