<dcl-loading-spinner
    *ngIf="!cruiseTiles"
    class="cruise-tiles-spinner">
</dcl-loading-spinner>

<div *ngIf="cruiseTiles?.tiles?.length > 0" class="cruise-tiles">
    <div class="cruise-tiles-division" aria-hidden="true" *ngIf="!isHomePage">
        <hr class="cruise-tiles-division__line">
        <span class="di di-dcl-our-ships cruise-tiles-division__icon"></span>
        <hr class="cruise-tiles-division__line">
    </div>
    <h2 *ngIf="cruiseTiles.title" class="cruise-tiles-title">
        {{ cruiseTiles.title }}
    </h2>
    <div class="cruise-tiles-cards">
        <dcl-carousel dclBreakPointDetection [dclBreakpoints]="[breakpoints.medium]">
            <dcl-carousel-item
                *ngFor="let item of cruiseTiles.tiles; let index = index; let last = last"
                [hasUniqueItem]="cruiseTiles?.tiles?.length === 1"
                [lastItem]="last"
                [equalHeight]="true"
                role="listitem">
                <dcl-cruise-tile
                    class="cruise-tiles-cards__tile"
                    [a11yDescription]="constants.a11y.tileDescription | translate: parseTranslateOptions(item, index)"
                    [customImgPlaceholder]="customImgPlaceholder"
                    [description]="item.description"
                    [isHomePage]="isHomePage"
                    [media]="isMobile ? item.tileImage.mobile : item.tileImage.desktop"
                    [ngClass]="{'has-unique-item': cruiseTiles?.tiles?.length === 1 }"
                    [title]="item.text"
                    (click)="onTileSelected(item)"
                    (dclBreakpointChange)="onBreakPointChange($any($event))">
                </dcl-cruise-tile>
            </dcl-carousel-item>
        </dcl-carousel>
    </div>
    <ng-container *ngIf="isHomePage; then footer else division"></ng-container>
    <ng-template #footer>
        <div class="cruise-tiles-footer">
                <wdpr-button
                    (click)="clearFiltersOnRedirect()"
                    theme="secondary">
                    {{ cruiseName }}
                </wdpr-button>
        </div>
    </ng-template>
    <ng-template #division>
        <hr class="cruise-tiles-division__line cruise-tiles__end-line" aria-hidden="true">
    </ng-template>
</div>
