import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DclSafeScript } from './interfaces/dcl-safe-script';

@Component({
    selector: 'dcl-script-loader',
    template: ''
})
export class ScriptLoaderComponent implements OnInit {
    @Input() src: string;
    trustScript: DclSafeScript;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        if (this.src) {
            this.trustScript = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
            this.loadScript(this.trustScript);
        }
    }

    /**
     * @description This function loads a javascript scr with files for the syndicated header.sss
     * @param src Url with the javascript url to load.
     */
    loadScript(src: DclSafeScript): void {
        const script = this.renderer.createElement('script');

        script.type = 'text/javascript';
        script.src = src.changingThisBreaksApplicationSecurity;
        this.renderer.appendChild(this.elementRef.nativeElement, script);
    }
}
