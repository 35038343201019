import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { GuestCastawayInfo } from '../interfaces/guest-castaway-info.interface';
import { TravelAgentService } from '../travel-agent/travel-agent.service';

@Component({
    selector: 'dcl-guest-castaway-info',
    templateUrl: './guest-castaway-info.component.html',
    styleUrls: ['./guest-castaway-info.component.scss']
})
export class DclGuestCastawayInfoComponent implements OnInit {
    @Output() dataDelete: EventEmitter<null> = new EventEmitter<null>();
    @Input() dataChange: EventEmitter<null>;
    @Input() castawayData: GuestCastawayInfo;
    @Input() hideDeleteBtn: boolean;

    castawayInfo: GuestCastawayInfo;
    castawayIdSubstring: string;
    fullName: string;
    hideIdNumber: boolean;

    constructor(private travelAgentService: TravelAgentService) {}

    ngOnInit() {
        this.setData();
        this.dataChange.subscribe(() => this.setData());
    }

    /**
     * Mehtod to set guest castaway data
     */
    setData(): void {
        const substrStart = -4;

        this.castawayInfo = this.castawayData || this.travelAgentService.getGuestCastawayData();
        this.hideIdNumber = this.travelAgentService.hideIdNumber(this.castawayInfo);
        this.fullName = this.castawayInfo ? `${this.castawayInfo.firstName} ${this.castawayInfo.lastName}`.trim() : '';
        this.castawayIdSubstring = this.castawayInfo &&
            this.castawayInfo.castawayId &&
            this.castawayInfo.castawayId.toString().substr(substrStart);
    }

    /**
     * Method to delete guest castaway data
     */
    deleteCastawayData(): void {
        this.travelAgentService.deleteGuestCastawayData();
        this.castawayInfo = null;
        this.dataDelete.emit();
    }
}
