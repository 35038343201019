import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AutofocusModule } from './../autofocus/autofocus.module';
import { DclLoginComponent } from './login/login.component';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { LoadingContentModule } from '../loading-content/loading-content.module';
import { LoginModalService } from './services/login-modal.service';
import { LoginService } from './services/login.service';
import { WarningMessagingModule } from '../warning-messaging/warning-messaging.module';
import { WindowRef } from './../window-ref/window-ref.service';

@NgModule({
    declarations: [
        DclLoginComponent
    ],
    imports: [
        AutofocusModule,
        CommonModule,
        ErrorMessageModule,
        LoadingContentModule,
        ReactiveFormsModule,
        TranslateModule,
        WarningMessagingModule
    ],
    providers: [
        CookieService,
        LoginModalService,
        LoginService,
        WindowRef
    ],
    exports: [
        DclLoginComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class LoginModalModule { }
