import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { DCLAnalyticsService } from '../services/analytics/analytics.service';
import { ReservationSummary } from './reservation-summary.interface';
import { RESERVATION_SUMMARY_CONSTANTS } from './reservation-summary-bar.constants';

@Component({
    selector: 'dcl-reservation-summary-bar',
    templateUrl: './reservation-summary-bar.component.html',
    styleUrls: ['./reservation-summary-bar.component.scss'],
})
export class ReservationSummaryBarComponent implements OnInit {
    @Input() showSailingInformation ?= false;
    @Input() isDclShipAdaPhase2 ?= false;
    @Input() reservationSummary: ReservationSummary;

    @Output() back = new EventEmitter();

    constants = RESERVATION_SUMMARY_CONSTANTS;
    isGTY = false;
    isCommunicative: boolean;
    locationKey: string;

    constructor(private analyticsService: DCLAnalyticsService) {}

    ngOnInit() {
        this.isGTY = (this.reservationSummary.stateroomId === this.constants.GTY);
        this.locationKey = this.getLocationKey();
        this.isCommunicative = this.isDclShipAdaPhase2 &&
            (this.reservationSummary.reserveType === this.constants.COMMUNICATIVE_RESERVE_TYPE);
    }

    /*
    * Go back to the modify reservation page
    */
    goBack() {
        this.back.emit(null);

        this.analyticsService.trackLink(this.constants.ANALYTICS.BACK_BUTTON_LINK);
    }

    /**
     * Get translation key for location
     * @returns {string}
     */
    private getLocationKey(): string {
        let key = '';
        const location = this.reservationSummary.location && this.reservationSummary.location.toLowerCase();

        if (!!location) {
            key = this.constants.TRANSLATE_KEY_LOCATION + location;
        }

        return key;
    }
}
