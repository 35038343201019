import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as moment from 'moment';
import { AnalyticsService } from '@wdpr/angular-analytics';

import { AnalyticsModel } from '@app/services/analytics/interfaces/analytics-model.interface';
import {
    Attribute,
    EventAttribute,
    PersonalizationLocation,
    PersonalizedContentRequest
} from '@app/services/personalization/interfaces/personalized-content-request.interface';
import { WindowRef } from '@app/shared/services/window-ref/window-ref.service';
import { LocaleService } from '@app/services/locale/locale.service';
import { CookieService } from 'ngx-cookie-service';
import { StoriesSelectorEnum } from '@app/services/personalization/enums/stories-selector.enum';

@Injectable({
    providedIn: 'root'
})
export class PersonalizationService {
    private readonly PERSONALIZATION_JAR = 'personalization_jar';
    private readonly localeCookieJar = 'localeCookie_jar';
    private geolocationCookieData: any;

    constructor(
        private analyticsService: AnalyticsService,
        private http: HttpClient,
        private windowRef: WindowRef,
        private localeService: LocaleService,
        private cookieService: CookieService
    ) {}

    /**
     * Fetches personalized content based on the analytics model.
     *
     * This method first retrieves the analytics model and updates the analytics service.
     * It then constructs the parameters for the personalized content request, including
     * locations, events, and other necessary data.
     *
     * @returns An observable containing the personalized content.
     * TODO - Add return type (GIT-55662)
     */
    public getPersonalizedContent(): Observable<any> {
        return from(this.analyticsService.getModel())
            .pipe(
                map((model: AnalyticsModel) => {
                    // after getModel() we need to update analytics service
                    this.analyticsService.update();

                    const personalizationCookie = this.getPersonalizationJarCookie();

                    let params: PersonalizedContentRequest = {
                        forceDecisions: true,
                        locations: this.getLocations(model?.personalizations),
                        checkUpsellContent: false,
                        personalizationId: personalizationCookie.id,
                        channel: 'web',
                        events: this.getPersonalizationEvents(model)
                    };

                    return { model, params };
                }),
                switchMap((data: { model: AnalyticsModel, params: PersonalizedContentRequest }) => {
                    return this.http
                        .post(
                            '/wam/recommendation-service/personalized-content',
                            data.params
                        )
                        .pipe(
                            map((response: any) => {
                                return response;
                            })
                        );
                }),
                catchError((error) => {
                    console.error('Error fetching analytics model: ', error);

                    return of([]);
                })
            );
    }


    /**
     * Filters and maps the provided locations to create an array of `PersonalizationLocation` objects.
     *
     * This method filters the input locations to include only those that match specific criteria
     *
     * @param locations - The array of locations to be filtered and mapped.
     * @returns An array of `PersonalizationLocation` objects.
     */
    private getLocations(locations): PersonalizationLocation[] {
        return locations
            ?.filter((personalization) =>
                personalization?.selector?.includes(StoriesSelectorEnum.STORY_BLOCK_GROUP) ||
                personalization?.selector?.includes(StoriesSelectorEnum.STORY_BLOCK))
            .map((personalization) => ({
                id: personalization.location,
                shouldFetchContent: true
            }));
    }

    private getPersonalizationJarCookie(): any {
        const personalizationCookie = this.cookieService.get(this.PERSONALIZATION_JAR);
        let cookieData = { id: '' };

        if (personalizationCookie) {
            try {
                cookieData = JSON.parse(decodeURIComponent(personalizationCookie));
            } catch (e) {
                console.error(e);
            }
        }

        return cookieData;
    }

    public getMoment() {
        return moment;
    }

    /**
     * Generates personalization events based on the provided analytics model.
     *
     * @param model - The analytics model used to generate the events.
     * @returns An array of personalization events.
     */
    private getPersonalizationEvents(model: AnalyticsModel): EventAttribute[] {
        const utcMoment = this.getMoment().utc();

        return [
            {
                datetime: utcMoment.format(),
                eventType: 'd-decision-request',
                attributes: this.getPersonalizationEventsAttributes(model)
            }
        ];
    }

    private getPersonalizationEventsAttributes(model: AnalyticsModel): Attribute[] {
        const attributes: Attribute[] = [
            {
                name: 'channel',
                value: 'web'
            },
            {
                name: 'store-type',
                value: 'Consumer'
            }
        ];

        attributes.push(this.getUserAgent());

        attributes.push(this.getPageUrl());

        attributes.push(this.getGuestAffiliations());

        if (model.siteSection) {
            attributes.push(this.getSiteSection(model));
        }

        if (model.guestType) {
            attributes.push(this.getGuestType(model));
        }

        const contentRegion = this.getContentRegion();
        if (contentRegion) {
            attributes.push(contentRegion);
        }

        const contentLocale = this.getContentLocale();
        if (contentLocale) {
            attributes.push(contentLocale);
        }

        return attributes;
    }

    // Get Attributes Methods Section
    private getUserAgent() {
        return {
            name: 'user-agent',
            value: this.windowRef.nativeWindow.navigator.userAgent
        };
    }

    private getPageUrl() {
        return {
            name: 'page-url',
            value: this.windowRef.nativeWindow.location.href
        };
    }

    private getGuestAffiliations() {
        return {
            name: 'guest-affiliations-fl-res',
            "value": false
        };
    }

    private getSiteSection(analyticsModel: AnalyticsModel) {
        return {
            name: 'site-section',
            value: analyticsModel.siteSection
        };
    }

    private getGuestType(analyticsModel: AnalyticsModel) {
        return {
            name: 'guest-type',
            value: analyticsModel.guestType
        };
    }

    private getContentLocale() {
        const locationCookieData = this.getLocationCookieData();
        if (locationCookieData) {
            return {
                name: 'content-locale',
                value: locationCookieData.contentLocale
            };
        }

        return null;
    }

    private getContentRegion() {
        const geoData = this.getLocationCookieData();

        if (geoData && geoData.region) {
            return {
                name: 'geolocation-region',
                value: geoData.region
            };
        }

        return null;
    }

    private getLocationCookieData() {
        if (!this.geolocationCookieData) {
            const geolocationCookieRaw = this.cookieService.get(this.localeCookieJar);
            if (geolocationCookieRaw) {
                try {
                    this.geolocationCookieData = JSON.parse(decodeURIComponent(geolocationCookieRaw));
                } catch (e) {
                    this.geolocationCookieData = {};
                }
            }
        }

        return this.geolocationCookieData;
    }
}
