<div class="guest-name-container">
    <fieldset>
        <legend class="form-legend">
            <div class="guest-name-description" [ngClass]="{'show-required': showRequiredLabel}">
                <div class="title-container">
                    <h4 class="guest-name-title"
                        [ngClass]="{ 'error': isInvalidForm() }"
                        translate>
                        guest-name.title
                    </h4>
                    <span *ngIf="showRequiredLabel" class="action-message" translate>
                        guest-name.guest-required
                    </span>
                </div>
                <p class="guest-name-text" translate>guest-name.subtitle</p>
            </div>
        </legend>
        <div
            class="guest-name-info"
            [ngClass]="{'full-row': isFullMiddleName}"
            [formGroup]="guestName">
            <div class="input-select">
                <wdpr-single-select
                    #wdprSelect
                    ngDefaultControl
                    formControlName="title"
                    class="select-title guestSensitive"
                    [label]="translations['title-label']"
                    [customAriaLabel]="translations['aria-label']?.title | replaceString: { number: guestNumber } | requiredLabel"
                    [options]="prefixes"
                    [invalid]="isInvalid(guestName.get('title'))"
                    required-field="true"
                    [customErrorAriaLabel]="getErrors('title', guestName.get('title'), errorMessagesKeys) | translate">
                </wdpr-single-select>
                <dcl-error-message
                    class="guest-name-error"
                    *ngIf="isFullMiddleName && isInvalid(guestName.get('title'))"
                    [control]="guestName.controls.title"
                    [errorMessage]="errorMessagesKeys['title']"
                    [ngClass]="{'items-inside': getErrorLength(guestName.controls.title.errors) > 1}">
                </dcl-error-message>
            </div>
            <div class="input-text-container large-input">
                <wdpr-input
                    ngDefaultControl
                    autocorrect="off"
                    autocomplete="given-name"
                    type="text"
                    name="fname"
                    formControlName="firstName"
                    class="input-field guestSensitive"
                    [label]="translations['name-label']"
                    [customAriaLabel]="translations['aria-label']?.name | replaceString: { number: guestNumber } | requiredLabel"
                    no-validate-on-change="true"
                    no-validate-on-blur="true"
                    required="true"
                    maxlength="28"
                    [valid]="!isInvalid(guestName.get('firstName'))"
                    [ngClass]="{ 'invalid touched': isInvalid(guestName.get('firstName')) }"
                    [customErrorAriaLabel]="getErrors('firstName', guestName.get('firstName'), errorMessagesKeys) | translate">
                </wdpr-input>
                <dcl-error-message
                    class="guest-name-error"
                    *ngIf="isFullMiddleName && isInvalid(guestName.get('firstName'))"
                    [control]="guestName.controls.firstName"
                    [errorMessage]="errorMessagesKeys['firstName']"
                    [ngClass]="{'items-inside': getErrorLength(guestName.controls.firstName.errors) > 1}">
                </dcl-error-message>
            </div>
            <div class="input-text-container mi">
                <wdpr-input
                    ngDefaultControl
                    autocorrect="off"
                    autocomplete="additional-name"
                    type="text"
                    name="mname"
                    formControlName="middleName"
                    class="input-field guestSensitive"
                    [label]="translations[middleNameLabel]"
                    [customAriaLabel]="translations['aria-label']?.mi | replaceString: { number: guestNumber }"
                    no-validate-on-change="true"
                    no-validate-on-blur="true"
                    [maxlength]="middleNameMaxLength"
                    [valid]="!isInvalid(guestName.get('middleName'))"
                    [ngClass]="{ 'invalid touched': isInvalid(guestName.get('middleName')) }"
                    [customErrorAriaLabel]="getErrors('middleName', guestName.get('middleName'), errorMessagesKeys) | translate">
                </wdpr-input>
                <dcl-error-message
                    class="guest-name-error"
                    *ngIf="isFullMiddleName && isInvalid(guestName.get('middleName'))"
                    [control]="guestName.controls.middleName"
                    [ngClass]="{'items-inside': getErrorLength(guestName.controls.middleName.errors) > 1}">
                </dcl-error-message>
            </div>
            <div class="input-text-container large-input">
                <wdpr-input
                    ngDefaultControl
                    autocorrect="off"
                    autocomplete="family-name"
                    type="text"
                    name="lname"
                    formControlName="lastName"
                    class="input-field large-text guestSensitive"
                    [label]="translations['last-name-label']"
                    [customAriaLabel]="(translations['aria-label'] && translations['aria-label']['last-name']) | replaceString: { number: guestNumber } | requiredLabel"
                    no-validate-on-change="true"
                    no-validate-on-blur="true"
                    required="true"
                    maxlength="28"
                    [valid]="!isInvalid(guestName.get('lastName'))"
                    [ngClass]="{ 'invalid touched': isInvalid(guestName.get('lastName')) }"
                    [customErrorAriaLabel]="getErrors('lastName', guestName.get('lastName'), errorMessagesKeys) | translate">
                </wdpr-input>
                <dcl-error-message
                    class="guest-name-error"
                    *ngIf="isFullMiddleName && isInvalid(guestName.get('lastName'))"
                    [control]="guestName.controls.lastName"
                    [errorMessage]="errorMessagesKeys['lastName']"
                    [ngClass]="{'items-inside': getErrorLength(guestName.controls.lastName.errors) > 1}">
                </dcl-error-message>
            </div>
            <div class="input-select suffix">
                <wdpr-single-select
                    #wdprSelectSuffix
                    ngDefaultControl
                    formControlName="suffix"
                    class="select-suffix guestSensitive"
                    [label]="translations['suffix-label']"
                    [customAriaLabel]="translations['aria-label']?.suffix | replaceString: { number: guestNumber }"
                    [options]="suffixes">
                </wdpr-single-select>
            </div>
        </div>
        <div class="guest-name-errors"
            *ngIf="!isFullMiddleName">
            <dcl-error-message
                class="guest-name-error"
                *ngIf="isInvalid(guestName.get('title'))"
                [control]="guestName.controls.title"
                [errorMessage]="errorMessagesKeys['title']"
                [ngClass]="{'items-inside': getErrorLength(guestName.controls.title.errors) > 1}">
            </dcl-error-message>
            <dcl-error-message
                class="guest-name-error"
                *ngIf="isInvalid(guestName.get('firstName'))"
                [control]="guestName.controls.firstName"
                [errorMessage]="errorMessagesKeys['firstName']"
                [ngClass]="{'items-inside': getErrorLength(guestName.controls.firstName.errors) > 1}">
            </dcl-error-message>
            <dcl-error-message
                class="guest-name-error"
                *ngIf="isInvalid(guestName.get('middleName'))"
                [control]="guestName.controls.middleName"
                [ngClass]="{'items-inside': getErrorLength(guestName.controls.middleName.errors) > 1}">
            </dcl-error-message>
            <dcl-error-message
                class="guest-name-error"
                *ngIf="isInvalid(guestName.get('lastName'))"
                [control]="guestName.controls.lastName"
                [errorMessage]="errorMessagesKeys['lastName']"
                [ngClass]="{'items-inside': getErrorLength(guestName.controls.lastName.errors) > 1}">
            </dcl-error-message>
        </div>
    </fieldset>
</div>
