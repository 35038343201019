/* tslint:disable max-file-line-count */
import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsDataModel } from '../../interfaces/analytics-data-model.interface';
import { AnalyticsFilterStateroom } from '../../../interfaces/analytics-data-stateroom.interface';
import { CHECKMARK_ICON } from './quick-quote-filter-wrapper.constants';
import { Filter } from '../../interfaces/filter.interface';
import { GrayoutService } from '../../services/grayout.service';
import { ItemChanged } from '../../../interfaces/item-changed.interface';
import { NewItinerariesBannerService } from '../../services/new-itineraries.banner.service';
import { NewItinerariesV2BannerService } from '../../services/new-itineraries-v2.banner.service';
import { NewItinerariesV3BannerService } from '../../services/new-itineraries-v3.banner.service';
import { OptionItem } from './../../../options-groups/interfaces/option-item.interface';
import { OptionsGroup } from './../../../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from '../../quick-quote.constants';
import { QuickQuoteService } from './../../services/quick-quote.service';
import { ReservationFlowService } from './../../../services/reservation-flow/reservation-flow.service';

@Component({
    selector: 'dcl-quick-quote-filter-wrapper',
    templateUrl: './quick-quote-filter-wrapper.component.html',
    styleUrls: ['./quick-quote-filter-wrapper.component.scss']
})
export class QuickQuoteFilterWrapperComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() emitNewItinerariesClick: boolean;
    @Input() filterData: Filter;
    @Input() filterDataSubGroup: Filter;
    @Input() isDclAdvancedBooking: boolean;
    @Input() isMobileAriaActive: boolean;
    @Input() isSubtitleAriaHidden: boolean;
    @Input() isDclAdvancedBookingPhase2: boolean;
    @Input() isDclAdvancedBookingPhase3: boolean;
    @Input() isDclShipAdaPhase2: boolean;
    @Input() showClearBtn = true;
    @Input() showFilterType = true;
    @Output() closeWrapper = new EventEmitter<string>();
    @Output() newItinerariesClickEvent = new EventEmitter<AnalyticsDataModel>();
    @Output() onApplyFilters = new EventEmitter<boolean>();
    @Output() advanceBookingClickEvent = new EventEmitter();

    announceAriaLabel = false;
    checkmarkIconUrl: string;
    constants = QUICK_QUOTE_CONSTANTS;
    disneyWishShipFilter: OptionItem;
    contextPath: string;
    groupAriaLabel: string;
    imagePlaceholder: string;
    moment = moment;
    isNewItinerariesBannerAvailable: boolean;
    showSubtitle: boolean;
    analyticsDataModel: AnalyticsDataModel = {
        trackLinkID: this.constants.analytics.cruiseFiltersLink
    };
    subgroupTitle = '';
    subgroupSubtitle = '';
    isModFlow: boolean;

    constructor(
        private grayoutService: GrayoutService,
        private newItinerariesBannerService: NewItinerariesBannerService,
        private newItinerariesV2BannerService: NewItinerariesV2BannerService,
        private newItinerariesV3BannerService: NewItinerariesV3BannerService,
        private quickQuoteService: QuickQuoteService,
        private reservationFlowService: ReservationFlowService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.checkmarkIconUrl = `${this.quickQuoteService.getContextPath()}${CHECKMARK_ICON}`;
        this.imagePlaceholder = `${this.quickQuoteService.getContextPath()}${this.constants.images.placeholder}`;
        this.isMobileAriaActive = this.quickQuoteService.verifyIsMobile();
        this.contextPath = this.quickQuoteService.getContextPath();
        this.isModFlow = this.reservationFlowService.isModFlow();
    }

    ngOnChanges(): void {
        this.showSubtitle = this.filterData.type !== this.constants.filters['more-filters'].id;
        this.setGroupAriaLabel();
        this.setSubgroupTitleAndSubtitle();
        this.initNewSailingBanner();
    }

    /**
     * create an analytics data model for each clear filter button on the QQ
     *
     * @param filterType filter type selected
     */
    createAnalyticsModelForClearFilters(filterType: string): void {
        const analyticsModel = cloneDeep(this.analyticsDataModel);

        analyticsModel.trackLinkID +=
            `${
                this.constants.analytics[filterType].filterButtonLink
            }_${
                this.constants.analytics.clearFiltersButtonLink
            }`;

        this.quickQuoteService.trackAnalyticsEvent(analyticsModel);
    }

    /**
     * sets the correct analytics data model for each item on each filter
     *
     * @param itemChanged filter have been modified
     * @param type filter type selected
     * @param travelPartyAnalytics if is a stateroom comes data depending the type of filter selected
     */
    setAnalyticsModel(itemChanged: ItemChanged, type: string, travelPartyAnalytics?: AnalyticsFilterStateroom): void {
        const analyticsModel: AnalyticsDataModel = cloneDeep(this.analyticsDataModel);

        analyticsModel.trackLinkID += `${this.constants.analytics[type].filterButtonLink}`;

        switch (type) {
            case 'city':
            case 'destination':
            case 'privateIsland':
                analyticsModel.trackLinkID += `_${this.constants.analytics[type].cardButtonLink}`;
                analyticsModel.facetsUtilized = [
                    {
                        name: itemChanged.name,
                        action: itemChanged.isSelected ?
                            this.constants.analytics.actionAdd :
                            this.constants.analytics.actionRemove
                    }
                ];

                break;

            case 'date':
                analyticsModel.trackLinkID += `_${this.constants.analytics[type].cardButtonLink}`;
                analyticsModel.facetsUtilized = [
                    {
                        name: this.moment(itemChanged.urlFriendlyId, 'MMMM-YYYY').format('MMMM-YY').toLowerCase(),
                        action: itemChanged.isSelected ?
                            this.constants.analytics.actionAdd :
                            this.constants.analytics.actionRemove
                    }
                ];

                break;

            case 'travel-party':
                const analyticsData = this.analyticsGuestTrackingType(travelPartyAnalytics, type);

                analyticsModel.trackLinkID += analyticsData.trackLinkID;
                analyticsModel.facetsUtilized = analyticsData.facetsUtilized;
                analyticsModel.bookingSearchPartySize = analyticsData.bookingSearchPartySize;
                analyticsModel.bookingSearchNumRooms = travelPartyAnalytics.numberRoomsBooked;

                break;

            case 'more-filters':
                analyticsModel.trackLinkID += `_${this.constants.analytics[type][itemChanged.groupType]}`;
                analyticsModel.facetsUtilized = [
                    {
                        name: itemChanged.urlFriendlyId,
                        action: itemChanged.isSelected ?
                            this.constants.analytics.actionAdd :
                            this.constants.analytics.actionRemove
                    }
                ];

                if (itemChanged.groupType === this.constants.filters[type].groupKey.night) {
                    analyticsModel.contentDisplayed = itemChanged.urlFriendlyId;
                }

                break;
            }

        this.quickQuoteService.trackAnalyticsEvent(analyticsModel);
    }

    /**
     * Functions that receives the events when a filter item has been changed
     * @param travelPartyAnalyticsData filter of the stateroom
     * @param type  filter type selected
     * @returns Analytics model for Travel Party based on params
     */
    analyticsGuestTrackingType(travelPartyAnalyticsData: AnalyticsFilterStateroom, type: string): AnalyticsDataModel {
        switch (travelPartyAnalyticsData.stateroomTypeAction) {
            case this.constants.analytics[type].typeFilters.child:
                return {
                    trackLinkID: travelPartyAnalyticsData.isAddGuest ?
                        this.constants.analytics[type].addChild :
                        this.constants.analytics[type].removeChild,
                    bookingSearchPartySize: travelPartyAnalyticsData.bookingSearchPartySize,
                    facetsUtilized: [
                        {
                            name: this.constants.analytics[type].typeFilters.child,
                            action: travelPartyAnalyticsData.isAddGuest ?
                                this.constants.analytics[type].typeFilters.add :
                                this.constants.analytics[type].typeFilters.remove
                        }
                    ]
                };

            case this.constants.analytics[type].typeFilters.adult:
                return {
                    trackLinkID: travelPartyAnalyticsData.isAddGuest ?
                        this.constants.analytics[type].addAdult :
                        this.constants.analytics[type].removeAdult,
                    bookingSearchPartySize: travelPartyAnalyticsData.bookingSearchPartySize,
                    facetsUtilized: [
                        {
                            name: this.constants.analytics[type].typeFilters.adult,
                            action: travelPartyAnalyticsData.isAddGuest ?
                                this.constants.analytics[type].typeFilters.add :
                                this.constants.analytics[type].typeFilters.remove
                        }
                    ]
                };

            case this.constants.analytics[type].typeFilters.stateroomRemove:
                return {
                    trackLinkID: this.constants.analytics[type].stateroom,
                    bookingSearchPartySize: '',
                    facetsUtilized: [
                        {
                            name: this.constants.analytics[type].typeFilters.stateroom,
                            action: this.constants.analytics[type].typeFilters.remove
                        }
                    ]
                };

            case this.constants.analytics[type].typeFilters.stateroomAdd:
                return {
                    trackLinkID: this.constants.analytics[type].stateroom,
                    bookingSearchPartySize: '',
                    facetsUtilized: [
                        {
                            name: this.constants.analytics[type].typeFilters.stateroom,
                            action: this.constants.analytics[type].typeFilters.add
                        }
                    ]
                };

            case this.constants.analytics[type].typeFilters.accessibleAdd:
                return {
                    trackLinkID: this.constants.analytics[type].accessible,
                    bookingSearchPartySize: '',
                    facetsUtilized: [],
                };

            case this.constants.analytics[type].typeFilters.accessibleRemove:
                return {
                    trackLinkID: this.constants.analytics[type].accessibleDeselect,
                    bookingSearchPartySize: '',
                    facetsUtilized: [],
                };
        }
    }

    /**
     * Functions that receives the events when a filter item has been changed
     * @param itemChanged
     * @param type
     */
    onFiltersChanged(itemChanged: ItemChanged, type: string): void {
        this.setAnalyticsModel(itemChanged, type);
        this.quickQuoteService.changedFilterItem({
            filterType: type,
            ...itemChanged
        });

        if (type === this.constants.filters['more-filters'].id ||
            (
                type === this.constants.filters['destination'].id ||
                type === this.constants.filters['privateIsland'].id
            )
        ) {
            this.grayoutService.checkGrayout();
        }
    }

    /**
     * Function that receives the event when travel party count has been changed
     * @param travelPartyCount
     */
    onTravelPartyCountChange(travelPartyCount: number): void {
        this.quickQuoteService.changedTravelPartyCount(travelPartyCount);
    }

    /**
     * Clear filter applied by type
     */
    clearFiltersByType(): void {
        this.quickQuoteService.clearFiltersByType(this.filterData.type);

        if (
            this.filterData.type === this.constants.filters['more-filters'].id ||
            this.filterData.type === this.constants.filters['destination'].id
        ) {
            this.grayoutService.checkGrayout();
        }

        this.createAnalyticsModelForClearFilters(this.filterData.type);
    }

    /**
     * Calls service to sends focus to the next filter tab in the Quick Quote Bar
     * @param event Keyboard event
     */
    focusNextTab(event: KeyboardEvent): void {
        if (!this.quickQuoteService.verifyIsMobile()) {
            event.preventDefault();
            this.quickQuoteService.focusToBar(this.filterData.type, 1);
        }
    }

    /**
     * Calls service to sends focus to the previous filter tab in the Quick Quote Bar
     * @param event Keyboard event
     */
    focusPrevTab(event: KeyboardEvent): void {
        if (event.shiftKey && event.code === this.constants.keyboardKeysCodes.tab) {
            if (!this.quickQuoteService.verifyIsMobile()) {
                event.preventDefault();
                this.quickQuoteService.focusToBar(this.filterData.type, -1);
            }
        }
    }

    /**
     * Listens escape key press to emit event to close the Quick Quote Wrapper
     */
    @HostListener('document:keydown.escape', [])
    onEscapeKeyPress(): void {
        this.closeWrapper.emit(this.filterData.type);
    }

    /**
     * selects which ariaLabel key will be announced by the SR for each filter in the QQ
     * or a different key if it is a more filter option
     * @returns ariaLabel selected key
     */
    getAriaLabel(): string {
        const isMoreFilter = this.filterData.type === this.constants.filters['more-filters'].id;
        let title = '';
        let subtitle = '';

        try {
            title = this.translateService.instant(this.constants.filters[this.filterData.type].nameKey);
            subtitle = isMoreFilter ?
                this.translateService.instant((this.filterData.data[0] as OptionsGroup).labelKey) :
                this.translateService.instant(this.constants.filters[this.filterData.type].wrapperLabel);
        } catch (e) {}

        return `${title} ${this.constants.filters.header} ${subtitle} ${this.constants.filters.subHeader}`;
    }

    /**
     * define the title and subtitle subgroup aria label if filterDataSubGroup exists
     */
    setGroupAriaLabel(): void {
        if (this.filterDataSubGroup) {
            const titleKey = this.constants.filters[this.filterDataSubGroup.type].nameKey;
            const subtitleKey = this.constants.filters[this.filterDataSubGroup.type]
                .header['subtitle' + (this.filterDataSubGroup.data.length > 1 ? 'Plural' : 'Singular')
            ];
            const translationKeys = [
                titleKey,
                subtitleKey
            ];

            this.translateService.get(translationKeys)
                .subscribe((data: object) => {
                    this.groupAriaLabel = data[subtitleKey] + ' ' + data[titleKey];
                });
        }
    }

    ngAfterViewInit(): void {
        /**
         * the timing is necessary to wait all the components inside of wrapper be reendered
         * in order to be read correctly by the SR
         */
        setTimeout(() => {
            this.announceAriaLabel = true;
        }, this.constants.timeToRenderQuickQuoteWrapper);
    }

    /**
     * Determine the Subgroup title and subtitle
     */
    setSubgroupTitleAndSubtitle(): void {
        if (this.filterDataSubGroup) {
            const titleKey = this.constants.filters[this.filterDataSubGroup.type]
                .header['title' + (this.filterDataSubGroup.data.length > 1 ? 'Plural' : 'Singular')
            ];
            const subtitleKey = this.constants.filters[this.filterDataSubGroup.type]
                .header['subtitle' + (this.filterDataSubGroup.data.length > 1 ? 'Plural' : 'Singular')
            ];
            const translationKeys = [
                titleKey,
                subtitleKey
            ];

            this.translateService.get(translationKeys)
                .subscribe((data: object) => {
                    this.subgroupTitle = data[titleKey];
                    this.subgroupSubtitle = data[subtitleKey];
                });
        }
    }

    /**
     * Initialize the New Sailing banner filter
     */
    private initNewSailingBanner(): void {
        if (this.isDclAdvancedBookingPhase3) {
            this.isNewItinerariesBannerAvailable = true;
        } else if (this.isDclAdvancedBookingPhase2) {
            this.isNewItinerariesBannerAvailable = this.newItinerariesV2BannerService.isAvailable;
        } else {
            this.newItinerariesBannerService.init(this.filterData);
            this.isNewItinerariesBannerAvailable = this.newItinerariesBannerService.isAvailable;
        }
    }

    /**
     * Behaviours when click on New Sailing banner happens
     * If emitNewItinerariesClick is true, the beheaviour is delegated to the parent component
     */
    onNewSailingClick(): void {
        if (this.emitNewItinerariesClick) {
            this.newItinerariesClickEvent.emit(this.getNewItinerariesAnalyticsData());

            return;
        }

        if (this.isDclAdvancedBookingPhase3) {
            this.newItinerariesV3BannerService.onClick();
        } else if (this.isDclAdvancedBookingPhase2) {
            this.newItinerariesV2BannerService.onClick(this.getNewItinerariesAnalyticsData());
        } else {
            this.newItinerariesBannerService.onClick(this.getNewItinerariesAnalyticsData());
        }

        if (this.isModFlow) {
            this.advanceBookingClickEvent.emit();
        }

        this.onApplyFilters.emit(true);
    }

    /**
     * Get new itineraries analytics data
     * @returns AnalyticsDataModel
     */
    private getNewItinerariesAnalyticsData(): AnalyticsDataModel {
        let name: string;
        let selected: boolean;

        if (this.isDclAdvancedBookingPhase2) {
            name = this.newItinerariesV2BannerService.getAnalyticsAdvBookingFilterValue();
            selected = this.newItinerariesV2BannerService.isSelected();
        } else {
            name = this.newItinerariesBannerService.getAnalyticsAdvBookingFilterValue();
            selected = this.newItinerariesBannerService.isSelected();
        }

        return {
            trackLinkID: this.analyticsDataModel.trackLinkID +
                    QUICK_QUOTE_CONSTANTS.analytics['more-filters'].filterButtonLink + '_' +
                    QUICK_QUOTE_CONSTANTS.analytics.advBooking.name,
            facetsProgress: [
                {
                    action: selected ?
                            QUICK_QUOTE_CONSTANTS.analytics.facets_utilized.action_add :
                            QUICK_QUOTE_CONSTANTS.analytics.facets_utilized.action_remove,
                    name
                }
            ]
        };
    }
}
