import { ReservationFlow } from './reservation-flow.interface';

const FLOWS = {
    hub: 'hub',
    room: 'room',
    party: 'party',
    largerRoom: 'larger-room',
    sailing: 'sailing',
    cancel: 'cancel'
};

const FLOW_VALUES: { [key: string]: ReservationFlow; } = {
    hub: {
        id: FLOWS.hub,
        urlParams: '',
        sessionScope: '',
        releaseSessionNeeded: false
    },
    room: {
        id: FLOWS.room,
        urlParams: FLOWS.room,
        sessionScope: 'MOD_STATE_RM',
        releaseSessionNeeded: true
    },
    party: {
        id: FLOWS.party,
        urlParams: FLOWS.party,
        sessionScope: 'MOD_TP',
        releaseSessionNeeded: true
    },
    'larger-room': {
        id: FLOWS.largerRoom,
        urlParams: FLOWS.largerRoom,
        sessionScope: 'MOD_TP',
        releaseSessionNeeded: true
    },
    sailing: {
        id: FLOWS.sailing,
        urlParams: FLOWS.sailing,
        sessionScope: 'MOD_SAILING',
        releaseSessionNeeded: true
    },
    cancel: {
        id: FLOWS.cancel,
        urlParams: FLOWS.cancel,
        sessionScope: 'CANCEL_RESERVATION',
        releaseSessionNeeded: true
    }
};

export {
    FLOWS,
    FLOW_VALUES
};
