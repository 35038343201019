<section
    #guestSelector
    class="dcl-guest-selector"
    id="dcl-guest-selector-component"
    [ngClass]="{ 'open': isSelectorOpen }"
    (keyup.esc)="autoCloseGuestSelector($event)">
    <div class="dcl-guest-selector__header">
        <div class="dcl-guest-selector__content">
            <div class="dcl-guest-selector__guest-selected">
                <div class="option-avatar-wrapper">
                    <img
                        aria-hidden="true"
                        src="{{guestSelected.img}}"
                        alt="{{guestSelected.alt | translate}}"
                        class="option-avatar">
                    <span class="option-avatar-initials" aria-hidden="true">{{guestSelected.guestInitials}}</span>
                </div>
                <span
                    class="dcl-guest-selector__text guestSensitive">
                    {{guestSelected.value | titlecase}}
                </span>
            </div>
            <div
                class="dcl-guest-selector__guest-selection"
                (keydown.ArrowDown)="nextItem($event)"
                (keydown.ArrowUp)="prevItem($event)"
                (keydown.Shift.Tab)="prevItem($event)"
                (keydown.Tab)="nextItem($event)">
                <button
                    #guestSelectorComponentButton
                    class="dcl-guest-selector__placeholder"
                    (click)="openGuestSelector($event)"
                    [disabled]="actionLoading"
                    (blur)="autoCloseGuestSelector($event)"
                    [attr.aria-expanded]="isSelectorOpen">
                    <span
                        class="dcl-guest-selector__guest">
                        {{ placeholder | translate }}
                    </span>
                    <i class="di di-next-2 carrot-icon" aria-hidden="true"></i>
                </button>
                <ul
                    #guestSelectorComponentList
                    class="dcl-guest-selector__options">
                    <li
                        *ngFor="let option of options; let index = index"
                        class="dcl-guest-selector__guest-select-item"
                        [ngClass]="{'item-selected': currentIndexSelected === index}"
                        role="button"
                        tabindex="-1"
                        (click)="changeSelection(option, index)"
                        (keydown.enter)="changeSelection(option, index)">
                        <div class="option-avatar-wrapper">
                            <img
                                aria-hidden="true"
                                src="{{option.img}}"
                                alt="{{option.alt | translate}}"
                                class="option-avatar">
                            <span class="option-avatar-initials" aria-hidden="true">{{option.guestInitials}}</span>
                        </div>
                        <span
                            class="dcl-guest-selector__text guestSensitive">
                            {{option.value | titlecase}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="isSelectorOpen" class="dcl-guest-selector__overlay"></div>
</section>
