import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ItemChanged } from '../interfaces/item-changed.interface';
import { OptionsGroup } from './interfaces/options-group.interface';
import { OptionItem } from './interfaces/option-item.interface';

@Component({
    selector: 'dcl-options-groups',
    templateUrl: './options-groups.component.html',
    styleUrls: ['./options-groups.component.scss']
})
export class OptionsGroupsComponent {
    @Input() optionsGroups: OptionsGroup[];
    @Output() onChangedItem: EventEmitter<ItemChanged> = new EventEmitter();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();

    /**
     * Receives the items id that selected value in the optionItemComponent and append the type
     * @param item
     * @param groupType
     */
    onItemChanged(item: ItemChanged, groupType: string): void {
        this.onChangedItem.emit({
            ...item,
            groupType
        });
    }

    /**
     * TrackBy function for groups
     * @param index product index
     * @param group product data
     * @return group id
     */
    trackByGroupId(index: number, group: OptionsGroup): string {
        return group.id;
    }

    /**
     * TrackBy function for options
     * @param index product index
     * @param item product data
     * @return optionItem id
     */
    trackByOptionId(index: number, item: OptionItem): string {
        return item.id;
    }
}
