export const DISCLAIMER_CONSTANTS = {
    acceptLanguageKey: 'Accept-Language',
    currencyDisclaimerEndpoint: 'content/environment/preview/entities/urlFriendly/types/currency-disclaimer/',
    currencyIdModule: 'currencyDisclaimer',
    currencyKey: 'currency',
    disclaimerKey: 'disclaimer',
    exchangeRateKey: '{exchangeRate}',
    maxCachedTime: 4,
    minModuleSize: 2,
    webApiUrl: 'webApiUrl'
};
