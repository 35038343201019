<div class="guest-castaway-info" *ngIf="castawayInfo">
    <div class="info-container">
        <div class="info-item" *ngIf="fullName">
            <strong class="item-label">{{ 'travelAgent.guestCastawayInfo.name' | translate }}</strong>
            <span>{{ fullName }}</span>
        </div>
        <div class="info-item" *ngIf="castawayInfo.eligibleGrant">
            <strong class="item-label">{{ 'travelAgent.guestCastawayInfo.clubLevel' | translate }}</strong>
            <span>{{ castawayInfo.eligibleGrantKey | translate  }}</span>
        </div>
        <div class="info-item" *ngIf="castawayIdSubstring && !hideIdNumber">
            <strong class="item-label">{{ 'travelAgent.guestCastawayInfo.membershipId' | translate }}</strong>
            <span>{{ castawayIdSubstring }}</span>
        </div>
    </div>
    <button
        *ngIf="!hideDeleteBtn"
        class="button-delete"
        (click)="deleteCastawayData()"
        attr.aria-label="{{ 'travelAgent.guestCastawayInfo.deleteAriaLabel' | translate }}">
        <span class="di di-delete-button" aria-hidden="true"></span>
    </button>
</div>
