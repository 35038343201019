import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { ExtendedSelect } from './interfaces/extended-select.interface';
import { ExtendedSelectType } from './enums/extended-select.type.enum';
import { ExtendedSelectOption } from './interfaces/extended-select-option.interface';
import { EXTENDED_SELECT_CONSTANTS } from './extended-select.constants';

/**
 * Extended Select to support custom options
 *
 * # How to use?
 *
 *    <dcl-extended-select
 *         [data]="[ExtendedSelect API interface]"
 *         [selected]="[ExtendedSelectOption API interface]"
 *    >
 *    </dcl-extended-select>
 *
 * ## Important
 * - if you don´t define the type of the element a "default" element will be displayed,
 *   so if you create a "group" or "subgroup" remember to use the ExtendedSelectType.group type
 * - arialLabel input should be necessary to make this component accesible using VoiceOver in Safari
 *
 */
@Component({
    selector: 'dcl-extended-select',
    templateUrl: './extended-select.component.html',
    styleUrls: ['./extended-select.component.scss']
})
export class ExtendedSelectComponent implements AfterViewInit, OnInit {
    @Input() data: any[];
    @Input() ariaLabel: string;
    @Input() selected: ExtendedSelectOption;
    @Output() selectChange?: EventEmitter<Event> = new EventEmitter();

    /**
     * Every template ref should be defined here so can be loaded dynamically based on its type(ExtendedSelectType)
     */
    @ViewChild('avatarPlaceholder', {static: false}) avatarPlaceholder: TemplateRef<HTMLElement>;
    @ViewChild('avatarTemplate', {static: false}) avatarTemplate: TemplateRef<HTMLElement>;
    @ViewChild('defaultPlaceholder', {static: false}) defaultPlaceholder: TemplateRef<HTMLElement>;
    @ViewChild('defaultTemplate', {static: false}) defaultTemplate: TemplateRef<HTMLElement>;
    @ViewChild('linkPlaceholder', {static: false}) linkPlaceholder: TemplateRef<HTMLElement>;
    @ViewChild('linkTemplate', {static: false}) linkTemplate: TemplateRef<HTMLElement>;
    @ViewChild('groupTemplate', {static: false}) groupTemplate: TemplateRef<HTMLElement>;
    @ViewChild(MatSelect, {static: false}) select: MatSelect;

    selectArialLabel = '';
    selectCtrl = new UntypedFormControl('');
    templatesRefTypes = EXTENDED_SELECT_CONSTANTS.templatesRefTypes;
    isPlaceholderVisible = true;

    constructor(
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.selectCtrl.setValue(this.selected);
        this.selectArialLabel = !this.selected.value ?
            this.ariaLabel :
            this.addSelectedOptionToAriaLabel(this.selected.title);
    }

    /**
     * Needed for dynamically templates
     *
     * @see https://angular.io/api/core/ChangeDetectorRef#changedetectorref
     */
    ngAfterViewInit(): void {
        if (this.select._positions) {
            this.select._positions = EXTENDED_SELECT_CONSTANTS.overlayDir.positions as ConnectedPosition[];
        }

        this.cdr.detectChanges();
    }

    /**
     * Selected value is not detected by the screen readers in <mat-select>
     * component due to current version, so it is needed to add the selected value
     * to the aria label.
     */
    private addSelectedOptionToAriaLabel(title: string): string {
        return this.ariaLabel && `${this.ariaLabel + ', ' + title}` || '';
    }

    /**
     * Get the templateRef to load the partial template or partial placeholder, by default if "type" property is
     * not defined the "default" template or placeholder is returned
     *
     * @param option    option Interface element
     * @param type      the string type of the TemplateRef, could be "Template"(by default) or "Placeholder"
     * @returns         the string template name based on its type
     */
    getTemplateRef(
        option: ExtendedSelectOption,
        type: string = this.templatesRefTypes.template
    ) {
        return this[this.getType(option) + type];
    }

    /**
     * Get the type of an option
     *
     * @param option    option Interface element
     * @returns         the string "type" of the option if is defined otherwise "default" type is returned
     */
    getType(
        option: ExtendedSelectOption
    ): string {
        return option && option.type ? option.type : ExtendedSelectType.default;
    }

    /**
     * Trigger an option click function if it is defined and emit the onChange event component with the selected item
     *
     * @param source MatSelect instance
     */
    onSelectionChange(source: MatSelect): void {
        const optionTitle: string | undefined = source.value && source.value.title;
        this.reRender();

        // Add selected option to select aria label
        if (optionTitle) {
            this.selectArialLabel = this.addSelectedOptionToAriaLabel(optionTitle);
        }
        // Link elements couldn't be stored because are "actionable" items
        if (source.value.type !== ExtendedSelectType.link) {
            this.selected = source.value;
        }

        // trigger option callback if is defined
        if (typeof source.value.onSelected === 'function') {
            source.value.onSelected(source.value);
        }

        // trigger global select change event
        this.selectChange.emit(source.value);
    }

    /**
     * Manual rendering the placeholder
     * The idea is to not cache the placeholders and force rerendering view using ngIf
     * specially when child components are used
     */
    private reRender(): void {
        this.isPlaceholderVisible = false;
        this.cdr.detectChanges();
        this.isPlaceholderVisible = true;
    }
}
