<div class="castaway-club-table" role="table" *ngIf="data">
    <div class="table-header" role="rowgroup">
        <div class="row" role="row">
            <ng-container *ngFor="let row of data.headers">
                <div class="column" role="columnheader" [ngStyle]="{ 'text-align': row.alignment }">
                    <h3 class="table-header-title">{{ row.title }}</h3>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="table-body" *ngIf="data.rows" role="rowgroup">
        <div *ngFor="let row of data.rows" class="row" role="row">
            <ng-container *ngFor="let column of row.columns; index as index; first as isFirst">
                <div class="column" role="cell"
                    *ngIf="isFirst || !showColumns || showColumns.includes(index)"
                    [ngStyle]="{ 'text-align': column.alignment }"
                    [ngClass]="column.alignment">
                    <h4 class="table-body-title" *ngIf="column.title">{{ column.title }}</h4>
                    <div class="table-body-text" *ngIf="column.summary" [innerHtml]="column.summary"></div>
                    <img class="table-body-image" *ngIf="column.media" [src]="column.media?.url" [alt]="column.media?.alt || ''">
                </div>
            </ng-container>
        </div>
    </div>
</div>
