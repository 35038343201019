export const BREAKPOINT_CONSTANTS = {
    small: {
        label: 'small',
        breakpoint: '(max-width: 567px)'
    },
    medium: {
        label: 'medium',
        breakpoint: '(max-width: 767px)'
    },
    large: {
        label: 'large',
        breakpoint: '(max-width: 1023px)'
    }
};
