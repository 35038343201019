import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { WindowRef } from '../window-ref/window-ref.service';

import { BASE_BANNER_CONSTANTS } from './base-banner.constants';
import { BaseBannerData } from './base-banner.interface';

@Component({
    selector: 'dcl-base-banner',
    templateUrl: './base-banner.component.html',
    styleUrls: ['./base-banner.component.scss'],
})
export class BaseBannerComponent implements OnInit {
    @Input() bannerData: BaseBannerData;
    @Input() theme: string = 'default';
    @Output() onClickEventCloseButton = new EventEmitter();
    @Output() onClickEventCta = new EventEmitter();

    backgroundStyle: SafeStyle;
    constants = BASE_BANNER_CONSTANTS;
    ctaDecorationImageStyle: SafeStyle;
    imageError = false;

    constructor(
        private sanitizer: DomSanitizer,
        private windowRef: WindowRef
    ) {}

    ngOnInit(): void {
        this.setBackgroundStyle();
        this.setCtaDecorationImage();
    }

    /**
     * Handle close banner action
     */
    closeBanner(): void {
        this.onClickEventCloseButton.emit();
    }

    /**
     * Handle error loading the image
     */
    handleImageError(): void {
        this.imageError = true;
    }

    /**
     * Handle cta action
     */
    onClickCta(): void {
        this.onClickEventCta.emit();

        if (this.bannerData.bannerCTA && this.bannerData.bannerCTA.href) {
            this.windowRef.nativeWindow.location.href = this.bannerData.bannerCTA.href;
        }
    }

    /**
     * Set background style based on gradient colors
     */
    private setBackgroundStyle(): void {
        if (this.bannerData.backgroundColor &&
            this.bannerData.backgroundColor.from && this.bannerData.backgroundColor.to) {
            this.backgroundStyle = this.sanitizer.bypassSecurityTrustStyle(
                `--base-banner-background-gradient-from: ${this.bannerData.backgroundColor.from};
                --base-banner-background-gradient-to: ${this.bannerData.backgroundColor.to};`
            );
        }
    }

    /**
     * Set cta background style
     */
    private setCtaDecorationImage(): void {
        if (this.bannerData.decorationImage) {
            this.ctaDecorationImageStyle = this.sanitizer.bypassSecurityTrustStyle(
                `--base-banner-cta-wrapper-background-decoration-image: url(${this.bannerData.decorationImage});`
            );
        }
    }
}
