import { Injectable } from '@angular/core';

import { AppLocaleService } from '../app-locale/app-locale.service';
import { WindowRef } from '../../window-ref/window-ref.service';
import { UpdateLocaleCookie } from '../interfaces/update-locale-cookie';
import { UPDATE_LOCAL_COOKIE_CONSTANTS } from './update.locale.cookie.constants';

@Injectable({
    providedIn: 'root'
})
export class UpdateLocaleCookieService {
    private currentLocale: string;
    private currentCurrency: string;

    constructor(
        private appLocaleService: AppLocaleService,
        private windowRef: WindowRef
    ) {
        this.currentLocale = this.appLocaleService.content();
        this.currentCurrency = this.appLocaleService.currency();
    }

    /**
     * If the reservation has different region than localeCookies set new cookies and reload the page
     * @param currencyAndLocale
     */
    public checkAndUpdateLocaleCookie(currencyAndLocale: UpdateLocaleCookie): void {
        if (currencyAndLocale.newCurrency && currencyAndLocale.newLocale) {
            const newLocaleFormated = currencyAndLocale.newLocale.replace('-', '_');

            if (this.currentCurrency !== currencyAndLocale.newCurrency ||
                newLocaleFormated !== currencyAndLocale.newLocale
            ) {
                this.appLocaleService.setLocaleCookie({
                    newLocale: newLocaleFormated,
                    newCurrency: currencyAndLocale.newCurrency,
                    path: '/'
                });
                // reloads needed because when the locale cookie is applied
                // the page is already loaded with a different locale
                // reloads to call services with the new locale
                this.windowRef.nativeWindow.location.reload();
            }
        }
    }

    /**
     * Check if the locale is valid to be displayed
     * @param currencyAndLocale
     * @return {Boolean}
     */
    public isAcceptLocale(currencyAndLocale: UpdateLocaleCookie): boolean {
        const newLocaleFormated = currencyAndLocale.newLocale.replace('-', '_');

        if (currencyAndLocale.isDclInternationalDirectPhase2) {
            const acceptLocales = currencyAndLocale.acceptLocalePhaseOne.concat(currencyAndLocale.acceptLocalePhaseTwo);

            return acceptLocales.includes(newLocaleFormated);
        } else {
            return currencyAndLocale.acceptLocalePhaseOne.includes(newLocaleFormated);
        }
    }

    /**
     * Set the cookie to default if there is a different one
     */
    public checkCookieDefault(): void {
        const isDefaultLocale = this.currentLocale === UPDATE_LOCAL_COOKIE_CONSTANTS.default.locale ||
            this.currentLocale ===  UPDATE_LOCAL_COOKIE_CONSTANTS.default.localeEn;

        if (!isDefaultLocale) {
            this.appLocaleService.setLocaleCookie({
                newLocale: UPDATE_LOCAL_COOKIE_CONSTANTS.default.localeEn,
                newCurrency: UPDATE_LOCAL_COOKIE_CONSTANTS.default.currency
            });

            this.windowRef.nativeWindow.location.reload();
        }
    }
}
