<div class="carousel-hero-image">
    <div class="carousel">
        <div class="carousel-inner">
            <ng-container *ngFor="let image of images; index as index; first as isFirst">
                <div class="carousel__image" #carouselImages>
                    <dcl-hero-image
                        [customMediaDesktop]="image.desktopImage"
                        [customMediaMobile]="image.mobileImage"
                        [media]="image.media"
                        [hasPlaceholder]="hasPlaceholder"
                        [customImgPlaceholder]="customImgPlaceholder"
                        (imageLoaded)="isFirst && onFirstImageLoaded()">
                    </dcl-hero-image>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="showControls">
        <div class="carousel-hero-image__gradient"></div>
        <div class="carousel-items-wrapper" #carouselItemsWrapper>
            <div class="carousel-info-container">
                <div class="carousel-info" *ngIf="showTitle">
                    <h1 class="carousel-info__title" #carouselTitle>{{ title }}</h1>
                </div>
            </div>
            <div class="carousel-controls-container">
                <div class="carousel-controls" *ngIf="images.length > 1">
                    <div class="carousel-controls__line-base"
                        [ngClass]="{ 'carousel-controls__line-filled': imageIndex > controlIndex }"
                        *ngFor="let image of images; index as controlIndex"
                        (click)="selectImageByIndex(controlIndex)"
                        [attr.aria-label]="image.altText"
                        (focus)="selectAndPauseImageByIndex(controlIndex)"
                        (blur)="resumeTransition()"
                        role="button"
                        tabindex="0">
                        <div class="carousel-controls__line-filled"
                            *ngIf="imageIndex === controlIndex"
                            #progressBarControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
