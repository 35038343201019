import {
    Directive,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    HostListener,
    OnInit
} from '@angular/core';

/**
 * Custom directive to handle click outside from the decorated element, also
 * can define custom elements/classes/ids that can be excluded when those elements/clases/ids
 * are not present or are not part of the parent element
 */
@Directive({selector: '[dclClickOutside]'})
export class DclClickOutsideDirective implements OnInit {
    @Input('dclClickOutside') exclude?: Array<string> | any;
    @Output() clickOutside: EventEmitter<Event> = new EventEmitter<Event>();

    private itemsExcluded: Array<string> = [];
    private mousedownInsideFired: boolean;

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        let ite: string;

        if (Array.isArray(this.exclude)) {
            this.exclude.forEach((element: string) => {
                ite = element.replace('#', '').replace('.', '');

                if (this.itemsExcluded.indexOf(ite) === -1) {
                    this.itemsExcluded.push(ite);
                }
            });
        }
    }

    /**
     * Handle Mousedown event
     * @param target target element
     */
    @HostListener('document:mousedown', ['$event.target'])
    onMousedown(target: HTMLElement) {
        this.mousedownInsideFired = this.isTargetInside(target);
    }

    /**
     * Handle Click event
     * @param target target element
     */
    @HostListener('document:click', ['$event.target'])
    onClick(target: HTMLElement) {
        const clickedInside = this.isTargetInside(target);

        if (!clickedInside && !this.mousedownInsideFired) {
            this.clickOutside.emit();
        }
    }

    /**
     * Returns if the target provided is inside the current element or it is part of the excluded list
     * @param target target element
     */
    private isTargetInside(target: HTMLElement) {
        const classList = Array.from(target.classList);

        return this.elementRef.nativeElement.contains(target)
            || this.itemsExcluded.indexOf(target.id) !== - 1
            || classList.some((val: string) => this.itemsExcluded.indexOf(val) !== -1);
    }
}
