import { Component, OnInit, Input } from '@angular/core';

import { DclBaseModalComponent } from '../modal/base-modal.component';
import { ModalConfig } from '../interfaces/modal-config.interface';
import { ModalData } from '../interfaces/modal-data.interface';
import { ModalService } from '../services/modal.service';

@Component({
    selector: 'dcl-link-modal',
    templateUrl: './link-modal.component.html',
    styleUrls: ['./link-modal.component.scss']
})
export class DclLinkModalComponent implements OnInit {
    @Input() config: ModalConfig;
    @Input() data: ModalData;
    customData: {};

    constructor(private modalService: ModalService) { }

    ngOnInit() {
        this.setData();
    }

    /**
     * Open the modal
     */
    openModal(): void {
        this.modalService.openModal(this.customData);
    }

    /**
     * Config modal data to open the modal
     */
    setData(): void {
        const component = this.config.component || DclBaseModalComponent;
        this.data.animateClass = this.data.animate ? 'slide-in-up' : 'no-animate';

        this.customData = {
            component,
            config: {
                panel: this.config.panelClass || '',
                backdrop: this.config.backDropClass || ''
            },
            data: {
                ... this.data
            }
        };
    }
}
