import { Component, Input } from '@angular/core';

@Component({
    selector: 'dcl-carousel-item',
    templateUrl: './carousel-item.component.html',
    styleUrls: ['./carousel-item.component.scss']
})
export class CarouselItemComponent {
    @Input() lastItem: boolean;
    @Input() equalHeight: boolean;
    @Input() hasUniqueItem: boolean;

    active = false;
    carouselActive = false;
    config = {
        position: undefined,
        translateX: 0,
        transitionTime: 0,
        styles: { transform: '', transition: '' }
    };
    clickDisabled = false;
}
