export const HEALTH_MONITOR_CONSTANTS = {
    DFM_PAGE_DWARVES: '/cruise-booking-down/',
    DFM_PAGE_DORY: '/cruise-booking-is-down/',
    CAPABILITIES: {
        DCL_CRUISE_BOOKING: 'DCL-Cruise-Booking',
        DCL_CRUISE_BOOKING_UNPLANNED: 'DCL-Cruise-Booking-Unplanned'
    },
    COOKIE_DFM_DEACTIVATE: 'dfmDeactivate',
    ENDPOINT: 'health-monitor/'
};
