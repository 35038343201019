const HERO_IMAGE_CONSTANTS = {
    IMAGE_MEDIA_SIZES: {
        DESKTOP: {
            WIDTH: '960',
            HEIGHT: '270'
        },
        TABLET: {
            WIDTH: '767',
            HEIGHT: '216'
        },
        MOBILE: {
            WIDTH: '480',
            HEIGHT: '135'
        }
    },
    IMAGE_MEDIA_HERO_SIZES: {
        DESKTOP: {
            WIDTH: '1500',
            HEIGHT: '500'
        },
        TABLET: {
            WIDTH: '767',
            HEIGHT: '216'
        },
        MOBILE: {
            WIDTH: '480',
            HEIGHT: '135'
        }
    },
    DEFAULT_PRODUCT_IMAGE: 'https://secure.cdn1.wdpromedia.com/dam/wdpro-assets/dcl/finder/' +
        'destinations/cruise-destinations/evergreen/sunset-cruise-32x9.jpg'
    ,
    DEFAULT_PRODUCT_TITLE: 'hero-image.product-hero-image',
    IMAGE_MEDIA_QUERY: {
        MEDIA_QUERY_DESKTOP: 'only screen and (min-width: 768px)',
        MEDIA_QUERY_TABLET: 'only screen and (min-width: 481px) and (max-width: 767px)',
        MEDIA_QUERY_MOBILE: 'only screen and (max-width: 480px)'
    }
};

export { HERO_IMAGE_CONSTANTS };
