const cookies = {
    isLoggedIn: 'isLoggedIn',
    jwtToken: 'pep_jwt_token',
    oauthRefreshPP: 'pep_oauth_refresh_token_pp',
    oauthRefreshToken: 'pep_oauth_refresh_token',
    oauthToken: 'pep_oauth_token',
    rememberme: 'rememberme',
    sessionTimeOut: 'SESSION_TIMEOUT',
    swid: 'SWID'
};

const domains = {
    baseProtocol: 'https://',
    default: '/',
    goCom: '.go.com',
};

const path = '/';

export { cookies, domains, path };
