import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { ADVANCED_BOOKING_BANNER_CONSTANTS } from './advanced-booking-banner.constants';
import { BannerBackground } from './advanced-booking-banner.interface';

@Component({
    selector: 'dcl-advanced-booking-banner',
    templateUrl: './advanced-booking-banner.component.html',
    styleUrls: ['./advanced-booking-banner.component.scss'],
})
export class AdvancedBookingBannerComponent implements OnInit, OnDestroy {
    @Input() actionText: string;
    @Input() altLogo: string;
    @Input() ariaText: string;
    @Input() bannerTitle: string;
    @Input() bannerSubTitle: string;
    @Input() contextPath: string;
    @Input() cursorDefault: boolean;
    @Input() disableClick = false;
    @Input() eventNameBanner: string = '';
    @Input() eventNameButton: string = '';
    @Input() firstGradientColor: string;
    @Input() hideStars: boolean;
    @Input() imageLogo: string;
    @Input() isLargeBanner: boolean;
    @Input() openEBModal: boolean;
    @Input() secondGradientColor: string;
    @Input() starsColor: string = ADVANCED_BOOKING_BANNER_CONSTANTS.defaultStarsColor;
    @Output() handleTabEvent = new EventEmitter<KeyboardEvent>();
    @Output() onClickEventBanner = new EventEmitter<string>();
    @Output() onClickEventButton = new EventEmitter<string>();

    a11yDescription = ADVANCED_BOOKING_BANNER_CONSTANTS.ariaOpenModalBtnAction;
    backgroundStyle: BannerBackground;
    buttonId = ADVANCED_BOOKING_BANNER_CONSTANTS.buttonId;
    desktopBreakpoint: number = ADVANCED_BOOKING_BANNER_CONSTANTS.viewBreakpoints.desktop;
    imgStars: string;
    imgStipple: string;
    isMobile: boolean;
    mediaMatcher: MediaQueryList;
    starsPath: string = ADVANCED_BOOKING_BANNER_CONSTANTS.bannerStars;
    starStyle: SafeStyle;
    stipplePath = ADVANCED_BOOKING_BANNER_CONSTANTS.bannerSstipple;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.imgStars = `${this.contextPath}${this.starsPath}`;
        this.imgStipple = `${this.contextPath}${this.stipplePath}`;
        this.mediaMatcher = window.matchMedia(`(max-width: ${this.desktopBreakpoint}px)`);
        this.isMobile = this.mediaMatcher.matches;
        this.backgroundStyle = this.getBackgroundStyle(this.isMobile);
        this.mediaMatcher.addEventListener('change', (ev: Event) => this.sizeListener(ev));
        this.starStyle = this.sanitizer.bypassSecurityTrustStyle(
            `--star-image: url('${this.imgStars}');
            --stars-color: ${this.starsColor};`
        );
    }

    /**
     * Manage the click event executed when the banner is clicked
     * Manage the click event executed when the button in the banner is clicked
     * @param event click event
     */
    onClickAllBanner(event: MouseEvent): void {
        const target = event.target as Element;
        const { buttonTag } = ADVANCED_BOOKING_BANNER_CONSTANTS;

        if (target && (target.id === this.buttonId ||
            target.parentElement.id === this.buttonId)) {
            this.onClickEventButton.emit(this.eventNameButton);
        } else if (target.tagName === buttonTag || target.parentElement.tagName === buttonTag) {
            this.onClickEventBanner.emit(this.eventNameBanner);
        } else if (!this.disableClick) {
            this.onClickEventBanner.emit(this.eventNameBanner);
        }
    }

    /**
     * Handles tab key press event
     * @param event Keyboard event
     */
    onTabPress(event: KeyboardEvent) {
        this.handleTabEvent.emit(event);
    }

    /**
     * Set the background image and gradient according to colors received in the inputs and the start image.
     * @param isMobile
     */
    getBackgroundStyle(isMobile: boolean): BannerBackground {
        const direction = (this.isLargeBanner && isMobile) ? 'to bottom' : 'to right';
        const backgroundGradient = `
            linear-gradient(${direction},
            ${this.firstGradientColor},
            ${this.secondGradientColor})`;
        const backgroundStipple = `url(${this.imgStipple})`;

        return {
            banner: {
                'background-image': `${backgroundStipple}, ${backgroundGradient}`,
            },
            halfCircle: {
                'background-image': `${backgroundStipple}`,
                'background-color': `${this.firstGradientColor}`,
            },
            logo: {
                'background': `url(${this.imageLogo}) no-repeat center / contain`
            }
        };
    }

    /**
     * Set the changes in thes styles according to the size of the windown
     * @param event
     */
    sizeListener(event: Event) {
        this.isMobile = (<MediaQueryListEvent>event).matches;
        this.backgroundStyle = this.getBackgroundStyle(this.isMobile);
    }

    ngOnDestroy() {
        this.mediaMatcher.removeEventListener('change', (ev: Event) => this.sizeListener(ev));
    }
}
