import {
    Directive,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { BreakpointItem } from '../interfaces/breakpoint-item.interface';

@Directive({
    selector: '[dclBreakPointDetection]'
})
export class MockBreakPointChangeDetectionDirective {
    @Input() dclBreakpoints: BreakpointItem[] = [];
    @Output() dclBreakpointChange = new EventEmitter<BreakpointItem>();
}
