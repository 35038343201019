import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsDataModel } from '../quick-quote/interfaces/analytics-data-model.interface';
import { CONTEXTUAL_FILTER_CONSTANTS } from './contextual-filter.constants';
import { FilterState } from './../quick-quote/interfaces/filter-state.interface';
import { OptionItem } from '../options-groups/interfaces/option-item.interface';
import { OptionsGroup } from '../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote/quick-quote.constants';

@Component({
    selector: 'dcl-contextual-filter',
    templateUrl: './contextual-filter.component.html',
    styleUrls: ['./contextual-filter.component.scss']
})
export class ContextualFilterComponent implements OnInit, AfterViewChecked {
    @Input() filterOptionGroup: OptionsGroup;
    @Input() filterTitle: string;
    @Input() selectedFilters: string[];
    @Output() filterOptionEvent: EventEmitter<OptionItem> = new EventEmitter();
    @Output() analyticsTrackingEvent: EventEmitter<AnalyticsDataModel> = new EventEmitter();
    @ViewChildren('contextualFilterButtons') contextualFilterButtons: QueryList<ElementRef>;
    analyticsConstants = QUICK_QUOTE_CONSTANTS.analytics;
    trackLinkID =
        `${
            this.analyticsConstants.cruiseFiltersLink
        }${
            this.analyticsConstants.contextualFilter.contextualPrefix
        }${
            this.analyticsConstants.contextualFilter.nightFilterPrefix
        }`;
    contextualFilterList: ElementRef[];
    moreFiltersId = QUICK_QUOTE_CONSTANTS.filters['more-filters'].id;
    states: FilterState;
    stateKeys = QUICK_QUOTE_CONSTANTS.filters.states;

    constructor(private translateService: TranslateService) {
        this.translateService.get([
            this.stateKeys.disabled,
            this.stateKeys.selected,
            this.stateKeys.unavailable,
            this.stateKeys.unselected
        ]).subscribe((states: FilterState) => {
            this.states = {
                disabled: states[this.stateKeys.disabled],
                selected: states[this.stateKeys.selected],
                unavailable: states[this.stateKeys.unavailable],
                unselected: states[this.stateKeys.unselected]
            };
        });
    }

    ngOnInit(): void {
        this.emitAnalyticsTrackingEvent(this.analyticsConstants.contextualFilter.impressionAction);
    }

    /**
     * Emit the item that has been selected, besides change its state selected/unselected
     *
     * @param filterOptionItem filter selected
     * @param groupType group related to the filter
     */
    onChangeItemState(filterOptionItem: OptionItem): void {
        this.filterOptionEvent.emit(filterOptionItem);
        this.emitAnalyticsTrackingEvent(this.analyticsConstants.contextualFilter.clickAction, filterOptionItem);
    }

    /**
     * Search if a specific filter is applied
     *
     * @param filterOptionItem each filter displayed to be verified
     * @returns true if filter is applied
     */
    isFilterActive(filterOptionItem: OptionItem): boolean {
        let isActive = false;

        if (this.selectedFilters && this.selectedFilters.length > 0) {
            this.selectedFilters.forEach((filter: string) => {
                const filterId = filter.split(';')[0];
                const currentFilterId = filterOptionItem.id.split(';')[0];
                const currentFilterFriendlyId = filterOptionItem.urlFriendlyId;

                if (filterId === currentFilterId || filterId === currentFilterFriendlyId) {
                    isActive = true;
                }
            });
        }

        return isActive;
    }

    /**
     * Concat the state for each option item
     * @param filterOptionItem current option item filter
     * @returns aria label to be announced
     */
    getAriaLabel(filterOptionItem: OptionItem): string {
        const ariaLabel = `${filterOptionItem.label} ${CONTEXTUAL_FILTER_CONSTANTS.nights_suffix}`;
        const isActive = this.isFilterActive(filterOptionItem);
        let ariaLabelResult = null;

        if (this.states) {
            const ariaSelected = isActive ?
                this.states.selected :
                this.states.unselected;
            let ariaState = '';

            if (filterOptionItem.disabled) {
                ariaState = isActive ?
                    this.states.unavailable :
                    this.states.disabled;
            }

            ariaLabelResult = `${ariaLabel}, ${ariaSelected}, ${ariaState}`;
        }

        return ariaLabelResult;
    }

    /**
     * Move the focus at the previous position, if the current position is the first one
     * the focus will be moved in the last position
     * @param index current position
     */
    focusPrevButton(index: number): void {
        const filterLength = index || this.contextualFilterList.length;

        this.contextualFilterList[filterLength - 1].nativeElement.focus();
    }

    /**
     * Move the focus at the next position, if the current position is the last one
     * the focus will be moved in the first position
     * @param index current position
     */
    focusNextButton(index: number): void {
        if (index === (this.contextualFilterList.length - 1)) {
            this.contextualFilterList[0].nativeElement.focus();
        } else {
            this.contextualFilterList[index + 1].nativeElement.focus();
        }
    }

    /**
     * Changes to make after view init
     */
    ngAfterViewChecked(): void {
        this.contextualFilterList = this.contextualFilterButtons.toArray();
    }

    /**
     * Emit the analyticsDataModel event setting the track link id or action for the current filter clicked
     * @param action add or remove
     * @param filterOptionItem filter clicked
     */
    emitAnalyticsTrackingEvent(action: string, filterOptionItem?: OptionItem): void {
        const analyticsDataModel: AnalyticsDataModel = {
            trackLinkID: `${this.trackLinkID}${action}`
        };

        if (filterOptionItem) {
            analyticsDataModel.facetsUtilized = [
                {
                    name: filterOptionItem.label,
                    action: filterOptionItem.selected ?
                        this.analyticsConstants.actionAdd :
                        this.analyticsConstants.actionRemove
                }
            ];
        }

        this.analyticsTrackingEvent.emit(analyticsDataModel);
    }
}
