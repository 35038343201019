<div class="stateroom-container">
    <!-- Warning Messages go here -->
    <ng-template [ngTemplateOutlet]="warningsContainerRef"></ng-template>
    <div class="stateroom-item-wrapper" *ngIf="!showAcceptance">
        <div class="stateroom-item">
            <!-- Stateroom Header -->
            <header class="accessible-room-wrapper__header">
                <span class="stateroom-label" aria-hidden="true">
                    {{ 'stateroom.stateroomNumber' | translate: { stateroomIndex: (index + 1) } }}
                </span>
            </header>
            <!-- Accessible Room Checkbox -->
            <div class="accessible-room-wrapper">
                <div class="accessible-room"
                    tabindex="0"
                    #accessibleRoomCheckbox
                    role="checkbox"
                    [attr.disabled]="disableAccessibleRoom || null"
                    [attr.checked]="stateroom.accessible"
                    [attr.aria-disabled]="disableAccessibleRoom || null"
                    [attr.aria-checked]="stateroom.accessible"
                    [attr.aria-label]="'stateroom.accessibleRoomAria' | translate: { stateroomIndex: (index + 1) }"
                    (click)="showAccessibleMessage($event)"
                    (keydown.enter)="showAccessibleMessage($event)"
                    (keydown.tab)="firstItemTabbed.emit($any($event))">
                    <wdpr-checkbox
                        aria-hidden="true"
                        tabindex="-1"
                        [checked]="stateroom.accessible"
                        [attr.disabled]="disableAccessibleRoom || null">
                        <div class="accessible-text-section">
                            <span class="accessible-room-accessible-text" translate>
                                {{ ( isDclShipAdaPhase2 ? 'stateroomADA.accessibleRoom' : 'stateroom.accessibleRoom' ) | translate }}
                            </span>
                            <i aria-hidden="true" class="di di-accessibility accessible-icon"></i>
                            <i aria-hidden="true" class="di di-dlp-hearing-disabilities accessible-icon" *ngIf="isDclShipAdaPhase2"></i>
                        </div>
                    </wdpr-checkbox>
                </div>
                <ng-container *ngIf="isDclShipAdaPhase2; then accessiblePdf"></ng-container>
            </div>
            <!-- accessibleDisclaimers -->
            <ng-container *ngIf="isDclShipAdaPhase2 && showDisclaimers; then accessibleContent_isDclShipAdaPhase2"></ng-container>
            <!--Plus-Minus RA Component-->
            <div class="counter-wrapper">
                <div class="counter-item">
                    <span
                        #adultsLabel
                        class="counter-label"
                        [ngClass]="{ 'has-error': adultsError }"
                        [attr.tabindex]="adultsError ? 0 : null"
                        [attr.aria-label]="adultsError ? adultsAriaLabel : null"
                        translate>
                        stateroom.adultsLabel
                    </span>
                    <wdpr-counter
                        class="wdpr-counter-wrapper"
                        field-name="adults"
                        [attr.minus-alt]="'stateroom.minusAltForAdults' | translate: { adults: stateroom.adultCount }"
                        [attr.counter-alt]="'stateroom.countAltForAdults' | translate: { adults: stateroom.adultCount }"
                        [attr.plus-alt]="'stateroom.plusAltForAdults' | translate: { adults: stateroom.adultCount }"
                        [attr.upper-limit]="constants.adultsUpperLimit"
                        [attr.lower-limit]="constants.adultslowerLimit"
                        [value]="stateroom.adultCount"
                        (value-changed)="$any($event.target).value !== 0 && updateStateroom($any($event), true)">
                    </wdpr-counter>
                </div>
                <div class="counter-item">
                    <span
                        #childrenLabel
                        class="counter-label"
                        [ngClass]="{ 'has-error': childrenError }"
                        [attr.tabindex]="childrenError ? 0 : null"
                        [attr.aria-label]="childrenError ? childrenAriaLabel : null"
                        translate>
                        stateroom.childsLabel
                    </span>
                    <wdpr-counter
                        class="wdpr-counter-wrapper"
                        field-name="childrens"
                        [attr.minus-alt]="'stateroom.minusAltForChilds' | translate: { childs: stateroom.childCount }"
                        [attr.counter-alt]="'stateroom.countAltForChilds' | translate: { childs: stateroom.childCount }"
                        [attr.plus-alt]="'stateroom.plusAltForChilds' | translate: { childs: stateroom.childCount}"
                        [attr.upper-limit]="constants.childsUpperLimit"
                        [value]="stateroom.childCount"
                        (value-changed)="updateStateroom($any($event), false)">
                    </wdpr-counter>
                </div>
            </div>
            <!-- Age selector -->
            <div class="age-selector-wrapper" *ngIf="stateroom.nonAdultAges.length > 0">
                <span
                    class="age-selector-label"
                    [ngClass]="{ 'has-error': errors.infantsPerAdultRestriction }"
                    translate>
                    stateroom.ageChildren
                </span>
                <div class="age-selector-container">
                    <wdpr-single-select
                        *ngFor="let child of stateroom.nonAdultAges; let childIndex = index"
                        #childSelectors
                        class="age-options"
                        [label]="'stateroom.childLabel' | translate: { childNumber: (childIndex + 1) }"
                        [options]="options.ages"
                        [value]="getChildAge(child)"
                        required-field="true">
                    </wdpr-single-select>
                </div>
            </div>
        </div>
        <div class="stateroom-remove-wrapper">
            <button
                class="dcl-link travel-party-remove-stateroom"
                *ngIf="checkForMoreStaterooms"
                attr.aria-label="{{ 'stateroom.removeButton' | translate }} {{ 'stateroom.stateroomNumber' | translate: { stateroomIndex: (index + 1) } }}"
                (click)="removeStateroom($event, stateroom)"
                (keydown.Tab)="lastItemTabbed.emit($any($event))"
                translate>
                stateroom.removeButton
            </button>
        </div>
    </div>
    <ng-container *ngIf="!isDclShipAdaPhase2; then accessibleContentTmp"></ng-container>
</div>

<ng-template #accessiblePdf>
    <div class="accessible-pdf__container">
        <a class="accessible-pdf__link"
            target="_blank"
            [attr.aria-label]="constants.shipADAPhase2.PDFAria | translate"
            [attr.href]="constants.shipADAPhase2.PDFLink | translate"
            translate>
            {{ constants.shipADAPhase2.PDFLabel }}
        </a>
    </div>
</ng-template>

<ng-template #accessibleContent_isDclShipAdaPhase2>
    <dcl-ada2-accessible-room
        class="ada2-accessible-room"
        [accessibleBehavior]="accessibleBehavior"
        [errors]="errors"
        [stateroom]="stateroom"
        (disclaimerAccepted)="handleCheckUnderstand($any($event))"
        (radioButtonChanged)="handleADA2RadioButtonChange()">
    </dcl-ada2-accessible-room>
</ng-template>

<ng-template #accessibleContentTmp>
    <div class="accessible-container" #accessibleContent tabindex="-1">
        <ng-container *ngIf="showAcceptance">
            <span class="accessible-stateroom-label">{{ 'stateroom.stateroomNumber' | translate: { stateroomIndex: (index + 1) } }}</span>
            <div class="accessible-content">
                <div class="accessible-info" translate>
                    stateroom.accessibleInfo
                </div>
                <div class="accessible-options">
                    <button class="cancel-accessible dcl-link"
                        attr.aria-label="{{ 'stateroom.accessibleCancelAria' | translate }}"
                        (click)="addAccessible($event, false)"
                        translate>
                        stateroom.accessibleCancel
                    </button>
                    <wdpr-button
                        class="confirm-accessible"
                        theme="secondary"
                        (click)="addAccessible($event, true)"
                        (touchstart)="addAccessible($any($event), true)"
                        attr.aria-label="{{ 'stateroom.accessibleConfirmAria' | translate }}"
                        (keydown.tab)="lastItemTabbed.emit($any($event))">
                        <span>{{ 'stateroom.accessibleConfirm' | translate }}</span>
                    </wdpr-button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #warningsContainerRef>
    <div class="warning-messages-container"
        tabindex="-1"
        #warningMessageContainerRef
        [ngClass]="{'tp-box': showTravelPartyBox && !checkErrors()}">
        <dcl-warning-messaging
            class="warning-message"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.primaryUnderAge | translate"
            [showMessage]="errors.primaryUnderAgeWarning">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            class="warning-message"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.accessibleRoom | translate"
            [showMessage]="disableAccessibleRoom">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            class="warning-message"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.infantRestriction | translate"
            [showMessage]="errors.infantRestriction">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            class="has-error"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.infantsPerAdult | translate"
            [showMessage]="errors.infantsPerAdultRestriction">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            class="has-error"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.maxCapacity | translate: { url: constants.callUrl }"
            [showMessage]="childrenError && !errors.disneyPlusMaxGuestRestriction">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            class="warning-message"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.onlyOneAdult | translate"
            [showMessage]="errors.onlyOneAdultWarning">
        </dcl-warning-messaging>
        <dcl-warning-messaging
            class="has-error"
            [configuredStyles]="true"
            messageType="warning"
            [message]="constants.warningMessageI18N.disneyPlusMaxGuest | translate: { url: constants.callUrl }"
            [showMessage]="errors.disneyPlusMaxGuestRestriction">
        </dcl-warning-messaging>
    </div>
</ng-template>
