export const AVATAR_HEADER_CONSTANTS = {
    adultAge: 18,
    eighteenAgePlus: '18+',
    defaultAltText: 'image',
    defaultsAvatars: {
        goofy: 'https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-standard-assets/' +
            'disney-cruise-line-navigator-app/avatars/DCL_Default_Avatars_Goofy_1x.png',
        daisy: 'https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-standard-assets/' +
            'disney-cruise-line-navigator-app/avatars/DCL_Default_Avatars_Daisy_1x.png',
        donald: 'https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-standard-assets/' +
            'disney-cruise-line-navigator-app/avatars/DCL_Default_Avatars_Donald_1x.png',
        minnie: 'https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-standard-assets/' +
            'disney-cruise-line-navigator-app/avatars/DCL_Default_Avatars_Minnie_1x.png',
        mickey: 'https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-standard-assets/' +
            'disney-cruise-line-navigator-app/avatars/DCL_Default_Avatars_Mickey_1x.png',
        pluto: 'https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-standard-assets/' +
            'disney-cruise-line-navigator-app/avatars/DCL_Default_Avatars_Pluto_3x.png',
    },
    signedInAvatar: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/' +
        'avatars/180x180/RetAvatar_180x180_Default_SignedIn.png'
};
