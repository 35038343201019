import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CART_CONSTANTS } from '../cart.constants';
import { CartDelete } from '../interfaces/cart-delete.interface';

@Injectable()
export class CartRestService {
    private constants = CART_CONSTANTS;
    private baseUrl: string;

    constructor(private httpClient: HttpClient) {}

    /**
     * Set base URL from the Spa that uses this service
     * It is mandatory to configure this url in the appModule
     * @param url base url to be set
     */
    setBaseUrl(url: string): void {
        this.baseUrl = url;
    }

    /**
     * Cart Delete
     * @param data cart delete data
     * @param data.cartId cart identifier
     * @param data.voyageId voyage identifier
     * @returns Observable for cart delete process
     */
    deleteCart(data: CartDelete): Observable<null> {
        const path = this.constants.ENDPOINTS.DELETE_CART
            .replace('{cartId}', data.cartId)
            .replace('{voyageId}', data.voyageId);

        return this.httpClient.delete<null>(
            `${this.baseUrl}${path}`
        );
    }
}
