<div class="option-item-container"
    tabindex="0"
    role="checkbox"
    [ngClass]="{'disabled': option.disabled}"
    [attr.aria-label]="content.label"
    [attr.checked]="option.selected"
    [attr.aria-checked]="option.selected"
    [attr.disabled]="option.disabled"
    [attr.aria-disabled]="option.disabled"
    (click)="toggleCheck(option, $event)"
    (keyup.enter)="toggleCheck(option, $event)"
    (keydown.tab)="onTabPress($any($event))">
    <wdpr-checkbox #checkboxItem class="option-item-checkbox" tabindex="-1" aria-hidden="true"
        [checked]="option.selected" [disabled]="option.disabled">
        <span class="checkbox-label">
            {{ content.text }}
        </span>
    </wdpr-checkbox>
</div>
