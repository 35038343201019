import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'dcl-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
    @Input() ariaHidden: boolean = true;
    @Input() control: AbstractControl;
    @Input() errorMessage: {};
    @Input() showSingleError: boolean = false;

    errorListTypes: Array<String> = [];

    constructor() { }

    ngOnInit() {
        this.getErrorTypes();
        this.getErrors();
    }

    getErrorMessage(errorType): String {
        return this.errorMessage && this.errorMessage[errorType] ?
            this.errorMessage[errorType] :
            'error-messages.default-errors.' + errorType;
    }

    getErrorTypes() {
        this.errorListTypes = this.control && this.control.errors ? Object.keys(this.control.errors) : [];

        if (this.showSingleError && this.errorListTypes.length > 1) {
            this.errorListTypes = [ this.errorListTypes[0] ];
        }
    }

    getErrors() {
        if (this.control) {
            this.control.valueChanges.subscribe(
                () => {
                    if (this.control.invalid && (this.control.dirty || this.control.touched)) {
                        this.getErrorTypes();
                    }
                }
            );
        }
    }
}
