import { Component, OnInit, Input } from '@angular/core';

import { EVENT_FILTER_CONSTANTS } from './event-filter.constants';

@Component({
    selector: 'dcl-event-filter',
    templateUrl: './event-filter.component.html',
    styleUrls: ['./event-filter.component.scss']
})
export class EventFilterComponent implements OnInit {
    @Input() promoCode: string;
    @Input() portOfCall: string;

    labelTranslationKey: string;
    nameTranslationKey: string;
    constants = EVENT_FILTER_CONSTANTS;
    isSpecialOffer: boolean;

    constructor() { }

    ngOnInit() {
        this.isSpecialOffer = !!this.promoCode;

        this.isSpecialOffer ?
            this.setSpecialOfferAttributes() :
            this.setFilteredPortAttributes();
    }

    /**
     * set attributes for special offer
     */
    setSpecialOfferAttributes(): void {
        this.labelTranslationKey = this.constants.translationsKeys.specialOffer;
        this.nameTranslationKey = this.getSpecialOfferName();
    }

    /**
     * set attributes for filtered port
     */
    setFilteredPortAttributes(): void {
        this.labelTranslationKey = this.constants.translationsKeys.filteredPort;
        this.nameTranslationKey = this.getPortName();
    }

    /**
     * retrieve the special offer translation key
     * @returns special offer name
     */
    getSpecialOfferName(): string {
        return this.constants.specialOfferNameTemplate.replace('{promoCode}', this.promoCode);
    }

    /**
     * Get the port name based on portId
     * @returns string port name
     */
    getPortName(): string {
        return this.portOfCall.replace('ports_', '').replace(/-/g, ' ');
    }
}
