import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, mergeMap, map, finalize, share } from 'rxjs/operators';
import { throwError, Observable, of } from 'rxjs';
import * as momentModule from 'moment';

import { LOGIN_CONSTANTS } from './../login-modal.contants';

@Injectable()
export class LoginService {
    constants = LOGIN_CONSTANTS;
    moment = momentModule;
    private baseUrl: String;
    private observableAuthzStatus;

    constructor(
        private cookieService: CookieService,
        private http: HttpClient
    ) { }

    /**
     * Set baseUrl from SPA
     * @param baseUrl
     */
    setBaseUrl(baseUrl: String): void {
        this.baseUrl = baseUrl;
    }

    /**
     * Send Data and status to authenticate
     * @param username user name or email to authenticate
     * @param password password to authenticate
     * @param remember validate remember option to save user
     */
    login(username: string, password: string, remember: boolean): Observable<{}> {
        const postData = {
            username,
            password,
            rememberMe: (remember ? '1' : '0')
        };

        return this.getStatus()
            .pipe(
                mergeMap(response => {
                    return this.authenticate(postData, response);
                }),
                catchError((error): Observable<{}> => {
                    return throwError(error);
                })
            );
    }

    /**
     * Post login data and set isLoggedIn cookie
     * @param post object with login data
     * @param status authentication status
     */
    authenticate(post, status): Observable<{}> {
        const url = `${this.baseUrl}/authentication/login-form/`;
        const headers = new HttpHeaders({
            [status.csrf.header]: status.csrf.token,
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest'
        });

        return this.http
            .post<{}>(
                url,
                post,
                { headers, observe: 'response' }
            )
            .pipe(
                map((res) => {
                    this.setLoggedInCookie({ isLoggedIn: true, isSecure: true });

                    return res;
                })
            );
    }

    /**
     * get isLoggedIn cookie to know if the user is login
     * @returns {Observable}
     */
    isLoggedIn(): Observable<boolean> {
        if (this.getStateFromCookie()) {
            return of(true);
        } else {
            return this.getAndCheckStatus();
        }
    }

    /**
     * get user authentication status and check if it is valid
     */
    private getAndCheckStatus(): Observable<boolean> {
        if (!this.observableAuthzStatus) {
            this.observableAuthzStatus = this.getStatus()
                .pipe(
                    share(),
                    map((response) => {
                        const isLoggedIn = this.checkLogin(response);

                        return isLoggedIn;
                    }),
                    catchError(err => {
                        return of(false);
                    }),
                    finalize(() => {
                        delete this.observableAuthzStatus;
                    })
                );
        }

        return this.observableAuthzStatus;
    }

    private getStateFromCookie(): boolean {
        const isLoggedInCookie = this.cookieService.get('isLoggedIn');
        const loginDetails = this.decodeCookie(isLoggedInCookie);

        return this.checkLogin(loginDetails);
    }

    /**
     * get dclTravelAgentInfo cookie to know if the user is login as Travel Agent
     * @return {boolean} Travel Agent login status
     */
    isTravelAgentLoggedIn(): boolean {
        return !!this.cookieService.get('dclTravelAgentInfo');
    }

    /**
     * Save isLoggedIn cookie from status response
     * @param {Object} loginDetails status response
     */
    setLoggedInCookie(loginDetails): void {
        const expires = this.moment().add(this.constants.durationIsLoggedInCookie, 'minutes').toDate();
        const cookieValue = JSON.stringify({
            isLoggedIn: loginDetails.isLoggedIn,
            isSecure: loginDetails.isSecure
        });

        this.cookieService.set('isLoggedIn', cookieValue, expires, '/');
    }

    /**
     * Set userName or Email when the user select remember me
     * @param {String} user userName or Email
     */
    setUserLocalStorage(user: string): void {
        localStorage.setItem('loginUsernameOrEmail', user);
    }

    /**
     * get userName or Email from local storage
     * @return {String} user userName or Email
     */
    getUserLocalStorage(): string {
        return localStorage.getItem('loginUsernameOrEmail');
    }

    /**
     * Validate if the user is login or not
     * @return {Boolean} login Status
     */
    private checkLogin(loginDetails): boolean {
        return !!loginDetails && loginDetails.isLoggedIn && loginDetails.isSecure;
    }

    /**
     * Decode isLoggedIn Cookie
     * @return {Object} decoded cookie
     */
    private decodeCookie(cookie: string): { isLoggedIn: boolean, isSecure: boolean } {
        try {
            const decodedCookie = decodeURIComponent(cookie);

            return JSON.parse(decodedCookie);
        } catch (err) {
            // cookie is not decodable using decodeURIComponent or it is not a Json object
            return undefined;
        }
    }

    /**
     * Http call to get user authentication status
     * @returns status call
     */
    getStatus(): Observable<{}> {
        const url = `${this.baseUrl}/authentication/status/`;

        return this.http.get<{}>(url);
    }
}
