import { AnalyticsService, ConfigService as AnalyticsConfigService } from '@wdpr/angular-analytics';
import { CookieService } from 'ngx-cookie-service';
import { delay, filter } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PageKeyService } from '@wdpr/ra-angular-page-key';

import { ANALYTICS_CONSTANTS } from './analytics.constant';
import { ConfigService } from '../config/config.service';
import { LOCALE } from '../../locale/locale.constants';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { TravelAgentService } from '../../travel-agent/travel-agent.service';

@Injectable({
    providedIn: 'root'
})
export class DCLAnalyticsService {
    pageKey = false;
    scheduledAnalyticsTrackPages: object;
    scheduledAnalyticsTrackLinks = [];

    constructor(
        private analyticsService: AnalyticsService,
        private analyticsConfigService: AnalyticsConfigService,
        private config: ConfigService,
        private cookieService: CookieService,
        private pageKeyService: PageKeyService,
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private travelAgentService: TravelAgentService
    ) { }

    initAnalytics(href: string) {
        // remove first / in url to avoid duplicate it when analytics url is made
        const baseHref = href.substring(1);
        const autoPageViewTrackingTiming = ANALYTICS_CONSTANTS.AUTO_PAGE_VIEW_TRACKING_TIMING;
        // This value sets the path for the analytics JSON files
        // There are also public properties to set server and other values
        this.analyticsConfigService.serverHost = this.config.getValue('baseUrl');
        this.analyticsConfigService.serverPath = baseHref + ANALYTICS_CONSTANTS.SERVER_PATH;
        this.analyticsConfigService.autoPageViewTrackingTiming = autoPageViewTrackingTiming;
        this.initSubscribers();
    }

    /**
     * Initialize subscribers to handle analytics updates when router changes
     */
    initSubscribers(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            // Remove pageKey to prevent tracking analytics before configuration is ready
            this.pageKey = false;
        });

        this.pageKeyService
            .pipe(
                // wait until analytics module defines pageKey from its side
                delay(ANALYTICS_CONSTANTS.ANALYTICS_PAGE_KEY_TIMING)
            ).subscribe(() => {
                this.pageKey = true;

                // Force analytics to get the latest configurations without tracking anything when
                // main service has not finished yet
                if (!this.scheduledAnalyticsTrackPages && !this.scheduledAnalyticsTrackLinks.length) {
                    this.analyticsService.getModel();
                } else {
                    // track analytics when main service finishes before pagekey service
                    if (this.scheduledAnalyticsTrackPages) {
                        this.updateAnalytics(this.scheduledAnalyticsTrackPages);
                        delete this.scheduledAnalyticsTrackPages;
                    }

                    if (this.scheduledAnalyticsTrackLinks.length) {
                        this.scheduledAnalyticsTrackLinks.forEach(data => {
                            this.trackLink(data.trackLinkEvent, data.modelProps);
                        });
                        this.scheduledAnalyticsTrackLinks = [];
                    }
                }
            });
    }

    /**
     * Check if pageKey has been defined before update the model
     * @param modelProps data to add to the model
     */
    updateAnalyticsModel(modelProps?: object) {
        this.setLocationValues(modelProps);

        if (this.pageKey) {
            this.updateAnalytics(modelProps);
        } else {
            this.scheduledAnalyticsTrackPages = modelProps;
        }
    }

    /**
     * Set an object to update the analytics model for a Travel Agent
     */
    updateAnalyticsModelTravelAgent(): object {
        const model = {};

        if (this.travelAgentService.isTravelAgentLoggedIn()) {
            model['DTALoginId'] = this.travelAgentService.getTravelAgencyId();
            model['store'] = ANALYTICS_CONSTANTS.TRAVEL_AGENT.STORE;
            model['storeType'] = ANALYTICS_CONSTANTS.TRAVEL_AGENT.STORETYPE;
        }

        return model;
    }

    /**
     * Updates the analytics model with page-specific data
     * @param {object} modelProps data to add to the model
     * @returns Model Promise
     */
    updateAnalytics(modelProps?: object): Promise<void | object> {
        return this.analyticsService.getModel().then(model => {
            this.setLocationValues(modelProps);

            Object.assign(model, modelProps || {}, this.updateAnalyticsModelTravelAgent());
            this.addLoggedInEventTravelAgent(model);
            this.analyticsService.update();
        });
    }

    /**
     * Updates global processors with page-specific data
     */
    updateGlobalProcessors(data?: object) {
        const processor: object = {
            facility: {
                id: data['pageId'],
                name: data['pageId']
            }
        };

        this.setLocationValues(processor);

        Object.assign(processor, data);
        this.analyticsConfigService.addGlobalProcessors(processor);
    }

    /**
     * Get the model and send the model for tracking.
     * @param {object} modelProps data to add to the model
     */
    trackElement(modelProps?: object): void {
        this.analyticsService.getModel()
            .then((model: {}) => {
                Object.assign(model, modelProps || {});

                if (this.travelAgentService.isTravelAgentLoggedIn()) {
                    model['DTALoginId'] = this.travelAgentService.getTravelAgencyId();
                    model['store'] = ANALYTICS_CONSTANTS.TRAVEL_AGENT.STORE;
                    model['storeType'] = ANALYTICS_CONSTANTS.TRAVEL_AGENT.STORETYPE;
                }

                this.analyticsService.trackElement(model);
        });
    }

    /**
     * Get the model, set the link ID, and send the model back for tracking.
     * Recently the eventsPropOverride param has been added to allow for events property to be set
     * @param {string} trackLinkEvent Event to track
     * @param {object} modelProps data to add to the model
     * @param {string} eventsPropOverride backwards-compatible that allows for events property to be set
     */
    trackLink(trackLinkEvent: string, modelProps?: object, eventsPropOverride: string | string[] = ''): void {
        if (this.pageKey) {
            this.analyticsService.getModel()
                .then((model: {}) => {
                    this.setLocationValues(model);
                    Object.assign(model, modelProps || {});

                    if (this.travelAgentService.isTravelAgentLoggedIn()) {
                        model['DTALoginId'] = this.travelAgentService.getTravelAgencyId();
                        model['store'] = ANALYTICS_CONSTANTS.TRAVEL_AGENT.STORE;
                        model['storeType'] = ANALYTICS_CONSTANTS.TRAVEL_AGENT.STORETYPE;
                    }
        /**
         * The "events" property has been set to '' disregarding the original "events" sent in the parameters
         * and this is the expected behavior that all SPAs consuming this service are expecting. So as discussed
         * with Fabian Moreno and Ariel Vera, a way to allow events property to be set and at the same time not
         * breaking any code using this function, is to add an additional parameter.
         *
         * This parameter "eventsPropOverride" will allow for "events" to be set. If this parameter is not sent,
         * the function will behave as it always has, setting "events" to blank string regardless of the model sent
         */
                    model['events'] = eventsPropOverride;
                    model['linkId'] = trackLinkEvent;
                    this.analyticsService.trackLink(model);
                });
        } else {
            this.setLocationValues(modelProps);

            this.scheduledAnalyticsTrackLinks.push({
                trackLinkEvent,
                modelProps
            });
        }
    }

    /**
     * Add LoggedIn event for Travel Agent
     * @param model analytics model
     */
    private addLoggedInEventTravelAgent(model: object): void {
        const sessionStorageKey = ANALYTICS_CONSTANTS.TRAVEL_AGENT.LOGGED_IN_SESSION_STORAGE_ID;

        if (this.travelAgentService.isTravelAgentLoggedIn() &&
            this.sessionStorageService.getItem(sessionStorageKey) === 'true') {
            model['events'] = model['events'] || [];
            model['events'].push(ANALYTICS_CONSTANTS.TRAVEL_AGENT.LOGGED_IN_EVENT);
            this.sessionStorageService.removeItem(sessionStorageKey);
        }
    }

    /**
     * Sets the location values for the analytics model
     * @param modelProps general analytics model
     */
    setLocationValues(modelProps: object = {}): void {
        const akamaiCookie = this.cookieService.get(LOCALE.AKAMAI_COOKIE) ||
            this.cookieService.get(LOCALE.AKAMAI_COOKIE_PROD);
        const [ defaultLanguage, defaultLocale ] = LOCALE.DEFAULT_LOCALE.split('-');

        try {
            if (modelProps && akamaiCookie) {
                const akamaiCookieObject = JSON.parse(akamaiCookie);
                const localeArray = (akamaiCookieObject[LOCALE.CONTENT_LOCALE] as string).split('_');

                modelProps[LOCALE.CONTENT_LANGUAGE] = localeArray[0] || defaultLanguage;
                modelProps[LOCALE.CONTENT_LOCALE] = localeArray[1] || defaultLocale;
            }
        } catch (error) {
            modelProps[LOCALE.CONTENT_LANGUAGE] = defaultLanguage;
            modelProps[LOCALE.CONTENT_LOCALE] = defaultLocale;
        }
    }
}
