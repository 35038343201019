/**
 * See https://all.docs.genesys.com/WID/Current/SDK/WebChat-combined#API_commands for API commands
 */
import { CoreWidgetConfig, GenesysChatConfig, WebchatPlugin, WebchatPluginOpenOptions } from '../../interfaces';
import { GenesysChatConstants } from '../../genesys-chat.constants';
import { WindowRef } from '../../../window-ref/window-ref.service';

export type GenesysChatEvents = 'ready' | 'opened' | 'started' | 'submitted' | 'rejected' | 'completed' |
    'cancelled' | 'closed' | 'minimized' | 'unminimized' | 'messageAdded';

export class GenesysChatService {
    webchatPlugin: WebchatPlugin;
    private constants = GenesysChatConstants;

    constructor(private genesysChatConfig: GenesysChatConfig, private windowRef: WindowRef) {}

    /**
     * Method to load genesys webchat plugin
     * @returns A Promise with resolution status.
     *  resolved - WebChat is successfully loaded.
     *  rejected - WebChat load failed.
     */
    loadWebchatPlugin(): Promise<void> {
        const nativeWindow = this.windowRef.nativeWindow;
        const { main = <CoreWidgetConfig> {}, webchat } = this.genesysChatConfig;
        const document = nativeWindow.document;
        const head = document.head;
        const script: HTMLScriptElement = document.createElement('script');

        main.themes = this.constants.THEMES;
        main.i18n = this.constants.DEFAULT_I18N;

        if (!main.theme) {
            main.theme = this.constants.DEFAULT_THEME;
        }

        script.type = 'text/javascript';
        script.src = `${this.genesysChatConfig.baseUrl}/${this.constants.CX_BUS_SCRIPT}`;

        if (!nativeWindow[this.constants.NAMESPACE]) {
            nativeWindow[this.constants.NAMESPACE] = {};
        }

        if (!nativeWindow[this.constants.GT_NAMESPACE]) {
            nativeWindow[this.constants.GT_NAMESPACE] = {};
        }

        return new Promise((resolve, reject) => {
            nativeWindow[this.constants.NAMESPACE].widgets = {
                main,
                webchat,
                onReady: () => {
                    this.webchatPlugin =
                        nativeWindow[this.constants.NAMESPACE].widgets.bus.registerPlugin(this.constants.PLUGIN_NAME);

                    resolve(null);
                }
            };

            script.onerror = (e: Event | string) => reject(e);
            script.onload = () => {
                const cxBus = nativeWindow[this.constants.CX_BUS];

                cxBus.configure({ pluginsPath: `${this.genesysChatConfig.baseUrl}/${this.constants.PLUGIN_PATH}/` });
                cxBus.loadPlugin(this.constants.WIDGETS_CORE);
            };

            head.appendChild(script);
        });
    }

    /**
     * Opens the WebChat UI.
     * @param options
     * @returns A Promise with resolution status.
     *  resolved - WebChat is successfully opened.
     *  rejected - WebChat is already open.
     */
    open(options?: WebchatPluginOpenOptions): Promise<void> {
        return new Promise((resolve, reject) => {
            this.webchatPlugin.command('WebChat.open', options)
                .done(resolve)
                .fail(reject);
        });
    }

    /**
     * Subscribe to chat events
     * @param eventName
     * @param callback
     */
    subscribe(eventName: GenesysChatEvents, callback: Function): void {
        this.webchatPlugin.subscribe(`WebChat.${eventName}`, callback);
    }
}
