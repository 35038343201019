import { Injectable } from '@angular/core';

import { FLOWS, FLOW_VALUES } from './reservation-flow.constants';
import { ReservationFlow } from './reservation-flow.interface';

@Injectable({
    providedIn: 'root',
})
export class ReservationFlowService {
    private flow: ReservationFlow;

    constructor() { }

    setFlow(flowValue: string): void {
        this.flow = flowValue && FLOW_VALUES[flowValue];
    }

    getFlow(): {} {
        return this.flow;
    }

    isModFlow(): boolean {
        return !!this.flow;
    }

    isModSailingFlow(): boolean {
        return this.flow && this.flow.id === FLOWS.sailing;
    }
}
