import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';

import { BaseModalV2 } from '../base-modal/v2/interfaces/base-modal-v2.interface';
import { ExtendedSelect } from '../extended-select/interfaces/extended-select.interface';
import { ExtendedSelectOption } from '../extended-select/interfaces/extended-select-option.interface';
import { EXTENDED_SELECT_CONSTANTS } from '../extended-select/extended-select.constants';
import { ExtendedSelectType } from '../extended-select/enums/extended-select.type.enum';
import CONSTANS from './constants/modal-dropdown.constants';
import { AVATAR_HEADER_CONSTANTS } from '../avatar-header/avatar-header.constants';

/**
 * Base modal Mobile Component
 *
 * It has body container with:
 * - Image
 * - Title
 * - Short description
 * Defined in modalData input.
 *
 * A header for mobile version
 * And buttons section
 *
 */
@Component({
    selector: 'dcl-modal-dropdown',
    templateUrl: './modal-dropdown.component.html',
    styleUrls: ['./modal-dropdown.component.scss']
})
export class ModalDropdownComponent {
    @Input() baseModal: BaseModalV2;
    @Input() extendedSelectData: any[];
    @Input() extendedSelectSelected: ExtendedSelectOption;
    @Input() timeoutIsMobile = 300;

    @Output() modalSelectionChange = new EventEmitter<{}>();

    @ViewChild('avatarTemplate', { static: false }) avatarTemplate: TemplateRef<HTMLElement>;
    @ViewChild('defaultTemplate', { static: false }) defaultTemplate: TemplateRef<HTMLElement>;
    @ViewChild('linkTemplate', { static: false }) linkTemplate: TemplateRef<HTMLElement>;
    @ViewChild('groupTemplate', { static: false }) groupTemplate: TemplateRef<HTMLElement>;
    @ViewChild('dropdownDocument', { static: false }) dropdownDocument: ElementRef<HTMLElement>;

    isMobile: boolean;
    templatesRefTypes = EXTENDED_SELECT_CONSTANTS.templatesRefTypes;
    extendedSelectTypes = ExtendedSelectType;
    focusedListItemIndex = 0;

    readonly arrowUp = 38;
    readonly arrowDown = 40;
    readonly enterKey = 13;
    readonly eighteenAgePlus = AVATAR_HEADER_CONSTANTS.eighteenAgePlus;

    /**
     * Emit modal action every time an option is clicked
     * @param event     click event
     * @param option    selected option
     */
    onSelectionChange(event: PointerEvent | KeyboardEvent, option: ExtendedSelectOption): void {
        if (option.type !== ExtendedSelectType.link) {
            this.extendedSelectSelected = option;
        }

        // trigger option callback if is defined
        if (typeof option.onSelected === 'function') {
            option.onSelected(option);
        }

        this.openCloseModal(false);

        this.modalSelectionChange.emit({
            event,
            option
        });
    }

    /**
     * Get the templateRef to load the partial template or partial placeholder, by default if "type" property is
     * not defined the "default" template or placeholder is returned
     *
     * @param option    option Interface element
     * @param type      the string type of the TemplateRef, could be "Template"(by default) or "Placeholder"
     * @returns         the string template name based on its type
     */
    getTemplateRef(
        option: ExtendedSelectOption,
        type: string = this.templatesRefTypes.template
    ) {
        return this[this.getType(option) + type];
    }

    /**
     * Get the type of an option
     *
     * @param option    option Interface element
     * @returns         the string "type" of the option if is defined otherwise "default" type is returned
     */
    getType(
        option: ExtendedSelectOption
    ): string {
        return option && option.type ? option.type : ExtendedSelectType.default;
    }

    /**
     * Open or Close the modal based on a state
     *
     * @param isOpen boolean
     */
    openCloseModal(isOpen = false) {
        this.baseModal.openModal = isOpen;
    }

    /**
     * Detect the keydown event from the keyboard
     * @param $event KeyBoardEvent received from the DOM
     * @returns
     */
    @HostListener('window:keydown', ['$event'])
    handlerKeyEvent($event: KeyboardEvent) {
        const code = $event.keyCode || $event.charCode;
        const items = this.dropdownDocument.nativeElement.querySelectorAll(
            `${CONSTANS.LIST_DROPDOWN_ITEM} ${CONSTANS.AVATAR}, ${CONSTANS.LINK}, ${CONSTANS.DEFAULT}`);
        const filtered = Array.prototype.filter.call(items, (node) => node.tagName === CONSTANS.LI &&
            !node.classList.contains(CONSTANS.HIDE_OPTION));
        const itemsLength = filtered.length;

        // Check keyboard arrow pressed
        switch (code) {
            case this.arrowUp:
                if (this.focusedListItemIndex > 0) { this.focusedListItemIndex--; }
                break;
            case this.arrowDown:
                if (this.focusedListItemIndex < itemsLength - 1) { this.focusedListItemIndex++; }
                break;
        }

        filtered[this.focusedListItemIndex].focus();
    }

    /**
     * Catch enter key event
     * @param event Context of li item
     * @param item option selected
     */
    onKeyDownEnterKey(event: KeyboardEvent, item: ExtendedSelectOption) {
        event.stopPropagation();
        this.onSelectionChange(event, item);
    }
}
