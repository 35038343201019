<div class="avatar-header">
    <div class="avatar-text-initials" *ngIf="initials">{{ initials }}</div>
    <img *ngIf="data.avatar && data.avatar.url"
        class="avatar-image"
        aria-hidden="true"
        alt="{{ data.avatar?.title }}"
        src="{{ data.avatar.url }}">
    <div *ngIf="!data.avatar || (data.avatar && !data.avatar.url)"
        class="avatar-image default">
    </div>
    <div *ngIf="label || description"
        class="avatar-text">
        <div class="avatar-text--label guestSensitive">{{ label }}</div>
        <div *ngIf="description"
            class="avatar-text--description">
            {{ description }}
        </div>
    </div>
</div>
