import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TAXES_CONSTANTS } from './taxes-fees-expenses.constants';
import { ToggleService } from '../toggle/toggle.service';

@Injectable({
    providedIn: 'root'
})
export class TaxesFeesExpensesService {
    // determine if the INTL phase-1 toggle is ON
    private isInternationalDirect = false;

    // List(String coma separated or Array) of sailings that GST should be included and displayed
    // Note that this list is managed by Producers
    private sailingsAffected = [];

    // Regex to extract a valid "Sailing ID" part that we will use it to match with producer list
    private regexSailingId = new RegExp(/^[A-Z]{2}\d+/, 'gm');

    // holder for the current sailingId affected
    private sailingId: string;

    constructor(
        private toggleService: ToggleService,
        private translate: TranslateService
    ) {
        this.isInternationalDirect = this.toggleService.getToggle(TAXES_CONSTANTS.toggles.dclInternationalDirect);

        // set the list of sailing affected defined by Producers
        this.translate
            .get(TAXES_CONSTANTS.sailingsContentKey)
            .subscribe((response: string) => {
                this.sailingsAffected = response.replace(/\s+/g, '').split(',');
            });
    }

    /**
     * Set the part of a "sailing id" that we need to use from a possible "sailing id" string
     *
     * @param {String} sailingId    the current Sailing ID
     * @return {TaxesFeesExpensesService}   self instance
     */
    setSailingId(sailingId: string) {
        const res = sailingId.match(this.regexSailingId);

        if (Array.isArray(res)) {
            this.sailingId = res[0];
        }

        return this;
    }

    /**
     * Determine if this item/flow should display GST information based on different strategics
     *
     * Possible strategics to be applied
     * - testSailingId
     *
     * @return {Boolean}    true if this flow/item should display the GST information
     */
    shouldShowGST() {
        let res = false;

        // apply sailingId strategic
        // for this strategic we use isInternationalDirect phase-1 toggle
        // --------------------------------------------------------------------
        if (this.isInternationalDirect && this.sailingId) {
            res = this.testSailingId();
        }

        return res;
    }

    /**
     * Stategic based on the "Sailing ID" entity validation
     * The idea is to check if the current "Sailing ID" is present in the list of the
     * sailings affected that is managed by Producers
     *
     * @return {Boolean}    true if affected otherwise false
     */
    private testSailingId() {
        return !!(this.sailingId &&
            this.sailingsAffected.length &&
            this.sailingsAffected.indexOf(this.sailingId) !== -1);
    }
}
