import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Optional,
    Output,
    ViewChild
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AnalyticsDataModel } from './../../interfaces/analytics-data-model.interface';
import { DocumentRef } from '../../../document-ref/document-ref.service';
import { EventService } from '../../../services/event-service/event.service';
import { Filter } from '../../interfaces/filter.interface';
import { GrayoutService } from '../../services/grayout.service';
import { NavigatorRef } from '../../../navigator-ref/navigator-ref.service';
import { NativeBridgeService } from '../../../native-bridge/native-bridge.service';
import { OptionsGroup } from '../../../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from './../../quick-quote.constants';
import { QuickQuoteService } from './../../services/quick-quote.service';
import { ReservationFlowService } from '../../../services/reservation-flow/reservation-flow.service';
import { TRAVEL_PARTY_CONSTANTS } from '../../../travel-party/travel-party.constants';
import { TravelPartyService } from './../../../travel-party/services/travel-party.service';
import { ViewChildProperties } from '../../../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-quick-quote-modal',
    templateUrl: './quick-quote-modal.component.html',
    styleUrls: ['./quick-quote-modal.component.scss']
})
export class QuickQuoteModalComponent implements OnInit, AfterViewInit {
    @Input() filtersData: Filter[];
    @Input() filterDataSubGroup: Filter;
    @Input() isDclAdvancedBooking: boolean;
    @Input() isDclAdvancedBookingPhase2: boolean;
    @Input() isDclAdvancedBookingPhase3: boolean;
    @Input() isDclShipAdaPhase2: boolean;
    @Input() open: boolean;
    @Input() useLuxliteTheme: boolean;
    @Output() onApplyFilters = new EventEmitter();
    @Output() onClearFilters = new EventEmitter();
    @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter();
    @Output() advanceBookingClickEvent = new EventEmitter();
    @ViewChild('closeButton', { static: false } as ViewChildProperties) closeButton: ElementRef;
    @ViewChild('footer', { static: false } as ViewChildProperties) footerContainer: ElementRef;
    @ViewChild('header', { static: false } as ViewChildProperties) headerContainer: ElementRef;
    @ViewChild('body', { static: false } as ViewChildProperties) bodyContainer: ElementRef;
    @ViewChild('modal', { static: false } as ViewChildProperties) modal: ElementRef;

    buttonLabel: string;
    cancelButtonArialabel: string;
    constants = QUICK_QUOTE_CONSTANTS;
    filterIndex: number;
    filterSelected: Filter;
    isAndroid: RegExpMatchArray;
    isIOS: RegExpMatchArray;
    showClearBtn: boolean = false;
    showFilterType: boolean = false;
    subtitleLabel: string;
    titleLabel: string;
    travelPartyConstants = TRAVEL_PARTY_CONSTANTS;
    isEmbedded = false;
    parentWithScroll: HTMLElement;
    showBaymax = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private documentRef: DocumentRef,
        private eventService: EventService,
        private grayoutService: GrayoutService,
        private navigatorRef: NavigatorRef,
        private quickQuoteService: QuickQuoteService,
        private reservationFlowService: ReservationFlowService,
        private translateService: TranslateService,
        private travelPartyService: TravelPartyService,
        @Optional() private nativeBridgeService?: NativeBridgeService,
        @Optional() private cd?: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.buttonLabel = this.constants.translationKeys.modal.buttonDefault;
        this.titleLabel = this.constants.translationKeys.modal.titleDefault;
    }

    ngAfterViewInit(): void {
        const userAgent = this.navigatorRef.nativeNavigator.userAgent;

        // TODO. Investigate why the RA device svc is not working on mobile
        this.isAndroid = userAgent && userAgent.match(/Android/i);
        this.isIOS = userAgent && userAgent.match(/iPhone|iPad|iPod/i);

        // Conditions to show baymax header
        this.showBaymax = true;

        if (this.nativeBridgeService) {
            this.isEmbedded = this.nativeBridgeService.isEmbedded() && this.reservationFlowService.isModFlow();

            if (this.isEmbedded) {
                this.parentWithScroll = document.querySelector('dcl-modal[open]').shadowRoot.querySelector('#body');
            }
        }

        if (this.isAndroid || this.isIOS) {
            this.modal.nativeElement.shadowRoot.querySelector('#body').removeAttribute('tabindex');
            this.bodyContainer.nativeElement.removeAttribute('tabindex');
        }

        if (this.headerContainer) {
            this.headerContainer.nativeElement.removeAttribute('tabindex');
        }

        this.footerContainer.nativeElement.removeAttribute('tabindex');

        if (this.closeButton) {
            this.closeButton.nativeElement.focus();
        }

        this.documentRef.nativeDocument.querySelector('body').firstElementChild.setAttribute('aria-hidden', 'true');
    }

    /**
     * Closes the modal and emit an event to notify.
     */
    @HostListener('document:keydown.escape')
    closeModal(): void {
        this.cancelAllFilterSelections();
        this.modal.nativeElement.removeAttribute('open');
        this.documentRef.nativeDocument.querySelector('body').firstElementChild.removeAttribute('aria-hidden');
        this.onCloseModal.emit(true);
    }

    /**
     * Sets the selected filter and updates the title and button labels
     * @param filterType
     */
    setFilterSelected(filterType: string): void {
        this.grayoutService.checkGrayout();
        this.analyticsTriggerEvent(filterType);
        this.filterIndex = this.filtersData.findIndex((filter: Filter) => filter.type === filterType);
        this.filterSelected = this.filtersData[this.filterIndex];
        this.titleLabel = this.filterSelected.nameKey;
        this.buttonLabel = this.constants.translationKeys.modal.buttonAfterFilter;
        this.subtitleLabel = filterType !== this.constants.filters['more-filters'].id ?
            this.constants.filters[this.filterSelected.type].wrapperLabel :
            null;
        this.changeDetectorRef.detectChanges();

        if (this.closeButton) {
            this.closeButton.nativeElement.focus();
        }

        this.setFilterDataSubGroup(this.filterSelected);
        this.showBaymax = false;
    }

    /**
     * throws the analytics data model with the tracklinkID when any filter is used when the filterHub is opened
     * besides adds the tracklinkID of the modal button if it is sent like parameter
     * @param  filterType current selected filter
     * @param modalButtonLink tracklink use by the current modal button
     */
    analyticsTriggerEvent(filterType: string, modalButtonLink?: string): void {
        let analyticsModel: AnalyticsDataModel;
        const trackLinkPrefix = this.constants.analytics.cruiseFiltersLink;

        if (this.filterSelected && modalButtonLink) {
            // Case when there is a filter selected
            const analyticsFilterSelected = this.constants.analytics[filterType];

            analyticsModel = {
                trackLinkID: `${trackLinkPrefix}${analyticsFilterSelected.filterButtonLink}_${modalButtonLink}`
            };

            if (filterType === this.constants.filters['travel-party'].id) {
                analyticsModel.bookingSearchPartySize = this.travelPartyService.getAnalyticsPartySize();
            }
        } else {
            // Case when there is not a filter selected
            analyticsModel = {
                trackLinkID: `${trackLinkPrefix}${this.constants.analytics[filterType].filterButtonLink}`
            };
        }

        this.quickQuoteService.trackAnalyticsEvent(analyticsModel);
    }

    /**
     * Clears the filter selected and updates the title and button labels to defaults
     */
    clearFilterSelected(): void {
        delete this.filterSelected;
        delete this.filterIndex;
        this.titleLabel = this.constants.translationKeys.modal.titleDefault;
        this.buttonLabel = this.constants.translationKeys.modal.buttonDefault;
    }

    /**
     * Clear filter applied by type
     */
    clearFiltersByType(): void {
        this.analyticsTriggerEvent(this.filterSelected.type, this.constants.analytics.clearFiltersButtonLink);
        this.quickQuoteService.clearFiltersByType(this.filterSelected.type);

        if (this.filterSelected.type === this.constants.filters['more-filters'].id) {
            this.grayoutService.checkGrayout();

        } else if (this.filterSelected.type === this.constants.filters['destination'].id) {
            this.grayoutService.checkGrayout();
        }
    }

    /**
     * Cancel all of the selections made by the user. This action is related
     * to the clear filters button in 'allFiltersContent' section in the page
     */
    cancelAllFilterSelections(): void {
        this.filtersData.forEach((filterSelected: Filter, filterIndex: number) => {
            this.quickQuoteService.cancelFilterSelections(filterIndex, true);

            if (filterSelected.type === this.constants.filters['more-filters'].id) {
                this.grayoutService.checkGrayout();
            }
        });
    }

    /**
     * Clear all filters applied or selected in the filter HUB.
     */
    clearAllFilters(): void {
        this.onClearFilters.emit();
        this.quickQuoteService.clearAllFilters();
    }

    /**
     * Cancels any selection or unselection in the filter.
     */
    cancelFilterSelections(): void {
        this.analyticsTriggerEvent(this.filterSelected.type, this.constants.analytics.backButtonLink);
        this.quickQuoteService.cancelFilterSelections(this.filterIndex, false);
        this.clearFilterSelected();

        // reset any travel party errors
        this.eventService.sendNextItem({
            name: this.travelPartyConstants.travelPartyError,
            value: false
        });
        this.changeDetectorRef.detectChanges();

        if (this.closeButton) {
            this.closeButton.nativeElement.focus();
        }

        this.showBaymax = true;
    }

    /**
     * Click event of when the user clicks on the DONE Selections Button
     */
    onButtonDoneClick(): void {
        this.quickQuoteService.enableADA2TPErrors();
        const allValidStaterooms = this.travelPartyService.getStateroomsWithErrors().length === 0;

        // Done
        this.analyticsTriggerEvent(this.filterSelected.type, this.constants.analytics.doneButtonLink);

        if (allValidStaterooms) {
            this.quickQuoteService.mergeIntoDoneFilters();
            this.clearFilterSelected();
        }

        this.showBaymax = true;
    }

    /**
     * Click event of when the user clicks on the Apply Selections Button
     */
    onButtonApplyClick(): void {
        const allValidStaterooms = this.travelPartyService.getStateroomsWithErrors().length === 0;

        if (allValidStaterooms) {
            this.quickQuoteService.mergeIntoTempSelectedFilters();
            this.travelPartyService.mergeIntoStateroomSaved();
        }

        this.onApplyFilters.emit();
        this.closeModal();
    }

    /**
     * Execute the apply and close the Modal
     * - used by "dcl-quick-quote-filter-wrapper" in mobile version
     * @param skipTrackLink flag to skip the analytics track link call
     */
    onApplyAndClose(skipTrackLink?: boolean) {
        this.onApplyFilters.emit(skipTrackLink);
        this.closeModal();
    }

    /**
     * Retrieves if the modal button should be disabled
     * @returns true if should be disabled and false if not
     */
    isButtonDisabled(): boolean {
        let isDisabled = false;

        if (this.filterIndex !== undefined) {
            isDisabled = this.quickQuoteService.filterHasErrors(this.filterIndex);
        }

        return isDisabled;
    }

    /**
     * selects which ariaLabel key will be announced by the SR for the modal
     * when it has a filter selected or it doesn't.
     * @param [hasCancelButton=false] is need in true when the filter has been selected
     * in order to read the arialabel key for cancel button
     * @returns arialabel selected key
     */
    getAriaLabel(hasCancelButton: boolean = false): string {
        const ariaTitleLabel =
            `${this.translateService.instant(this.titleLabel)} ${this.constants.filters.header}`;
        const ariaSubtitleLabel = this.subtitleLabel &&
            `${this.translateService.instant(this.subtitleLabel)} ${this.constants.filters.subHeader}`;
        const ariaButtonCancel = this.translateService.instant(this.constants.translationKeys.modal.buttonCancel);
        const isMoreFilters =
            this.filterSelected && this.filterSelected.type === this.constants.filters['more-filters'].id;

        let moreFilterSubtitle: string;

        if (isMoreFilters) {
            const newItinerariesId = this.constants.filters['more-filters'].groupKey.newItineraries;
            const firstFilter = this.filterSelected.data
                .find((filter: OptionsGroup) => filter.type !== newItinerariesId);

            moreFilterSubtitle = this.translateService.instant((firstFilter as OptionsGroup).labelKey);
        } else {
            moreFilterSubtitle = null;
        }

        let ariaResult = this.subtitleLabel ?
            `${ariaTitleLabel} ${ariaSubtitleLabel}` :
            `${ariaTitleLabel}`;

        if (hasCancelButton) {
            ariaResult = isMoreFilters ?
                `${ariaTitleLabel} ${moreFilterSubtitle} ${this.constants.filters.subHeader} ${ariaButtonCancel}` :
                `${ariaTitleLabel} ${ariaSubtitleLabel} ${ariaButtonCancel}`;
        }

        return ariaResult;
    }

    /**
     * Set the content for the property "filterDataSubGroup" based on rules
     * @TODO this can be deleted when QQ support subgroups
     * @param filterSelected the selected filter object
     */
    setFilterDataSubGroup(filterSelected: Filter): void {
        if ( filterSelected &&
            this.constants.filters['destination'].id === filterSelected.type) {
            this.filterDataSubGroup = this.filtersData.find(
                (item: Filter) => item && item.type === this.constants.filters['privateIsland'].id
            );
        } else {
            this.filterDataSubGroup = undefined;
        }
    }
}
