<div class="reservation-selector">
    <div
        class="reservation-selector__indicator"
        [ngClass]="{'open': isOpen}">
        <span class="reservation-selector__indicator-text">{{ resSelectorConstants.reservation | translate }} #{{selectedId}}</span>
        <button
            attr.aria-label="{{ resSelectorConstants.changeA11y | translate }}"
            class="reservation-selector__indicator-change"
            (click)="toggleMenu()">
            {{ resSelectorConstants.change | translate }}
        </button>
    </div>

    <div
        class="reservation-selector__menu-wrapper"
        [ngClass]="{'open': isOpen}"
        (keyup.esc)="toggleMenu()">
        <section class="reservation-selector__menu">
            <div class="reservation-selector__menu-header">
                <h2 class="reservation-selector__menu-header-title" translate>
                    {{ resSelectorConstants.title }}
                </h2>
                <button
                    attr.aria-label="{{ resSelectorConstants.closeMenuA11y | translate }}"
                    class="reservation-selector__menu-header-close"
                    (click)="toggleMenu()"
                    (keydown.Shift.Tab)="toggleMenu()">
                    <i class="close-icon di di-close-reversed" aria-hidden="true"></i>
                </button>
            </div>

            <div class="reservation-selector__menu-list">
                <div
                    *ngFor="let reservation of reservationsList; last as lastReservation"
                    class="reservation-selector__menu-option">
                    <img
                        *ngIf="reservation.imgSource"
                        alt="{{reservation.imgAlt || ''}}"
                        aria-hidden="true"
                        class="reservation-selector__menu-option-image"
                        src="{{reservation.imgSource}}">

                    <div class="reservation-selector__menu-option-details">
                        <span class="reservation-selector__menu-option-name">
                            {{ reservation.productName }}
                        </span>
                        <p class="reservation-selector__menu-option-date">
                            {{
                                this.isInternationalDirect || this.isInternationalDirectPhase2 ?
                                    (reservation.intDatesConfig | DateFormatterIntl) :
                                    reservation.displayDate
                            }}
                        </p>

                        <button
                            *ngFor="let stateroom of reservation.staterooms; last as lastStateroom"
                            class="reservation-selector__menu-option-stateroom"
                            (click)="selectReservation(stateroom.reservationId, stateroom.selectable)"
                            (focusout)="nextItem(lastReservation && lastStateroom)">
                            <p class="stateroom-number">
                                {{resSelectorConstants.stateroom | translate}} {{ stateroom.stateroomNumber }}
                            </p>

                            <div class="stateroom-reservation">
                                <span class="stateroom-reservation-id">
                                    {{resSelectorConstants.reservation | translate}}: {{stateroom.reservationId}}
                                </span>
                                <div *ngIf="!stateroom.selectable"
                                    class="stateroom-reservation-select disabled"
                                    [attr.aria-label]="resSelectorConstants.unavailableA11y | translate"
                                    translate>
                                    {{ resSelectorConstants.unavailable }}
                                </div>
                                <div *ngIf="stateroom.selectable && stateroom.reservationId === selectedId"
                                    class="stateroom-reservation-select selected"
                                    [attr.aria-label]="resSelectorConstants.selectedA11y | translate"
                                    translate>
                                    {{ resSelectorConstants.selected }}
                                </div>
                                <div *ngIf="stateroom.selectable && stateroom.reservationId !== selectedId"
                                    class="stateroom-reservation-select selectable"
                                    [attr.aria-label]="resSelectorConstants.selectA11y | translate"
                                    translate>
                                    {{ resSelectorConstants.select }}
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
