import { Component, Input } from '@angular/core';

@Component({
    selector: 'dcl-whats-included-modal-header',
    templateUrl: './whats-included-modal-header.html',
    styleUrls: ['./whats-included-modal-header.scss']
})
export class WhatsIncludedModalHeaderComponent {
    @Input() imageAlt: string;
    @Input() message: string;
    @Input() source: string;
    @Input() title: string;
}
