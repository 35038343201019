import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AvatarHeaderComponent } from './avatar-header.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
       AvatarHeaderComponent
   ],
    exports: [
        AvatarHeaderComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AvatarHeaderModule {}
