import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, catchError, finalize, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';
import * as momentModule from 'moment';

import { AT_HOME } from '../at-home/at-home.constants';
import { ConfigService } from '../config/config.service';
import { cookies, domains, path } from './authentication.constants';
import { ErrorHandlingEmbeddedService } from '../error-handling-embedded/error-handling-embedded.service';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';
import { NavigationService } from '../navigation/navigation.service';
import { TravelAgentService } from '../../travel-agent/travel-agent.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private moment = momentModule;
    private baseUrl;
    private durationIsLoggedInCookie: number;
    private observableAuthzStatus;

    constructor(
        private config: ConfigService,
        private cookieService: CookieService,
        private httpClient: HttpClient,
        private errorHandlingEmbeddedService: ErrorHandlingEmbeddedService,
        private nativeBridgeService: NativeBridgeService,
        private navigationService: NavigationService,
        private travelAgentService: TravelAgentService
    ) {
        this.baseUrl = this.config.getValue('baseUrl');
        this.durationIsLoggedInCookie = this.config.getValue('durationIsLoggedInCookie');
    }

    /**
     * Returns if the user is logged in
     * @param data The returnUrl, the flow and the reject flag
     * @returns {Observable} the user's logged in status
     */
    isLoggedIn(data: {
        returnUrl?: string,
        flow?: string,
        rejectWith401?: boolean
    } = {}): Observable<boolean> {
        let statusCode;

        if (this.nativeBridgeService.isEmbedded()) {
            if (this.nativeBridgeService.isSessionValid()) {
                return of(true);
            }

            statusCode = data.rejectWith401 ? AT_HOME.errorStates.unauthorized : AT_HOME.errorStates.jwtTokenMissing;
            this.errorHandlingEmbeddedService.handleEmbeddedErrors(statusCode, data.flow);

            return of(false);
        } else if (this.travelAgentService.isTravelAgentLoggedIn() || this.getStateFromCookie()) {
            return of(true);
        } else {
            return this.getAndCheckStatus(data.returnUrl);
        }
    }

    /**
     * Http call to get user authentication status
     * @returns {Observable} get status call
     */
    getStatus(): Observable<{}> {
        const url = `${this.baseUrl}/profile-api/authentication/status/`;

        return this.httpClient.get<{}>(url);
    }

    /**
     * Save isLoggedIn cookie from status response
     * @param {Object} loginDetails status response
     */
    setLoggedInCookie(loginDetails): void {
        const expires = this.moment().add(this.durationIsLoggedInCookie, 'minutes').toDate();
        const cookieValue = JSON.stringify({
            isLoggedIn: loginDetails.isLoggedIn,
            isSecure: loginDetails.isSecure
        });
        this.cookieService.set('isLoggedIn', cookieValue, expires, '/');
    }

    /**
     * @description Returns user identifier based on the session user (Travel agent, Guest)
     * @param swid: identifier from current execution thread
     * @return userIdentifier: according to user type
     */
    getUserIdentifier(swid: string, returnAgencyId = false): string {
        if (this.travelAgentService.isTravelAgentLoggedIn()) {
            return returnAgencyId
                ? this.travelAgentService.getTravelAgencyId()
                : this.travelAgentService.getAgencyUUID();
        }

        return swid;
    }

    goToLogin(returnUrl: string): void {
        this.logOut();

        const targetUrl = encodeURIComponent(decodeURIComponent(returnUrl));
        const url = `/login/?returnUrl=${targetUrl}&cancelUrl=/`;

        this.navigationService.navigateByUrl({
            url,
            redirect: true
        });
    }

    /**
     * Delete user session before redirecting the user to Profile page so we avoid loops in specific cases.
     */
    logOut(): void {
        const domain = this.baseUrl ? this.baseUrl.split(domains.baseProtocol) : '';
        let cookieDomain = domains.default;

        if (domain && domain[1]) {
            cookieDomain = domain[1];
        }

        this.cookieService.delete(cookies.isLoggedIn, path, cookieDomain);
        this.cookieService.delete(cookies.jwtToken, path, cookieDomain);
        this.cookieService.delete(cookies.oauthRefreshPP, path, cookieDomain);
        this.cookieService.delete(cookies.oauthRefreshToken, path, cookieDomain);
        this.cookieService.delete(cookies.oauthToken, path, cookieDomain);
        this.cookieService.delete(cookies.rememberme, path, cookieDomain);
        this.cookieService.delete(cookies.sessionTimeOut, path, cookieDomain);
        this.cookieService.delete(cookies.swid, path, cookieDomain);

        // go.com Cookies
        this.cookieService.delete(cookies.isLoggedIn, path, domains.goCom);
        this.cookieService.delete(cookies.jwtToken, path, domains.goCom);
        this.cookieService.delete(cookies.oauthRefreshPP, path, domains.goCom);
        this.cookieService.delete(cookies.oauthRefreshToken, path, domains.goCom);
        this.cookieService.delete(cookies.oauthToken, path, domains.goCom);
        this.cookieService.delete(cookies.rememberme, path, domains.goCom);
        this.cookieService.delete(cookies.sessionTimeOut, path, domains.goCom);
        this.cookieService.delete(cookies.swid, path, domains.goCom);
    }

    /**
     * get user authentication status and check if it is valid
     * @param returnUrl url to return after user is logged in from login page
     * @return {observable} The authz status
     */
    private getAndCheckStatus(returnUrl?: string): Observable<boolean> {
        if (!this.observableAuthzStatus) {
            this.observableAuthzStatus = this.getStatus()
                .pipe(
                    share(),
                    map((response) => {
                        const isLoggedIn = this.checkLogin(response);

                        if (!isLoggedIn && returnUrl) {
                            this.goToLogin(returnUrl);
                        }

                        return isLoggedIn;
                    }),
                    catchError(err => {
                        if (returnUrl) {
                            this.goToLogin(returnUrl);
                        }

                        return of(false);
                    }),
                    finalize(() => {
                        delete this.observableAuthzStatus;
                    })
                );
        }

        return this.observableAuthzStatus;
    }

    private getStateFromCookie(): boolean {
        const isLoggedInCookie = this.cookieService.get('isLoggedIn');
        const loginDetails = this.decodeCookie(isLoggedInCookie);

        return this.checkLogin(loginDetails);
    }

    private checkLogin(loginDetails): boolean {
        return !!loginDetails && loginDetails.isLoggedIn && loginDetails.isSecure;
    }

    private decodeCookie(cookie: string): { isLoggedIn: boolean, isSecure: boolean } {
        try {
            const decodedCookie = decodeURIComponent(cookie);

            return JSON.parse(decodedCookie);
        } catch (err) {
            // cookie is not decodable using decodeURIComponent or it is not a Json object
            return undefined;
        }
    }
}
