import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';

import { WARNING_MESSAGING_CONSTANTS } from './warning-messaging.constants';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-warning-messaging',
    templateUrl: './warning-messaging.component.html',
    styleUrls: ['./warning-messaging.component.scss']
})
export class WarningMessagingComponent implements OnInit, OnChanges {
    @Input() configuredStyles: boolean;
    @Input() message: string;
    @Input() messageType: string;
    @Input() noAriaLive: boolean;
    @Input() showMessage = true;
    @Input() size: string;
    @Input() setFocus: boolean = false;

    @ViewChild('errorMessageSpan', {static: false} as ViewChildProperties) errorMessageSpan: ElementRef;

    ariaLive: string = null;
    iconLabel: string;

    constructor() {}

    ngOnChanges() {
        if (this.setFocus && this.errorMessageSpan) {
            this.errorMessageSpan.nativeElement.blur();

            setTimeout(() => {
                this.errorMessageSpan.nativeElement.focus();
            });
        }
    }

    ngOnInit() {
        this.iconLabel = `warning-messaging.${this.messageType}-icon-label`;

        if (!this.noAriaLive) {
            switch (this.messageType) {
                case 'error':
                    this.ariaLive = WARNING_MESSAGING_CONSTANTS.WARNING_MSG_ARIA_LIVE.ERROR;
                    break;
                case 'warning':
                    this.ariaLive = WARNING_MESSAGING_CONSTANTS.WARNING_MSG_ARIA_LIVE.WARNING;
                    break;
                default:
                    this.ariaLive = WARNING_MESSAGING_CONSTANTS.WARNING_MSG_ARIA_LIVE.INFO;
            }
        }
    }

    /**
     * Returns the error class type for ngclass
     */
    getErrorClass(): Object {
        return {
            'error': this.messageType === 'error',
            'small': this.size === 'small',
            'configuredStyles': this.configuredStyles
        };
    }
}
