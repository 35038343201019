import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class FormDataService {

    constructor() {}

    /**
     * Gets error message from form control
     * @param {string} key controlName to search into messages object
     * @param {AbstactControl} control
     * @param {Object} errorKeysMessages error messages for the specific form
     * @returns {string}
     */
    getErrorMessage(key: string, formControl: AbstractControl, errorKeysMessages: {}): string {
        let errorMessage = ' ';
        const errors = formControl && formControl.errors;

        if (errors) {
            const error = Object.keys(errors)[0];

            errorMessage = (errorKeysMessages && (errorKeysMessages[key] && errorKeysMessages[key][error] ||
                errorKeysMessages[error])) || `error-messages.default-errors.${error}`;
        }

        return errorMessage;
    }
}
