import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { Stateroom } from '../../interfaces/stateroom.interface';
import { StateroomErrors } from '../../interfaces/stateroom-errors.interface';
import { TRAVEL_PARTY_CONSTANTS } from './../../travel-party.constants';

@Component({
    selector: 'dcl-ada2-accessible-room',
    templateUrl: './ADA2-accessible-room.component.html',
    styleUrls: ['./ADA2-accessible-room.component.scss']
})
export class ADA2AccessibleRoomComponent implements OnInit {
    @Input() accessibleBehavior = {
        disclaimerSelected: '',
        showDescriptions: false
    };
    @Input() errors: StateroomErrors;
    @Input() stateroom: Stateroom;

    @Output() disclaimerAccepted: EventEmitter<string> = new EventEmitter();
    @Output() radioButtonChanged: EventEmitter<void> = new EventEmitter();

    disclaimerConstants;
    disableAccessibleRoom: boolean;

    constructor() {}

    ngOnInit(): void {
        this.disclaimerConstants = TRAVEL_PARTY_CONSTANTS.shipADAPhase2.disclaimers;
        this.accessibleBehavior.disclaimerSelected = this.stateroom.accessibleType;
    }

    /**
     * Resets the stateroom accessibility type, saves the option selected from the
     * radio buttons and enables showing the corresponding descriptions
     * @param value the radio group's new selected value
     */
    handleRadioDisclaimersChanged(value: string) {
        this.stateroom.accessibleType = null;
        this.accessibleBehavior.disclaimerSelected = value;
        this.accessibleBehavior.showDescriptions = true;
        this.radioButtonChanged.emit();
    }

    /**
     * Emits the currently selected accessibility type to the parent to assign it
     * to the stateroom
     * @param event the MouseEvent
     */
    handleCheckUnderstand(event: MouseEvent) {
       this.disclaimerAccepted.emit(this.accessibleBehavior.disclaimerSelected);

       event.stopPropagation();
       event.preventDefault();
    }
}
