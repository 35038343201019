import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { AT_HOME } from '../../services/at-home/at-home.constants';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';

@Injectable()
export class CanActivateIsLoggedInGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private nativeBridgeService: NativeBridgeService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        const rejectWith401 = !state.url.match(new RegExp(AT_HOME.initialPages.join('|')));

        return this.authenticationService.isLoggedIn({
            returnUrl: state.url,
            flow: next.params['flow'] || next.data['flowValue'],
            rejectWith401
        }).pipe(
            map((isLoggedIn) => {
                if (!isLoggedIn && !this.nativeBridgeService.isEmbedded()) {
                    this.authenticationService.goToLogin(state.url);
                }

                return isLoggedIn;
            })
        );
    }
}
