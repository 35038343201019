import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AT_HOME } from '../at-home/at-home.constants';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';
import { ReservationFlowService } from '../reservation-flow/reservation-flow.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingEmbeddedService {

    constructor(
        private nativeBridgeService: NativeBridgeService,
        private reservationFlow: ReservationFlowService,
        private translate: TranslateService
    ) {
    }

    /**
     * Get messages to send them to native app
     * @param statusCode
     * @param currentFlow
     */
    handleEmbeddedErrors(statusCode: string | number, currentFlow?: string) {
        const reservationFlow = this.reservationFlow.getFlow();
        const flow = currentFlow || (reservationFlow && reservationFlow['id']);
        const flowData = AT_HOME.flows[flow];
        const errorTitle = AT_HOME.messages.errorTitle;
        const errorMessage = statusCode === AT_HOME.errorStates.dfmError ?
            AT_HOME.messages.errorMessageDfm :
            AT_HOME.messages.errorMessage;

        this.translate.get([
            errorTitle.key,
            errorMessage.key
        ]).subscribe((res) => {
            errorTitle['value'] = res[errorTitle.key] && res[errorTitle.key] !== errorTitle.key ?
                res[errorTitle.key] :
                errorTitle.defaultValue;

            errorMessage['value'] = res[errorMessage.key] && res[errorMessage.key] !== errorMessage.key ?
                res[errorMessage.key] :
                errorMessage.defaultValue;

            this.nativeBridgeService.sendErrorMessage(
                flowData && flowData.flowId,
                statusCode,
                errorTitle['value'],
                errorMessage['value']
            );
        });
    }
}
