import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {lastValueFrom} from "rxjs";

export const ConfigServiceToken = new InjectionToken('config.service');

@Injectable()
export class ConfigService {
  config: Object;

  constructor(private http: HttpClient) {}

  async load(): Promise<any> {
    try {
      this.config = await lastValueFrom(this.http.get('/wdpr-web-dcl-homepage/config'));
      console.log('Config service: ', this.config);

      return this.config;
    } catch(err) {
      console.error('Error fetching config service', err);
    }
  }

  getValue(key: string): any {
    // Get value of config key:
    return this.config[key];
  }
}
