import { Component, Input, Output, EventEmitter } from '@angular/core';

import { GridConfig } from './interfaces/grid-config.interface';
import { ItemChanged } from './../interfaces/item-changed.interface';

@Component({
    selector: 'dcl-grid-picker',
    templateUrl: './grid-picker.component.html',
    styleUrls: ['./grid-picker.component.scss']
})
export class GridPickerComponent {
    @Input() ariaLabelKey: string;
    @Input() gridPickerData: GridConfig[];
    @Input() earlyBookingData: boolean;
    @Output() onChangedItem: EventEmitter<ItemChanged> = new EventEmitter();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();

    /**
     * Recives the items' id that is been selected in the gridComponent and emits this id
     * @param itemSelected
     */
    onItemChanged(item: ItemChanged): void {
        this.onChangedItem.emit(item);
    }
}
