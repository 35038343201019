import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';
import { ControlContainer, UntypedFormGroup, Validators, ValidatorFn, UntypedFormBuilder } from '@angular/forms';
import * as momentModule from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { DateOfBirthConstants } from './date-of-birth.constants';
import { FormDataService } from '../form-data/form-data.service';
import { missingValueValidator } from './custom-validators/missing-value/missing-value.validator';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';
import { yearValidator } from './custom-validators/valid-year/valid-year.validator';

@Component({
    selector: '[formGroup] dcl-date-of-birth',
    templateUrl: './date-of-birth.component.html',
    styleUrls: ['./date-of-birth.component.scss']
})
export class DateOfBirthComponent implements OnInit {
    @Input() customValidators: Array<ValidatorFn> = [];
    @Input() errorMessages: {} = {};
    @Input() guestData: {};
    @Input() hasTitle = true;
    @Input() hasSubtitle = false;
    @Input() index: number = 0;
    @Input() showInfo: boolean;

    @ViewChild('dobContainer', {static: false} as ViewChildProperties) dobContainer: ElementRef;
    @ViewChild('wdprSelectMonth', {static: true} as ViewChildProperties) wdprSelectMonth: ElementRef;
    @ViewChild('wdprSelectDay', {static: true} as ViewChildProperties) wdprSelectDay: ElementRef;

    constants = DateOfBirthConstants;
    dateOfBirth: UntypedFormGroup;
    days: Array<{}>;
    daySelected: number;
    errorMessagesKeys: {} = {
        missingValue: 'date-of-birth.errors.invalid-date',
        invalidYear: 'date-of-birth.errors.invalid-date'
    };
    moment = momentModule;
    months: Array<{}>;
    monthSelected: number;
    yearSelected: number;
    componentFocus: boolean;
    translations: {} = {};
    guestNumber: number;
    getErrors;

    constructor(
        private controlContainer: ControlContainer,
        private fb: UntypedFormBuilder,
        private renderer: Renderer2,
        private translate: TranslateService,
        private formDataService: FormDataService) { }

    ngOnInit() {
        this.getErrors = this.formDataService.getErrorMessage;
        this.setData();
        this.translateValues();
        this.setDateOfBirthForm();
        this.guestNumber = this.index + 1;
    }

    /**
     * Translates the labels for the form controls
     */
    translateValues(): void {
        this.translate.get('date-of-birth.form')
            .subscribe((res: [string]) => {
                this.translations = res;
            });
    }

    /**
     * Sets the form group, the options for each control, and the events when the value changes
     */
    setDateOfBirthForm(): void {
        const monthControl = this.fb.control(this.monthSelected && this.monthSelected.toString(), [
            Validators.required
        ]);
        const yearControl = this.fb.control(this.yearSelected && this.yearSelected.toString() || '', [
            Validators.pattern('[0-9]*')
        ]);
        const dayControl = this.fb.control({value: this.daySelected && this.daySelected.toString(), disabled: true}, [
            Validators.required
        ]);

        this.customValidators.push(missingValueValidator);
        this.customValidators.push(yearValidator);

        this.dateOfBirth = <UntypedFormGroup>this.controlContainer.control;
        this.dateOfBirth.setControl('month', monthControl);
        this.dateOfBirth.setControl('year', yearControl);
        this.dateOfBirth.setControl('day', dayControl);
        this.dateOfBirth.setValidators(Validators.compose(this.customValidators));

        this.errorMessagesKeys = {...this.errorMessages, ...this.errorMessagesKeys};

        this.setMonths();
        this.setDays();

        this.renderer.listen(this.wdprSelectMonth.nativeElement, 'item-selected', (evt) => {
            const value = Number(evt.detail.key);

            this.monthSelected = value && Number.isInteger(value) ? value : 0;
            this.dateOfBirth.get('month').setValue(this.monthSelected.toString());

            if (this.monthSelected > 0) {
                this.dateOfBirth.get('day').enable();
            }

            this.setDays();
        });

        this.renderer.listen(this.wdprSelectDay.nativeElement, 'item-selected', (evt) => {
            const value = Number(evt.detail.key);

            this.daySelected = value && Number.isInteger(value) ? value : 0;
            this.dateOfBirth.get('day').setValue(this.daySelected.toString());
        });

        this.changeDaysData();
    }

    /**
     * Sets the month options with text and value
     */
    setMonths(): void {
        const months = this.moment.months();

        this.months = [];

        months.forEach((month, i) => {
            this.months.push({
                value: month,
                key: (i + 1).toString(),
                tagClasses: [ DateOfBirthConstants.GUEST_SENSITIVE_CLASS ]
            });
        });
    }

    /**
     * Sets the days for the year and month selected
     */
    setDays(): void {
        const defaultDays = 31;
        const year = this.yearSelected ? Number(this.yearSelected) : this.moment().year();
        const daysPerMonth = this.monthSelected ?
            this.moment().year(year).month(Number(this.monthSelected) - 1).daysInMonth() :
            defaultDays;

        this.days = [];

        for (let i = 1; i <= daysPerMonth; i++) {
            this.days.push({
                value: i.toString(),
                key: i.toString(),
                tagClasses: [ DateOfBirthConstants.GUEST_SENSITIVE_CLASS ]
            });
        }

        this.daySelected = this.daySelected > daysPerMonth ? daysPerMonth : this.daySelected;
    }

    /**
     * Validates if the control is invalid
     * @returns {boolean}
     */
    isInvalid(): boolean {
        return this.dateOfBirth.errors &&
            !this.componentFocus &&
            (this.dateOfBirth.get('month').touched ||
            this.dateOfBirth.get('day').touched ||
            this.dateOfBirth.get('year').touched);
    }

    /**
     * Validates the year and sets the days for the specific year and month selected
     */
    changeDaysData(): void {
        if (this.dateOfBirth.get('year').value) {
            this.yearSelected = Number(this.dateOfBirth.get('year').value);

            if (this.monthSelected > 0) {
                this.dateOfBirth.get('day').enable();
            }

            this.setDays();
        }
    }

    /**
     * Sets the data in the form if the guest already exist
     */
    setData(): void {
        if (this.showInfo && this.guestData) {
            const dayIndex = 2;
            const dateOfBirth = this.guestData['birthdate'] ? this.guestData['birthdate'].split('-') : '';
            // Set data of existing guest
            this.yearSelected = Number(dateOfBirth[0]) || null;
            this.monthSelected = Number(dateOfBirth[1]) || null;
            this.daySelected = Number(dateOfBirth[dayIndex]) || null;
        }
    }

    /**
     * handler for component focus
     */
    dobFocusHandler(): void {
        this.componentFocus = true;
    }

    /**
     * handler for input blur events
     * @param event
     */
    dobBlurHandler(event): void {
        this.componentFocus = this.dobContainer.nativeElement.contains(event.relatedTarget);
    }
}
