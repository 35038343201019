import { animate, style } from '@angular/animations';

const imagesMotion = (animationDuration: number, translateDisplacement: number) => [
    style({
        transform: '*'
    }),
    animate(animationDuration, style({
        transform: `translate(-${translateDisplacement}%)`
    }))
];

const progressBarStatus = (animationDuration: number) => [
    style({ width: 0 }),
    animate(
        animationDuration,
        style({
            width: '100%'
        })
    )
];

export { imagesMotion, progressBarStatus };
