import { throwError, Observable } from 'rxjs';
import { get as getValue, values } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorOptions } from '../interfaces/error-options.interface';
import { HANDLE_ERRORS_CAST_CONSTANTS } from '../cast/handle-errors.cast.constants';
import { HANDLE_ERRORS_CONSTANTS } from './handle-errors.constants';
import { HandleErrorsCastService } from '../cast/handle-errors.cast.service';

@Injectable()
export class HandleErrorsService {
    private castErrorsValues: Array<String>;

    constructor(
        private handleErrorsCastService: HandleErrorsCastService,
        private router: Router
    ) {
        this.castErrorsValues = values(HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES);
    }

    /**
     * Generic Handle error
     * @param error
     * @param options options to handle the error
     * @returns throw an error with the given statusCode
     */
    handleServiceError(error: HttpErrorResponse, options: ErrorOptions): Observable<string> {
        const errorStatus = getValue(error, 'status', HANDLE_ERRORS_CONSTANTS.DEFAULT_ERROR_STATE);
        const statusCode = HANDLE_ERRORS_CONSTANTS.CHARACTER_ERRORS.indexOf(errorStatus) === -1 ?
            HANDLE_ERRORS_CONSTANTS.DEFAULT_ERROR_STATE :
            errorStatus;
        const errorCode = getValue(error, 'error.errorCode') || getValue(error, 'error.message.errorCode');

        if (this.castErrorsValues.indexOf(errorCode) !== -1) {
            return this.handleErrorsCastService.handleCastError(statusCode, errorCode, options);
        } else {
            this.router.navigate([options.errorState], {
                queryParams: { statusCode },
                skipLocationChange: true
            });

            return throwError(statusCode);
        }
    }
}
