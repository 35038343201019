import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    OnChanges,
    SimpleChanges,
    Renderer2
} from '@angular/core';

import { BUTTON_SELECTOR_FOCUS_TIME } from './guest-selector-header.constants';
import { SelectorGuestComponentDetails} from './interfaces/selector-guest-component-details.interface';
import { SelectorGuestData } from './interfaces/selector-guest-data.interface';
import { ScrollEventType } from '../interfaces/sticky-bar.interface';

@Component({
    selector: 'dcl-guest-selector-header',
    templateUrl: './guest-selector-header.component.html',
    styleUrls: ['./guest-selector-header.component.scss']
})
export class GuestSelectorHeaderComponent implements OnChanges {
    @ViewChild('dropDownComponent', { static: false }) dropDownComponent: ElementRef;
    @ViewChild('guestSelectorComponentButton', { static: false }) dropDownComponentButton: ElementRef;
    @ViewChild('guestSelectorComponentList', { static: false }) dropDownComponentList: ElementRef;
    @ViewChild('guestSelector', { static: false }) guestSelector: ElementRef;
    // indicates that the request to update the addon is being processed
    @Input() actionLoading: boolean;
    @Input() desktopSize: boolean;
    @Input() guestSelected: SelectorGuestData;
    @Input() isEmbedded: boolean;
    @Input() stickyBarData: ScrollEventType;
    @Output() guestSelectionChange: EventEmitter<SelectorGuestData> = new EventEmitter<SelectorGuestData>();

    public currentIndexSelected = -1;
    public focusedOption = -1;
    public isSelectorOpen = false;
    public options: SelectorGuestData[];
    public placeholder: string;

    constructor(private renderer: Renderer2) {}

    /**
     * @description receives the new data when the sticky bar directive from the ui-components-library parameters change
     * @param changes SimpleChanges
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const stickyBarData = changes.hasOwnProperty('stickyBarData');
        const newGuest = changes.guestSelected;

        if (stickyBarData) {
            this.stickyOnScroll();
        }

        if (newGuest && !newGuest.firstChange) {
            this.currentIndexSelected = this.options.indexOf(newGuest.currentValue);
        }
    }

    @Input()
    public set details(details: SelectorGuestComponentDetails) {
        const { guestData, placeholder, guestSelectedIndex } = details;

        guestData.forEach(guest => {
            const guestInitials = guest.value.split(' ').map(guestItem => guestItem[0]);
            guest.guestInitials = guestInitials.join('').toUpperCase();
        });

        this.guestSelected = guestData[guestSelectedIndex];
        this.currentIndexSelected = guestSelectedIndex;
        this.placeholder = placeholder;
        this.options = guestData;
    }

    /**
     * @description calculates if element should hide or show with scroll position
     */
    stickyOnScroll(): void {
        if (this.isEmbedded && this.stickyBarData) {
            const { top, height, down } = this.stickyBarData;

            if (down) {
                // Scroll down
                this.renderer.setStyle(this.guestSelector.nativeElement, 'top', `${height + top}px`);
            } else {
                // Scroll up
                this.renderer.setStyle(this.guestSelector.nativeElement, 'top', `${height}px`);
            }
        }
    }

    /**
     * Change guest selected
     * @param selection SelectorGuestData
     */
    public changeSelection(selection: SelectorGuestData, indexSelected) {
        this.isSelectorOpen = false;

        if (selection !== this.guestSelected) {
            this.focusedOption = indexSelected;
            this.guestSelectionChange.emit(selection);
        }

        if (!this.desktopSize) {
            setTimeout(() => {
                this.dropDownComponentButton.nativeElement.focus();
            }, BUTTON_SELECTOR_FOCUS_TIME);
        }
    }

    /**
     * This method is called on blur event
     * @param event Keyboard event
     */
    public autoCloseGuestSelector(event) {
        event.stopPropagation();

        // When closing the menu relatedEvent is undefined, so we escape it.
        // When it enters the menu instead, it sets focus to current selection.
        if (!event.relatedTarget) {
            this.focusedOption = this.currentIndexSelected;
            this.isSelectorOpen = false;
        }
    }

    /**
     * This method is used to open Guest Selector list.
     * It focuses the guest item that has been selected by the user.
     * @param event Keyboard event
     */
    public openGuestSelector(event) {
        event.preventDefault();
        this.isSelectorOpen = !this.isSelectorOpen;
    }

    /**
     * Move to next dropdown item
     * @param event Keyboard event
     */
    public nextItem(event) {
        if (!this.isSelectorOpen) {
            return;
        }

        const itemIndex = this.focusedOption + 1;

        if (itemIndex < this.options.length) {
            event.preventDefault();
            this.focusedOption = itemIndex;
            this.dropDownComponentList.nativeElement.querySelectorAll('li')[itemIndex].focus();
        } else {
            event.stopImmediatePropagation();
            this.autoCloseGuestSelector(event);
        }
    }

    /**
     * Move to prev dropdown item
     * @param event Keyboard event
     */
    public prevItem(event) {
        if (!this.isSelectorOpen) {
            return;
        }

        const itemIndex = this.focusedOption - 1;

        if (itemIndex >= 0 && itemIndex < this.options.length) {
            event.preventDefault();
            this.focusedOption = itemIndex;
            this.dropDownComponentList.nativeElement.querySelectorAll('li')[itemIndex].focus();
        } else {
            event.stopImmediatePropagation();
            this.autoCloseGuestSelector(event);
        }
    }
}
