<div class="park-hours-container" id="park-hours-container">
  <ul 
    class="park-hours-ul"
    *ngIf="!parksAvailable">
      <li class="toggle">
          <div class="toggle-container toggle-button">
            <svg class="toggle-container-icon" viewBox="0 0 100 100" aria-hidden="true"> 
              <path d="M25 10L75 50L25 90z"></path> 
            </svg>
            <span *ngIf="isFast" class="dpo-icon"></span>
            <a target="_self" href="calendars/day/">
              {{ parkHoursText.todaysParkHours }}
            </a>
          </div>
      </li>
  </ul>
  <ul 
    id="park-hours-ul-available"
    class="park-hours-ul"
    *ngIf="parksAvailable"
    >
    <li class="toggle">
      <button 
        id="park-hours-toggle-button"
        class="toggle-button" 
        (click)="onToggleClick()" 
        aria-expanded="false" 
        aria-controls="park-hours-items-container">
        <div class="toggle-container">
          <svg class="toggle-container-icon" id="park-hours-icon-available" viewBox="0 0 100 100" aria-hidden="true"> 
            <path d="M25 10L75 50L25 90z"></path> 
          </svg>
          <span *ngIf="isFast" class="dpo-icon"></span>
          {{ parkHoursText.todaysParkHours }}
        </div>
      </button>
    </li>
    <li id="park-hours-items-container" class="container">
      <div class="park-hours-items-container">
        <div *ngFor="let item of parkHoursItems" data="item">
          <a class="park-hours-park" target="_self" href="calendars/day/{{item.calendar}}">
            <img class="park-hours-park_icon" [src]="item.icon"/>
            <div class="park-hours-park_wrapper">
              <div class="park-hours-park_title">{{ item.name }}</div>
              <div class="park-hours-park_schedule">
                <a *ngIf="item.operating">
                  <span>{{ item.startTime}} {{ parkHoursText.to }} {{ item.endTime }}</span>
                </a>
                <a *ngIf="!item.operating">
                  <span>{{ parkHoursText.closedToday }}</span>
                </a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </li>
  </ul>
</div>
