import '@wdpr/button';
import '@wdpr/checkbox';
import '@wdpr/counter';
import '@wdpr/single-select';
import { Component, OnInit, Input } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { SeoService } from '@wdpr/ra-angular-seo-metadata';
import { ConfigService } from '@app/core/config.service';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { GeoLocationInfo } from './services/geolocation/geolocation.info';
import { LocaleService } from '@app/services/locale/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import { ResourceBundleService } from './services/resource-bundle/resource-bundle.service';
import { AffiliationsService } from '@app/services/affiliations/affiliations.service';
import { CookieService } from 'ngx-cookie-service';
import { DEFAULT_SOCIAL_TAGS } from '@app/app.constants';
import { get } from 'lodash';
import getLangFromOrigin from '@app/shared/utils/string/getLangFromOrigin';
import {
  ME_CONFIG,
  getClosedCaptioningUrl,
  getResizeImageUrl,
  getDescriptiveTranscriptUrl,
  getVideoDetailSources,
  getVideoMainSources,
  getVideoPlaceholders,
  getDefaultImageUrl,
  dataHasValueAtKey,
  getMediaEngineId,
  getHeroContentMode,
  HeroContent
} from './shared/utils/media-engine';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public siteId: string;
  public pixieHomepageClassList: string;
  @Input() dataType: string;

  title = 'wdpr-pixie-page';
  environment: string;
  seoUrl: string;
  geolocation: GeoLocationInfo;
  affiliations: Object;
  content;
  useSyndicated: Boolean = true;
  mediaKeyBaseLegacy = 'heroes[0].descriptions.';
  isMobile = false;
  hasFullScreenVideo = false;
  ME: any;
  mediaEngineId: string;
  fullscreen = false;
  locale: string = 'en';
  themes: string[] = [];
  isWdwOrDlr: Boolean = false;


  constructor(
    private logger: LoggerService,
    private seoService: SeoService,
    private configService: ConfigService,
    private homepageAnalyticsService: HomepageAnalyticsService,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private resourceBundle: ResourceBundleService,
    private localeService: LocaleService,
    private affiliationsService: AffiliationsService,
    private cookieService: CookieService
  ) {
    this.siteId = this.configService.getValue('siteId');
    this.isWdwOrDlr = ['wdw', 'dlr'].includes(this.siteId);
    this.pixieHomepageClassList = `${this.siteId} ${this.isWdwOrDlr ? 'loading' : ''}`;
    this.environment = this.configService.getValue('environment');
    this.resourceBundle.get('heroes').then(data => {
      const seoImageUrl = this.getSeoImageUrl(data);
      this.resourceBundle.get('translations').then(translations => {
        const { meta = {} } = translations || {};
        // Add social meta tags
        this.metaService.addTags(this.getSocialTags(meta, seoImageUrl));
        if (meta) {
          this.setupSeo(meta);
        }
      });
    });
    this.setupSyndicated();
    this.setupAnalytics();
    this.setThemes();
    this.setFastTheme();
    // If WDW or DLR, show homepage elements at once
    if (this.isWdwOrDlr) {
      this.waitForHomepageElements();
    }

    this.translate.addLangs(['pt-br', 'es-ar', 'es-cl', 'es-co', 'es-mx', 'es-pe', 'es-es', 'en']);
    const appComponentMediaEngineId = getMediaEngineId('appComponent', 1);
    this.mediaEngineId = appComponentMediaEngineId;
  }

  ngOnInit(): void {
    const MEConfigKey = `${this.mediaEngineId}Config`;
    window[MEConfigKey] = ME_CONFIG;

    this.localeService.getLocale().then((locale: string) => {
      this.locale = locale || 'en';
      this.translate.setDefaultLang(this.locale);
      this.translate.use(this.locale);
      this.setLangAttribute();
    });

    this.resourceBundle.get('heroes').then(data => {
      if (this.siteId === 'hkdl') {
        // Add data for Media Engine
        if (dataHasValueAtKey(data, 'videoMain', this.isMobile)) {
          this.dataType = 'video';
          window[MEConfigKey].media.push({
            type: 'video',
            thumbnail: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            source: getVideoMainSources(data, this.isMobile),
            preview: getVideoMainSources(data, this.isMobile),
            poster: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            placeholders: getVideoPlaceholders(data, this.isMobile),
            title: this.getVideoTitle(data),
            alt: this.siteId,
            tags: ['a']
          });
        } else {
          this.dataType = 'image';
          const accessibility = {
            labels: {
              en: {
                meTitle: get(data, 'heroes[0].media.defaultImage.title', '')
              }
            }
          };
          window[MEConfigKey].accessibility = accessibility;
          window[MEConfigKey].media.push({
            type: 'image',
            thumbnail: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            source: [getDefaultImageUrl(data, this.isMobile)],
            preview: [getDefaultImageUrl(data, this.isMobile)],
            poster: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            title: this.siteId,
            alt: this.siteId,
            tags: ['a'],
          });
        }

        if (dataHasValueAtKey(data, 'videoDetail', this.isMobile)) {
          this.hasFullScreenVideo = true;
          window[MEConfigKey].media.push({
            type: 'video',
            thumbnail: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            source: getVideoDetailSources(data, this.isMobile),
            preview: getVideoDetailSources(data, this.isMobile),
            poster: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            closedCaptioning: getClosedCaptioningUrl(data, this.isMobile),
            descriptiveTranscript: getDescriptiveTranscriptUrl(data, this.isMobile),
            title: this.getVideoTitle(data),
            alt: this.siteId,
            tags: ['b']
          });
        }
      } else if (this.siteId === 'dcl') {
          if (get(data, 'heroes[0].descriptions.defaultImage.sections.source', false)
            && data.heroes[0].descriptions.defaultImage.sections.source.length > 1) {
            this.dataType = 'image';

            // This gets used from within the frame
            window[MEConfigKey].media.push({
              type: 'image',
              thumbnail: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              source: [
                data.heroes[0].descriptions.defaultImage.sections.source
              ],
              preview: [
                data.heroes[0].descriptions.defaultImage.sections.source
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              title: this.siteId,
              alt: this.siteId,
              tags: ['a']
            });
          } else if (get(data, 'heroes[0].descriptions', false)) {
            if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
              this.hasFullScreenVideo = true;
              window[MEConfigKey].media.push({
                type: 'video',
                thumbnail: this.getThumbnailUrl(data),
                source: [
                  data.heroes[0].descriptions.videoMain.sections.source1,
                  data.heroes[0].descriptions.videoMain.sections.source2
                ],
                preview: [
                  data.heroes[0].descriptions.videoMain.sections.source1,
                  data.heroes[0].descriptions.videoMain.sections.source2
                ],
                poster: getResizeImageUrl(
                  data.heroes[0].descriptions.defaultImage.sections.source
                ),
                placeholders: [
                  data.heroes[0].descriptions.videoMain.sections.placeholder,
                  data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                  data.heroes[0].descriptions.videoMain.sections.placeholderLarge
                ],
                title: this.getVideoTitle(data),
                alt: this.siteId,
                tags: ['a'],
                uploadedDate: data.heroes[0].descriptions.videoDetail.sections.uploadedDate || '',
                duration: data.heroes[0].descriptions.videoDetail.sections.duration || '',
                description: data.heroes[0].descriptions.videoDetail.sections.description || ''
              });
              window[MEConfigKey].media.push({
                type: 'video',
                thumbnail: getResizeImageUrl(
                  data.heroes[0].descriptions.defaultImage.sections.source
                ),
                source: [
                  data.heroes[0].descriptions.videoDetail.sections.source1,
                  data.heroes[0].descriptions.videoDetail.sections.source2
                ],
                preview: [
                  data.heroes[0].descriptions.videoDetail.sections.source1,
                  data.heroes[0].descriptions.videoDetail.sections.source2
                ],
                poster: getResizeImageUrl(
                  data.heroes[0].descriptions.defaultImage.sections.source
                ),
                placeholders: [
                  data.heroes[0].descriptions.videoMain.sections.placeholder,
                  data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                  data.heroes[0].descriptions.videoMain.sections.placeholderLarge
                ],
                closedCaptioning: data.heroes[0].descriptions.videoDetail.sections.closedCaption,
                descriptiveTranscript: data.heroes[0].descriptions.videoDetail.sections.descriptiveTranscript,
                title: this.siteId,
                alt: this.siteId,
                tags: ['b']
              });
            } else {
              window[MEConfigKey].media.push({
                type: 'video',
                thumbnail: this.getThumbnailUrl(data),
                source: [
                  data.heroes[0].descriptions.videoMain.sections.source1,
                  data.heroes[0].descriptions.videoMain.sections.source2
                ],
                preview: [
                  data.heroes[0].descriptions.videoMain.sections.source1,
                  data.heroes[0].descriptions.videoMain.sections.source2
                ],
                poster: getResizeImageUrl(
                  data.heroes[0].descriptions.defaultImage.sections.source
                ),
                placeholders: [
                  data.heroes[0].descriptions.videoMain.sections.placeholder,
                  data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                  data.heroes[0].descriptions.videoMain.sections.placeholderLarge
                ],
                title: this.getVideoTitle(data),
                alt: this.siteId,
                tags: ['a']
              });
            }
          }
      } else {
        const isMobileSize = 'ontouchstart' in window && window.screen.width < 768;
        const heroContent = getHeroContentMode(data);

        if (heroContent === HeroContent.ImageWithVideo) {
          if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
            this.hasFullScreenVideo = true;
          }
          let imageUrl = data.heroes[0].descriptions.videoMain.sections.display;
          let alt = this.siteId;
          if (isMobileSize) {
            imageUrl = data.heroes[0].descriptions.mobileDefaultImage?.sections?.source || imageUrl;
            alt = data.heroes[0].descriptions.mobileDefaultImage?.sections?.alt || alt;
          }
          window[MEConfigKey].media.push({
            type: 'image',
            thumbnail: getResizeImageUrl(imageUrl),
            source: [imageUrl],
            preview: [imageUrl],
            poster: getResizeImageUrl(imageUrl),
            title: this.siteId,
            alt: alt,
            tags: ['a']
          });
          if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
            window[MEConfigKey].media.push({
              type: 'video',
              thumbnail: this.getThumbnailUrl(data),
              source: [
                data.heroes[0].descriptions.videoDetail.sections.source1,
                data.heroes[0].descriptions.videoDetail.sections.source2
              ],
              preview: [
                data.heroes[0].descriptions.videoDetail.sections.source1,
                data.heroes[0].descriptions.videoDetail.sections.source2
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              placeholders: [
                data.heroes[0].descriptions.videoMain.sections.placeholder,
                data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                data.heroes[0].descriptions.videoMain.sections.placeholderLarge
              ],
              closedCaptioning: data.heroes[0].descriptions.videoDetail.sections.closedCaption,
              descriptiveTranscript: data.heroes[0].descriptions.videoDetail.sections.descriptiveTranscript,
              title: this.getVideoTitle(data),
              alt: this.siteId,
              tags: ['b'],
              uploadedDate: data.heroes[0].descriptions.videoDetail.sections.uploadedDate || '',
              duration: data.heroes[0].descriptions.videoDetail.sections.duration || '',
              description: data.heroes[0].descriptions.videoDetail.sections.description || ''
            });
          }
        } else {
        const isMobileSize = 'ontouchstart' in window && window.screen.width < 768;
        const heroContent = getHeroContentMode(data);

        if (heroContent === HeroContent.ImageWithVideo) {
          if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
            this.hasFullScreenVideo = true;
          }
          let imageUrl = data.heroes[0].descriptions.videoMain.sections.display;
          let alt = this.siteId;
          if (isMobileSize) {
            imageUrl = data.heroes[0].descriptions.mobileDefaultImage?.sections?.source || imageUrl;
            alt = data.heroes[0].descriptions.mobileDefaultImage?.sections?.alt || alt;
          }
          window[MEConfigKey].media.push({
            type: 'image',
            thumbnail: getResizeImageUrl(imageUrl),
            source: [imageUrl],
            preview: [imageUrl],
            poster: getResizeImageUrl(imageUrl),
            title: this.siteId,
            alt: alt,
            tags: ['a']
          });
          if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
            window[MEConfigKey].media.push({
              type: 'video',
              thumbnail: this.getThumbnailUrl(data),
              source: [
                data.heroes[0].descriptions.videoDetail.sections.source1,
                data.heroes[0].descriptions.videoDetail.sections.source2
              ],
              preview: [
                data.heroes[0].descriptions.videoDetail.sections.source1,
                data.heroes[0].descriptions.videoDetail.sections.source2
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              placeholders: [
                data.heroes[0].descriptions.videoMain.sections.placeholder,
                data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                data.heroes[0].descriptions.videoMain.sections.placeholderLarge
              ],
              closedCaptioning: data.heroes[0].descriptions.videoDetail.sections.closedCaption,
              descriptiveTranscript: data.heroes[0].descriptions.videoDetail.sections.descriptiveTranscript,
              title: this.getVideoTitle(data),
              alt: this.siteId,
              tags: ['b'],
              uploadedDate: data.heroes[0].descriptions.videoDetail.sections.uploadedDate || '',
              duration: data.heroes[0].descriptions.videoDetail.sections.duration || '',
              description: data.heroes[0].descriptions.videoDetail.sections.description || ''
            });
          }
        } else if (heroContent === HeroContent.ImageOnly) {
          this.dataType = 'image';

          let imageUrl = data.heroes[0].descriptions.defaultImage.sections.source;
          let alt = this.siteId;
          if (isMobileSize) {
            imageUrl = data.heroes[0].descriptions.mobileDefaultImage?.sections?.source || imageUrl;
            alt = data.heroes[0].descriptions.mobileDefaultImage?.sections?.alt || alt;
          }
          // This gets used from within the frame
          window[MEConfigKey].media.push({
            type: 'image',
            thumbnail: getResizeImageUrl(imageUrl),
            source: [imageUrl],
            preview: [imageUrl],
            poster: getResizeImageUrl(imageUrl),
            title: this.siteId,
            alt: alt,
            tags: ['a']
          });
        } else if (heroContent === HeroContent.VideoAutoplay) {
          if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
            this.hasFullScreenVideo = true;
          }
          if (isMobileSize && get(data, 'heroes[0].descriptions.mobileVideoDetail', false)) {
            window[MEConfigKey].media.push({
              type: 'video',
              thumbnail: this.getThumbnailUrl(data),
              source: [
                data.heroes[0].descriptions.mobileVideoDetail.sections.source1,
                data.heroes[0].descriptions.mobileVideoDetail.sections.source2
              ],
              preview: [
                data.heroes[0].descriptions.mobileVideoDetail.sections.source1,
                data.heroes[0].descriptions.mobileVideoDetail.sections.source2
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.mobileDefaultImage.sections.source
              ),
              placeholders: [
                data.heroes[0].descriptions.mobileVideoDetail.sections.placeholder,
                data.heroes[0].descriptions.mobileVideoDetail.sections.placeholderSmall,
                data.heroes[0].descriptions.mobileVideoDetail.sections.placeholderLarge
              ],
              closedCaptioning: data.heroes[0].descriptions.mobileVideoDetail.sections.closedCaption,
              descriptiveTranscript: data.heroes[0].descriptions.mobileVideoDetail.sections.descriptiveTranscript,
              title: this.getVideoTitle(data),
              alt: this.siteId,
              tags: ['b'],
              uploadedDate: data.heroes[0].descriptions.mobileVideoDetail.sections.uploadedDate || '',
              duration: data.heroes[0].descriptions.mobileVideoDetail.sections.duration || '',
              description: data.heroes[0].descriptions.mobileVideoDetail.sections.description || ''
            });
          } else if (isMobileSize) {
            const imageUrl = data.heroes[0].descriptions.mobileDefaultImage?.sections?.source || '';
            const alt = data.heroes[0].descriptions.mobileDefaultImage?.sections?.alt || this.siteId;
            // This gets used from within the frame
            window[MEConfigKey].media.push({
              type: 'image',
              thumbnail: getResizeImageUrl(imageUrl),
              source: [imageUrl],
              preview: [imageUrl],
              poster: getResizeImageUrl(imageUrl),
              placeholders: [
                data.heroes[0].descriptions.videoMain.sections.placeholder,
                data.heroes[0].descriptions.videoMain.sections.placeholderSmall
              ],
              title: this.siteId,
              alt: alt,
              tags: ['a']
            });
          }
          if (get(data, 'heroes[0].descriptions.videoDetail', false)) {
            window[MEConfigKey].media.push({
              type: 'video',
              thumbnail: this.getThumbnailUrl(data),
              source: [
                data.heroes[0].descriptions.videoMain.sections.source1,
                data.heroes[0].descriptions.videoMain.sections.source2
              ],
              preview: [
                data.heroes[0].descriptions.videoMain.sections.source1,
                data.heroes[0].descriptions.videoMain.sections.source2
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              placeholders: [
                data.heroes[0].descriptions.videoMain.sections.placeholder,
                data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                data.heroes[0].descriptions.videoMain.sections.placeholderLarge
              ],
              title: this.getVideoTitle(data),
              alt: this.siteId,
              tags: ['a'],
              uploadedDate: data.heroes[0].descriptions.videoDetail.sections.uploadedDate || '',
              duration: data.heroes[0].descriptions.videoDetail.sections.duration || '',
              description: data.heroes[0].descriptions.videoDetail.sections.description || ''
            });
            window[MEConfigKey].media.push({
              type: 'video',
              thumbnail: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              source: [
                data.heroes[0].descriptions.videoDetail.sections.source1,
                data.heroes[0].descriptions.videoDetail.sections.source2
              ],
              preview: [
                data.heroes[0].descriptions.videoDetail.sections.source1,
                data.heroes[0].descriptions.videoDetail.sections.source2
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              placeholders: [
                data.heroes[0].descriptions.videoMain.sections.placeholder,
                data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                data.heroes[0].descriptions.videoMain.sections.placeholderLarge
              ],
              closedCaptioning: data.heroes[0].descriptions.videoDetail.sections.closedCaption,
              descriptiveTranscript: data.heroes[0].descriptions.videoDetail.sections.descriptiveTranscript,
              title: this.siteId,
              alt: this.siteId,
              tags: ['b']
            });
          } else {
            window[MEConfigKey].media.push({
              type: 'video',
              thumbnail: this.getThumbnailUrl(data),
              source: [
                data.heroes[0].descriptions.videoMain.sections.source1,
                data.heroes[0].descriptions.videoMain.sections.source2
              ],
              preview: [
                data.heroes[0].descriptions.videoMain.sections.source1,
                data.heroes[0].descriptions.videoMain.sections.source2
              ],
              poster: getResizeImageUrl(
                data.heroes[0].descriptions.defaultImage.sections.source
              ),
              placeholders: [
                data.heroes[0].descriptions.videoMain.sections.placeholder,
                data.heroes[0].descriptions.videoMain.sections.placeholderSmall,
                data.heroes[0].descriptions.videoMain.sections.placeholderLarge
              ],
              title: this.getVideoTitle(data),
              alt: this.siteId,
              tags: ['a']
            });
          }
        }
      }
    }

      const self = this;
      // Allow multiple attempts of initializing ME in case media-engine.js has not finished loading
      let initME = setInterval(function () {
        if (self.ME) {
          clearInterval(initME);
        } else {
          self.initializeME(MEConfigKey);
        }
      }, 100);
      // Stop trying to initialize ME after 5 seconds - this is a fallback as it should already be initialized before this
      setTimeout(function () { clearInterval(initME); }, 5000);
    });
  }

  private initializeME(MEConfigKey) {
    try {
      // @ts-ignore
      this.ME = new window.ME3();
      // this instance of the ME is set on the window because its call to fullscreen is invoked in the hero component
      window[this.mediaEngineId] = this.ME;
      this.ME.init('.hero-me', window[MEConfigKey]);

      const pixieSelector = this.siteId === 'dcl' ? 'pixie-hero-dcl' : 'pixie-hero';

      window['initConfigOverrides'] = () => {
        if (this.hasFullScreenVideo) {
          this.ME.config.callbacks.customFullscreenEnter = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            document.body.classList.add('pixie-fullscreen');
            document.querySelector(pixieSelector).classList.add('fade-out-fade-in');
            document.getElementById('media-engine-hero').classList.add('me-fullscreen');
            this.ME.changeMediaSet(2);
            this.ME.fullscreenEnter();
            this.fullscreen = true;
          };
          this.ME.config.callbacks.customFullscreenExit = () => {
            document.body.classList.remove('pixie-fullscreen');
            document.querySelector(pixieSelector).classList.add('fade-out-fade-in');
            document.getElementById('media-engine-hero').classList.remove('me-fullscreen');
            this.ME.changeMediaSet(1);
            this.ME.fullscreenExit();
          };
        } else {
          this.ME.config.callbacks.customFullscreenEnter = () => { };
          this.ME.config.callbacks.customFullscreenExit = () => { };
        }
      };

      window.addEventListener('scroll', () => {
        if (this.fullscreen && window.scrollY > 250) {
          this.ME.config.callbacks.customFullscreenExit();
        }
      });
    } catch (error) {
      console.log('error initializing me3', error);
    }
  }

  /* Check for presence of top elements before showing content */
  private waitForElement(selector) {
    return new Promise(function(resolve) {
      if (document.querySelector(selector)) {
        resolve(true);
      }

      const observer = new MutationObserver(function() {
        if (document.querySelector(selector) || document.querySelector('pixie-homepage.loaded')) {
          observer.disconnect();
          resolve(true);
        }
      });

      observer.observe(document.querySelector('body'), {
        childList: true,
        attributes: true
      });
    });
  }

  private waitForHomepageElements() {
    let qqReady = false;
    let heroReady = false;
    let storiesReady = false;
    let timeout = false;
    let contentShown = false;

    function showContent() {
      if (!contentShown && (timeout || (heroReady && qqReady && storiesReady))) {
        document.querySelector('pixie-homepage').classList.remove('loading');
        document.querySelector('pixie-homepage').classList.add('loaded');
        document.querySelector('body').classList.add('loaded');
        contentShown = true;
      }
    }

    // Fallback to show homepage content in case hero or quickquote do not load quickly enough
    setTimeout(function () {
      timeout = true;
      showContent();
    }, 5000);

    // me-ready class gets added when hero media engine ready callback fires
    this.waitForElement('body.me-ready').then(function() {
      heroReady = true;
      showContent();
    });

    // qq-loaded class gets added when utilsConfig is populated
    this.waitForElement('body.qq-ready').then(function() {
      qqReady = true;
      showContent();
    });

    this.waitForElement('pixie-stories').then(function() {
      storiesReady = true;
      showContent();
    });
  }

  private setupSyndicated() {
    if (document.getElementById('pageContainerSyndicated')) {
      this.useSyndicated = false;
    }
    console.log('this.useSyndicated  ', this.useSyndicated);
  }

  private setupAnalytics() {
    this.homepageAnalyticsService.initAnalytics();
  }

  private setupSeo(data: any) {
    this.seoService.setSeoData(data);
    this.titleService.setTitle(data.pageTitle);
  }

  private getStringArray(value): string[] {
    const array = typeof value === 'string' && value ? value.split(',') : [];

    return array.map(value => value.trim());
  }

  private setThemes() {
    const toggles = this.configService.getValue('toggles');
    const themes = this.getStringArray(toggles?.homepageTheme);
    const unthemedAffiliations = this.getStringArray(toggles?.unthemedAffiliations);
    const overrideThemes = this.getStringArray(this.cookieService.get('theme'));

    // Add themes from testing cookie set in browser
    if (overrideThemes.length > 0) themes.push(...overrideThemes);

    if (themes.length === 0) return;

    if (unthemedAffiliations.length === 0) {
      this.themes = themes;
      document.body.classList.add(...this.themes);

      return;
    }

    // Check for affiliations that match unthemedAffiliations
    // If there are any matches, do not set themes from the toggles file
    this.affiliationsService.getAffiliations().then(affiliations => {
      const storedAffiliations = affiliations[this.siteId]?.storedAffiliations || [];
      const matches = storedAffiliations.filter(affiliation => unthemedAffiliations.includes(affiliation));

      this.themes = matches.length > 0 ? overrideThemes : themes;
      document.body.classList.add(...this.themes);
    });
  }

  private setFastTheme() {
    const toggles = this.configService.getValue('toggles');
    const currentLanguage = getLangFromOrigin();

    if (toggles?.fastTheme?.[currentLanguage] === true) {
      document.body.classList.add('showfast');

      return;
    }
  }

  private getSeoImageUrl(data: any): string {
    const heroData = data?.heroes?.[0];
    // Get image url from media default image if it exists
    const mediaDefaultImage = heroData?.media?.defaultImage;
    const mediaDefaultImageUrl = mediaDefaultImage?.url || (mediaDefaultImage?.sections && mediaDefaultImage?.sections?.source) || '';
    // Otherwise get image url from descriptions
    const descriptions = heroData?.descriptions;
    const descImageUrl = descriptions?.videoMain?.sections?.display || descriptions?.defaultImage?.sections?.source || '';

    return mediaDefaultImageUrl || descImageUrl;
  }

  private getSocialTags(data: any, seoImageUrl: string): { property?: string, name?: string, content: string }[] {
    let tagsContent = DEFAULT_SOCIAL_TAGS[this.siteId];
    tagsContent = {
      ...tagsContent,
      // Update URL in case a different language is used
      url: window.location.href,
      // Update title and description with meta data if it exists
      pageTitle: data?.pageTitle || tagsContent?.pageTitle,
      description: data?.description || tagsContent?.description,
      // Update with hero imageUrl if it exists
      image: seoImageUrl || tagsContent?.image
    };

    const socialTags: { property?: string, name?: string, content: string }[] = [
      { property: "og:title", content: tagsContent.pageTitle },
      { property: "og:site_name", content: tagsContent.siteName },
      { property: "og:url", content: tagsContent.url },
      { property: "og:description", content: tagsContent.description },
      { property: "og:type", content: tagsContent.type },
      { property: "og:image", content: tagsContent.image },
      { name: "twitter:card", content: tagsContent.twitterCard },
      { name: "twitter:site", content: tagsContent.twitterSite },
      { name: "twitter:title", content: tagsContent.pageTitle },
      { name: "twitter:description", content: tagsContent.description },
      { name: "twitter:image", content: tagsContent.image }
    ];

    // Remove any tags with undefined or null content
    // If brand doesn't exist in DEFAULT_SOCIAL_TAGS, some values will be undefined
    return socialTags.filter(tag => !!tag.content);
  }

  private setLangAttribute() {
    let lang = this.locale.replace('_', '-').toLowerCase();
    if (window.location?.pathname?.length > 1) {
      lang = getLangFromOrigin();
    }
    if (lang !== 'en') {
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    }
  }

  private cleanHtmlTags(text: string): string {
    return text?.replace(/<[^>]*>/g, '') || '';
  }

  private getVideoTitle(data: any): string {
    const rawTitle = data?.heroes[0]?.descriptions?.videoTitle?.sections?.title ||
                    data?.heroes[0]?.descriptions?.mobileVideoTitle?.sections?.title ||
                    data?.heroes[0]?.name ||
                    this.siteId;
                    
    return this.cleanHtmlTags(rawTitle);
  }

  private getThumbnailUrl(data: any): string {
    if (!data?.heroes?.[0]?.descriptions) {
      return null;
    }

    const descriptions = data.heroes[0].descriptions;
    
    if (descriptions.defaultImage?.sections?.source) {
      try {
        return getResizeImageUrl(descriptions.defaultImage.sections.source);
      } catch (error) {
        console.warn('Error getting thumbnail url:', error);
      }
    }

    const possibleSources = [
      descriptions.defaultImage?.sections?.source,
      descriptions.videoMain?.sections?.display,
      descriptions.videoDetail?.sections?.display,
      descriptions.mobileVideoDetail?.sections?.display
    ];

    return possibleSources.find(source => !!source) || null;
  }
}

declare global {
  interface Window {
    ME3: object;
  }
}
