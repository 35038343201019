<div class="dcl-cast-member-header desktop" role="region" *ngIf="castMemberData" (window:resize)="onResize($event)">
    <ng-container *ngTemplateOutlet="castMemberHeaderContent"></ng-container>
</div>
<div class="dcl-cast-member-header mobile" role="region" *ngIf="castMemberData" dclStickyBar (window:resize)="onResize($event)">
    <ng-container *ngTemplateOutlet="castMemberHeaderContent"></ng-container>
</div>

<ng-template #castMemberHeaderContent>
    <h1 class="sr-only">{{ 'castMemberHeader.castInformation' | translate }}</h1>
    <div class="info-table">
        <div class="info-row">
            <div class="info-col">
                <span class="info-full-name">
                    <span class="info-name">{{ 'castMemberHeader.name' | translate }}</span>
                    <span class="info-name-content guestSensitive">{{castMemberData.givenName}}
                        {{castMemberData.familyName}}
                    </span>
                </span>
            </div>
            <div class="info-col">
                <span class="info-general">
                    <span class="info-name">{{ 'castMemberHeader.employeeNumber' | translate }}</span>
                    <span class="guestSensitive">{{castMemberData.employeeNumber}}</span>
                </span>
            </div>
            <div class="info-col">
                <span class="info-general">
                    <span class="info-name">{{ 'castMemberHeader.hiringDate' | translate }}</span>
                    <span class="guestSensitive">{{castMemberData.hireDate | date:'MM/dd/yyyy'}}</span>
                </span>
            </div>
            <div class="info-col">
                <span class="info-general">
                    <span class="info-name">{{ 'castMemberHeader.businessArea' | translate }}</span>
                    <span class="guestSensitive">{{castMemberData.businessArea}}</span>
                </span>
            </div>
        </div>
    </div>
</ng-template>
