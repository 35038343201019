import { Component, OnDestroy, AfterViewInit } from '@angular/core';

import { CastMemberData } from '../interfaces/cast-user.interface';
import { CAST_HEADER_CONSTANTS } from './cast-member-header.constants';

@Component({
    selector: 'dcl-cast-member-header',
    templateUrl: './cast-member-header.component.html',
    styleUrls: ['./cast-member-header.component.scss'],
})
export class CastMemberHeaderComponent implements AfterViewInit, OnDestroy {
    castMemberData: CastMemberData;
    parent = CAST_HEADER_CONSTANTS.PAGE_CONTAINER_ID;

    constructor() {
        try {
            this.castMemberData = JSON.parse(
                sessionStorage.getItem(CAST_HEADER_CONSTANTS.STORAGE_KEY)
            );
        } catch (error) {}
    }

    onResize(event: {}) {
        this.adjustParentPosition();
    }

    ngAfterViewInit() {
        this.adjustParentPosition();
    }

    ngOnDestroy() {
        this.setContainerPosition('0');
    }

    private adjustParentPosition() {
        let pageContanerPosition = '0';

        const castMemberHeader = document.querySelector(
            CAST_HEADER_CONSTANTS.MOBILE_CAST_HEADER
        );

        if (castMemberHeader) {
            pageContanerPosition = castMemberHeader.clientHeight.toString();
        }

        this.setContainerPosition(pageContanerPosition);
    }

    private setContainerPosition(position: string) {
        const elem = document.querySelector(
            CAST_HEADER_CONSTANTS.PAGE_CONTAINER_ID
        ) as HTMLBodyElement;

        if (elem) {
            if (position === '0') {
                elem.style.top = '';
                elem.style.position = 'static';
            } else {
                elem.style.top = position + 'px';
                elem.style.position = 'absolute';
            }
        }
    }
}
