<section class="error-states">
    <div class="content">
        <div class="error-container" [ngClass]="errorContainerClass">
            <div class="character" [ngClass]="[character]" [style.background-image]="image"></div>
            <div class="text">
                <h2 class="title" translate>{{ title }}</h2>
                <div class="description">
                    <p translate>{{ firstDesc }}</p>
                    <p translate>{{ secondDesc }}</p>
                </div>
                <div class="error-action-cta" *ngIf="actionText">
                    <button role="link" (click)="onActionClick()" translate>
                        {{ actionText }}
                    </button>
                </div>
            </div>
            <div *ngIf="footerImage" class="character footer-image" [style.background-image]="footerImage"></div>
        </div>
    </div>
</section>
