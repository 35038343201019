import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderReservationDetailComponent } from './header-reservation-detail.component';
import { DatesFormatIntlModule } from '../dates-format-intl/dates-format-intl.module';
import { DatesFormatModule } from '../dates-format/dates-format.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        DatesFormatIntlModule,
        DatesFormatModule
    ],
    declarations: [
        HeaderReservationDetailComponent
    ],
    exports: [
        HeaderReservationDetailComponent
    ]
})
export class HeaderReservationDetailModule {}
