export const EXTENDED_SELECT_CONSTANTS = {
    templatesRefTypes: {
        template: 'Template',
        placeholder: 'Placeholder'
    },
    overlayDir: {
        positions: [
            {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top'
            },
            {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom'
            }
        ]
    }
};
