import { CacheResult } from '../../interfaces/cache-result.interface';
import { Cache } from '../../interfaces/cache.interface';
import { StorageHandler } from '../../interfaces/storage-handler.interface';
import * as momentModule from 'moment';

export class LocalStorageCacheHandler implements StorageHandler {
    private moment = momentModule;

    /**
     * Set value in localstorage with cache metadata
     * @param key
     * @param value
     * @param expireTime
     */
    set<T>(key: string, value: T, expireTime?: string): void {
        const cacheValue: Cache<T> =  {
            data: value,
            metadata: {
                created: new Date(),
                expires: expireTime
                    ? this.moment(expireTime).toString()
                    : ''
            }
        };

        localStorage.setItem(key, JSON.stringify(cacheValue));
    }

    /**
     *  Get value from localstorage with cache metadata
     * @param key
     * @returns saved value if existing or null otherwise
     */
    get<T>(key: string): CacheResult<T> | null {
        const value = localStorage.getItem(key);

        if (!value) {
            return null;
        }

        const result: CacheResult<T> = JSON.parse(value);

        result.expired = result.metadata.expires !== ''
            ? this.moment().isAfter(result.metadata.expires)
            : false;

        return result;
    }

    /**
     * Delete localstorage key with cache metadata
     * @param key
     * @returns deletion confirmation if key exists
     */
    delete(key: string): boolean {
        const value = localStorage.getItem(key);

        localStorage.removeItem(key);

        return Boolean(value);
    }
}
