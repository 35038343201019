import { CookieService } from 'ngx-cookie-service';
import { empty, finalize, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CART_CONSTANTS } from './cart.constants';
import { CartDelete } from './interfaces/cart-delete.interface';
import { CartRestService } from './rest/cart-rest.service';

@Injectable()
export class CartService {
    private constants = CART_CONSTANTS;

    constructor(private cookieService: CookieService, private cartRestService: CartRestService) {}

    /**
     * Set webapi base url for rest service
     * @param baseUrl
     */
    setRestBaseUrl(baseUrl: string): void {
        this.cartRestService.setBaseUrl(baseUrl);
    }

    /**
     * Retrieves cart identifier based on cookie
     * @returns value for cart identifier
     */
    getCartId(): string {
        try {
            const cartIdMapping = this.cookieService.get(this.constants.COOKIES.CART_ID_MAPPING);
            const parsedCartIdMapping = JSON.parse(cartIdMapping);

            return parsedCartIdMapping['new'] || '';
        } catch (err) {
            return '';
        }
    }

    /**
     * Retrieves voyage identifier based on cookie
     * @returns value for voyage identifier
     */
    getVoyageId(): string {
        return this.cookieService.get(this.constants.COOKIES.SAILING_ID);
    }

    /**
     * Clear cart id mapping cookie
     */
    clearCartIdMappingCookie(): void {
        const { path } = this.constants.COOKIES.COOKIE_OPTIONS;

        this.cookieService.delete(this.constants.COOKIES.CART_ID_MAPPING, path);
    }

    /**
     * Cart Delete
     * @returns Observable for cart delete process
     */
    deleteCart(): Observable<null> {
        const cartId = this.getCartId();
        const voyageId = this.getVoyageId();

        const observable: Observable<null> = !!(cartId && voyageId) ?
            this.cartRestService.deleteCart(<CartDelete>{cartId, voyageId}) :
            empty();

        return observable.pipe(finalize(() => this.clearCartIdMappingCookie()));
    }
}
