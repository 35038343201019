import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { AccessibleType } from '../../../interfaces/party-mix.interface';
import { Child } from '../../interfaces/child.interface';
import { ChildCountChanges } from './../../interfaces/child-count-changes.interface';
import { EventService } from '../../../services/event-service/event.service';
import { QUICK_QUOTE_CONSTANTS } from '../../../quick-quote/quick-quote.constants';
import { Stateroom } from '../../interfaces/stateroom.interface';
import { StateroomErrors } from './../../interfaces/stateroom-errors.interface';
import { TRAVEL_PARTY_CONSTANTS } from './../../travel-party.constants';
import { TravelPartyService } from './../../services/travel-party.service';
import { ViewChildProperties } from '../../../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-stateroom',
    templateUrl: './stateroom.component.html',
    styleUrls: ['./stateroom.component.scss']
})
export class StateroomComponent implements OnInit, AfterViewInit {
    @Input() checkForMoreStaterooms: boolean;
    @Input() index: number;
    @Input() lastIndex: number;
    @Input() stateroom: Stateroom;
    @Input() isDclShipAdaPhase2: boolean;
    @Input() showTravelPartyBox: boolean;
    @Input() isModFlow: boolean;

    @Output() removeStateroomEvent: EventEmitter<Stateroom> = new EventEmitter();
    @Output() updateStateroomEvent: EventEmitter<Stateroom> = new EventEmitter();
    @Output() analyticsStateroomEvent: EventEmitter<Stateroom> = new EventEmitter();
    @Output() firstItemTabbed = new EventEmitter<KeyboardEvent>();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();

    @ViewChild('warningMessageContainerRef', { static: false }) warningMessageContainerRef: ElementRef;
    @ViewChild('accessibleRoomCheckbox', { static: false } as ViewChildProperties) accessibleRoomCheckbox: ElementRef;
    @ViewChild('accessibleContent', { static: false } as ViewChildProperties) accessibleContent: ElementRef;
    @ViewChild('adultsLabel', { static: false } as ViewChildProperties) adultsLabel: ElementRef;
    @ViewChild('childrenLabel', { static: false } as ViewChildProperties) childrenLabel: ElementRef;
    @ViewChildren('childSelectors') childSelectors: QueryList<ElementRef>;

    adultsAriaLabel: string;
    adultsError = false;
    accessibleBehavior = {
        disclaimerSelected: '',
        showDescriptions: false,
    };
    changes: ChildCountChanges;
    childrenAriaLabel: string;
    childrenArray: Child[] = [];
    childrenError = false;
    constants = TRAVEL_PARTY_CONSTANTS;
    disableAccessibleRoom: boolean;
    errors: StateroomErrors = {
        acceptedAccessibleRoom: false,
        infantRestriction: false,
        infantsPerAdultRestriction: false,
        maxGuestsPerStateroom: false,
        onlyOneAdultWarning: false
    };
    options = {
        ages: []
    };
    preserveAutoUpdateFromWdprCounter = true;
    qqConstants = QUICK_QUOTE_CONSTANTS;
    showDisclaimers = false;
    showAcceptance = false;
    maxCapacityWarningMessage: string;
    ariaLiveMessage: string = '';

    constructor(
        private eventService: EventService,
        private renderer: Renderer2,
        private translateService: TranslateService,
        private travelPartyService: TravelPartyService
    ) {}

    ngOnInit(): void {
        this.stateroom.enableShowADAError = false;
        this.options.ages = this.travelPartyService.ageOptionsGenerator();
        this.changes = {
            newValue: null,
            oldValue: null
        };
        this.disableAccessibleRoom = this.travelPartyService.getDisableAccessibleRoomValue();
        this.maxCapacityWarningMessage = this.isModFlow ?
            this.constants.warningMessageI18N.modifyMaxCapacity :
            this.constants.warningMessageI18N.maxCapacity;

        if (this.isDclShipAdaPhase2) {
            this.showDisclaimers = this.stateroom.accessible;

            if (this.stateroom.accessibleType) {
                this.accessibleBehavior.disclaimerSelected = this.stateroom.accessibleType;
                this.accessibleBehavior.showDescriptions = true;
            }
        }

        this.setLabelsAria();
        this.setTPErrors();
    }

    ngAfterViewInit(): void {
        if (this.childSelectors) {
            this.addListenerForChildSelectors();
        }
    }

    /**
     * Listener for child selectors to update child ages
     */
    addListenerForChildSelectors(): void {
        this.childSelectors.forEach((child: ElementRef, index: number) => {
            this.renderer.listen(child.nativeElement, 'item-selected', (event) => {
                this.onChildAgeSelected(event as CustomEvent, index);
            });
        });
    }

    /**
     * Set data of child age according the event
     */
    onChildAgeSelected(event: CustomEvent, index: number): void {
        this.stateroom.nonAdultAges[index].isDefault = false;

        if ((event.detail.key).toString() === '0') {
            this.stateroom.nonAdultAges[index].age = event.detail.key;
            this.stateroom.nonAdultAges[index].staticValue.age = this.constants.infantAge;
            this.stateroom.nonAdultAges[index].staticValue.ageUnit = this.constants.monthLabel;
        } else {
            this.stateroom.nonAdultAges[index].age = event.detail.value;
            this.stateroom.nonAdultAges[index].staticValue.age = event.detail.value;
            this.stateroom.nonAdultAges[index].staticValue.ageUnit = this.constants.yearLabel;
        }

        this.setTPErrors();
        this.updateStateroomEvent.emit(this.stateroom);
    }

    /**
     * Returns if there's infant ages to show warning message
     */
    getInfantAges(): number {
        let infantsAges = 0;

        if (this.stateroom.nonAdultAges.length > 0) {
            infantsAges = this.travelPartyService.getInfantAges(this.stateroom);
        }

        return infantsAges;
    }

    /**
     * Emits event to parent component when user clicks on 'Remove' link
     * and sends the stateroom's index that needs to be removed
     * @param ev the MouseEvent
     * @param stateroom data from the staterooms
     */
    removeStateroom(ev: MouseEvent, stateroom: Stateroom): void {
        this.stateroom.stateroomAction = TRAVEL_PARTY_CONSTANTS.analytics.actionTypes.removeStateroom;
        this.removeStateroomEvent.emit(stateroom);
        ev.stopPropagation();
    }

    /**
     * Validates if its the child counter clicked, if the value of the child count is updated
     * to add/remove a dropdown
     * Emits event to parent component to update the stateroom if its been edited
     * @param event click of counter
     * @param isAdultCounter in order to identify what count should be update
     */
    updateStateroom(event: CustomEvent, isAdultCounter: boolean): void {
        this.setAutoUpdate(event, isAdultCounter);

        if (!this.preserveAutoUpdateFromWdprCounter && this.stateroom) {
            this.stateroom.isDefault = false;

            if (isAdultCounter) {
                this.stateroom.isAddGuest = this.stateroom.adultCount < event.detail.value;
                this.stateroom.adultCount = event.detail.value;
                this.stateroom.stateroomAction = TRAVEL_PARTY_CONSTANTS.analytics.actionTypes.adult;
            } else {
                this.changes = {
                    newValue: event.detail.value,
                    oldValue: this.stateroom.childCount
                };
                this.stateroom.stateroomAction =  TRAVEL_PARTY_CONSTANTS.analytics.actionTypes.child;
                this.stateroom.isAddGuest = this.changes.oldValue < this.changes.newValue;
                this.stateroom.childCount = event.detail.value;

                if (this.stateroom.childCount > 0 && this.changes.newValue > this.changes.oldValue) {
                    this.travelPartyService.createChildrenArray(this.stateroom);
                }

                if (this.changes.newValue < this.changes.oldValue) {
                    this.travelPartyService.deleteAgeSelector(this.stateroom);
                }
            }

            this.updateStateroomEvent.emit(this.stateroom);

            setTimeout(() => {
                this.addListenerForChildSelectors();
                this.setTPErrors();
            });
        } else {
            this.preserveAutoUpdateFromWdprCounter = false;
        }
    }

    /**
     * Adds focus when user clicks on the plus button ans exceed the amount of children in the room
     */
    private triggerFocusToWarningMessage(): void {
        if (this.warningMessageContainerRef) {
            this.warningMessageContainerRef.nativeElement.focus();
        }
    }

    /**
     * Set if the the emit is valid to start
     * @param isConfirmed in order to identify if is Cancel or Confirm button
     * @param event click of counter
     * @param isAdultCounter in order to identify what count should be update
     */
    setAutoUpdate(event: CustomEvent, isAdultCounter: boolean): void {
        if (this.stateroom) {
            if (this.stateroom.adultCount === event.detail.value && isAdultCounter) {
                this.preserveAutoUpdateFromWdprCounter = true;
            }
            if (this.stateroom.childCount === event.detail.value && !isAdultCounter) {
                this.preserveAutoUpdateFromWdprCounter = true;
            }
        }
    }

    /**
     * Check the option button and also hides the accessible information
     * @param ev the MouseEvent
     * @param isConfirmed in order to identify if is Cancel or Confirm button
     */
    addAccessible(ev: MouseEvent, isConfirmed: boolean): void {
        this.showAcceptance = false;
        this.stateroom.accessible = isConfirmed;
        this.preserveAutoUpdateFromWdprCounter = true;

        setTimeout(() => {
            this.accessibleRoomCheckbox.nativeElement.focus();
        }, this.constants.checkboxTimeout);

        if (isConfirmed) {
            this.stateroom.stateroomAction = TRAVEL_PARTY_CONSTANTS.analytics.actionTypes.accessible;
            this.updateStateroomEvent.emit(this.stateroom);
        }

        ev.stopPropagation();
    }

    /**
     * Handles changes in ADA2 radio button changes to set the disclaimer acceptance error
     */
    handleADA2RadioButtonChange() {
        this.setTPErrors();
    }

    /**
     * Sets the stateroom's accessibility type according to value received
     * @param value the accessibility type of the stateroom
     */
    handleCheckUnderstand(value: AccessibleType) {
        this.stateroom.accessibleType = this.stateroom.accessibleType === value ? null : value;
        this.setTPErrors();
    }

    /**
     * Checks if the accessible option is checked and then shows the accessible information
     * @param  event click event
     */
    showAccessibleMessage(event: Event): void {
        if (this.isDclShipAdaPhase2) {
            this.stateroom.accessible = !this.stateroom.accessible;
            this.showDisclaimers = !this.showDisclaimers;

            if (!this.stateroom.accessible) {
                this.accessibleBehavior = {
                    ...this.accessibleBehavior,
                    disclaimerSelected: '',
                    showDescriptions: false,
                };
                this.stateroom.accessibleType = null;
                this.stateroom.enableShowADAError = false;
            }

            this.setTPErrors();

        } else if (!this.disableAccessibleRoom) {
            this.stateroom.accessible ?
                this.stateroom.accessible = false :
                this.showAcceptance = true;

            if (!this.stateroom.accessible && !this.showAcceptance) {
                this.stateroom.stateroomAction = TRAVEL_PARTY_CONSTANTS.analytics.actionTypes.accessibleRemove;
                this.updateStateroomEvent.emit(this.stateroom);
            }

            this.accessibleContent.nativeElement.focus();
        }

        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * Sets errors related to Travel Party
     * If there's an infant selected.
     * If there's more than 2 infants for 1 adult.
     * If there's more than 5 guests in a stateroom.
     */
    setTPErrors(): void {
        let hasTPErrors: boolean;

        this.errors = this.travelPartyService.validateStateroomErrors(this.stateroom, this.isModFlow);
        this.childrenError = this.errors.maxGuestsPerStateroom;
        this.adultsError = this.errors.infantsPerAdultRestriction || this.childrenError;
        hasTPErrors = Object.keys(this.errors).some((key: string) => this.errors[key]);

        this.eventService.sendNextItem({
            name: this.constants.travelPartyError,
            value: hasTPErrors
        });

        if (hasTPErrors) {
            this.triggerFocusToWarningMessage();
        }
    }

    /**
     * Sets labels to be used in aria-label attributes
     */
    setLabelsAria(): void {
        const adultsAria = this.constants.adultsLabelAria;
        const childrenAria = this.constants.childrenLabelAria;

        this.adultsAriaLabel = adultsAria && this.translateService.instant(adultsAria);
        this.childrenAriaLabel = childrenAria && this.translateService.instant(childrenAria);
    }

    /**
     * Sends focus to the accessible room checkbox
     */
    focusCheckbox(): void {
        this.accessibleRoomCheckbox.nativeElement.focus();
    }

    /**
     * Gets if there are any error
     * @returns {boolean} if there is any error to be shown
     */
    checkErrors(): boolean {
        const showError = !!Object.values(this.errors).some((value: boolean) => !!value === true);

        return this.disableAccessibleRoom || this.childrenError || showError;
    }

    /**
     * Gets the child age validating if it's an infant
     * @param child object
     * @returns {number}
     */
    getChildAge(child: Child): number {
        return get(child, 'staticValue.ageUnit', '') === this.constants.monthLabel ? 0 : child.age;
    }
}
