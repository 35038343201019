import { Component, Input } from '@angular/core';

import { Table } from '../table/interfaces/table.interface';

@Component({
    selector: 'dcl-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent {
    @Input() data: Table;
    @Input() showColumns: number[];
}
