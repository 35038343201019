export const PRICE_SUMMARY_CONSTANTS = {
    GUEST: {
        ADULT: 'ADULT',
        CHILD: 'CHILD',
        INFANT: 'INFANT',
    },
    TOTAL_PRICE_TEXT: 'priceSummary.totalPrice',
    TOTAL_PRICE_LEGEND: 'rateDetailsModal.priceDetailsLegend',
    PRICE_TEXT: 'priceSummary.totalPriceLabel',
    PRICE_LEGEND: 'rateDetailsModal.priceDetailsLegendLabel',
    ADULT_AGE_GROUP: 'priceSummary.ageGroupAdult',
    CHILD_AGE_GROUP: 'priceSummary.ageGroupChild',
    INFANT_AGE_GROUP: 'priceSummary.ageGroupInfant'
};
