import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { COOKIES } from '../../cookies/cookies.constants';
import { LanguageSelectionJar } from '../../cookies/interfaces/language-selection-jar.interface';
import { LocaleCookieJar } from '../../cookies/interfaces/locale-cookie-jar.interface';
import { NewLocaleInterface } from '../../cookies/interfaces/new-locale.interface';

@Injectable({
    providedIn: 'root'
})
export class AppLocaleService {
    private localeCookie: LocaleCookieJar | null;
    private localeCookieJarAka: LocaleCookieJar | null;
    private languageSelectionJar: LanguageSelectionJar | null;
    private languageSelectionJarAka: LanguageSelectionJar | null;
    private contentRegex: RegExp = /([A-Za-z]{2,8})(_|-)[A-Za-z0-9]{2,8}/;

    constructor(
        private cookieService: CookieService
    ) {
        this.init();
    }

    /**
     * Returns a valid value for being used as an Accept-Language HTTP Header.
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
     * @link https://datatracker.ietf.org/doc/html/rfc3066#section-2.1
     * @returns Valid Accept-Language header value
     */
    public acceptLanguage(): string {
        const defaultContent = COOKIES.DEFAULTS.CONTENT;
        const content = this.content();
        const isValid = this.contentRegex.test(content);
        const acceptLanguage = isValid ? content : defaultContent;

        return acceptLanguage.replace('_', '-');
    }

    /**
     * Returns the locale content value if defined.
     * The order was defined by the following change
     * https://github.disney.com/wdpro-development-dcl/wdpr-ui-service-connector/pull/721
     *
     * [Analytics]
     * If the value is needed for analytics we return the ISO 3166-1 alpha-2
     * @see https://mywiki.disney.com/display/GO4/Disney+Data+Layer+-+Semantic+Analytics+Model+Properties
     *
     * @param {boolean} [isAnalytics] determine if the value returned will be used for the Semantic Analytics Model
     * @returns {string} Locale Content
     */
    public content(isAnalytics?: boolean): string {
        let content: string = COOKIES.DEFAULTS.CONTENT;
        let localeCookieJar: LocaleCookieJar;
        let languageSelectionJar: LanguageSelectionJar;

        if (this.locale) {
            localeCookieJar = this.locale as LocaleCookieJar;
            languageSelectionJar = this.locale as LanguageSelectionJar;

            if (localeCookieJar.preferredRegion) {
                content = localeCookieJar.preferredRegion;
            } else if (localeCookieJar.contentLocale) {
                content = localeCookieJar.contentLocale;
            } else if (languageSelectionJar.preferredLanguage) {
                content = languageSelectionJar.preferredLanguage;
            } else if (languageSelectionJar.language) {
                content = languageSelectionJar.language;
            }
        }

        if (isAnalytics) {

            // @todo this is a fix to prevent use language value in place of region,
            // note that this happens only with default content defined in localeCookie(appears as "en")
            // a fix is coming with the internationalization project so this
            // could be removed once the fix is ready
            if (content.indexOf('_') === -1) {
                content = COOKIES.DEFAULTS.CONTENT;
            }

            content = content.split('_').pop();
        }

        return content;
    }

    /**
     * Returns the locale currency value if defined
     * @returns {string} Locale Currency
     */
    public currency(): string {
        const localeCookieJar: LocaleCookieJar = this.locale as LocaleCookieJar;
        let currency: string = COOKIES.DEFAULTS.CURRENCY;

        if (this.locale && localeCookieJar.localeCurrency) {
            currency = localeCookieJar.localeCurrency;
        }

        return currency;
    }

    /**
     * Returns the language conform to ISO 639-1 (a two-letter lowercase)
     * The value is taken from locale content
     * @returns {string} language string
     */
    public language(): string {
        return this.content()
            .split('_')
            .shift()
            .toLowerCase();
    }

    /**
     * Get locale value from localeCookie
     * @returns {(string)} Locale value
     */
    public contentLocale() {
        const language = this.acceptLanguage();
        const index = language.indexOf('-') + 1;

        return language.substring(index);
    }

    /**
     * Method to set the cookie localeCookie_jar.preferredRegion
     * @param item
     */
    public setLocaleCookie(item: NewLocaleInterface) {
        const localeExpiration = new Date();
        const localeCookie = this.locale as LocaleCookieJar;

        localeExpiration.setDate(localeExpiration.getDate() + COOKIES.EXPIRE_DAYS);

        localeCookie.preferredRegion = item.newLocale.replace('-', '_');
        localeCookie.contentLocale = item.newLocale.replace('-', '_');
        localeCookie.localeCurrency = item.newCurrency || COOKIES.DEFAULTS.CURRENCY;

        this.cookieService.set(COOKIES.LOCALE, JSON.stringify(localeCookie), localeExpiration);
        this.cookieService.set(COOKIES.AKAMAI.LOCALE, JSON.stringify(localeCookie), localeExpiration, item.path);
    }

    private get locale(): LocaleCookieJar | LanguageSelectionJar | null {
        // this order is the strategic taken to see from where the values should be taken
        return this.localeCookieJarAka || this.localeCookie ||
            this.languageSelectionJarAka || this.languageSelectionJar ||
            COOKIES.DEFAULTS.LOCALE_COOKIE_JAR;
    }

    /**
     * Method with the responsability of initialize the locale properties
     */
    private init(): void {
        const localeCookieDto = this.cookieService.get(COOKIES.LOCALE);
        const localeCookieJarAkaDto = this.cookieService.get(COOKIES.AKAMAI.LOCALE);
        const langSelectionJarDto = this.cookieService.get(COOKIES.LANGUAGE_SELECTION);
        const langSelectionJarAkaDto = this.cookieService.get(COOKIES.AKAMAI.LANGUAGE_SELECTION);

        this.localeCookie = this.parse<LocaleCookieJar>(localeCookieDto);
        this.localeCookieJarAka = this.parse<LocaleCookieJar>(localeCookieJarAkaDto);
        this.languageSelectionJar = this.parse<LanguageSelectionJar>(langSelectionJarDto);
        this.languageSelectionJarAka = this.parse<LanguageSelectionJar>(langSelectionJarAkaDto);
    }

    /**
     * Parses the locale cookie json value into an object if defined
     * @param value Locale cookie value
     * @returns {Object}
     */
    private parse<C extends (LanguageSelectionJar | LocaleCookieJar)>(value: string): C | null {
        let localeCookie: C | null = null;

        try {
            localeCookie = JSON.parse(value);
        } catch (error) {
            // nothing is done on error. function will return null as designed
        }

        return localeCookie;
    }

}
