import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupDirective, UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { CASTAWAY_MEMBERSHIP_CONSTANTS } from '../castaway-membership-modal.constants';
import { CastawayMembershipService } from '../services/castaway-membership.service';
import { CastawayOption } from '../interfaces/castaway-option.interface';
import { DclDatesService } from '../../dates/dates.service';
import {
    GuestCastawayInfo,
    GuestCastawayInfoEBS,
    GuestCastawayRankingInfo
} from '../../interfaces/guest-castaway-info.interface';
import {
    GuestCastawayInfoRequest,
    GuestCastawayInfoRequestEBS
} from '../../interfaces/guest-castaway-info-request.interface';
import { TravelAgentService } from '../../travel-agent/travel-agent.service';
import { ViewChildProperties } from '../../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-castaway-membership',
    templateUrl: './castaway-membership.component.html',
    styleUrls: ['./castaway-membership.component.scss']
})
export class CastawayMembershipComponent implements OnInit, AfterViewInit {
    @ViewChild('firstFocusableControl', { static: false } as ViewChildProperties) firstFocusableControl: ElementRef;
    @ViewChild('form', { static: false } as ViewChildProperties) form: FormGroupDirective;
    @ViewChild('castawayOptionsSelect', { static: false } as ViewChildProperties) castawayOptionsSelect: ElementRef;
    @ViewChild('castawayNumberInput', { static: false } as ViewChildProperties) castawayNumberInput: ElementRef;

    castawayForm: UntypedFormGroup;
    constants = CASTAWAY_MEMBERSHIP_CONSTANTS;
    formControls = this.constants.FORM_CONTROLS;
    showSpinner: boolean;
    serviceError: string;
    submited: boolean;
    warningMsgType: string = this.constants.ERRORS.WARNING_MSG_TYPE;
    castawayOptionsList: CastawayOption[];
    castawayNumberLabel: string;
    castawayNumberError: string;
    isEarlyBookingSegmentation: boolean;
    castawayInputControl = this.constants.FORM_CONTROLS.CASTAWAY_ID;
    hideIdNumberInput: boolean;

    constructor(
        private castawayMembershipService: CastawayMembershipService,
        private datesService: DclDatesService,
        private dialogRef: MatDialogRef<CastawayMembershipComponent>,
        private elementRef: ElementRef,
        private fb: UntypedFormBuilder,
        private renderer: Renderer2,
        private travelAgentService: TravelAgentService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.isEarlyBookingSegmentation = this.castawayMembershipService.isEarlyBooking();
        this.initForm();
    }

    ngAfterViewInit(): void {
        if (this.isEarlyBookingSegmentation) {
            this.castawayMembershipService.trackLink(this.constants.ANALYTICS.LAUNCH);
        }

        this.setCastawayOption();
    }

    /**
     * Initialize form config
     */
    initForm(): void {
        if (this.isEarlyBookingSegmentation) {
            this.castawayInputControl = this.formControls.ID;
        }

        this.castawayForm = this.fb.group({
            [this.formControls.FIRST_NAME]: ['', [Validators.required]],
            [this.formControls.LAST_NAME]: ['', [Validators.required]],
            [this.formControls.DATE_OF_BIRTH]: ['', [
                Validators.required,
                Validators.pattern(this.constants.DATE_OF_BIRTH_REGEXP)
            ]],
            [this.castawayInputControl]: ['', [Validators.required]]
        });

        if (this.isEarlyBookingSegmentation) {
            this.castawayForm.addControl(
                this.formControls.ID_TYPE,
                new UntypedFormControl('', Validators.required)
            );
            this.getCastawayOptions();
        } else {
            this.castawayNumberLabel = this.constants.CASTAWAY_NUMBER_LABEL;
            this.castawayNumberError = this.constants.CASTAWAY_NUMBER_ERROR;
        }
    }

    /**
     * Keypress event handler for castaway id input field
     * @param event
     * @returns field validation
     */
    onCastawayIdKeyPress(event: KeyboardEvent): boolean {
        const controlName = this.formControls && this.castawayInputControl;
        const castawayIdValue = this.castawayForm.get(controlName).value.toString();

        return castawayIdValue.length < this.constants.CASTAWAY_ID_LENGTH &&
            this.constants.NUMERIC_REGEXP.test(event.key);
    }

    /**
     * Check when an input is valid/invalid
     * @param fieldName field to be validated
     * @returns field state (valid/invalid)
     */
    isInvalid(fieldName: string): boolean {
        const fieldIsInvalid = (this.castawayForm.get(fieldName).touched || this.submited) &&
            this.castawayForm.get(fieldName).invalid;

        if (fieldIsInvalid) {
            this.serviceError = null;
        }

        return fieldIsInvalid;
    }

    /**
     * Close dialog
     */
    closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * Handle submit form event
     */
    submit(): void {
        let dataToSend;
        let isCastaway;

        this.submited = true;
        this.serviceError = '';

        if (this.castawayForm.valid) {
            this.showSpinner = true;
            isCastaway = this.castawayForm.value[this.formControls.ID_TYPE] ===
                this.constants.CASTAWAY_OPTIONS.ID_TYPES.castawayClub;
            dataToSend = this.getCastawayParams(isCastaway);

            if (this.isEarlyBookingSegmentation) {
                this.castawayMembershipService.trackLink(
                    this.constants.ANALYTICS.CLICK,
                    this.castawayForm.get(this.formControls.ID_TYPE).value
                );
            }

            if (this.isEarlyBookingSegmentation && !isCastaway) {
                this.castawayMembershipService.getGuestAffiliationByType(dataToSend)
                    .subscribe(
                        (castawayData: GuestCastawayInfoEBS) => {
                            const idType = dataToSend.idType.replace(' ', '_');
                            const castawayDataObj = {
                                castawayId: castawayData.id,
                                firstName: dataToSend.firstName,
                                lastName: dataToSend.lastName,
                                dateOfBirth: dataToSend.dateOfBirth,
                                idType,
                                eligibleGrant: idType
                            };

                            this.castawayMembershipService.getMemberRanking(castawayData.id)
                                .subscribe((data: GuestCastawayRankingInfo) => {
                                    if (data && data.eligibleGrant) {
                                        castawayDataObj.eligibleGrant = data.eligibleGrant;
                                        this.onValidationSuccess(castawayDataObj);
                                    } else {
                                        this.onValidationError();
                                    }
                                },
                                (error: HttpErrorResponse) => {
                                    this.onValidationError();
                                });
                        },
                        (error: HttpErrorResponse) => {
                            this.onValidationError();
                        }
                    );
            } else {
                this.castawayMembershipService.getGuestAffiliation(dataToSend)
                    .subscribe(
                        (data: GuestCastawayInfo) => {
                            this.onValidationSuccess(data);
                        },
                        (error: HttpErrorResponse) => {
                            this.onValidationError();
                        }
                    );
            }
        }
    }

    /**
     * We implemented this functionality to call the submit because using polymer components the submit doesn't work
     * and in order to do autocompletion on the inputs there must be submission events.
     */
    callSubmit(): void {
        if (!this.castawayOptionsSelect ||
            document.activeElement.id !== this.castawayOptionsSelect.nativeElement.id) {
            this.form.ngSubmit.emit();
        }
    }

    /**
     * Set the castaway options for the dropdown
     */
    private getCastawayOptions(): void {
        const translationKey = this.constants.CASTAWAY_OPTIONS.OPTION_KEY;
        const brandsToShow = this.castawayMembershipService.getEBSBrandsToShow();
        let defaultMemberTypeOption;

        this.castawayOptionsList = [];
        this.translate.get(translationKey).subscribe((res) => {
            if (res && typeof res === 'object') {
                Object.keys(res).forEach((key) => {
                    this.castawayOptionsList.push({
                        key,
                        value: res[key]
                    });
                });
            }
        });

        // Filter the brands that should not be available
        this.castawayOptionsList = this.castawayOptionsList.filter((brand: CastawayOption) => {
            return brandsToShow[brand.key] && brand;
        });

        // The array is sorted alphabetically
        this.castawayOptionsList.sort((a: CastawayOption, b: CastawayOption) => {
            const valA = a.value.toUpperCase();
            const valB = b.value.toUpperCase();

            return (valA < valB) ? -1 : (valA > valB) ? 1 : 0;
        });

        if (this.castawayOptionsList.length === 1) {
            defaultMemberTypeOption = this.castawayOptionsList[0].key;

            setTimeout(() => {
                this.castawayForm.get(this.formControls.ID_TYPE).setValue(defaultMemberTypeOption);

                this.selectMemberOption(defaultMemberTypeOption, false);
            });
        }
    }

    /**
     * Select castaway type option
     */
    private setCastawayOption(): void {
        if (this.castawayOptionsSelect) {
            this.renderer.listen(this.castawayOptionsSelect.nativeElement, 'item-selected', (event) => {
                const key = event.detail.key;

                this.selectMemberOption(key, true);
            });
        }
    }

    /**
     * Select the type member option and show the input to enter the number
     * @param key type selected
     * @param focusNumberInput if the member number should have the focus
     */
    private selectMemberOption(key: string, focusNumberInput: boolean): void {
        if (key) {
            this.hideIdNumberInput = this.constants.BRANDS_WITHOUT_ID_NUMBER.includes(key);

            if (this.hideIdNumberInput) {
                this.castawayForm.get(this.castawayInputControl).clearValidators();
            } else {
                this.castawayForm.get(this.castawayInputControl).setValidators([Validators.required]);
            }

            this.castawayForm.get(this.castawayInputControl).updateValueAndValidity();
            this.castawayNumberLabel = this.constants.CASTAWAY_OPTIONS.PLACE_HOLDER_KEY + key;
            this.castawayForm.get(this.constants.FORM_CONTROLS.ID_TYPE).setValue(key);
            this.castawayNumberError = this.constants.CASTAWAY_OPTIONS.ERROR_KEY + key;

            this.castawayMembershipService.trackLink(
                this.constants.ANALYTICS.SELECTION,
                this.castawayForm.get(this.formControls.ID_TYPE).value
            );

            if (focusNumberInput) {
                setTimeout(() => {
                    if (this.castawayNumberInput) {
                        this.castawayForm.get(this.castawayInputControl).reset();
                        this.castawayNumberInput.nativeElement.focus();
                    }
                }, this.constants.FOCUS_INPUT_TIME);
            }
        }
    }

    /**
     * set error status after castaway validation
     */
    private onValidationError(): void {
        this.showSpinner = false;
        this.serviceError = this.constants.ERRORS.NOT_MATCH;
    }

    /**
     * Set castaway info in session and close modal
     * @param {GuestCastawayInfo} data
     */
    private onValidationSuccess(data: GuestCastawayInfo): void {
        this.showSpinner = false;
        this.travelAgentService.storeGuestCastawayData(data);
        this.dialogRef.close(data);
    }

    /**
     * Return params to be sent to validation endpoint
     * @param {boolean} isCastaway if castawayId selected
     * @returns {GuestCastawayInfoRequest | GuestCastawayInfoRequestEBS}
     */
    private getCastawayParams(isCastaway: boolean): GuestCastawayInfoRequest | GuestCastawayInfoRequestEBS {
        let castawayInfo = {
            ...this.castawayForm.value,
            [this.formControls.DATE_OF_BIRTH]:
                this.datesService.toISOCalendarString(this.castawayForm.value[this.formControls.DATE_OF_BIRTH])
        };

        if (this.isEarlyBookingSegmentation) {
            castawayInfo = {
                ...castawayInfo,
                [this.formControls.ID_TYPE]:
                    this.constants.CASTAWAY_OPTIONS.ID_TYPES[this.castawayForm.value[this.formControls.ID_TYPE]] ||
                    this.castawayForm.value[this.formControls.ID_TYPE]
            };

            if (isCastaway) {
                castawayInfo = {
                    ...castawayInfo,
                    [this.formControls.CASTAWAY_ID]: this.castawayForm.value[this.castawayInputControl]
                };
                delete castawayInfo.idType;
                delete castawayInfo.id;
            }
        }

        return castawayInfo;
    }

    /**
     * Focus the first focusable control in the modal when tab key is pressed outside of it
     * Trap the focus on the modal for tab and shift+tab combination keys
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown', ['$event'])
    onTab(event: KeyboardEvent): void {
        if (
            event.key.toLowerCase() === this.constants.TAB_KEY &&
            !this.elementRef.nativeElement.contains(event.target)
        ) {
            event.preventDefault();
            this.firstFocusableControl.nativeElement.focus();
        }
    }
}
