import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceString'
})
export class ReplaceStringPipe implements PipeTransform {
    constructor() {}

    transform(value: string, properties: {}): string {
        let key: string;

        if (value) {
            for (key in properties) {
                if (properties[key] || properties[key] === 0) {
                    value = value.replace(`{{${key}}}`, properties[key]);
                }
            }
        }

        return value;
    }
}
