
import { Pipe, PipeTransform } from '@angular/core';
import * as momentModule from 'moment';

/**
 * Format two dates in a tuple in just one string
 * Takes a first string argument to divide the two dates
 * and a second boolean argument to display the days of the week
 *
 * Usage:
 *    datesTuple | datesFormat: separatorString:booleanValue
 *
 * Example:
 *    ['2020-12-28', '2021-01-01'] | datesFormat: '-':true
 *    formats to: 'Dec 28 2020 - Jan 1 2021, Monday - Friday'
 */
@Pipe({
    name: 'datesFormat'
})
export class DatesFormatPipe implements PipeTransform {
    moment = momentModule;

    transform(dates: [string, string], separator: string, daysOfWeek?: boolean): string {
        const startDate = this.moment(dates[0]);
        const endDate = this.moment(dates[1]);
        let formatedDate: string;

        if (startDate.year() === endDate.year()) {
            if (startDate.month() === endDate.month()) {
                formatedDate = `${startDate.format('MMMM D')} ${separator} ${endDate.format('D, YYYY')}`;
            } else {
                formatedDate = `${startDate.format('MMMM D')} ${separator} ${endDate.format('MMMM D, YYYY')}`;
            }
        } else {
            formatedDate = `${startDate.format('MMMM D, YYYY')} ${separator} ${endDate.format('MMMM D, YYYY')}`;
        }

        if (daysOfWeek) {
            formatedDate += `, ${startDate.format('dddd')} ${separator} ${endDate.format('dddd')}`;
        }

        return formatedDate;
    }
}
