<div class="image-picker">
    <ul class="image-picker-items" role="list">
        <li *ngFor="let image of imagesPickerData, let i = index; last as last; first as first">
            <button
                class="image-picker-item"
                [ngClass]="{
                    'selected': image.selected,
                    'disabled': image.disabled,
                    'focused': image.focused
                }"
                [attr.role]="ariaRole"
                [attr.aria-checked]="customA11yRole ? null : image.selected"
                [attr.aria-label]="getAriaLabel(image, first)"
                (click)="toggleActive(image)"
                (keydown.tab)="onTabPress($any($event), last)"
                (focus)="onFocus(image)"
                (focusout)="onFocusout(image)">
                <div class="image-picker-card">
                    <dcl-hero-image
                        aria-hidden="true"
                        class="dcl-hero-image"
                        [customImgPlaceholder]="customImgPlaceholder"
                        [media]="$any(image?.media)"
                        [mediaSize]="mediaSize">
                    </dcl-hero-image>
                    <ng-container *ngIf="image.selected">
                        <div *ngIf="checkedIcon; else defaultIcon" class="icon-checked" [@fadeInOut]>
                            <img [src]="checkedIcon" [alt]="checkmarkIconAlt | translate" aria-hidden="true">
                        </div>
                        <ng-template #defaultIcon>
                            <div class="icon-checked icon-checked-default" [@fadeInOut]>
                                <i class="di di-selected-solid-checkbox"></i>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
                <div class="image-picker-legend" aria-hidden="true">
                    {{ image.legend }}
                </div>
            </button>
        </li>
    </ul>
</div>
