import { capitalize } from 'lodash';

/**
 * Some words are exceptions, words like:
 * McCain, MacCain, D'Olveira
 * @param word is a string that will be validated with the RegExp if it should be capitalized
 * @returns true or false, depending on whether the word meets the condition
 */
function shouldBeCapitalized(word: string): boolean {
    const reg = new RegExp(/[A-Z]{1}[a-z\'\.]{1,2}[A-Z]/gm);

    return reg.test(word);
}

/**
 * It will go through each word in a string and capitalize them.
 * @param stringData is a string with one or more words.
 * @returns the string received with all words capitalized.
 */
export function capitalizeEachString(stringData: string): string {
    if (!stringData) {
        return '';
    }

    return stringData.split(' ').map((word) => shouldBeCapitalized(word) ? word : capitalize(word)).join(' ');
}
