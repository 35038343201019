/** Converts object key values into a query params string.
 * @param {object} object
 * @returns {string}
 * @example
 * const object = {
 *  key1: 'value1',
 *  key2: 'value2'
 * };
 *
 * const params = getParams(object);
 */
function getParams(object: Object): string {
    const params = Object.keys(object).map(key => {
        return key + '=' + object[key];
    });

    return '?' + params.join('&');
}

export default getParams;
