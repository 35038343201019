<div [ngClass]="{'dcl-layout': siteId === 'dcl'}">
    <section id="stories-container">
        <ng-container *ngFor="let story of stories; let i=index;" [ngSwitch]="story.generalPurposeStrings.template">
            <pixie-lead-gen-dcl
                *ngSwitchCase="'lead-gen'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-lead-gen-dcl>
            <pixie-revenue
                *ngSwitchCase="'5-revenue-hero'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-revenue>
            <pixie-destination
                *ngSwitchCase="getDestinationTemplate(story.generalPurposeStrings.template)"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-destination>
            <pixie-fast-destination
                *ngSwitchCase="getFastDestinationTemplate(story.generalPurposeStrings.template)"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-fast-destination>
            <pixie-crosspromo
                *ngSwitchCase="'cross-promo'"
                [id]="getStoryId(story.generalPurposeStrings, i)" 
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                [analyticsTrackingEvent]="analyticsTrackingEvent"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-crosspromo>
            <pixie-fast-crosspromo
                *ngSwitchCase="'fast-cross-promo'"
                [id]="getStoryId(story.generalPurposeStrings, i)" 
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                [analyticsTrackingEvent]="analyticsTrackingEvent"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-fast-crosspromo>
            <pixie-fast-plan-your-vacation
                *ngSwitchCase="'fast-plan-your-vacation'"
                [id]="getStoryId(story.generalPurposeStrings, i)" 
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                [analyticsTrackingEvent]="analyticsTrackingEvent"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-fast-plan-your-vacation>
            <pixie-fast-kbyg
                *ngSwitchCase="'fast-know-before-you-go'"
                [id]="getStoryId(story.generalPurposeStrings, i)" 
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                [analyticsTrackingEvent]="analyticsTrackingEvent"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-fast-kbyg>
            <pixie-fast-plan-disney
                *ngSwitchCase="'fast-plan-disney'"
                [id]="getStoryId(story.generalPurposeStrings, i)" 
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                [analyticsTrackingEvent]="analyticsTrackingEvent"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-fast-plan-disney>
            <pixie-vpg
                *ngSwitchCase="'dcl-banner'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-vpg>
            <pixie-awards
                *ngSwitchCase="'awards'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-awards>
            <pixie-dvic-banner
                *ngSwitchCase="'dvic-offer'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-dvic-banner>
            <pixie-featured-product
                *ngSwitchCase="'featured-cruise-tile'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-featured-product>

        </ng-container>
    </section>
</div>