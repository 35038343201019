<div class="quick-quote-container border-radius" [ngClass]="{'border-radius-filter-open': isOpen}">
    <ul class="filter-list" role="tablist">
        <ng-container *ngFor="let filterOption of filterOptions; index as index">
            <li #tabs
                *ngIf="!filterOption.isSubgroup"
                id="tab-{{ index }}"
                role="tab"
                [attr.aria-selected]="filterSelectedIndex === index && isOpen ? 'true' : 'false'"
                class="filter-option-{{ filterOption?.type }} filter-options border-radius"
                [ngClass]="{ 'filter-selected': filterSelectedIndex === index && isOpen, 'filter-disabled': filterOption?.isDisabled }"
                [attr.tabindex]="filterSelectedIndex === index ? 0 : tabindex"
                (click)="selectFilter(index)"
                (keydown)="onKeyPress($event, index)">
                <ng-container
                    [ngTemplateOutlet]="filterOption?.type === constants.filters['travel-party'].id ||
                        filterOption?.type === constants.filters['more-filters'].id ? lastFilterOptionTemplate : filterOptionTemplate"
                    [ngTemplateOutletContext]="{ filterOption: filterOption }">
                </ng-container>
            </li>
        </ng-container>
    </ul>
    <div [ngClass]="{ 'button-container-hide': hideViewCruisesButton }" class="button-container">
        <button
            #viewCruisesButton
            translate
            class="view-cruises-button"
            [disabled]="hasTPError || disableViewCruisesButton"
            [attr.tabindex]="tabindex"
            (click)="applyFilters()"
            (keydown.arrowLeft)="focusPrevTab(filtersTabs.length)"
            (keydown.arrowRight)="focusNextTab(-1)">
            {{ constants.translationKeys.bar.findCruisesButton }}
        </button>
    </div>
</div>

<ng-template let-filterOption="filterOption" #filterOptionTemplate>
    <div class="filter">
        <span class="title" translate>{{ filterOption.headingKey }}</span>
        <span class="subtitle">{{ filterOption.value | translate }}</span>
    </div>
</ng-template>

<ng-template let-filterOption="filterOption" #lastFilterOptionTemplate>
    <div class="more-options">
        <div class="filter" *ngIf="filterOption?.type === constants.filters['travel-party'].id">
            <i class="custom-icon di di-friends-family" aria-hidden="true"></i>
            <span
                class="subtitle"
                [attr.role]="hasTPError ? 'alert' : null"
                [class.has-error]="hasTPError">
                {{ filterOption.value }}
            </span>
        </div>
        <ng-container *ngIf="filterOption?.type === constants.filters['more-filters'].id">
            <div class="middle-bar"></div>
            <div class="filter">
                <i class="custom-icon di di-lists" aria-hidden="true"></i>
                <span class="subtitle">{{ filterOption.headingKey | translate}}</span>
            </div>
        </ng-container>
    </div>
</ng-template>
