<div class="accessible-disclaimers">
    <wdpr-radio-group
        class="accessible-disclaimers__container"
        [selected]="this.accessibleBehavior.disclaimerSelected">
        <wdpr-radio
            class="accessibility-radio accessible-disclaimers__container-mobilityRadio"
            [attr.name]="disclaimerConstants.mobility.name"
            ngDefaultControl
            [checked]="this.accessibleBehavior.disclaimerSelected === disclaimerConstants.mobility.name"
            (click)="handleRadioDisclaimersChanged(disclaimerConstants.mobility.name)">
                {{ disclaimerConstants.mobility.name | titlecase }}
        </wdpr-radio>
        <ng-container *ngIf="accessibleBehavior.disclaimerSelected === disclaimerConstants.mobility.name"
            [ngTemplateOutlet]="accessibleDescriptions"
            [ngTemplateOutletContext]="{ disclaimerType: disclaimerConstants.mobility }">
        </ng-container>
        <wdpr-radio
            class="accessibility-radio accessible-disclaimers__container-communicativeRadio"
            [attr.name]="disclaimerConstants.communicative.name"
            ngDefaultControl
            [checked]="this.accessibleBehavior.disclaimerSelected === disclaimerConstants.communicative.name"
            (click)="handleRadioDisclaimersChanged(disclaimerConstants.communicative.name)">
                {{ disclaimerConstants.communicative.name | titlecase }}
        </wdpr-radio>
        <ng-container *ngIf="accessibleBehavior.disclaimerSelected === disclaimerConstants.communicative.name"
            [ngTemplateOutlet]="accessibleDescriptions"
            [ngTemplateOutletContext]="{ disclaimerType: disclaimerConstants.communicative }">
        </ng-container>
        <wdpr-radio
            class="accessibility-radio accessible-disclaimers__container-bothRadio"
            [attr.name]="disclaimerConstants.both.name"
            ngDefaultControl
            [checked]="this.accessibleBehavior.disclaimerSelected === disclaimerConstants.both.name"
            (click)="handleRadioDisclaimersChanged(disclaimerConstants.both.name)">
                {{ disclaimerConstants.both.name | titlecase }}
        </wdpr-radio>
        <ng-container *ngIf="accessibleBehavior.disclaimerSelected === disclaimerConstants.both.name"
            [ngTemplateOutlet]="accessibleDescriptions"
            [ngTemplateOutletContext]="{ disclaimerType: disclaimerConstants.both }">
        </ng-container>
        <ng-container *ngIf="!accessibleBehavior.disclaimerSelected" 
            [ngTemplateOutlet]="errorMessage">
        </ng-container>
    </wdpr-radio-group>
</div>

<ng-template #accessibleDescriptions let-disclaimerType="disclaimerType">
    <div class="accessible-disclaimers__descriptions bottom-spacing" *ngIf="accessibleBehavior.showDescriptions">
        <div 
            *ngIf="accessibleBehavior.disclaimerSelected !== disclaimerConstants.mobility.name"
            class="accessible-disclaimers__descriptions-info bottom-spacing">
            <i class="di di-information accessible-icon" aria-hidden="true"></i>
            <div class="accessible-info" translate>
                {{ disclaimerConstants.info }}
            </div>
        </div>
        <div class="accessible-disclaimers__descriptions-understand">
            <wdpr-checkbox
                alignment="top"
                [checked]="stateroom.accessibleType === disclaimerType.name || false"
                (click)="handleCheckUnderstand($event)"
                translate>
                {{ disclaimerType.description }}
            </wdpr-checkbox>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="errorMessage"></ng-container>
</ng-template>

<ng-template #errorMessage>
    <dcl-warning-messaging
        class="has-error"
        [ngClass]="{'bottom-spacing': stateroom.enableShowADAError && errors.acceptedAccessibleRoom}"
        [configuredStyles]="true"
        messageType="warning"
        [message]="'stateroomADA.errorMessage' | translate"
        [showMessage]="stateroom.enableShowADAError && errors.acceptedAccessibleRoom">
    </dcl-warning-messaging>
</ng-template>