import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';

import { NativeBridgeService } from '../../../native-bridge/native-bridge.service';
import { ReservationFlowService } from '../../../services/reservation-flow/reservation-flow.service';
import { ViewChildProperties } from '../../../interfaces/view-child-properties.interface';
import { WHATS_INCLUDED_MODAL_CONSTANTS } from '../../whats-included-modal.constants';
import { WhatsIncludedAnalyticsKeys } from '../../interfaces/whats-included-analytics-keys.interface';
import { WhatsIncludedData } from '../../interfaces/whats-included-data.interface';
import { WhatsIncludedModalContent } from '../../interfaces/whats-included-modal-content.interface';
import { WhatsIncludedModalHeader } from '../../interfaces/whats-included-modal-header.interface';
import { WhatsIncludedModalService } from '../../services/whats-included-modal.service';
import { WhatsIncludedModel } from '../../interfaces/whats-included-model.interface';
import { WhatsIncludedSvcParams } from '../../interfaces/whats-included-svc-url.interface';

@Component({
    selector: 'dcl-whats-included-modal',
    templateUrl: './whats-included-modal.component.html',
    styleUrls: ['./whats-included-modal.component.scss']
})
export class WhatsIncludedModalComponent implements OnInit {
    @Input() showButton: boolean = true;
    @Input() svcParams: WhatsIncludedSvcParams;
    @Output() analyticsTrackingEvent: EventEmitter<{trackLinkID: string}> = new EventEmitter();
    @ViewChild('whatsIncludedModal', { static: false } as ViewChildProperties) modal: ElementRef;

    analyticsKeys: WhatsIncludedAnalyticsKeys;
    constants = WHATS_INCLUDED_MODAL_CONSTANTS;
    contents: WhatsIncludedModalContent[] = [];
    data: WhatsIncludedData;
    header: WhatsIncludedModalHeader;
    isError: boolean;
    isLoading: boolean;
    isOpened: boolean;
    analyticsCustomKeys: WhatsIncludedAnalyticsKeys;
    isEmbedded: boolean;
    baseAnalyticsKeys: WhatsIncludedAnalyticsKeys = {
        tabbedContentKeys: {
            printKey: this.constants.DCL_WHATSINCLUDED_PRINT,
            tabsKeys: Object.values(this.constants.tabsKeys)
        },
        modalCloseKey: this.constants.DCL_WHATSINCLUDED_EXIT,
        whatsIncludedModalLink: this.constants.DCL_WHATSINCLUDED_MODAL
    };

    constructor(
        private nativeBridgeService: NativeBridgeService,
        private reservationFlowService: ReservationFlowService,
        private whatsIncludedModalService: WhatsIncludedModalService
    ) {}

    ngOnInit() {
        this.setData();
    }

    /**
     * Opens the modal
     */
    openWhatsIncludedModal(): void {
        if (this.modal) {
            this.modal.nativeElement.setAttribute('open', true);
            this.isOpened = true;
            this.emitAnalyticsDataModel();
        }
    }

    /**
     * Capture teh onClose event and emit analytics update
     */
    onClose(): void {
        this.isOpened = false;
        this.whatsIncludedModalService.setAnalyticsCustomKeys({
            modalCloseKey: this.analyticsKeys.modalCloseKey
        });
        this.emitAnalyticsDataModel();
    }

    /**
     * throws trackLinkID when the modal is opened or closed
     */
    emitAnalyticsDataModel(): void {
        // update any key with custom before proceed
        this.updateAnalyticsKeys();

        if (this.analyticsKeys) {
            const trackLink = this.isOpened ?
                this.analyticsKeys.whatsIncludedModalLink :
                this.analyticsKeys.modalCloseKey;

            this.analyticsTrackingEvent.emit({ trackLinkID: trackLink });
        }
    }

    /**
     * Sets the data got from the service into an attribute component that will be
     * used to extract the info rendered in the modal
     */
    setData(): void {
        this.whatsIncludedModalService.isOpenModal().pipe(
            filter((isOpened: boolean) => isOpened && !!this.svcParams),
            switchMap(() => {
                this.isError = false;
                this.isLoading = true;
                this.isEmbedded = this.nativeBridgeService.isEmbedded() &&
                    this.reservationFlowService.isModFlow();

                this.openWhatsIncludedModal();

                return this.whatsIncludedModalService.getWhatsIncludedInfo(this.svcParams);
            })
        ).subscribe((whatsIncludedModel: WhatsIncludedModel) => {
            if (whatsIncludedModel.error) {
                this.isError = true;
                this.header = {} as WhatsIncludedModalHeader;
            } else {
                this.header = whatsIncludedModel.header;
                this.contents = whatsIncludedModel.contents;
            }

            this.isLoading = false;
        });
    }

    /**
     * Close modal from baymax header
     */
    closeModalFromBaymax() {
        if (this.modal) {
            this.modal.nativeElement.removeAttribute('open');
            this.onClose();
        }
    }

    /**
     * Event listener for callback-key-triggered attribute
     * @param {CustomEvent} event The custom event emitted by the web component
     */
    emitAnalyticsTracking(event: CustomEvent): void {
        if (event && event.detail && event.detail.value) {
            // TODO:  Investige the following issue
            // For some reason the dcl-tabbed content polymer component
            // is not overriding the default callback-keys.
            // Maybe a conflict between the library and the web component polymer versions.
            const base = 10;
            const defaultTabId = event.detail.value;
            const tabIndex = parseInt(defaultTabId.slice(-1), base);
            const tabKey = this.baseAnalyticsKeys.tabbedContentKeys.tabsKeys[tabIndex] || defaultTabId;

            this.analyticsTrackingEvent.emit({ trackLinkID: tabKey });
        }
    }

    /**
     * Update the default analytics data with a custon data provided
     */
    private updateAnalyticsKeys(): void {
        const customValues = this.whatsIncludedModalService.getAnalyticsCustomKeys();

        if (customValues) {
            this.analyticsKeys = { ...this.baseAnalyticsKeys, ...customValues };
        }
    }
}
