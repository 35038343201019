import { Component, Input, OnInit } from '@angular/core';

import getParams from '../utils/getParams';
import { SyndicatedOptions } from '../interfaces/syndicated-options.model';
import { SYNDICATED_FOOTER_ANIMATIONS } from './syndicated-footer.animations';

@Component({
    selector: 'dcl-syndicated-footer',
    templateUrl: './syndicated-footer.component.html',
    styleUrls: ['./syndicated-footer.component.scss'],
    animations: [...SYNDICATED_FOOTER_ANIMATIONS]
})
export class SyndicatedFooterComponent implements OnInit {
    @Input() baseUrl: string;
    @Input() environment: string;
    @Input() legalPath: string;
    @Input() mode: string;
    // determine if the top footer separator should be displayed, when is "true" it will be hide
    @Input() noTopSeparator?: boolean;
    @Input() path: string;
    @Input() siteId: string;
    @Input() version: number;
    @Input() locale: string;
    @Input() language: string;
    @Input() brandFooterDefaultOptions;
    @Input() cachedPath = '/cached/';
    defaultOptions: SyndicatedOptions;
    syndicatedFooterUrl: string;
    useCached = true;
    stateName: string;
    legalContainer = 'legalWrapper';

    ngOnInit() {
        const path = this.mode !== 'full' ? this.legalPath : this.path;

        this.defaultOptions = { ...this.brandFooterDefaultOptions[this.siteId] };
        this.defaultOptions.container = this.mode === 'full' ? this.defaultOptions.container : this.legalContainer;
        this.defaultOptions.version = this.version || this.defaultOptions.version;
        this.defaultOptions.locale = this.locale || this.defaultOptions.locale;
        this.defaultOptions.language = this.language || this.defaultOptions.language;
        this.defaultOptions.siteOverride = this.siteId;
        this.syndicatedFooterUrl =
            `${this.baseUrl}${path}${this.useCached ? this.cachedPath : '/'}${getParams(this.defaultOptions)}`;
    }

    /**
     * Once the footer ends to load, it removes the style attribute
     * @param event
     */
    footerStateDone(event: AnimationEvent) {
        setTimeout(() => {
            event['element'].removeAttribute('style');
        });
    }
}
