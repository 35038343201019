import { NgModule } from '@angular/core';

import { DateInputMaskDirective } from './date-input-mask.directive';

@NgModule({
    declarations: [
        DateInputMaskDirective
    ],
    exports: [
        DateInputMaskDirective
    ]
})
export class DateInputMaskModule {}
