import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { checkedFadeAnimation } from './images-picker.animation';
import { HeroSize } from '../hero-image/interfaces/hero-size.interfaces';
import { ImagePicker } from './image-picker.interface';
import { ItemChanged } from '../interfaces/item-changed.interface';
import {
    imagePickerStatesKey,
    imagePickerRole,
    IMAGE_PICKER_IMAGE_SIZE
} from './images-picker.constants';

@Component({
    selector: 'dcl-images-picker',
    templateUrl: 'images-picker.component.html',
    styleUrls: ['images-picker.component.scss'],
    animations: [ checkedFadeAnimation ]
})

export class ImagesPickerComponent implements OnInit {
    @Input() ariaLabelKey: string;
    @Input() checkedIcon: string;
    @Input() checkmarkIconAlt: string;
    @Input() customA11yRole: string;
    @Input() customImgPlaceholder: string;
    @Input() imagesPickerData: ImagePicker[];
    @Input() groupAriaLabel: string;
    @Output() onChangedImage: EventEmitter<ItemChanged> = new EventEmitter();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();

    ariaRole: string;
    ariaLabel: string;
    imageStates: {};
    mediaSize: HeroSize;

    constructor(
        private translateService: TranslateService
    ) {
        this.translateService.get(imagePickerStatesKey)
            .subscribe((translateValues: {}) => this.imageStates = translateValues);
    }

    ngOnInit(): void {
        this.mediaSize = IMAGE_PICKER_IMAGE_SIZE;
        this.ariaRole = this.getAriaRole;
    }

    /**
     * Sets role to link for SO drawer and checkbox for
     * @returns role attribule value
     */
    get getAriaRole(): string {
        return this.customA11yRole || imagePickerRole;
    }

    /**
     * Toggle active state
     * @param image selected image
     */
    toggleActive(image: ImagePicker): void {
        if (!image.disabled) {
            image.selected = !image.selected;
            image.focused = image.selected;
            this.onChangedImage.emit({
                id: image.id,
                isSelected: image.selected,
                name: image.legend,
                urlFriendlyId: image.urlFriendlyId
            });
        }
    }

    /**
     * check between 4 different states of the image
     * in order to returns the correct a11y copy to be read
     *
     * @param image param that will be checked
     * @returns string value with the correct copy
     */
    getImageState(image: ImagePicker): string {
        let state = '';

        if (image && image.disabled) {
            state = this.imageStates['disabled'];
        }

        return state;
    }

    /**
     * Retrieve the aria label information
     */
    getAriaLabel(image: ImagePicker, isFirst: boolean): string {
        const transData = {
            state: this.getImageState(image),
            cruise: get(image, 'legend') + (image.media ? ' ' + get(image, 'media.mediaProductDesktopHero.alt') : '')
        };

        this.translateService.get(this.ariaLabelKey, transData)
            .subscribe((translateResult: string) => {
                const validatePosition = `${(this.groupAriaLabel && isFirst ? `${this.groupAriaLabel} ` : '')}`;

                this.ariaLabel = `${validatePosition}${translateResult}`;
            });

        return this.ariaLabel;
    }

    /**
     * Handles tab key press event
     * @param event Keyboard event
     * @param isLastItem boolean to know if item tabbed was the last
     */
    onTabPress(event: KeyboardEvent, isLastItem: boolean): void {
        if (isLastItem) {
            this.lastItemTabbed.emit(event);
        }
    }

    /**
     * Handles focus event to activate the custom "focused" prop that will be used in A11y
     * Note: if you try to use ":focus" selector the outline will happens when is "clicked" or when "tabbed",
     * and will removed when those event happens again so outline remains visible
     * when you navigate hovering others images, do not remove this implementation without a11y validation.
     * @param image param that will be checked
     */
    onFocus(image: ImagePicker): void {
        image.focused = true;
    }

    /**
     * Handles focusout event to deactivate the custom "focused" prop that will be used in A11y
     * Note: see "Note" in "onFocus"
     * @param image param that will be checked
     */
    onFocusout(image: ImagePicker): void {
        image.focused = false;
    }
}
