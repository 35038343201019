<div class="message-container" [ngClass]="getErrorClass()">
    <div *ngIf="showMessage">
        <i class="warning-icon di di-alert" aria-hidden="true"></i>
    </div>
    <div [attr.aria-live]="ariaLive" aria-atomic="false">
        <div *ngIf="showMessage">
            <span class="sr-only">{{ iconLabel | translate }}<br></span>
            <span #errorMessageSpan
                [attr.tabindex]="setFocus ? -1 : null"
                class="message-label"
                [innerHtml]="message">
            </span>
        </div>
    </div>
</div>
