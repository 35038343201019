import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directive to mask input into MM/DD/YYYY format
 */
@Directive({
    selector: '[formControlName][dclDateInputMask]'
})
export class DateInputMaskDirective implements OnInit {
    separator: string = '/';
    private valueMaxLength: number = 10;
    private numberRegexp: RegExp = /[0-9]/;
    private separatorRegexp: RegExp = new RegExp(`\\${this.separator}`, 'g');
    private inputTypeRegexp: RegExp = new RegExp('deleteContent', 'i');
    private firstSeparatorIndex: number = 2;
    private lastSeparatorIndex: number = 4;

    constructor(private ngControl: NgControl, private renderer: Renderer2, private elementRef: ElementRef) {}

    ngOnInit() {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'maxlength', `${this.valueMaxLength}`);
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        return this.numberRegexp.test(event.key);
    }

    @HostListener('input', ['$event'])
    onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const rawValue = input.value.replace(this.separatorRegexp, '').split('');
        const isDeleteEvent = this.inputTypeRegexp.test(event['inputType']);
        const firstSeparatorPosition = isDeleteEvent ? this.firstSeparatorIndex : this.firstSeparatorIndex - 1;
        const lastSeparatorPosition = isDeleteEvent ? this.lastSeparatorIndex : this.lastSeparatorIndex - 1;

        if (rawValue[lastSeparatorPosition]) {
            rawValue.splice(this.lastSeparatorIndex, 0, this.separator);
            rawValue.splice(this.firstSeparatorIndex, 0, this.separator);
        } else if (rawValue[firstSeparatorPosition]) {
            rawValue.splice(this.firstSeparatorIndex, 0, this.separator);
        }

        this.ngControl.control.setValue(rawValue.join(''));
    }
}
