<div class="grid-container">
    <div class="header">{{ gridConfig.label }}</div>
    <button
        *ngFor="let item of gridConfig.contentData; last as last"
        class="block"
        [ngClass]="{ 'disabled': item.isDisabled }"
        [attr.aria-label]="ariaLabelKey | translate: {
            content: getGridA11yContent(item.contentAriaLabel),
            state: getGridState(item) }"
        [attr.aria-checked]="item.isSelected"
        (keydown.enter)="onChangeItem(item, $event)"
        (click)="onChangeItem(item, $event)"
        (keydown.tab)="onTabPress($any($event), last)"
        role="checkbox">
        <div
            class="item"
            [ngClass]="{ 'selected': item.isSelected, 'disabled': item.isDisabled }"
            aria-hidden="true">
            <span class="content-text">{{ item.content }}</span>
            <span *ngIf="item.isEarlyBooking" class="early-book-available">*</span>
        </div>
    </button>
</div>
