import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';

import { WHATS_INCLUDED_MODAL_CONSTANTS } from '../whats-included-modal.constants';
import { WhatsIncludedData } from '../interfaces/whats-included-data.interface';
import { WhatsIncludedDataColumn } from '../interfaces/whats-included-data-column.interface';
import { WhatsIncludedDataModule } from '../interfaces/whats-included-data-module.interface';
import { WhatsIncludedModalContent } from '../interfaces/whats-included-modal-content.interface';
import { WhatsIncludedModalHeader } from '../interfaces/whats-included-modal-header.interface';
import { WhatsIncludedModel } from '../interfaces/whats-included-model.interface';
import { WhatsIncludedSvcParams } from '../interfaces/whats-included-svc-url.interface';
import { WhatsIncludedAnalyticsKeys } from '../interfaces/whats-included-analytics-keys.interface';

@Injectable()
export class WhatsIncludedModalService {
    constants = WHATS_INCLUDED_MODAL_CONSTANTS;
    contents: WhatsIncludedModalContent[] = [];
    data: WhatsIncludedData;
    header: WhatsIncludedModalHeader;
    showModal: BehaviorSubject<boolean>;
    private whatsIncludedModel: WhatsIncludedModel;
    private analyticsCustomKeys: WhatsIncludedAnalyticsKeys;

    constructor(private httpClient: HttpClient) {
        this.showModal = new BehaviorSubject(false);
    }

    /**
     * Return an observable to watch when the modal should open
     */
    isOpenModal(): Observable<boolean> {
        return this.showModal;
    }

    /**
     * Open the modal
     */
    openModal(): void {
        this.showModal.next(true);
    }

    /**
     * Close the modal
     */
    closeModal(): void {
        this.showModal.next(false);
    }

    /**
     * Retrieves the info to be rendered on the modal
     * @param svcParams Service params used to consume the api
     * @returns What's Included info to be rendered on the modal
     */
    getWhatsIncludedInfo(svcParams: WhatsIncludedSvcParams): Observable<WhatsIncludedModel> {
        const { url, language, useLanguageAsHeader } = svcParams;
        const headers = new HttpHeaders().set(this.constants.headers.ACCEPT_LANGUAGE, language);
        const params = { language };
        const requestOptions = useLanguageAsHeader ? { headers } : { params };

        if (this.whatsIncludedModel) {
            return of(this.whatsIncludedModel);
        } else {
            return this.httpClient.get<WhatsIncludedData>(url, { ...requestOptions })
                .pipe(
                    map((response: WhatsIncludedData) =>
                        this.whatsIncludedModel = this.getModel(response)),
                    catchError(() => {
                        // When using throwError, this.whatsIncludedModalService.isOpenModal()
                        // subscription is being killed, as a result the modal is opened once when getting
                        // a service error. I am changing it to return of()

                        // TODO: This should be replaced in the future by a throwError to manage
                        // errors properly.
                        return of({ error: true } as WhatsIncludedModel);
                    })
                );
        }
    }

    /**
     * Set a custom analytics keys that will be used with the WhatsIncluded components
     * @param data
     */
    setAnalyticsCustomKeys(data: WhatsIncludedAnalyticsKeys): void {
        this.analyticsCustomKeys = data;
    }

    /**
     * Retrive the analytics custom keys list
     *
     * @returns WhatsIncludedAnalyticsKeys the custom analytics keys defined
     */
    getAnalyticsCustomKeys(): WhatsIncludedAnalyticsKeys {
        return this.analyticsCustomKeys;
    }

    /**
     * This function gets the model for what's included modal
     * @param dataResponse Raw data got from the service
     * @returns What's Included Model
     */
    private getModel(dataResponse: WhatsIncludedData): WhatsIncludedModel {
        if (dataResponse && dataResponse.modules instanceof Array) {
            const columnsModule = dataResponse.modules.find(module => module.id === this.constants.COLUMNS_MODULE_ID);

            if (columnsModule) {
                const columns = columnsModule.descriptions;

                dataResponse.modules.forEach(module => {
                    if (module.id === this.constants.NAVIGATION_MODULE_ID) {
                        this.setModelHeader(module);
                    } else if (module.id !== this.constants.COLUMNS_MODULE_ID) {
                        this.setModelContent(module, columns);
                    }
                });
            }

            return {
                header: this.header,
                contents: this.contents
            };
        }
    }

    /**
     * This function sets the header data for the Whats Included Modal
     * @param navigationModule
     */
    private setModelHeader(navigationModule: WhatsIncludedDataModule): void {
        const { descriptions, sections } = navigationModule;
        const { title, body: subtitle } = sections;

        const tabs = descriptions.map(description => ({
            id: description.id,
            title: description.sections.header,
            activeImage: description.media['active-iconography'].url,
            inactiveImage: description.media['inactive-iconography'].url
        }));

        this.header = {
            title, subtitle, tabs, enablePrint: 'false'
        };
    }

    /**
     * This function sets the content data for the Whats Included Modal
     * @param contentModule
     * @param columns
     */
    private setModelContent(contentModule: WhatsIncludedDataModule, columns: WhatsIncludedDataColumn[]): void {
        const { id, media, sections, descriptions } = contentModule;
        const { title, body: subtitle } = sections;

        const flagTypes = columns.map(column => ({
            id: column.id,
            title: column.sections.title,
            icon: get(column, 'webfont.htmlCharacter')
                || this.constants.DEFAULTS[column.id].htmlCharacter
                || this.constants.fallbackHtmlCharacter,
            ariaLabel: this.getDefaultAriaLabel(column.id)
        }));

        const contentBlocks = descriptions.map(description => ({
            title: description.sections.title,
            body: description.sections.body || '',
            flag: description.sections.hasOwnProperty(this.constants.INCLUDED_PROPERTY_NAME) ?
                [this.constants.INCLUDED_COLUMN_ID] :
                [this.constants.EXTRAS_COLUMN_ID]
        }));

        const content = {
            id, title, subtitle, flagTypes, contentBlocks,
            img: {
                src: media.modalHero.url,
                alt: media.modalHero.alt || media.modalHero.title || '',
            }
        };

        this.contents.push(content);
    }

    /**
     * Gets the default aria label set in whatsIncludedConstants based on the column id
     * @param columnId
     */
    getDefaultAriaLabel(columnId: string): string {
        let defaultAriaLabel = '';

        if (columnId === this.constants.FLAG_TYPES.included.columnId) {
            defaultAriaLabel = this.constants.FLAG_TYPES.included.iconDefaultAriaLabel;
        } else if (columnId === this.constants.FLAG_TYPES.extras.columnId) {
            defaultAriaLabel = this.constants.FLAG_TYPES.extras.iconDefaultAriaLabel;
        }

        return defaultAriaLabel;
    }
}
