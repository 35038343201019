import { Injectable } from '@angular/core';

import { AnalyticsService } from './../../quick-quote/services/analytics.service';
import { TravelPartyService } from './../../travel-party/services/travel-party.service';

import { ActionTile } from '../interfaces/action-tile.interface';
import { CRUISE_TILES_CONSTANTS } from '../featured-cruise-tiles.constants';
import { CruiseTileAction } from '../interfaces/cruise-tile-action.interface';

@Injectable()
export class FeaturedCruisesAnalyticsService {

    constructor(
        private analyticsServiceFromQuickQuote: AnalyticsService,
        private travelPartyService: TravelPartyService
    ) {}

    /**
     * Checks properties of two CruiseTileActions. Returns true on match, false otherwise
     * @param firstAction First CruiseTileAction to compare
     * @param secondAction Second CruiseTileAction to compare
     * @returns result of comparison
     */
    cruiseTileActionIsEqual(firstAction: CruiseTileAction, secondAction: CruiseTileAction): boolean {
        return (
            firstAction.id === secondAction.id
            && firstAction.filterValue === secondAction.filterValue
            && firstAction.type === secondAction.type
            && firstAction.urlFriendlyId === secondAction.urlFriendlyId
        );
    }

    /**
     * Checks two arrays of CruiseTileActions. Returns true in case all elementa match, false otherwise
     * @param firstArray First Array of CruiseTileAction to compare
     * @param secondArray Second Array of CruiseTileAction to compare
     * @returns result of comparison
     */
    cruiseTileActionsAreEqual(firstArray: CruiseTileAction[], secondArray: CruiseTileAction[]) {
        if (firstArray.length !== secondArray.length) {
            return false;
        }

        for (let i = 0; i < firstArray.length; i++) {
            if (!this.cruiseTileActionIsEqual(firstArray[i], secondArray[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks every ActionTile to find the one matching according to its CruiseTileActions and returns an index
     * starting from 1.
     * returns -1 on no matchs
     * @param selectedTileActions clicked cta actions model
     * @param tilesDisplayed featured tiles displayed
     * @returns Additional props to send in the trackLink event
     */
    getSelectedTileIndex(selectedTileActions: CruiseTileAction[], tilesDisplayed: ActionTile[]): number {
        for (let i = 0; i < tilesDisplayed.length; i++) {
            if (this.cruiseTileActionsAreEqual(tilesDisplayed[i].actions, selectedTileActions)) {
                return (i + 1);
            }
        }

        return -1;
    }

    /**
     * Instantiates a map with needed properties to ensure the analytics model complies with contract
     * on the impression event of Featured Cruises
     * @returns Additional props to send in the trackLink event
     */
    getFeaturedCruisesAnalyticsImpressionEventModel(): object {
        // Additional props to send in the trackLink event
        const modelProps = {
            linkId: CRUISE_TILES_CONSTANTS.ANALYTICS.IMPRESSION_LINK_ID,
            events:  [CRUISE_TILES_CONSTANTS.ANALYTICS.IMPRESSION_EVENT]
        };

        return modelProps;
    }

    /**
     * Instantiates a map with needed properties to ensure the analytics model complies with contract
     * on the click event of Featured Cruises
     * @param ctaActions clicked cta actions model
     * @param tilesDisplayed featured tiles displayed
     * @returns Additional props to send in the trackLink event
     */
    getFeaturedCruisesAnalyticsClickEventModel(ctaActions: CruiseTileAction[], tilesDisplayed: ActionTile[]): object {
        const partyMix = this.travelPartyService.getPartySize();
        // Additional props to send in the trackLink event
        const modelProps = {
            linkId: CRUISE_TILES_CONSTANTS.ANALYTICS.CLICK_LINK_ID,
            events: [CRUISE_TILES_CONSTANTS.ANALYTICS.CLICK_EVENT],
            bookingSearchAdults: partyMix.totalAdults,
            bookingSearchChildren: partyMix.totalChildren,
            bookingSearchInfants: partyMix.totalInfants,
            promoImpressionsList: `${CRUISE_TILES_CONSTANTS.ANALYTICS.CLICK_PARTIAL_PROMO_IMPRESSIONS_LIST}`
                + `_${tilesDisplayed.length}`
                + `_${this.getSelectedTileIndex(ctaActions, tilesDisplayed)}`,
            facetsProgress: new Array<{name: string}>()
        };

        modelProps.facetsProgress = this.getCurrentFacetsProgressObjectArray();

        return modelProps;
    }

    /**
     * Retrieves facetsProgress from quick quote service (holding up to date facetProgress) formatted as necessary
     * for some analytics events according to specifications on requirements
     * @returns Facets Progress Object Array
     */
    getCurrentFacetsProgressObjectArray(): Array<{name: string}> {
        const analyticsFilters = this.analyticsServiceFromQuickQuote.applyFiltersAnalyticsTrackLink();
        const facetsProgressString = analyticsFilters.facetsProgress[0].name;

        return facetsProgressString.split(',').map((name: string) => ({ name }));
    }
}
