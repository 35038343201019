import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { EventType } from '../services/interfaces/event-type';
import { DeviceService } from '../device/device.service';
import { SIDE_MODAL_CONSTANTS } from './side-modal.constants';
import { WindowRef } from '../window-ref/window-ref.service';

@Component({
    selector: 'dcl-side-modal',
    templateUrl: './side-modal.component.html',
    styleUrls: ['./side-modal.component.scss'],
})
export class DclSideModalComponent {
    @ViewChild('sideModal', { static: false }) modal: ElementRef;
    @Output() closedOnEscape: EventEmitter<EventType> = new EventEmitter();
    @Input() closeLabel: string;
    @Input() contextPath: string;
    @Input() disableAnimation: boolean = false;
    @Input() disableCloseOnBackground = true;
    @Input() hideCloseButton: boolean;
    @Input() modalCssClass: string;
    @Output() onCloseModal: EventEmitter<EventType> = new EventEmitter();
    @Input() parentSelector: string;

    lastScroll: number;
    animationIsAdded = false;

    constructor(
        private deviceService: DeviceService,
        private windowRef: WindowRef
    ) {}

    /**
     * Open the modal
     */
     openModal(): void {
        let dclModalWrapper: HTMLElement;
        this.lastScroll = this.windowRef.nativeWindow.pageYOffset;
        this.modal.nativeElement.setAttribute('open', 'true');
        document.querySelector('html').classList.add(SIDE_MODAL_CONSTANTS.overFlowYHidden);
        dclModalWrapper = (document.querySelector('.dcl-modal-dom-wrapper div[tabindex="0"]') as HTMLElement);

        if (this.parentSelector) {
            document.querySelector(this.parentSelector).setAttribute('aria-hidden', 'true');
        }

        if (dclModalWrapper) {
            dclModalWrapper.focus();
        }
    }

    /**
     * Close the modal after clicking on the close button
     *
     * @param updateProp true to update open modal property (false by default)
     * @param emitEvent true to emit the close modal event (false by default)
     * @emits EventType (close)
     */
     closeModal(updateProp = false, emitEvent = false): void {
        this.checkScroll();

        if (emitEvent) {
            this.onCloseModal.emit(SIDE_MODAL_CONSTANTS.events.close);
        }

        if (updateProp) {
            this.modal.nativeElement.removeAttribute('open');
        }
    }

    /**
     * Informs that the modal was closed pressing the Escape key
     *
     * @emits EventType (close)
     */
    closedModalOnEscape() {
        this.checkScroll();
        this.closedOnEscape.emit(SIDE_MODAL_CONSTANTS.events.close);
    }

    /**
     * Scroll into last position if is iOS device
     */
    checkScroll(): void {
        if (this.deviceService.isIos()) {
            this.windowRef.nativeWindow.scrollTo(0, this.lastScroll);
        }

        document.querySelector('html').classList.remove(SIDE_MODAL_CONSTANTS.overFlowYHidden);

        if (this.parentSelector) {
            document.querySelector(this.parentSelector).removeAttribute('aria-hidden');
        }
    }
}
