import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreakPointChangeDetectionDirective } from './breakpoint-change-detection.directive';
import { MockBreakPointChangeDetectionDirective } from './mocks/mock-breakpoint-change-detection.directive';

@NgModule({
    imports: [
        CommonModule
     ],
    declarations: [
        BreakPointChangeDetectionDirective,
        MockBreakPointChangeDetectionDirective
    ],
    exports: [
        BreakPointChangeDetectionDirective,
        MockBreakPointChangeDetectionDirective
    ]
})

export class BreakPointChangeDetectionModule {}
