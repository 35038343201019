export const GUEST_NAME_CONSTANT = {
    INPUT_CONFIG: {
        MAX_LENGTH: 28,
        SHORT_LENGTH: 1,
        NAME_PATTERN: '[a-zA-Z\\s\\-\\\']+',
        MI_PATTERN: '^[a-zA-Z]*$',
        LABEL : {
            FULL_MIDDLE_NAME : 'full-middle-name-label',
            MI: 'mi-label'
        }
    },
};
