import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';
import { ReservationFlowService } from '../reservation-flow/reservation-flow.service';
import { ReservationIdService } from '../reservation-id/reservation-id.service';
import { ReservationSummary } from '../../reservation-summary-bar/reservation-summary.interface';
import { ReservationSummaryService } from './reservation-summary.service';
import { RESERVATION_SUMMARY_CONSTANTS } from '../../reservation-summary-bar/reservation-summary-bar.constants';

@Injectable()
export class ReservationSummaryResolver implements Resolve<ReservationSummary> {
    constructor(
        private cookieService: CookieService,
        private reservationFlowService: ReservationFlowService,
        private reservationIdService: ReservationIdService,
        private reservationSummaryService: ReservationSummaryService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ReservationSummary> {
        const alwaysCreateNew = (route.queryParams && route.queryParams.newSession);
        const reservationId = this.reservationIdService.getId();
        const swid = this.cookieService.get('SWID');
        const flow = this.reservationFlowService.getFlow()['id'];

        if (reservationId) {
            return this.reservationSummaryService.
                getReservationSummary(
                    alwaysCreateNew,
                    reservationId,
                    RESERVATION_SUMMARY_CONSTANTS.SCOPES[flow],
                    swid
                );
        } else {
            return of({});
        }
    }
}
