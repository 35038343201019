export const CONSTANTS = {
    config: {
        deviceWidth: 768,
        transitionTime: 545,
        borderItemDelay: 25,
        widthFactor: 64,
        percentBase: 100
    },
    lonelyElement: 1,
    sliderType: 2
};
