import { Component } from '@angular/core';
import { ImageTemplateType, StoryAbstractComponent } from '../shared/story-abstract.component';
import { get } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-fast-kbyg',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./fast-kbyg.component.scss']
})
export class FastKnowBeforeYouGoComponent extends StoryAbstractComponent {
    constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
        super(sanitizer, cookieService);
    }
    getTemplateBlockGroups(storyData) {
        const banner = [{
            'classes': 'full-desktop',
            'anchorName': 'fast-kbyg',
            'blocks': []
        }];

        let key = '';
        let classes: string;
        let backgroundImageTemplates: ImageTemplateType[];
        let data;

        key = 'banner';
        classes = this.getCSSSectionClasses(get(storyData, `descriptions.${key}`, false));
        data =  get(storyData, `descriptions.${key}`, {});
        backgroundImageTemplates = [
            this.getImageTemplate(
                data.media.icon1,
                'first-icon'
            ),
            this.getImageTemplate(
                data.media.icon2,
                'second-icon'
            )
        ];

        const blockToAdd = {
            'analyticsTrackSeen' : this.createAnalyticsTrackSeen(storyData, 1, data.analyticsLinkId),
            'classes': classes,
            'items': [
                {
                'type': 'link',
                'classes': '',
                'href': data.sections.cta1Link,
                'target': get(data, 'target', '_self'),
                'analyticsLinkId': this.getStoryAnalyticslinkId(storyData, 1, data.analyticsLinkId),
                itemsWrapped: {
                    background: backgroundImageTemplates,
                    foreground: []
                }
                }
            ]
        };

        const section = 'descriptions.banner';
        let itemsToAdd = this._generatePlanYourVacationBlockForeground(storyData, section);

        for (let i = 0; i < itemsToAdd.length; i++) {
            const item = itemsToAdd[i];
            let itemContent = get(storyData, item.contentKey, false);
  
            if (!itemContent) {
                continue;
            }
  
            if (item.type === 'text') {
                itemContent = this.getTextTemplate(itemContent, false, item.classes);
            } else {
                itemContent = this.getCtaTemplate(itemContent, item.text, item.classes);
            }

            blockToAdd.items[0].itemsWrapped.foreground.push(itemContent);
        }

        banner[0].blocks.push(blockToAdd);

        return banner;
    }

    private  _generatePlanYourVacationBlockForeground(storyData, section) {
        const itemsToAdd = [];
    
        if (get(storyData, section + '.sections.title', false)) {
            itemsToAdd.push({
                type: 'text',
                contentKey: section + '.sections.title',
                classes: 'title'
            });
        }
        if (get(storyData, section + '.sections.subtitle', false)) {
            itemsToAdd.push({
                type: 'text',
                contentKey: section + '.sections.subtitle',
                classes: 'subtitle'
            });
        }
        if (get(storyData, section + '.sections.cta1Text', false)) {
            itemsToAdd.push({
                type: 'cta',
                text: 1,
                contentKey: section + '.sections',
                classes: get(storyData, section + '.sections.cta1Css', false),
                href: get(storyData, section + '.sections.cta1Link', false),
            });
        }

        return itemsToAdd;
    }
}
