import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';

@Component({
    selector: 'dcl-icon-loader',
    templateUrl: './icon-loader.component.html',
    styleUrls: ['./icon-loader.component.scss']
})
export class IconLoaderComponent implements OnInit {
    fontFaceScriptUrl: string;
    fontFaceCssUrl: string;

    constructor(private configs: ConfigService) {
        const fontFaceConfig = this.configs.getValue('fontFace');

        if (fontFaceConfig) {
            this.fontFaceScriptUrl = fontFaceConfig.jsUrl;
            this.fontFaceCssUrl = fontFaceConfig.cssUrl;
        }
    }

    ngOnInit() { }
}
