export const ADVANCED_BOOKING_BANNER_CONSTANTS = {
    bannerStars: '/assets/images/advanced-booking-banner/banner_stars.svg',
    bannerSstipple: '/assets/images/advanced-booking-banner/stipple.svg',
    viewBreakpoints: {
        mobile: 767,
        desktop: 768
    },
    buttonId: 'banner-button',
    defaultStarsColor: '#F6D07D',
    buttonTag: 'BUTTON',
    ariaOpenModalBtnAction: 'earlyBookingCtaContent.sections.openModalBtnAction'
};
