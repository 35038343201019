import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { ItemChanged } from './../../../interfaces/item-changed.interface';
import { OptionItem } from '../../interfaces/option-item.interface';
import { QUICK_QUOTE_CONSTANTS } from '../../../quick-quote/quick-quote.constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dcl-option-item',
    templateUrl: './option-item.component.html',
    styleUrls: ['./option-item.component.scss']
})
export class OptionItemComponent implements OnChanges {
    @Input() option: OptionItem;
    @Input() isLastOption: boolean;
    @Input() isLastGroup: boolean;
    @Input() optionGroupLabel: string;
    @Output() onItemChanged: EventEmitter<ItemChanged> = new EventEmitter();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();

    content = {
        label: '',
        text: ''
    };
    constants = QUICK_QUOTE_CONSTANTS;

    constructor(private translate: TranslateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const optionGroupLabel = changes['optionGroupLabel'] && changes['optionGroupLabel'].currentValue;
        const option = changes['option'] && changes['option'].currentValue;

        if (optionGroupLabel && option) {
            const title = this.translate.instant(this.optionGroupLabel);
            const text = this.translate.instant(this.option.label);
            const subHeader = this.translate.instant(this.constants.translationKeys.subHeader);

            this.content.label = `${title}, ${subHeader}. ${text}`;
            this.content.text = text;
        }

        if (!optionGroupLabel && option) {
            this.content.label = this.translate.instant(this.option.label);
            this.content.text = this.translate.instant(this.option.label);
        }
    }

    /**
     * Toggles check active
     * @param  option option selected
     * @param  event
     */
    toggleCheck(option: OptionItem, event: Event): void {
        if (!option.disabled) {
            option.selected = !option.selected;
            this.onItemChanged.emit({
                id: option.id,
                isSelected: option.selected,
                urlFriendlyId: option.urlFriendlyId
            });

            event.preventDefault();
        }
    }

    /**
     * Handles tab key press event
     * @param event Keyboard event
     */
    onTabPress(event: KeyboardEvent): void {
        if (this.isLastGroup && this.isLastOption) {
            this.lastItemTabbed.emit(event);
        }
    }
}
