const CONSTANTS = {
    AVATAR: '.avatar',
    DEFAULT: '.default',
    HIDE_OPTION: 'hidden-option',
    LI: 'LI',
    LINK: '.link',
    LIST_DROPDOWN_ITEM: 'li.list-dropdown__item'
};

export default CONSTANTS;
