<footer class="syndicated-footer-component">
    <div
        class="separator top-separator"
        *ngIf="!noTopSeparator"
        [ngClass]="{'no-width': (mode === 'full')}">
    </div>

    <div class="content assistance-content" *ngIf="mode === 'legal-plus'">
        <div class="assistance">
            <span class="text-separator" translate>footerInfo.assistance</span>
            <a href="{{'footerInfo.link' | translate}}" target="_self" translate>footerInfo.number</a>
        </div>
        <div class="description">
            <div [innerHTML]="'footerInfo.description' | translate"></div>
            <div [innerHTML]="'footerInfo.restriction' | translate"></div>
        </div>
    </div>

    <div class="content no-gutter-mobile separator middle-separator" *ngIf="mode === 'legal-plus'"></div>

    <div id="{{mode === 'full' ? 'footerWrapper' : 'legalWrapper'}}"
        class="footer-wrapper"
        [@footerState]="stateName"
        (@footerState.done)="footerStateDone($any($event))">
        <dcl-script-loader [src]="syndicatedFooterUrl"></dcl-script-loader>
    </div>
</footer>
