<dcl-modal
    class="quick-quote-modal"
    [ngClass]="{'luxlite-theme': useLuxliteTheme}"
    [open]="open"
    [attr.aria-label]="getAriaLabel()"
    #modal
    fullscreen
    block-focus
    hide-close-icon
    prevent-focus-body>
    <div
        #header
        slot="title"
        class="quick-quote-modal-header"
        *ngIf="!isEmbedded || !showBaymax">
        <span class="quick-quote-modal-header__title">{{ titleLabel | translate }}</span>
    </div>
    <div #body slot="body" class="quick-quote-modal-content">
        <dcl-baymax-header
            slot="title"
            *ngIf="showBaymax && isEmbedded"
            title="quickQuote.modal.baymaxTitle"
            [isCloseBtn]="false"
            [isModal]="true"
            [parentWithScroll]="parentWithScroll"
            (iconEvent)="closeModal()">
        </dcl-baymax-header>
        <button class="quick-quote-modal-header__button quick-quote-modal-header__button--close"
            #closeButton
            [attr.aria-label]="(titleLabel | translate) + ' ' + (constants.translationKeys.modal.buttonClose | translate)"
            (click)="closeModal()"
            *ngIf="!filterSelected && !isEmbedded">
            <i class="di di-close-reversed"></i>
        </button>
        <button class="quick-quote-modal-header__button quick-quote-modal-header__button--close"
            #closeButton
            [attr.aria-label]="getAriaLabel(true)"
            [attr.aria-live]="isIOS ? 'polite' : null"
            (click)="cancelFilterSelections()"
            *ngIf="filterSelected">
            <i class="di di-previous-2"></i>
        </button>
        <button
            *ngIf="filterSelected && filterSelected.type !== constants.filters['travel-party'].id"
            class="quick-quote-modal-header__button quick-quote-modal-header__button--clear"
            [attr.aria-label]="constants.translationKeys.modal.buttonClearAria | translate"
            (click)="clearFiltersByType()"
            translate>
            {{ constants.translationKeys.modal.buttonClear }}
        </button>
        <ng-container [ngTemplateOutlet]="filterSelected ? filterSelectedContent : allFiltersContent"></ng-container>
        <button
            *ngIf="!filterSelected"
            (click)="clearAllFilters()"
            class="quick-quote-modal-content__button"
            translate>
            {{ constants.translationKeys.modal.buttonClearAll }}
        </button>
    </div>
    <div #footer slot="buttons" class="quick-quote-modal-footer">
        <wdpr-button
            *ngIf="filterSelected"
            [attr.aria-label]="buttonLabel | translate"
            [disabled]="isButtonDisabled()"
            (click)="onButtonDoneClick()"
            (keyup.enter)="onButtonDoneClick()">
            <span>{{ buttonLabel | translate }}</span>
        </wdpr-button>
        <wdpr-button
            *ngIf="!filterSelected"
            [attr.aria-label]="buttonLabel | translate"
            [disabled]="isButtonDisabled()"
            (click)="onButtonApplyClick()"
            (keyup.enter)="onButtonApplyClick()">
            <span>{{ buttonLabel | translate }}</span>
        </wdpr-button>
    </div>
</dcl-modal>

<ng-template #allFiltersContent>
    <ng-container *ngFor="let filter of filtersData">
        <ng-container *ngIf="!filter.isSubgroup">
            <div
                class="card"
                role="button"
                tabindex="0"
                [ngClass]="{ 'card-disabled': filter?.isDisabled }"
                (keyup.enter)="setFilterSelected(filter.type)"
                (click)="setFilterSelected(filter.type)">
                <div class="card-text-wrapper">
                    <span class="card-text-wrapper__filter-name" translate>{{ filter.headingKey }}</span>
                    <span class="card-text-wrapper__filter-value" *ngIf="filter.modalValue">
                        {{ filter.modalValue | translate }}
                    </span>
                </div>
                <div class="card-icon-wrapper">
                    <i class="di di-next-2"></i>
                </div>
            </div>
            <hr tabindex="-1" class="separator" aria-hidden="true">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #filterSelectedContent>
    <dcl-quick-quote-filter-wrapper
        [isDclAdvancedBooking]="isDclAdvancedBooking"
        [isDclAdvancedBookingPhase2]="isDclAdvancedBookingPhase2"
        [isDclAdvancedBookingPhase3]="isDclAdvancedBookingPhase3"
        [isDclShipAdaPhase2]="isDclShipAdaPhase2"
        (onApplyFilters)="onApplyAndClose($event)"
        [filterDataSubGroup]="filterDataSubGroup"
        [filterData]="filterSelected"
        [showClearBtn]="showClearBtn"
        [showFilterType]="showFilterType"
        (advanceBookingClickEvent)="advanceBookingClickEvent.emit()">
    </dcl-quick-quote-filter-wrapper>
</ng-template>
