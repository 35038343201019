export const COOKIES = {
    AKAMAI: {
        LANGUAGE_SELECTION: 'languageSelection_jar_aka',
        LOCALE: 'localeCookie_jar_aka'
    },
    LANGUAGE_SELECTION: 'languageSelection_jar',
    LOCALE: 'localeCookie_jar',
    SWID: 'SWID',
    DEFAULTS: {
        CONTENT: 'en_US',
        CURRENCY: 'USD',
        LANGUAGE: 'en_US',
        LOCALE_COOKIE_JAR: {
            'contentLocale': 'en_US',
            'version': '2',
            'precedence': '0',
            'akamai': 'true',
            'localeCurrency': 'USD',
            'preferredRegion': 'en-US'
        }
    },
    PATH: '/',
    EXPIRE_DAYS: 7,
};
