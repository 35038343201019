import { Component, ElementRef, EventEmitter, Input, Output, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TYPE_AHEAD_CONSTANTS } from './type-ahead.constants';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-type-ahead',
    templateUrl: 'type-ahead.component.html',
    styleUrls: ['type-ahead.component.scss']
})
export class TypeAheadComponent implements OnChanges {
    @Input() items = [];
    @Input() debounceTime = TYPE_AHEAD_CONSTANTS.defaultValues.debounceTime;
    @Input() minLength = TYPE_AHEAD_CONSTANTS.defaultValues.minLength;
    @Input() pattern?: string;
    @Input() placeholder = TYPE_AHEAD_CONSTANTS.defaultValues.placeholder;
    @Input() filter: UntypedFormControl = new UntypedFormControl('');
    @Input() useCustomFilter: boolean = false;
    @Output() searchValue = new EventEmitter();
    @ViewChild('portTextField', { static: false } as ViewChildProperties) portTextField: ElementRef;

    open = false;
    movePlaceholder = false;
    debouncer = new Subject();

    constructor() {
        this.debouncer
            .pipe(debounceTime(this.debounceTime))
            .subscribe((value) => this.searchValue.emit(value));
    }

    ngOnChanges(): void {
        if (!this.useCustomFilter) {
            this.sortItems();
        }
    }

    sortItems(): void {
        this.items = this.items ? this.items.sort((item1, item2) => item1.localeCompare(item2)) : [];
    }

    onSearchType(value: string): void {
        if (value.length < this.minLength) {
            this.items = [];
        } else {
            this.debouncer.next(value);
        }
    }

    /**
     * open the ports list
     */
    iconOpenList() {
        this.open ? this.portTextField.nativeElement.focusout() : this.portTextField.nativeElement.focus();
    }

    openList() {
        this.open = !this.open;
        this.movePlaceholder = !this.movePlaceholder;
    }

    closeList(): void {
        this.movePlaceholder = false;

        setTimeout(() => {
            this.open = false;
            if (!this.items.includes(this.filter.value)) {
                this.filter.setValue('');
            }
        }, TYPE_AHEAD_CONSTANTS.defaultValues.closeListTime);
    }

    selectItem(item): void {
        this.filter.setValue(item);
        this.onSearchType(item);
    }
}
