<div class="travel-party-container" *ngIf="staterooms.length > 0">
    <dcl-warning-messaging
        *ngIf="stateroomLimit"
        message="{{ 'travelParty.removeAdditionalStaterooms' | translate }}"
        messageType="error"
        [showMessage]="staterooms && staterooms.length > stateroomLimit.maxStaterooms">
    </dcl-warning-messaging>
    <div [ngClass]="{'tp-box': showTravelPartyBox, 'mod-flow': isModFlow}"
        class="travel-party-stateroom-container"
        *ngFor="let stateroom of staterooms; let stateroomIndex = index; first as first; last as last">
        <dcl-stateroom
            #staterooms
            [checkForMoreStaterooms]="checkForMoreStaterooms()"
            [index]="stateroomIndex"
            [isDclShipAdaPhase2]="isDclShipAdaPhase2"
            [isModFlow]="isModFlow"
            [stateroom]="stateroom"
            [showTravelPartyBox]="showTravelPartyBox"
            (removeStateroomEvent)="removeStateroom(stateroomIndex, stateroom)"
            (updateStateroomEvent)="updateStateroom(stateroom, stateroomIndex)"
            (firstItemTabbed)="onFirstItemTabbed($event, first)"
            (lastItemTabbed)="onLastItemTabbed($event, true, last)">
        </dcl-stateroom>
    </div>
    <div *ngIf="staterooms.length === maxStaterooms"
        class="travel-party-add-message-container"
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        <p class="travel-party-add-message-text block-1" innerHTML="{{ 'travelParty.moreStateroomsInfo' | translate: { url: constants.callUrl } }}"></p>
        <p class="travel-party-add-message-text block-2" translate>travelParty.ageGuardian</p>
    </div>
    <div *ngIf="!isModFlow && staterooms.length < maxStaterooms"
        class="travel-party-add-stateroom-link-container">
        <button class="add-stateroom-link btn-link"
            (click)="!stateroomLimit && addNewStateroom($event)"
            (keydown.tab)="onLastItemTabbed($any($event))"
            [attr.aria-disabled]="stateroomLimit"
            aria-describedby="stateroom-limit-message">
            <span class="di di-add-button add-button"></span>
            <div class="add-stateroom-link-content">
                <span class="add-stateroom-link-text" translate>travelParty.addStateroomButton</span>
            </div>
        </button>
    </div>
    <div *ngIf="stateroomLimit"
        id="stateroom-limit-message"
        class="travel-party-add-message-container"
        role="alert">
        <p class="travel-party-add-message-text" translate>
            {{stateroomLimit.message}}
        </p>
    </div>
</div>
