import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SanitizerService {
    constructor() {}

    /**
     * Decodes HTML entities in a given string, converting them back to their original characters.
     *
     * @param str - The string containing HTML entities to decode.
     * @returns The decoded string with HTML entities converted to their original characters.
     */
    public decodeHTML(str: string) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = str;

        return tempDiv.textContent || tempDiv.innerText || "";
    }
}
