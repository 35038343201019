import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AvatarHeaderModule } from '../avatar-header/avatar-header.module';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { LinkButtonModule } from '../link-button/link-button.module';
import { ModalDropdownComponent } from './modal-dropdown.component';

@NgModule({
    imports: [
        AvatarHeaderModule,
        BaseModalModule,
        CommonModule,
        LinkButtonModule,
        TranslateModule
    ],
    providers: [
        TranslateService
    ],
    declarations: [ModalDropdownComponent],
    exports: [
        ModalDropdownComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class ModalDropdownModule { }
