import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'dcl-link-button',
    templateUrl: 'link-button.component.html',
    styleUrls: ['link-button.component.scss']
})
export class LinkButtonComponent {
    @Output() clickEvent = new EventEmitter();
    @Input() label: string;
    @Input() disable: string;

    // '.di-print' to display the print icon
    // @see  https://raportal.disney.com/web-components-catalog/lux/mdx-icons
    @Input() icon?: string;

    onClick(): void {
        this.clickEvent.emit();
    }
}
