import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'requiredLabel'
})
export class RequiredLabelPipe implements PipeTransform {
    requiredKey: string = 'global.required-field';

    constructor(
        private translateService: TranslateService
    ) {}

    transform(text: string): string {
        let requiredTranslate;
        const html = document.querySelector('html');

        // TODO: Check why deviceService.isAndroid is not working properly in order to use it to do this validation.
        if (html.classList.contains('android')) {
            requiredTranslate = this.translateService.instant(this.requiredKey);

            if (requiredTranslate !== this.requiredKey) {
                return `${text}, ${requiredTranslate}`;
            }
        }

        return text;
    }
}
