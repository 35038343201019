<div class="quick-quote-filter-wrapper" *ngIf="filterData" [ngSwitch]="filterData?.type"
    [ngClass]="{'full-width-wrapper': filterData?.type === constants.filters['more-filters'].id}">
    <div class="wrapper-header"
        [ngClass]="{'wrapper-header-override': filterData?.type === constants.filters['more-filters'].id}">
        <div class="sr-only" aria-live="polite">
            <span *ngIf="!isMobileAriaActive && announceAriaLabel">
                {{ getAriaLabel() }}
            </span>
        </div>
        <div class="spaced-block">
            <div class="title-container">
                <span *ngIf="showFilterType" aria-live="polite" class="title">
                    {{ constants.filters[filterData.type].nameKey | translate | uppercase }}
                </span>
                <h2 class="subtitle" [attr.aria-hidden]="isMobileAriaActive" *ngIf="showSubtitle">
                    {{ constants.filters[filterData.type].wrapperLabel | translate }}
                </h2>
            </div>
            <button
                class="clear-filters"
                *ngIf="showClearBtn"
                (click)="clearFiltersByType()"
                (keydown)="focusPrevTab($event)">
                {{ constants.translationKeys.wrapper.buttonClearFilters | translate }}
            </button>
        </div>
    </div>
    <ng-container *ngSwitchCase="constants.filters['date'].id">
        <dcl-grid-picker
            [ariaLabelKey]="constants.translationKeys.gridPicker"
            [gridPickerData]="$any(filterData.data)"
            [earlyBookingData]="filterData.isEarlyBooking"
            (onChangedItem)="onFiltersChanged($event, filterData.type)"
            (lastItemTabbed)="focusNextTab($event)">
        </dcl-grid-picker>
    </ng-container>
    <ng-container *ngSwitchCase="constants.filters['destination'].id">
        <dcl-images-picker
            [customImgPlaceholder]="imagePlaceholder"
            [ariaLabelKey]="constants.translationKeys.imagesPicker"
            [imagesPickerData]="$any(filterData.data)"
            (onChangedImage)="onFiltersChanged($event, filterData.type)"
            [checkedIcon]="checkmarkIconUrl">
        </dcl-images-picker>
        <div class="subgroup" *ngIf="filterDataSubGroup">
            <div class="wrapper-header spaced-block">
                <div class="title-container">
                    <span *ngIf="showFilterType" class="title">{{ subgroupTitle | uppercase }}</span>
                    <h2 *ngIf="showSubtitle" class="subtitle" [attr.aria-hidden]="isMobileAriaActive">
                        {{ subgroupSubtitle }}
                    </h2>
                </div>
            </div>
            <dcl-images-picker
                class="groups-filters-items"
                [customImgPlaceholder]="imagePlaceholder"
                [ariaLabelKey]="constants.translationKeys.imagesPicker"
                [groupAriaLabel]="groupAriaLabel"
                [imagesPickerData]="$any(filterDataSubGroup.data)"
                (onChangedImage)="onFiltersChanged($event, filterDataSubGroup.type)"
                [checkedIcon]="checkmarkIconUrl"
                (lastItemTabbed)="focusNextTab($event)">
            </dcl-images-picker>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="constants.filters['city'].id">
        <dcl-images-picker
            [customImgPlaceholder]="imagePlaceholder"
            [ariaLabelKey]="constants.translationKeys.imagesPicker"
            [imagesPickerData]="$any(filterData.data)"
            [checkedIcon]="checkmarkIconUrl"
            (onChangedImage)="onFiltersChanged($event, filterData.type)"
            (lastItemTabbed)="focusNextTab($event)">
        </dcl-images-picker>
    </ng-container>
    <ng-container *ngSwitchCase="constants.filters['travel-party'].id">
        <dcl-travel-party
            [isDclShipAdaPhase2]="isDclShipAdaPhase2"
            (analyticsStateroomEvent)="setAnalyticsModel(null, constants.filters['travel-party'].id, $any($event))"
            (onTravelPartyCountChange)="onTravelPartyCountChange($event)"
            (lastItemTabbed)="focusNextTab($event)"
            (firstItemTabbed)="focusPrevTab($event)">
        </dcl-travel-party>
    </ng-container>
    <ng-container *ngSwitchCase="constants.filters['more-filters'].id">
        <dcl-options-groups
            *ngIf="filterData && filterData.data"
            [optionsGroups]="$any(filterData.data)"
            (onChangedItem)="onFiltersChanged($event, filterData.type)"
            (lastItemTabbed)="!isNewItinerariesBannerAvailable && focusNextTab($event)">
        </dcl-options-groups>
        <dcl-advanced-booking-banner
            *ngIf="isNewItinerariesBannerAvailable"
            class="new-sailings-banner"
            [actionText]="'advancedBookingBanner.quickQuote.linkText' | translate"
            [altLogo]="'advancedBookingBanner.quickQuote.altLogo' | translate"
            [bannerTitle]="'advancedBookingBanner.quickQuote.bannerTitle' | translate"
            [contextPath]="contextPath"
            [imageLogo]="'advancedBookingBanner.quickQuote.imageLogo' | translate"
            [firstGradientColor]="'advancedBookingBanner.quickQuote.firstGradientColor' | translate"
            [secondGradientColor]="'advancedBookingBanner.quickQuote.secondGradientColor' | translate"
            (handleTabEvent)="focusNextTab($event)"
            (onClickEventBanner)="onNewSailingClick()">
        </dcl-advanced-booking-banner>
    </ng-container>
</div>
