import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgModule } from '@angular/core';

import { CartService } from './cart.service';
import { CartRestService } from './rest/cart-rest.service';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        CookieService,
        CartService,
        CartRestService
    ]
})
export class CartModule { }
