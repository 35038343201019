import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GridConfig } from '../../interfaces/grid-config.interface';
import { GridContent } from '../../interfaces/grid-content.interface';
import { GRIDPICKER_CONSTANTS } from '../../grid-picker.constants';
import { GridContentStates } from '../../interfaces/grid-content-states.interface';
import { ItemChanged } from '../../../interfaces/item-changed.interface';

@Component({
    selector: 'dcl-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
    @Input() ariaLabelKey: string;
    @Input() gridConfig: GridConfig;
    @Input() isLastGrid: boolean;
    @Output() onItemChanged: EventEmitter<ItemChanged> = new EventEmitter();
    @Output() lastItemTabbed = new EventEmitter<KeyboardEvent>();

    itemStates: GridContentStates;
    statesKey = GRIDPICKER_CONSTANTS.statesKey;

    constructor(
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.itemStates = this.translateService.instant(this.statesKey);
    }

    /**
     * This function will verify that the item isn't disabled and then mark is as selected or not
     * @param item
     * @param index
     */
    onChangeItem(item: GridContent, event: Event): void {
        event.preventDefault();

        if (!item.isDisabled) {
            item.isSelected = !item.isSelected;
            this.onItemChanged.emit({
                id: item.id,
                isSelected: item.isSelected,
                urlFriendlyId: item.urlFriendlyId
            });
        }
    }

    /**
     * Set the aria label for each item in the list
     * @param  content value for aria-label
     * @returns string value with correct copy content
     */
    getGridA11yContent(contentAriaLabel: string): string {
        return `${this.gridConfig.label} ${contentAriaLabel}`;
    }

    /**
     * Check different states of GridContent obj
     * in order to returns the correct a11y copy state to be read
     * @param item GridContent obj will be checked
     * @returns string value with the correct copy state
     */
    getGridState(item: GridContent): string {
        let state = '';

        state += item.isEarlyBooking ? `, ${this.itemStates['earlyBooking']}` : '';
        state += item.isDisabled ? `, ${this.itemStates['unavailable']}` : '';

        return state;
    }

    /**
     * Handles tab key press event
     * @param event Keyboard event
     * @param isLastItem boolean to know if item tabbed was the last
     */
    onTabPress(event: KeyboardEvent, isLastItem: boolean): void {
        if (this.isLastGrid && isLastItem) {
            this.lastItemTabbed.emit(event);
        }
    }
}
