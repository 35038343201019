const CASTAWAY_MEMBERSHIP_CONSTANTS = {
    FORM_CONTROLS: {
        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        DATE_OF_BIRTH: 'dateOfBirth',
        CASTAWAY_ID: 'castawayId',
        ID_TYPE: 'idType',
        ID: 'id'
    },
    ENDPOINTS: {
        GUEST_CASTAWAY_INFO: 'cruise-booking-service/guest-castaway-info/',
        GUEST_CASTAWAY_INFO_EBS: 'marketing-offers/earlyBooking/validateGuest/',
        GUEST_RANKING_INFO_EBS: 'earlyBooking/ccid/{ccid}/'
    },
    ERRORS: {
        NOT_MATCH: 'travelAgent.castawayMembershipModal.userNotMatch',
        SERVER_ERROR_CODE: 500,
        WARNING_MSG_TYPE: 'error',
        NO_DATA: 'No castaway membership data'
    },
    DATE_OF_BIRTH_REGEXP: /(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/(?:[0-9]{4})/,
    NUMERIC_REGEXP: /^[0-9]+$/,
    CASTAWAY_ID_LENGTH: 8,
    TAB_KEY: 'tab',
    ENTER_KEY: 'enter',
    CASTAWAY_NUMBER_LABEL: 'travelAgent.castawayMembershipModal.inputCastawayNumber',
    CASTAWAY_NUMBER_ERROR: 'travelAgent.castawayMembershipModal.requiredCastawayNumber',
    CASTAWAY_OPTIONS: {
        OPTION_KEY: 'travelAgent.modalCastawayOptions',
        PLACE_HOLDER_KEY: 'travelAgent.modalCastawayIdPlaceHolder.',
        ERROR_KEY: 'travelAgent.modalCastawayIdErrors.',
        ID_TYPES: {
            castawayClub: 'castawayClub',
            disneyVacationClub: 'DVC',
            club33: 'club33',
            goldenOak: 'GOLDEN OAK'
        }
    },
    ANALYTICS: {
        PREFIX: 'DCL_AgentCruiseListing_EarlyBooking_Modal_',
        SELECTION: 'Selection_',
        CLICK: 'ViewCruises_',
        LAUNCH: 'Launch',
        DVC_OPTION: 'disneyVacationClub',
        LAUNCH_TRACKLINK_DELAY: 500
    },
    FOCUS_INPUT_TIME: 100,
    BRANDS_WITHOUT_ID_NUMBER: [
        'disneyVacationClub',
        'club33',
        'goldenOak'
    ]
};

export { CASTAWAY_MEMBERSHIP_CONSTANTS };
