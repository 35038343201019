import { Directive, ElementRef, HostListener } from '@angular/core';
import { AUTOFOCUS_FIRST_INVALID_CONSTANTS } from './autofocus-first-invalid.constants';

@Directive({
    selector: '[dclAutofocusFirstInvalid]'
})
export class AutofocusFirstInvalidDirective {

    constants = AUTOFOCUS_FIRST_INVALID_CONSTANTS;

    constructor(private el: ElementRef) { }

    @HostListener('ngSubmit')
    onFormSubmit() {
        const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

        if (invalidControl) {
            switch (invalidControl.localName) {
                case this.constants.ELEMENT_TYPE.RADIO_BUTTON:
                    invalidControl.shadowRoot.querySelector('wdpr-radio-button').focus();
                    break;
                case this.constants.ELEMENT_TYPE.INPUT:
                    invalidControl.focus();
                    break;
            }
        }
    }
}
